/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { POSTALCODE_PATTERN } from "../../../utils/constant";
// import "./dealer.css";
const Dealer = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    if (typeof cloudinaryRef.current !== "undefined") {
      widgetRef.current = cloudinaryRef.current.createUploadWidget(
        {
          cloudName: "dvhp99frp",
          uploadPreset: "oz0okrve",
        },
        function (error, result) {
          console.log(result);
          if (result.event === "success") {
            setInputData((prev) => ({
              ...prev,
              profileImage: result.info.secure_url,
            }));
          }
        }
      );
    }
  }, []);

  const [dealerImageToShow, setDealerImageToShow] = useState("");
  const [dealerUpdateCounter, setDealerUpdateCounter] = useState(0);
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const [inputData, setInputData] = useState({
    email: "",
    address: "",
    city: "",
    name: "",
    // phone: "",
    postalCode: "",
    profileImage: "",
    region: "",
    state: "",
    faxNumber: "",
    phone: "+1",
  });

  useEffect(() => {
    fetch(`${apiUrl}/dealer/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setInputData({
          email: data.contactEmail,
          address: data.address,
          city: data.city,
          name: data.name,
          phone: data.phone,
          postalCode: data.postalCode,

          region: data.region,
          state: data.state,
          faxNumber: data.faxNumber,
        });

        setDealerImageToShow(data.profileImage);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  }, [dealerUpdateCounter]);

  const submitDealerBasicInfo = (event) => {
    event.preventDefault();

    // if (inputData == "") {
    //   setDealerImageError(1);
    // } else {
    //   setDealerImageError(0);
    // }
    // {
    //   console.log(inputData, "inputData==============");
    // }
    // {
    //   inputData != "" &&
    fetch(`${apiUrl}/dealer/update-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },

      body: JSON.stringify({
        address: inputData.address,
        city: inputData.city,
        name: inputData.name,
        phone: inputData.phone,
        postalCode: inputData.postalCode,
        profileImage: inputData.profileImage,
        region: inputData.region,
        state: inputData.state,
        faxNumber: inputData.faxNumber,
        dealerRandomId: inputData.dealerRandomId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        if (data.hasOwnProperty("address")) {
          setDealerUpdateCounter(dealerUpdateCounter + 1);
        }
        console.log(data);

        // if (data) {

        toast.success("Changes Saved Sucessfully");
        // }
      })

      .catch((error) => {
        toast.error("Failed to save changes. Please try again.");
        console.error("Error:", error);
      });
    // }
  };
  const [isValidPostalCode, setIsValidPostalCode] = useState(false);
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "postalCode") {
      if (POSTALCODE_PATTERN.test(value)) {
        setIsValidPostalCode(true);
      } else {
        setIsValidPostalCode(false);
      }
    }
    // const formattedValue = value.startsWith('+1') ? value : `+1 ${value}`;
    // if (name === 'phone') {
    //   handlePhoneNumberChange(value);
    // }
    setInputData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;
  function initAutocomplete() {
    console.log("test");
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");

    // Check if the fields are found before proceeding
    if (address1Field) {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      address1Field.focus();

      autocomplete.addListener("place_changed", fillInAddress);
    } else {
      console.error("One or more required fields not found in the DOM.");
    }
  }

  function fillInAddress() {
    // if (!address1Field || !address2Field || !postalField) {
    //   console.error("One or more required fields not found in the DOM.");
    //   return;
    // }

    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = `${component.long_name} ${address1}`;
          console.log(address1);
          break;
        case "route":
          address1 += ` ${component.short_name}`;
          console.log(address1);
          setInputData((prev) => ({
            ...prev,
            address: address1,
          }));
          break;
        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          setInputData((prev) => ({
            ...prev,
            postalCode: postcode,
          }));
          console.log(postcode);
          break;
        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          console.log(postcode);
          break;
        case "locality":
          const localityElement = document.querySelector("#locality");
          if (localityElement) {
            localityElement.value = component.long_name;
            console.log(localityElement.value);
            console.log(component);
            setInputData((prev) => ({
              ...prev,
              city: localityElement.value,
            }));
          }
          break;
        case "administrative_area_level_1":
          const stateElement = document.querySelector("#state");
          if (stateElement) {
            stateElement.value = component.short_name;
            console.log(stateElement.value);
            console.log(component);
            setInputData((prev) => ({
              ...prev,
              state: component.long_name,
            }));
          }
          break;
        case "country":
          const countryElement = document.querySelector("#country");
          if (countryElement) {
            countryElement.value = component.long_name;
            console.log(countryElement.value);
          }
          break;
        default:
          break;
      }
    }
    if (address1Field) {
      address1Field.value = address1;
    }
    if (postalField) {
      postalField.value = postcode;
    }
    if (address2Field) {
      address2Field.focus();
    }
  }

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handlePhoneNumberChange = (value) => {
    const isValidPhoneNumber =
      /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value);
    setIsValid(isValidPhoneNumber);
  };

  return (
    <div>
      {/* <div className="tab-content">
      </div> */}

      {/* <div className="tab-pane" id="dealer"> */}
      <div className="ltn__myaccount-tab-content-inner ">
        <div className="ltn__form-box">
          <form action="submit" onSubmit={submitDealerBasicInfo}>
            <div className="row px-4 py-5 mb-50">
              <h3 className="mb-4">Dealer Information</h3>
              <div className="col-md-12 mb-4">
                <img
                  className="mb-4"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                  src={
                    dealerImageToShow
                      ? dealerImageToShow
                      : "/assets/img/avatar.png"
                  }
                  alt="picture of dealer"
                  class="img-thumbnail"
                />
              </div>
              <div className="col-md-4">
                <label>Email Address:</label>
                <input
                  // value={inputData.dealerEmail ? inputData.dealerEmail : ""}
                  style={{
                    paddingRight: "20px",
                    backgroundColor: "lightgrey",
                  }}
                  value={inputData.email}
                  type="email"
                  name="ltn__lastname"
                  disabled
                />
              </div>
              <div className="col-md-4">
                <label>
                  {" "}
                  Name
                  <span className="required-class">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  // value={inputData.dealerName ? inputData.dealerName : ""}
                  value={inputData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label>
                  Phone
                  <span className="required-class">*</span>
                </label>
                <input
                  className="text no-spin-buttons"
                  type="tel"
                  name="phone"
                  maxLength={13}
                  // value={inputData.dealerPhone ? inputData.dealerPhone : ""}
                  value={inputData.phone}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const formattedValue = value.startsWith("+1")
                      ? value
                      : `+1 ${value}`;

                    // If the input field is for phone, validate the phone number
                    if (name === "phone") {
                      handlePhoneNumberChange(formattedValue);

                      // Additional pattern test for Canadian phone number (for example purposes)
                      const phonePattern =
                        /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                      const isValidPhoneNumber =
                        phonePattern.test(formattedValue);

                      // Update the state for phone number validation
                      setIsValidPhoneNumber(isValidPhoneNumber);
                    }

                    setInputData((prev) => ({
                      ...prev,
                      [name]: formattedValue,
                    }));
                  }}
                  required
                />
                {inputData.phone?.length === 13 && (
                  <p
                    className=""
                    style={{
                      marginTop: "-20px",
                      color: isValidPhoneNumber ? "green" : "red",
                    }}
                  >
                    {
                      isValidPhoneNumber ? "" : <></>
                      // "Invalid input: please enter a valid Canadian phone number"
                    }
                  </p>
                )}
              </div>
              <div className="col-md-4">
                <label>
                  Address
                  <span className="required-class">*</span>
                </label>

                <input
                  type="text"
                  id="ship-address"
                  name="address"
                  // value={
                  //   inputData.dealerAddress ? inputData.dealerAddress : ""
                  // }
                  value={inputData.address}
                  onChange={(e) => {
                    handleInputChange(e);
                    initAutocomplete();
                  }}
                  required
                />
              </div>
              <div className="col-md-4">
                <label>
                  City
                  <span className="required-class">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  id="locality"
                  // value={inputData.dealerCity ? inputData.dealerCity : ""}
                  value={inputData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="col-md-4">
                <label>
                  Fax Number
                  {/* <span className="required-class">*</span> */}
                </label>
                <input
                  className="text no-spin-buttons"
                  type="text"
                  name="faxNumber"
                  value={inputData.faxNumber}
                  // value={
                  //   inputData.dealerFaxNumber
                  //     ? inputData.dealerFaxNumber
                  //     : ""
                  // }
                  onChange={handleInputChange}
                  required={false}
                />
              </div>
              <div className="col-md-4 ">
                <label>
                  Postal Code
                  <span className="required-class">*</span>
                </label>
                <input
                  className="text no-spin-buttons"
                  type="text"
                  id="postcode"
                  name="postalCode"
                  // value={
                  //   inputData.dealerPostalCode
                  //     ? inputData.dealerPostalCode
                  //     : ""
                  // }
                  maxLength={6}
                  value={inputData.postalCode?.toUpperCase()}
                  onChange={handleInputChange}
                  required
                />
                {inputData.postalCode?.length === 6 && (
                  <p
                    className=""
                    style={{
                      marginTop: "-20px",
                      color: isValidPostalCode ? "green" : "red",
                    }}
                  >
                    {
                      isValidPostalCode ? "Valid" : <></>
                      // "Invalid "
                    }
                    {/* : "Invalid input: please use a 6 characters sequence following the pattern A1A1A1"} */}
                  </p>
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Region
                  <span className="required-class">*</span>
                </label>
                <input
                  type="text"
                  name="region"
                  // value={
                  //   inputData.dealerRegion ? inputData.dealerRegion : ""
                  // }
                  value={inputData.region}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label>
                  Province
                  <span className="required-class">*</span>
                </label>
                <div className="input-item">
                  <select
                    className="nice-select"
                    // value={
                    //   inputData.dealerState ? inputData.dealerState : ""
                    // }
                    id="state"
                    value={inputData.state}
                    name="state"
                    onChange={handleInputChange}
                    required
                  >
                    <option disabled selected>
                      Select Province
                    </option>
                    <option>Alberta</option>
                    <option>British Columbia</option>
                    <option>Manitoba</option>
                    <option>New Brunswick</option>
                    <option>Newfoundland and Labrador</option>
                    <option>Nova Scotia</option>
                    <option>Ontario</option>
                    <option>Prince Edward Island</option>
                    <option>Quebec</option>
                    <option>Saskatchewan</option>
                  </select>
                </div>
              </div>
              {/* Customer# */}
              <div className="col-md-4">
                <label>Dealer # </label>
                <input
                  className="text no-spin-buttons"
                  type="text"
                  name="dealerRandomId"
                  // value={vehical.year || vehicleVin?.year}
                  value={
                    inputData.dealerRandomId ? inputData.dealerRandomId : ""
                  }
                  onChange={handleInputChange}
                  // required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  Image
                  {/* <span className="required-class">*</span> */}
                </label>
                <div className="btn-wrapper">
                  <button
                    type="button"
                    onClick={() => widgetRef.current.open()}
                    className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                  >
                    Upload
                  </button>
                </div>
                {/* {dealerImageError == 1 && (
                  <p style={{ color: "red" }}>Image needs to be uploaded</p>
                )} */}
              </div>
              <div className="btn-wrapper mt-5">
                <button
                  style={{ backgroundColor: "#000000" }}
                  type="submit"
                  className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                >
                  Save Changes
                </button>
                {/* {DealerSuccess === 1 && (
                  <p style={{ color: "green" }}>
                    Information Successfully Updated
                  </p>
                )} */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Dealer;
