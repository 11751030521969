import axios from "axios";
import React, { useEffect } from "react";
import { FaTimes, FaSearch } from "react-icons/fa";

const RequestManager = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {


    axios.get(`${apiUrl}/dealer/material`)
      .then((response) => {

        console.log('Response:', response.data);
      })
      .catch((error) => {

        console.error('Error:', error);
      });
  }, []);
  return (
    <div>
      <div className="tab-pane p-4" id="">
        <div>
          <h1 style={{ fontWeight: "bolder" }}>Search Material Requests</h1>
          <form>
            <div className="row">
              <div className="col-md-4 p-1">
                <label>Start Date</label>
                <input type="text" name="ltn__name" />
              </div>
              <div className="col-md-4 p-1">
                <label>End Date</label>
                <input type="text" name="ltn__name" />
              </div>
              <div className="col-md-4 mt-1">
                <button
                  className=""
                  style={{
                    marginTop: "33px",
                    padding: "10px 20px",
                    backgroundColor: "#4A6543",
                    color: "#FFF",
                    fontSize: "18px",
                  }}
                >
                  Clear Dates
                </button>
              </div>
            </div>

            <div className="button-divider">
              <button
                className=""
                style={{
                  marginTop: "2em",
                  padding: "10px 20px",
                  backgroundColor: "#4A6543",
                  color: "#FFF",
                  fontSize: "18px",
                }}
              >
                <FaSearch style={{ marginRight: "10px" }} />
                Search
              </button>
              <button
                className=""
                style={{
                  marginTop: "2em",
                  padding: "10px 20px",
                  backgroundColor: "#4A6543",
                  color: "#FFF",
                  fontSize: "18px",
                }}
              >
                <FaTimes style={{ marginRight: "10px" }} />
                Clear
              </button>
            </div>
          </form>
          <div style={{ marginTop: "60px" }}></div>
          <h3>MATERIAL REQUESTS</h3>
          <div className="mobileTable">

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Request</th>
                  <th scope="col">Request Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mark</td>
                  <td>Otto</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestManager;
