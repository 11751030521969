import React, { useState } from "react";
import CustomButton from "../../common/Button/CustomButton";
import { Badge } from "react-bootstrap";
import {
  capitalizeAllFirstLetters,
  parseBooleanVal,
} from "../../../utils/utils";
import { apiPostRequest } from "../../../helpers/Requests";
import { URL_SUPER_ADMIN_CHANGE_ADMIN_VERIFY } from "../../../service/URL";
import { toast } from "react-toastify";
import parseError from "../../../utils/ErrorParse";

const ItemAdmin = ({ item, setSelectedShowAdmin, handleDataUpdateById }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeVerify = async () => {
    try {
      setIsLoading(true);
      const response = await apiPostRequest(
        URL_SUPER_ADMIN_CHANGE_ADMIN_VERIFY,
        {
          id: item._id,
          isVerified: !item.isVerified,
        }
      );
      if (response.data) {
        toast.success(
          `User ${
            !item.isVerified === false ? "Unauthorized" : "Authorized"
          } Successfully!`
        );
        handleDataUpdateById(response.data.admin);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorMessage = parseError(err);
      toast.error(errorMessage ?? "Something went wrong!");
      console.log("err", err);
    }
  };

  return (
    <tr>
      <td>{capitalizeAllFirstLetters(item?.userName || "-") ?? "-"}</td>
      <td>{item?.email ?? "-"}</td>
      <td>
        {item?.isVerified ? (
          <Badge pill className="status-badge complete-badge">
            Active
          </Badge>
        ) : (
          <Badge pill className="status-badge rejected-badge">
            Inactive
          </Badge>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-start align-items-center">
          <CustomButton
            isDisabled={isLoading}
            showLoading={isLoading}
            label={
              parseBooleanVal(item?.isVerified) === false
                ? "Unauthorized"
                : "Authorized"
            }
            cssClass={
              parseBooleanVal(item?.isVerified) === false
                ? "btn-effect-5 btn1"
                : "btn-effect-1 btn1"
            }
            variant={
              parseBooleanVal(item?.isVerified) === false ? "danger" : "primary"
            }
            clickHandler={handleChangeVerify}
          />

          {/* <CustomButton
            label="View"
            type="button"
            cssClass="btn-effect-1 text-uppercase"
            clickHandler={() => {
              setSelectedShowAdmin(item);
            }}
          /> */}
        </div>
      </td>
    </tr>
  );
};

export default ItemAdmin;
