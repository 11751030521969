import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import OfferInitialTerm from "./OfferInitialTerm";
import OfferAddOnService from "./OfferAddOnService";
import { classNames } from "../../../../../utils/utils";
import { NumericFormat } from "react-number-format";
import { isEmpty } from "lodash";

const OfferItem = (props) => {
  const {
    serviceWithCatProgram,
    isPricingVisible,
    serviceTenureOptions,
    checkBoxesForTerms,
    checkBoxesForServices,
    handleUnCheckedOtherTerms,
    handleCheckedServicesByTenure,
    serviceTierOptions,
    vehical,
    handleInputServicesCatDetails,
  } = props;

  //Tire size
  const sizes = Array.from({ length: 24 }, (_, index) => index + 1);

  const isCategoryTermExist = () => {
    const relevantKeys = Object.keys(checkBoxesForTerms || {})?.filter((key) =>
      key.startsWith(serviceWithCatProgram._id + "-")
    );
    const allFalse = relevantKeys.every(
      (key) => checkBoxesForTerms[key] === false
    );

    return !allFalse;
  };

  const groupByServiceTenure = (items) => {
    return items.reduce((accumulator, currentItem) => {
      const key = currentItem.serviceTenure;
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentItem);
      return accumulator;
    }, {});
  };

  const serviceWithCatProgramToMap = useMemo(() => {
    if (serviceWithCatProgram?.categoryName.includes("Trust Shield")) {
      const data = groupByServiceTenure(
        serviceWithCatProgram.programs[0].prices
      );
      const firstObjects = Object.keys(data).map((key) => data[key][0]);
      return firstObjects.sort((a, b) => {
        const durationA = parseInt(
          serviceTenureOptions[a.serviceTenure]?.tenureDuration?.split(" ")[0],
          10
        );
        const durationB = parseInt(
          serviceTenureOptions[b.serviceTenure]?.tenureDuration?.split(" ")[0],
          10
        );

        return durationA - durationB;
      });
    }

    const arrayData =
      (serviceWithCatProgram.programs &&
        serviceWithCatProgram.programs.length > 0 &&
        serviceWithCatProgram.programs[0].prices) ||
      [];
    return arrayData.sort((a, b) => {
      const tenureA =
        serviceTenureOptions[a.serviceTenure]?.tenureDuration || "0 months";
      const tenureB =
        serviceTenureOptions[b.serviceTenure]?.tenureDuration || "0 months";
      const durationA = parseInt(tenureA.split(" ")[0], 10);
      const durationB = parseInt(tenureB.split(" ")[0], 10);
      return durationA - durationB;
    });
  }, [serviceWithCatProgram]);

  const getPriceObjByTenure = (prices) => {
    const data = Object.keys(checkBoxesForTerms).reduce((acc, key) => {
      if (!checkBoxesForTerms[key]) {
        return acc;
      }
      const [firstPart, secondPart] = key.split("-");
      if (!acc[firstPart]) {
        acc[firstPart] = {};
      }
      acc[firstPart]["tenureId"] = secondPart;
      return acc;
    }, {});
    if (serviceWithCatProgram?.categoryName.includes("Trust Shield")) {
      const tierPricesByTenure = groupByServiceTenure(prices);
      const tenureTierKey = data[serviceWithCatProgram._id].tenureId;
      return tierPricesByTenure?.[tenureTierKey];
    }
    if (data) {
      return prices.find(
        (x) => x.serviceTenure === data[serviceWithCatProgram._id].tenureId
      );
    }

    return null;
  };

  return (
    <div className="services-table">
      <div className="services-top">
        <div className="row">
          {/* Tire & Rim */}
          {serviceWithCatProgram?._id === "65de06875a4edc7aca3a08b3" && (
            <div className="col-md-4 ">
              <label className="bg-transparent mb-2 mt-2">Tire Size:</label>
              <Form.Select
                value={serviceWithCatProgram.tiresize}
                class="form-select form-select-option"
                id={`tireSize`}
                name={`tireSize`}
                // value={checkboxState[keyName]?.size || ""}
                style={{ height: "50px" }}
                aria-label="Default select example"
                onChange={(e) => {
                  handleInputServicesCatDetails(e, serviceWithCatProgram._id);
                }}
              >
                <option value={0}>Select Size</option>
                {sizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}

          {/* Anti Theft */}
          {serviceWithCatProgram?._id === "65de06875a4edc7aca3a08b2" && (
            <div className="col-md-4 px-sm-none">
              <label className="mb-2 bg-transparent mt-2">
                Anti-Theft ID #
              </label>
              <input
                value={serviceWithCatProgram.antitheft}
                id={`antitheft`}
                className="text"
                type="text"
                name={`antitheft`}
                onChange={(e) => {
                  handleInputServicesCatDetails(e, serviceWithCatProgram._id);
                }}
              />
            </div>
          )}

          <div className="col-md-4 px-sm-none">
            <label className="mb-2 bg-transparent mt-2">Agreement Date:</label>
            <input
              id={"agreementdate"}
              className="text"
              type="date"
              name="purchaseDate"
              placeholder="Todays Date"
              value={serviceWithCatProgram.purchaseDate}
              min={new Date().toISOString().split("T")[0]}
              onChange={(e) => {
                handleInputServicesCatDetails(e, serviceWithCatProgram._id);
              }}
              // required
            />
            {/* {error.purchaseDate && (
                                          <p className="error-msg">PurchaseDate is required</p>
                                        )} */}
          </div>
          <div className="col-md-4 px-sm-none">
            <label className="mb-2 bg-transparent mt-2">
              Contract Retail Selling Price:
            </label>
            <NumericFormat
              allowNegative={false}
              placeholder="Retail Price"
              id="numericInput"
              min={0}
              name={"retailerPrice"}
              defaultValue={0}
              //   type="text"
              maxLength={"13"}
              className={classNames(
                "text numericInput"
                // validationErrors?.price && "error-number-border"
              )}
              value={serviceWithCatProgram.retailerPrice}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              valueIsNumericString={true}
              decimalScale={2}
              prefix={"$"}
              allowLeadingZeros={false}
              onChange={(e) => {
                if (isEmpty(e.target.value)) {
                  handleInputServicesCatDetails(
                    {
                      target: {
                        name: "retailerPrice",
                        value: 0,
                      },
                    },
                    serviceWithCatProgram._id
                  );
                }
              }}
              onValueChange={(e) => {
                const { value } = e;
                if (isEmpty(value)) {
                } else {
                  handleInputServicesCatDetails(
                    {
                      target: {
                        name: "retailerPrice",
                        value: Number(value) > 0 ? value : 0,
                      },
                    },
                    serviceWithCatProgram._id
                  );
                }
              }}
            />
            {/* <input
              id={`retailerPrice`}
              type="number"
              name={"retailerPrice"}
              value={serviceWithCatProgram.retailerPrice}
              onChange={(e) => {
                handleInputServicesCatDetails(e, serviceWithCatProgram._id);
              }}
              // required
            /> */}
            {/* {error.firstName && (
                      <p className="error-msg">First Name is required</p>
                    )} */}
          </div>
        </div>
      </div>
      <div className="services-bottom">
        <div className="mainoffersection">
          <label className="Sercive-1 p-2 mb-4 d-flex align-items-center justify-content-between ">
            <span style={{ fontWeight: "700" }}>INITIAL TERM:</span>
          </label>

          {serviceWithCatProgramToMap
            // ?.sort((a, b) => {
            //   const durationA = parseInt(
            //     serviceTenureOptions[a.serviceTenure]?.split(" ")[0],
            //     10
            //   );
            //   const durationB = parseInt(
            //     serviceTenureOptions[b.serviceTenure]?.split(" ")[0],
            //     10
            //   );

            //   return durationA - durationB;
            // })
            .map((item, j) => (
              <OfferInitialTerm
                serviceCategoryObj={{
                  categoryId: serviceWithCatProgram._id,
                  categoryName: serviceWithCatProgram.categoryName,
                }}
                tenure={serviceTenureOptions[item.serviceTenure]}
                checkBoxesForTerms={checkBoxesForTerms}
                handleUnCheckedOtherTerms={handleUnCheckedOtherTerms}
                key={j}
                index={j}
              />
            ))}
        </div>
        <div className="servicesSection">
          <label className="Sercive-1 p-2 mb-4 d-flex align-items-center justify-content-between ">
            <span style={{ fontWeight: "700" }}>ADD-ON SERVICES</span>
          </label>

          {serviceWithCatProgram.categoryName.includes("Trust Shield") &&
          (vehical?.make === "" || vehical?.make === null) ? (
            <span className="mt-1">
              <strong>Please Decode Vehicle!</strong>
            </span>
          ) : (
            isCategoryTermExist() &&
            serviceWithCatProgram.programs
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((program, index) => (
                <OfferAddOnService
                  serviceTierOptions={serviceTierOptions}
                  vehical={vehical}
                  keyName={serviceWithCatProgram.categoryName}
                  key={index}
                  checkBoxesForServices={checkBoxesForServices}
                  priceObj={getPriceObjByTenure(program.prices)}
                  serviceCategoryObj={{
                    categoryId: serviceWithCatProgram._id,
                    categoryName: serviceWithCatProgram.categoryName,
                  }}
                  item={program}
                  index={index}
                  handleCheckedServicesByTenure={handleCheckedServicesByTenure}
                  isPricingVisible={isPricingVisible}
                />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferItem;
