import React from 'react';
import { FaYoutube, FaFilePdf } from 'react-icons/fa';

const SmartCares = () => {
  const handleDownload = (pdfUrl) => {
    const newTab = window.open(pdfUrl, '_blank');
  };

  return (
    <div>
      <div className='tab-pane p-4' id='smartcare'>
        <h3 className='product-resources-headings' style={{ color: '#ea7957' }}>
          {/* <FaYoutube /> */}
          Trust <span>Care</span>
        </h3>
        <div class='accordion' id='accordionExample'>
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingOne'>
              <button
                class='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#smartCare'
                aria-expanded='true'
                aria-controls='collapseOne'
              >
                Documents
              </button>
            </h2>
            <div
              id='smartCare'
              class='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div class='accordion-body mobileTable' style={{ padding: 0 }}>
                <table class='table'>
                  <thead>
                    <tr className='product-resources-row'>
                      <th scope='col' className='tablecol'>
                        File
                      </th>
                      <th scope='col'>Type</th>
                      <th scope='col'>Date Uploaded</th>
                      <th scope='col'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='tablecol'>Trust Care Program</td>
                      <td>
                        <FaFilePdf />
                      </td>
                      <td>November, 2022 12:19PM</td>
                      <td>
                        <button
                          className='btn theme-btn-1 btn-effect-1 text-uppercase btn1'
                          onClick={() =>
                            handleDownload(
                              'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/brochures/trustcare.pdf'
                            )
                          }
                          style={{
                            backgroundColor: '#171B2A',
                            color: '#FFF',
                            padding: '5px 10px',
                          }}
                        >
                          download
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          class='accordion'
          id='accordionExample'
          style={{ marginTop: '50px' }}
        >
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingOne'>
              <button
                class='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#smartCare2'
                aria-expanded='true'
                aria-controls='collapseOne'
              >
                Videos
              </button>
            </h2>
            <div
              id='smartCare2'
              class='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div class='accordion-body'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartCares;
