import React, { useEffect, useState } from "react";
import CustomSelect from "../../common/Select/CustomSelect";
import ServiceProgram from "./ServiceProgram/ServiceProgram";
import useDealer from "../../../Hooks/useDealer";
import { apiGetRequest } from "../../../helpers/Requests";
import {
  URL_ADMIN_SERVICE_CATEGOREIS_LIST,
  URL_ADMIN_SERVICE_PROGRAMS_LIST,
  URL_ADMIN_SERVICE_TENURES_LIST,
  URL_ADMIN_SERVICE_TIERS_LIST,
} from "../../../service/URL";
import Loader from "../../common/Loader/Loader";
import _ from "lodash";
import "./styles.css";
import { classNames, parseBooleanVal } from "../../../utils/utils";

const ServiceManagement = () => {
  const [dealerOptions, setDealerOptions] = useState([]);
  const [selectedDealerOption, setSelectedDealerOption] = useState(null);

  const { isDealerListLoader, dealersData } = useDealer();

  const [isFetchServiceCatLoader, setIsFetchServiceCatLoader] = useState(false);
  const [serviceCategoryOptions, setServiceCategoryOptions] = useState([]);
  const [selectedServiceCategoryOption, setSelectedServiceCategoryOption] =
    useState(null);

  const [isFetchServiceTenureLoader, setIsFetchServiceTenureLoader] =
    useState(false);
  const [serviceTenureOptions, setServiceTenureOptions] = useState([]);

  const [isFetchServiceProgramsLoader, setIsFetchServiceProgramsLoader] =
    useState(false);
  const [serviceCatPrograms, setServiceCatPrograms] = useState([]);

  const [validationErrors, setValidationErrors] = useState(null);

  const [isFetchSericeTier, setIsFetchServiceTier] = useState(false);
  const [serviceTierOptions, setServiceTierOptions] = useState([]);

  useEffect(() => {
    getServiceCategories();
    getServiceTenures();
  }, []);

  useEffect(() => {
    if (selectedServiceCategoryOption && selectedServiceCategoryOption?.value) {
      const dealerId =
        selectedDealerOption && selectedDealerOption?.value
          ? selectedDealerOption.value
          : null;
      getServiceProgramsByCategory({ dealerId });
    }
    if (
      selectedServiceCategoryOption &&
      parseBooleanVal(selectedServiceCategoryOption.isTiered)
    ) {
      getServiceTiers();
    }
  }, [selectedDealerOption, selectedServiceCategoryOption]);

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        label:
          // x?.firstName && x.firstName?.length > 0
          //   ? `${x.firstName} ${x.lastName}`
          //   : x?.name || "",
          x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  const getServiceCategories = async () => {
    try {
      setIsFetchServiceCatLoader(true);
      const response = await apiGetRequest(URL_ADMIN_SERVICE_CATEGOREIS_LIST);
      if (response.data && response.data.categories) {
        setServiceCategoryOptions(
          response.data.categories.map((x) => ({
            ...x,
            label: x.serviceCategoryName,
            value: x._id,
          }))
        );
      }
      setIsFetchServiceCatLoader(false);
    } catch (err) {
      setIsFetchServiceCatLoader(false);
      console.log("err", err);
    }
  };

  const getServiceTenures = async () => {
    try {
      setIsFetchServiceTenureLoader(true);
      const response = await apiGetRequest(URL_ADMIN_SERVICE_TENURES_LIST);
      if (response.data && response.data.tenures) {
        setServiceTenureOptions(
          response.data.tenures.map((x) => ({
            ...x,
            label: x.tenureDuration,
            value: x._id,
          }))
        );
      }
      setIsFetchServiceTenureLoader(false);
    } catch (err) {
      setIsFetchServiceTenureLoader(false);
      console.log("err", err);
    }
  };

  const getServiceTiers = async () => {
    try {
      setIsFetchServiceTier(true);
      const response = await apiGetRequest(URL_ADMIN_SERVICE_TIERS_LIST);
      if (response.data && response.data.tiers) {
        setServiceTierOptions(
          response.data.tiers.map((x) => ({
            ...x,
            label: x.tierName,
            value: x._id,
          }))
        );
      }
      setIsFetchServiceTier(false);
    } catch (err) {
      setIsFetchServiceTier(false);
      console.log("err", err);
    }
  };
 
  const getServiceProgramsByCategory = async (payload) => {
    try {
      let url = `${URL_ADMIN_SERVICE_PROGRAMS_LIST}/${
        selectedServiceCategoryOption?.value || "0"
      }`;
      if (payload?.dealerId) {
        url = `${url}/${payload.dealerId}`;
      }
      setIsFetchServiceProgramsLoader(true);
      const response = await apiGetRequest(url);
      if (response.data && response.data.programs) {
        setServiceCatPrograms(response.data.programs);
      }

      setIsFetchServiceProgramsLoader(false);
    } catch (err) {
      setIsFetchServiceProgramsLoader(false);
      console.log("err", err);
    }
  };

  const updateProgramPricesLocally = (data) => {
    const prevProgram = _.cloneDeep(serviceCatPrograms);
    const catProgramIndex = prevProgram.findIndex(
      (p) => p._id === data._id && p.serviceCategory === data.serviceCategory
    );
    if (catProgramIndex !== -1) {
      prevProgram.splice(catProgramIndex, 1, {
        ...prevProgram[catProgramIndex],
        prices: data.prices,
      });
      setServiceCatPrograms([...prevProgram]);
    }
  };

  return (
    <>
      {/* checkoverflow */}
      <div className=" px-4 pt-3 mb-20" style={{ height: "90%" }}>
        <div className="row mt-3 text-start">
          <div className="col-md-9">
            <h3 className="pb-3">Service Management</h3>
          </div>
          <div className="col-md-3">
            <CustomSelect
              isClear={true}
              isSearch={true}
              isDisabled={isDealerListLoader}
              loading={isDealerListLoader}
              label="Filter by Dealer"
              placeHolder={"Select Dealer"}
              selectedValue={selectedDealerOption}
              handleChange={(e) => {
                setSelectedDealerOption(e);
              }}
              options={dealerOptions}
            />
          </div>
        </div>
        <div className="row pt-2 text-start">
          <div className="col-12">
            <CustomSelect
              isSearch={true}
              isDisabled={isFetchServiceCatLoader}
              loading={isFetchServiceCatLoader}
              placeHolder={"Select Category"}
              label="Category"
              selectedValue={selectedServiceCategoryOption}
              handleChange={setSelectedServiceCategoryOption}
              options={serviceCategoryOptions}
              error={
                //   validationErrors?.dealer_id ? "Dealer is required!" : ""
                ""
              }
            />
          </div>
        </div>
        <div
          className={classNames(
            selectedServiceCategoryOption &&
              serviceCatPrograms.length > 0 &&
              "service-programs-container"
          )}
        >
          {isFetchServiceCatLoader ||
          isFetchServiceTenureLoader ||
          isFetchServiceProgramsLoader ||
          isFetchSericeTier ? (
            <Loader cssClass={"text-black"} isSmallView={true} />
          ) : (
            <>
              {selectedServiceCategoryOption && serviceCatPrograms.length > 0
                ? serviceCatPrograms.map((program, index) => (
                    <ServiceProgram
                      serviceTierOptions={serviceTierOptions}
                      isTieredService={parseBooleanVal(
                        selectedServiceCategoryOption.isTiered
                      )}
                      isLastIndex={serviceCatPrograms.length === index + 1}
                      dealerId={
                        selectedDealerOption && selectedDealerOption?.value
                          ? selectedDealerOption?.value
                          : null
                      }
                      updateProgramPricesLocally={updateProgramPricesLocally}
                      serviceCategoryId={selectedServiceCategoryOption?.value}
                      key={index}
                      program={program}
                      srNumber={index + 1}
                      serviceTenureOptions={serviceTenureOptions}
                      isFetchServiceTenureLoader={isFetchServiceTenureLoader}
                    />
                  ))
                : selectedServiceCategoryOption &&
                  serviceCatPrograms.length === 0 && (
                    <div
                      className="d-flex justify-content-center align-items-center pt-50"
                      style={{ height: "100%" }}
                    >
                      <span className="error-text">No Data Found!</span>
                    </div>
                  )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceManagement;
