import React from "react";
import { CSVLink } from "react-csv";
import CustomButton from "../common/Button/CustomButton";
import { FaFileCsv } from "react-icons/fa";

const CsvGenerator = ({ data, fileName }) => {
  const filename = fileName || "download.csv";

  return (
    <CSVLink
      onClick={() => {
        console.log("You click the link");
        // return false;
      }}
      data={data}
      filename={filename}
    >
      <CustomButton
        icon={<FaFileCsv />}
        label="Export CSV"
        cssClass="btn-effect-1 text-uppercase btn1"
      />
    </CSVLink>
  );
};

export default CsvGenerator;
