import React from "react";

const AntiTheft = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "2pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          {" "}
          Schedule C: Anti-Theft Protection{" "}
        </h1>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          {" "}
          <br />{" "}
        </p>
        <ol id="l1">
          <li data-list-text="1.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Definitions:
            </h1>
            <ul id="l2">
              <li data-list-text="">
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Anti-Theft Product:
                  <span className="p">
                    A unique marking on your vehicle's window glass and/or body
                    panels, linked to the VIN, along with warning stickers on
                    the windows.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Canadian Black Book:
                  <span className="p">
                    A guide that determines your vehicle's value.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Total Loss:
                  <span className="p">
                    When your vehicle is declared permanently and totally lost
                    by your car insurance due to an insured event.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Vehicle Value:
                  <span className="p">
                    The retail clean value of your vehicle, adjusted for mileage
                    and certain equipment, as per the Canadian Black Book.
                  </span>
                </h1>
              </li>
            </ul>
          </li>
          <li data-list-text="2.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Anti-Theft Product:
            </h1>
            <p
              style={{
                paddingLeft: "41pt",
                textIndent: "0pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              If You Choose Anti-Theft Protection:
            </p>
            <ul id="l3">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  If you decide to get Anti-Theft Protection during the
                  registration process, you're agreeing to buy the Anti-Theft
                  Product at the price given by the Selling Dealer. By paying
                  this price to Extended Trust, and following the terms in this
                  Contract and Schedule, we promise to provide and install the
                  Anti-Theft Product on your vehicle. We'll do this in a way we
                  consider appropriate, making sure to meet our commitments
                  outlined in this Schedule.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  If you've chosen Anti-Theft Protection on the Registration
                  Page and paid the necessary charges, we assure you that the
                  Anti-Theft Product, when correctly applied to your vehicle,
                  will effectively deter theft throughout the specified term.
                  However, if the Anti-Theft Product doesn't prevent the theft
                  as expected, and your vehicle is stolen during this term, we
                  commit to offering you the in-store discount or refund
                  detailed in Section 3 of this Schedule (Limits of Liability
                  (Specific Provisions)), following the terms of this Contract
                  and Schedule.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Please note, if the Anti-Theft Product is removed from your
                  vehicle, we won't cover any costs associated with removing or
                  repairing your vehicle. If you decide to cancel the purchase
                  of the Anti-Theft Product or remove it from your vehicle, all
                  our responsibilities and liabilities under this Schedule will
                  immediately end.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="3.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              Limits of Liability (Specific Provisions):
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Limits on Our Liability for Stolen Vehicles:
              <span className="p">
                This Schedule outlines specific limits to our responsibility
                concerning stolen vehicles, except as detailed in section 4:
              </span>
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              A. If your vehicle is stolen and not recovered within 30 days or
              is recovered but declared a Total Loss by your auto insurance
              company within the same period, you may be eligible (following the
              terms of this Contract and Schedule) for an in-store discount with
              the Selling Dealer. The discount is the lesser of:
            </p>
            <ul id="l4">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  50% of the Vehicle Value before the theft or
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Five Thousand Dollars ($5,000), taxes included.
                </p>
              </li>
            </ul>
            <p
              style={{
                paddingTop: "5pt",
                paddingLeft: "26pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              You can only use this in-store discount towards purchasing a
              replacement vehicle from the Selling Dealer's available inventory.
              This discount must be utilized within 45 days of Extended Trust
              approving your claim. If you would have been entitled to the
              discount, but the Selling Dealer is out of business at the time of
              theft or your valid claim, you may be eligible (according to the
              terms of this Contract and Schedule) for a refund from Extended
              Trust of the Anti-Theft Product amount. This refund must be
              claimed within 60 days from the date of theft, as indicated in the
              police report filed with the local police department.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              For Recovered Vehicles
              <span className="p">
                : If your stolen vehicle is recovered with damage or stolen
                personal items, you may be eligible (following the terms of this
                Contract and Schedule) for a refund from Extended Trust of the
                Anti-Theft Product amount. To qualify for this refund, and in
                addition to the conditions in section 6 of this Schedule
                (Procedure for Making a Claim), you must provide sufficient
                evidence of the insurance deductible paid by you for the theft
                occurrence, as confirmed by your automobile insurance company.
              </span>
            </h1>
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              This refund must be claimed within 60 days from the date of theft,
              as shown in the police report filed with the local police
              department.
            </p>
          </li>
          <li data-list-text="4.">
            <h1
              style={{
                paddingTop: "1pt",
                paddingLeft: "24pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              Limits of Liability (General Provisions); Disclaimers:
            </h1>
            <ul id="l5">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "60pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Our liability is limited to providing the Anti-Theft Product
                  and the in-store discount or refund as specified in Section 3.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  We are not responsible for injuries, deaths, or other property
                  damages, whether related to theft or vehicle damage.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="5.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                textAlign: "justify",
              }}
            >
              Additional Exclusions:
            </h1>
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "0pt",
                textAlign: "justify",
              }}
            >
              This schedule does not apply to, and we are not responsible for,
              and services will not be provided for the following:
            </p>
            <ul id="l6">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Any loss or damage if you or anyone on your behalf provides
                  false information or commits fraud regarding any claim.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Theft of the vehicle occurring outside of Canada or the
                  contiguous United States of America and Alaska.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  The vehicle is not continuously insured under an automobile
                  insurance policy covering various perils, including theft.
                  This policy must be issued by an insurance company duly
                  licensed to conduct business in the Canadian province or
                  territory where the vehicle is primarily used.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Your insurance company denies coverage for the theft under the
                  terms of your automobile insurance policy.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  You fail to take reasonable steps to locate, recover, and/or
                  minimize the loss or damage to the vehicle, regardless of
                  whether the theft resulted from such failure.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Vehicle theft in situations where the key, keyless entry
                  remote, key FOB, or similar device used to access and/or
                  operate the vehicle is left in the vehicle while it is
                  unlocked or unsecured. This applies regardless of whether a
                  police report was filed in relation to the theft.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Vehicle theft committed by anyone who had access to the
                  vehicle's key, keyless entry remote, key FOB, or similar
                  device.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Vehicle theft committed, directly or indirectly, by any person
                  related to you by blood, marriage, or adoption, including
                  wards or foster children, and/or by anyone not at arm's length
                  with you.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Any loss or damage to the vehicle resulting, directly or
                  indirectly, from negligent, reckless, dishonest, fraudulent,
                  willful, or criminal acts or omissions by you, the vehicle's
                  driver, or anyone acting with your knowledge and consent.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage that occurs if the vehicle is carjacked.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Failure to report the theft to the police as required by
                  section 6 of this schedule (Procedure for Making a Claim), or
                  failure to comply with any obligations under that section,
                  including the specified time periods.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Removal or tampering with the permanent identification number
                  affixed to certain body panels of the vehicle or security
                  stickers on the driver and passenger side windows.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "62pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Any loss or damage caused by collision, fire, vandalism
                  (unless during the period the vehicle was stolen and
                  subsequently recovered, in which case you are entitled only to
                  the refund for a recovered vehicle, as stated in section 3(b)
                  of this schedule), riot, explosion, lightning, earthquake,
                  freezing, rust or corrosion, windstorm, hail, water or floods,
                  salt, or environmental damage.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                textAlign: "justify",
              }}
            >
              Procedure for Making a Claim:
            </h1>
            <ul id="l7">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  If you need to make a claim, report the theft to your Selling
                  Dealer within 24 hours.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  File a police report within 24 hours of discovering the theft.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Inform your insurer within three days of the theft.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Provide proof of loss to Extended Trust within 60 days,
                  including documents such as this Contract, police reports,
                  insurance notices, and proof of vehicle ownership.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  If you can't provide all documents within 60 days, submit them
                  as soon as possible within six months from the theft date.
                </p>
              </li>
              <li data-list-text="">
                <p
                  className="s1"
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "90%",
                    textAlign: "left",
                  }}
                >
                  Once claim is approved from Extended Trust, credit will be
                  sent to the originated purchase dealer from 30 days after the
                  approval date.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  );
};

export default AntiTheft;
