import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../common/Button/CustomButton";
import Loader from "../../common/Loader/Loader";
import { STAFF_EMPTY_OBJ } from "../../../utils/constant";
import { capitalizeAllFirstLetters } from "../../../utils/utils";
import useStaff from "../../../Hooks/useStaff";
import CreateStaff from "./CreateStaff";

const ListStaff = ({ setActiveTab }) => {
  const [selectedShowStaff, setSelectedShowStaff] = useState(null);

  const {
    isStaffListLoader,
    staffsData = [],
    handleDataUpdateById,
  } = useStaff();

  return (
    <>
      <div className="checkoverflow " style={{ paddingRight: "20px" }}>
        <div className="row mb-50 mt-3">
          <h3 className="pb-3">Service Advisors List</h3>
          <div className="mobileTable">
            <table
              class="table overflow-set"
              style={{ margin: "20px 0px 20px 0px" }}
            >
              <thead>
                <tr style={{ backgroundColor: "#4A6543" }}>
                  <th scope="col">Dealer Name</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  {/* <th scope="col">Role</th> */}
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">Postal Code</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                {isStaffListLoader ? (
                  <Loader
                    style={{ width: "35px", height: "35px" }}
                    isSmallView={true}
                  />
                ) : staffsData?.length === 0 ? (
                  <tr>
                    <td colSpan={12}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                      >
                        <span className="error-text">No data found!</span>
                      </div>
                    </td>
                  </tr>
                ) : staffsData.length > 0 ? (
                  staffsData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item?.dealer
                          ? capitalizeAllFirstLetters(
                              `${item?.dealer?.firstName} ${item?.dealer?.lastName}`
                            )
                          : "-"}
                      </td>
                      <td>
                        {capitalizeAllFirstLetters(item?.firstName) ?? "-"}
                      </td>
                      <td>
                        {capitalizeAllFirstLetters(item?.lastName) ?? "-"}
                      </td>
                      <td>{item?.email ?? "-"}</td>
                      {/* <td>{capitalizeAllFirstLetters(item?.role)}</td> */}
                      <td>{item?.phone ?? "-"}</td>
                      <td>{item?.address || "-"}</td>
                      <td>{item?.postalCode || "-"}</td>
                      <td>{item?.city || "-"}</td>
                      <td>{item?.state || "-"}</td>
                      <td>
                        <CustomButton
                          label="View"
                          type="button"
                          cssClass="btn-effect-1 text-uppercase"
                          clickHandler={() => {
                            setSelectedShowStaff(item);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={selectedShowStaff !== null}
        onHide={() => {
          setSelectedShowStaff(null);
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Update Service Advisor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", height: "80vh" }}>
          <CreateStaff
            setActiveTab={setActiveTab}
            handleClose={() => {
              setSelectedShowStaff(null);
            }}
            selectedStaff={selectedShowStaff}
            handleDataUpdateById={handleDataUpdateById}
            isCallFrom="staffList"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListStaff;
