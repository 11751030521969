import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";

const FinanceManager = () => {
  const [managerInfo, setManagerInfo] = useState([]);
  const [financeManager, setfinanceManager] = useState({
    addManagerFirstName: "",
    addManagerLastName: "",
    addManagerEmail: "",
    addManagerEmailMessages: null,
  });
  const [formErrors, setFormErrors] = useState({
    addManagerFNameError: false,
    addManagerLNameError: false,
    addManagerEmailError: false,
  });
  var apiUrl = process.env.REACT_APP_API_URL;
  //States to add Serivices
  const [selectedManagerId, setSelectedManagerId] = useState();
  const [isActive, setIsActive] = useState(null);
  const handleAddManagerSubmit = (event) => {
    event.preventDefault();

    setfinanceManager((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (financeManager.FirstName == "") {
      return "First name can not be empty";
    }
    if (financeManager.LastName == "") {
      return "Last name can not be empty";
    }
    if (financeManager.Email == "") {
      return "Email can not be empty";
    }
    // if (
    //   addManagerFirstName.trim() === "" ||
    //   addManagerLastName.trim() === "" ||
    //   addManagerEmail.trim() === ""
    // ) {
    //   if (addManagerFirstName.trim() === "") {
    //     setaddManagerFNameError(true);
    //   }

    //   if (addManagerLastName.trim() === "") {
    //     setaddManagerLNameError(true);
    //   }

    //   if (addManagerEmail.trim() === "") {
    //     setaddManagerEmailError(true);
    //   }

    //   setAddManagerEmailMessages(0);
    //   return; // Exit the function if any of the conditions are true
    // }

    let isValid;

    if (financeManager.addManagerEmail !== "") {
      console.log("email logged");
      isValid = validateEmail(financeManager.addManagerEmail);
      console.log(isValid);

      if (isValid === 2) {
        setfinanceManager(isValid);
        console.log(financeManager.addManagerEmailMessages);
        return;
      }
    }
    // const isValidEmail = validateEmail(Email);
    // if (isValidEmail === 2) {
    //   console.log("Invalid email");
    //   // Return or set an appropriate error state/message
    //   return;
    // }
    // console.log("Form submission logic here");

    // console.log(addManagerEmailMessages);
    // console.log(addManagerFirstName, addManagerLastName, addManagerEmail);

    if (
      financeManager.addManagerFirstName !== "" ||
      !financeManager.addManagerLastName !== "" ||
      !financeManager.addManagerEmail !== ""
    ) {
      const accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
      console.log("1040");
      fetch(`${apiUrl}/dealer/manager-create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          firstName: financeManager.FirstName,
          lastName: financeManager.LastName,
          email: financeManager.Email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            !data[0] &&
            !data[0]?.message &&
            data !== "Manager Already Exists By This Email"
          ) {
            console.log("you");
            setManagerInfo([...managerInfo, data]);
            // financeManagerDashref.current.click();
          }

          if (data === "Manager Already Exists By This Email") {
            console.log("line 1038");
            setfinanceManager(1);
          }

          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      // Valid email
      return null;
    } else {
      // Invalid email
      return 2;
    }
  };
  useEffect(() => {
    if (managerInfo.length >= 1) {
      setSelectedManagerId(managerInfo[0]._id);
    }
  }, [managerInfo]);

  const [managerId, setManagerId] = useState(0);

  const handleEditFinanceManager = (row) => {
    console.log(row, "555");
    setfinanceManager((prev) => ({
      ...prev,
      // financeManager: data.row._id,
      editManagerEmail: row.email,
      editManagerFirstName: row.firstName,
      editManagerLastName: row.lastName,
    }));
    setManagerId(row._id);
    // setEditManagerEmail(row.email);
    // setEditManagerFirstName(row.firstName);
    // setEditManagerLastName(row.lastName);
    // setIsActive(row.isActive);
    // editFinanceManagerRefButton.current.click();
  };

  const [showAddForm, setShowAddForm] = useState(false);

  const handleAddButtonClick = () => {
    setShowAddForm(true);
  };

  const handleInputChange = (event) => {
    //Resetting Error Messages
    // setDealerSuccess(0);
    // setDealerImageError(0);
    // setaddManagerEmailError(false);
    // setaddManagerFNameError(false);
    // setaddManagerLNameError(false);
    // setAddManagerEmailMessages(0);
    //Resetting Error Messages

    setFormErrors({
      addManagerFNameError: false,
      addManagerLNameError: false,
      addManagerEmailError: false,
    });
    const { name, value, type, checked } = event.target;

    // console.log(name, "line 367");

    // setInputData((prev) => ({
    //   ...prev,
    //   [event.target.name]: event.target.value,
    // }));
  };
  return (
    <>
      {/* <div style={{ color: "black" }}>FinanceManager</div> */}
      <div className="tab-pane py-5 " id="financemanager">
        <div className="ltn__myaccount-tab-content-inner">
          <div
            className="ltn__my-properties-table table-responsive"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <button
              onClick={() => handleAddButtonClick}
              className="btn theme-btn-1 btn-effect-1 text-uppercase btn1 mb-2"
              style={{
                alignSelf: "flex-end",
                padding: "5px 10px",
                backgroundColor: "#4A6543",
                color: "#FFF",
                fontSize: "0.8rem",
                borderRadius: "4px",
              }}
            >
              <FaPlus />
              Add New
            </button>
            <div className="mobileTable">
              <table class="table col-md-12 ">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Dealer</th>

                    <th scope="col">Email</th>

                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {showAddForm && (
                    <>
                      {/* Create Manager Tab */}
                      <div className="tab-pane fade" id="managercreate">
                        <form action="submit" onSubmit={handleAddManagerSubmit}>
                          <div className="row mb-50">
                            <div className="col-md-6">
                              <label>Email:</label>
                              <input
                                type="email"
                                name="managerEmail"
                                placeholder="Your Email"
                                value={financeManager.Email}
                                onChange={handleInputChange}
                                required
                              />
                              <div>
                                {formErrors.addManagerEmailError &&
                                  financeManager.addManagerEmailMessages == 0 && (
                                    <p style={{ color: "red" }}>
                                      Email can not be empty
                                    </p>
                                  )}
                                {financeManager.addManagerEmailMessages == 1 && (
                                  <p style={{ color: "red" }}>
                                    Manager with email already existsltn_tab_1_11
                                  </p>
                                )}
                                {financeManager.addManagerEmailMessages == 2 && (
                                  <p style={{ color: "red" }}>
                                    Email is not valid
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <label>First Name:</label>
                              <input
                                type="text"
                                name="managerFirstName"
                                placeholder="Your First Name"
                                value={financeManager.FirstName}
                                // value={addManagerFirstName}
                                //   onChange={handleInputChange}
                                required
                              />
                              {formErrors.addManagerFNameError && (
                                <p style={{ color: "red" }}>
                                  First name can not be empty
                                </p>
                              )}
                            </div>

                            <div className="col-md-6">
                              <label>Last Name:</label>
                              <input
                                type="text"
                                name="managerLastName"
                                placeholder="Your Last Name"
                                value={financeManager.LastName}
                                // value={addManagerLastName}
                                onChange={handleInputChange}
                                required
                              />
                              {formErrors.addManagerLNameError && (
                                <p style={{ color: "red" }}>
                                  Last name can not be empty
                                </p>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={handleAddManagerSubmit}
                            className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                            style={{
                              padding: "15px 30px",
                              backgroundColor: "rgb(255, 90, 60)",
                              color: "#FFF",
                              marginBottom: "30px",
                              fontSize: "16px",
                            }}
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                      {/* Edit Manager */}
                      <div
                        className="tab-pane fade"
                        id="editManager"
                      //   onSubmit={handleSubmitEditManager}
                      >
                        <form action="submit">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h1>Edit Manager Details</h1>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={isActive}
                                onClick={() =>
                                  setIsActive((prevIsActive) => !prevIsActive)
                                }
                              />
                              <label
                                class="form-check-label"
                                for="flexSwitchCheckDefault"
                              >
                                Status
                              </label>
                            </div>
                          </div>
                          <div className="row mb-50">
                            <div className="col-md-6">
                              <label>Email:</label>
                              <input
                                type="email"
                                name="editManagerEmail"
                                placeholder="Your Email"
                                value={financeManager.editManagerEmail}
                                onChange={handleInputChange}
                                required
                              />
                            </div>

                            <div className="col-md-6">
                              <label>First Name:</label>
                              <input
                                type="text"
                                name="editManagerFirstName"
                                placeholder="Your First Name"
                                value={financeManager.editManagerFirstName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Last Name:</label>
                              <input
                                type="text"
                                name="editManagerLastName"
                                placeholder="Your Last Name"
                                value={financeManager.editManagerLastName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <button
                            className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                            style={{
                              padding: "15px 30px",
                              backgroundColor: "rgb(255, 90, 60)",
                              color: "#FFF",
                              marginBottom: "30px",
                              fontSize: "16px",
                            }}
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </>
                    //   <>
                    //     {managerInfo.map((row) => (
                    //       <tr
                    //       //   key={row._id}
                    //       >
                    //         <th scope="row"></th>
                    //         {/* <td>
                    //           {row.firstName} {row.lastName}
                    //         </td> */}

                    //         {/* <td>{row.email}</td> */}

                    //         <td>
                    //           <div
                    //             class="form-check form-switch"
                    //             style={{
                    //               marginLeft: "10px",
                    //               marginTop: "5px",
                    //             }}
                    //           >
                    //             <input
                    //               class="form-check-input"
                    //               type="checkbox"
                    //               role="switch"
                    //               id="flexSwitchCheckDefault"
                    //               //   checked={row.isActive ? true : false}
                    //               // onClick={() => changeManagerStatus(row._id)}
                    //             />
                    //             <label
                    //               class="form-check-label"
                    //               for="flexSwitchCheckDefault"
                    //             ></label>
                    //           </div>
                    //         </td>
                    //         <td>
                    //           {" "}
                    //           <button
                    //             className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                    //             style={{
                    //               padding: "5px 10px",
                    //               backgroundColor: "#4A6543",
                    //               color: "#FFF",
                    //               fontSize: "0.8rem",
                    //               borderRadius: "4px",
                    //             }}
                    //             // onClick={() => handleEditFinanceManager(row)}
                    //           >
                    //             <FaEdit /> Edit
                    //           </button>
                    //         </td>
                    //       </tr>
                    //     ))}
                    //   </>
                  )}
                </tbody>
              </table>
            </div>

          </div>
          <div className="ltn__pagination-area text-center"></div>
        </div>
      </div>
    </>
  );
};

export default FinanceManager;
