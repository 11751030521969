import React from "react";
import Form from "react-bootstrap/Form";

const SwitchInput = (props) => {
  const { checked, handleChange, label, isDisabled = false } = props;
  return (
    <Form>
      <Form.Check
        disabled={isDisabled}
        checked={checked}
        defaultChecked={checked}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
        type="switch"
        id="custom-switch"
        label={label}
        size="lg"
      />
    </Form>
  );
};

export default SwitchInput;
