import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  isValidFile,
  maxFileSize,
  parseBooleanVal,
} from "../../../utils/utils";
import "./DropzoneUploader.css";
import DropzoneFileItem from "./DropzoneFileItem";
import { toast } from "react-toastify";

const DropzoneUploader = ({ handleChange, size = 10 }) => {
  const [files, setFiles] = useState([]);

  const getCurrentFileLength = useMemo(() => files.length, [files]);

  const onDrop = (uploadFiles) => {
    const ttlNewFileLength = uploadFiles.length;
    if (getCurrentFileLength + ttlNewFileLength > 5) {
      toast.info("Max. 5 valid files allowed to upload!", {
        toastId: "Repair-attachment-upload",
      });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      const validFiles = [];
      for (const file of uploadFiles) {
        const uniqueKey = `${Date.now()}-${Math.floor(
          Math.random() * 10000000
        )}-${file.name}`;
        let message = isValidFile(file, "any");
        if (message.length > 0) {
          Object.assign(file, {
            isValid: false,
            message: message,
            id: uniqueKey,
          });
          validFiles.push(file);
          continue;
        }
        message = maxFileSize(file, size ? size : 10);
        if (message.length > 0) {
          Object.assign(file, {
            isValid: false,
            message: message,
            id: uniqueKey,
          });
          validFiles.push(file);
          continue;
        }
        if (file.name.length > 50) {
          Object.assign(file, {
            isValid: false,
            message: "Filename exeed by 50 letters!",
            id: uniqueKey,
          });
          validFiles.push(file);
          continue;
        }
        Object.assign(file, {
          isValid: true,
          message: null,
          id: uniqueKey,
        });
        validFiles.push(file);
      }
      if (validFiles.length > 0) {
        setFiles((prevFiles) => [
          ...prevFiles,
          ...validFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);
      }
    }
  };

  const removeFile = (id) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (files.length <= 5) {
      handleChange(files.filter((file) => parseBooleanVal(file.isValid)));
    } else {
      toast.info("Max. 5 valid files allowed to upload!", {
        toastId: "Repair-attachment-upload",
      });
    }
  }, [files]);

  return (
    <Container className="mt-3">
      <Row>
        <Col md={{ span: 12 }}>
          <Card>
            <Card.Body
              {...getRootProps()}
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "200px", cursor: "pointer" }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={{ span: 12 }}>
          <div
            className="d-flex overflow-auto custom-scroller"
            style={{ columnGap: "0.50rem" }}
          >
            {files.map((file, index) => (
              <DropzoneFileItem
                key={index}
                index={index}
                file={file}
                removeFile={removeFile}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DropzoneUploader;
