import React, { useState } from "react";
import { toast } from "react-toastify";
import CustomButton from "../../common/Button/CustomButton";
import { classNames } from "../../../utils/utils";
import { apiPostRequest } from "../../../helpers/Requests";
import { URL_SUPER_ADMIN_CREATE_ADMIN } from "../../../service/URL";

const CreateAdmin = (props) => {
  const { setActiveTab } = props;
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    isVerified: true,
  });
  const [isCreateLoader, setIsCreateLoader] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    isVerified: "",
  });

  const validateField = (field, value) => {
    // Customize these conditions as needed
    if (field === "phone") {
      return value === "" || value === "+1" || value.trim() === "+1";
    } else {
      return value === "" || value === undefined;
    }
  };

  const ValidationForm = () => {
    const errorObj = {};
    const notInclude = ["address", "city", "state", "postalCode"];

    for (const [field, value] of Object.entries(inputData)) {
      if (notInclude.findIndex((x) => x === field) === -1) {
        if (validateField(field, value)) {
          errorObj[field] = true;
        }
      }
    }
    setValidationErrors((prev) => ({ ...prev, ...errorObj }));
    return Object.keys(errorObj).length > 0;
  };

  const submitAdminDetails = async (event) => {
    event.preventDefault();
    const hasErrors = ValidationForm();
    if (hasErrors) {
      console.log("Form has validation errors", validationErrors);
      return;
    }

    const payload = {
      userName: inputData.name,
      email: inputData.email,
      isVerified: inputData.isVerified,
    };

    try {
      setIsCreateLoader(true);
      const response = await apiPostRequest(
        URL_SUPER_ADMIN_CREATE_ADMIN,
        payload
      );
      if (response.data) {
        toast.success("Admin Added Successfully!");
        setActiveTab("ListAdmins");
      }
      setIsCreateLoader(false);
    } catch (error) {
      const errMessage = new Error(error);
      setIsCreateLoader(false);
      toast.error(errMessage?.message ?? "Something went wrong!");
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setInputData((prev) => ({
      ...prev,
      [name]: name === "isVerified" ? checked : value,
    }));
    if (validationErrors[name]) {
      const error = validateField(name, value);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="ltn__form-box p-4">
        <form action="submit" onSubmit={submitAdminDetails}>
          <div className={classNames("row")}>
            <h3 className="pb-4">Add New Admin</h3>
            <div className="col-md-4">
              <label>
                Email Address:<span className="required-class">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={inputData.email ? inputData.email : ""}
                onChange={(e) => handleInputChange(e)}
                required
              />
              {validationErrors?.email && (
                <p className="error-message">Email is required!</p>
              )}
            </div>

            {/*Name */}
            <div className="col-md-4">
              <label>
                Name:<span className="required-class">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={inputData.name ? inputData.name : ""}
                onChange={handleInputChange}
                required
              />
              {validationErrors?.name && (
                <p className="error-message">Name is required!</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="toggle">
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    type="checkbox"
                    defaultChecked={inputData.isVerified}
                    onChange={(e) => handleInputChange(e)}
                    className="toggle"
                    name="isVerified"
                    id="toggle"
                  />
                  <label htmlFor="toggle">
                    <span className="on"></span>
                    <span className="off"></span>
                  </label>
                  <h4 style={{ paddingLeft: "5px", fontWeight: "600" }}>
                    Active
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper px-3">
            <CustomButton
              showLoading={isCreateLoader}
              isDisabled={isCreateLoader}
              label={"Add Admin"}
              type="submit"
              style={{ backgroundColor: "#000000" }}
              cssClass="btn-effect-1 text-uppercase btn1"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAdmin;
