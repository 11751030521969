import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import _ from "lodash";
import { USER_ROLE } from "../utils/constant";

const useManager = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var token = JSON.parse(secureLocalStorage.getItem("Token"));

  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [isManagerListLoader, setIsManagerLoader] = useState();
  const [managersData, setManagersData] = useState([]);

  useEffect(() => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.DEALER) {
      getAllManagers();
    }
  }, []);

  const handleDataUpdateById = (data) => {
    const index = managersData.findIndex((x) => x._id === data.id);
    if (index !== -1) {
      const prevData = _.cloneDeep(managersData);
      prevData.splice(index, 1, {
        ...prevData[index],
        ...data,
      });
      setManagersData([...prevData]);
    }
  };

  const getAllManagers = () => {
    const allManagers = `${apiUrl}/manager/list`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: headers,
    };
    setIsManagerLoader(true);
    fetch(allManagers, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setManagersData(data);
        setIsManagerLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsManagerLoader(false);
      });
  };

  return { isManagerListLoader, managersData, handleDataUpdateById };
};

export default useManager;
