import React from "react";
import "./SummaryCards.css";
import { isArray } from "lodash";

const SummaryCards = ({ registrations }) => {
  const totalCost = registrations?.reduce((total, registration) => {
    if (
      registration.hasOwnProperty("details") &&
      isArray(registration?.details)
    ) {
      return (
        total +
        registration?.details?.reduce((totalDetail, detail) => {
          return (
            totalDetail +
            detail.programs.reduce(
              (totalProgram, program) =>
                totalProgram + program.priceDetails.price,
              0
            )
          );
        }, 0)
      );
    }
    return 0;
  }, 0);

  const totalRetailCost = registrations.reduce((total, registration) => {
    if (
      registration.hasOwnProperty("details") &&
      isArray(registration?.details)
    ) {
      return (
        total +
        registration?.details?.reduce((totalDetail, detail) => {
          return (
            totalDetail +
            (detail.retailerPrice ? parseFloat(detail.retailerPrice) : 0)
          );
        }, 0)
      );
    }
    return 0;
  }, 0);

  return (
    <div className="container mt-5 d-flex justify-content-around flex-wrap">
      <div
        className="card summary-card text-center"
        style={{ width: "18rem", backgroundColor: "#f9f9f9" }}
      >
        <div className="card-body">
          <h5 className="card-title accent-text">Total Registrations</h5>
          <p className="card-text">{registrations.length}</p>
        </div>
      </div>

      <div
        className="card summary-card text-center"
        style={{ width: "15rem", backgroundColor: "#f9f9f9" }}
      >
        <div className="card-body">
          <h5 className="card-title accent-text">Total Cost (No Tax)</h5>
          <p className="card-text">${totalCost.toFixed(2)}</p>
        </div>
      </div>

      <div
        className="card summary-card text-center"
        style={{ width: "15rem", backgroundColor: "#f9f9f9" }}
      >
        <div className="card-body">
          <h5 className="card-title accent-text">Total Retail Cost (No Tax)</h5>
          <p className="card-text">${totalRetailCost.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default SummaryCards;
