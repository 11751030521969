import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import "../styles.css";
import CartItem from "./cartItem";
import "./cartStyles.css";
import CustomButton from "../../../common/Button/CustomButton";
import { apiPostRequest } from "../../../../helpers/Requests";
import { URL_DEALER_ORDER_PLACE } from "../../../../service/URL";
import parseError from "../../../../utils/ErrorParse";
import { toast } from "react-toastify";

const CatalogueMaterialCart = (props) => {
  const { cartData, show, handleClose, setCartData } = props;

  const [isSubmitLoader, setIsSubmitLoader] = useState(false);

  const handlePlaceOrder = async () => {
    try {
      setIsSubmitLoader(true);
      const payload = cartData.map((i) => ({
        materialId: i._id,
        quantity: i.quantity,
      }));
      const response = await apiPostRequest(URL_DEALER_ORDER_PLACE, {
        materials: payload,
      });
      if (response.data) {
        toast.success("Order Placed Successfully!");
        setCartData([]);
        handleClose();
      }
      setIsSubmitLoader(false);
    } catch (err) {
      setIsSubmitLoader(false);
      console.log("err", err);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  const calculateTotalGrandCost = () => {
    return cartData.reduce((total, material) => {
      return total + material.price * material.quantity;
    }, 0);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          //   setIsAddMaterialShow(false);
          handleClose();
        }}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom:
              "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
          }}
          closeButton
        >
          <Modal.Title>Material Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "0px" }}>
          <div>
            <h3>{`Total Cost: $${calculateTotalGrandCost()}`}</h3>
          </div>
          <div className="py-4">
            <div className="checkoverflow">
              <div className="mobileTable" style={{ height: "500px" }}>
                <Table className="overflow-table">
                  <thead>
                    <tr>
                      <th className="w-35">Name</th>
                      <th className="w-15">Price</th>
                      <th className="w-20">Total Price</th>
                      <th className="w-15">Quantity</th>
                      <th className="w-15 text-end px-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartData.length === 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100%" }}
                          >
                            <span className="error-text">Cart Empty!</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      cartData.map((material, index) => (
                        <CartItem
                          cartData={cartData}
                          setCartData={setCartData}
                          key={index}
                          material={material}
                        />
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            isDisabled={isSubmitLoader}
            label="Close"
            clickHandler={() => {
              !isSubmitLoader && handleClose();
            }}
            cssClass="btn-effect-1 btn1"
          />
          <CustomButton
            showLoading={isSubmitLoader}
            isDisabled={isSubmitLoader || cartData.length === 0}
            label={`Place order`}
            clickHandler={handlePlaceOrder}
            style={{ backgroundColor: "#000000" }}
            cssClass="btn-effect-1 btn1"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CatalogueMaterialCart;
