import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import CustomButton from "../common/Button/CustomButton";
import Loader from "../common/Loader/Loader";
import parseError from "../../utils/ErrorParse";
import AuthContext from "../../helpers/authContext";
import "./login.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";

const Login = () => {
  const { setTimezone, setRole } = useContext(AuthContext);

  useEffect(() => {
    const token = secureLocalStorage.getItem("Token");
    if (token) {
      navigate("/dashboard");
    }

    // const rememberMeEmail = localStorage.getItem("RememberMeEmail");
    // const rememberMePassword = localStorage.getItem("RememberMePassword");
    // if (rememberMeEmail && rememberMePassword) {
    //   setFormData({
    //     ...formData,
    //     email: rememberMeEmail,
    //     password: rememberMePassword,
    //   });
    // }
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    roleManage: "",
  });
  const [errorState, setErrorState] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [roleManage, setRoleManage] = useState();
  const [staffRole, setStaffRole] = useState();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordVisibility1, setPasswordVisibility1] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [token, setToken] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");

  const [validationErrors, setValidationErrors] = useState({
    emptyEmailError: false,
    emptyPasswordError: false,
    InvalidEmailError: false,
    EmptyRoleError: false,
    invalidPasswordError: false,
    notFoundError: false,
  });
  const [modalErrors, setModalErrors] = useState({
    emptyEmailError: false,
    InvalidEmailError: false,
  });
  const [modalStepTwoErrors, setModalStepTwoErrors] = useState({
    emptyPasswordError: false,
    emptyTokenError: false,
    invalidTokenError: false,
  });
  const [rememberMe, setRememberMe] = useState(false);

  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const handleInputChange = (event) => {
    setValidationErrors({
      emptyEmailError: false,
      emptyPasswordError: false,
      InvalidEmailError: false,
      EmptyRoleError: false,
      invalidPasswordError: false,
      notFoundError: false,
    });
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData({ ...formData, [name]: inputValue });
  };

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
    console.log("event.target.checked", event.target.checked);

    if (event.target.checked) {
      localStorage.setItem("RememberMe", true);
    } else {
      localStorage.setItem("RememberMe", false);
    }
  };

  const [loginError, setLoginError] = useState("");

  const handleSubmit = async (event) => {
    try {
      setLoginError("");
      event.preventDefault();

      const { email, password } = formData;

      if (rememberMe) {
        // secureLocalStorage.setItem("RememberMeEmail", formData.email);
        // secureLocalStorage.setItem("RememberMePassword", formData.password);
      }

      if (email === "") {
        setValidationErrors((prev) => ({
          ...prev,
          emptyEmailError: true,
        }));
      }
      if (
        email !== "" &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) === false
      ) {
        setValidationErrors((prev) => ({
          ...prev,
          InvalidEmailError: true,
        }));
      }
      if (password === "") {
        setValidationErrors((prev) => ({
          ...prev,
          emptyPasswordError: true,
        }));
      }
      if (roleManage === undefined) {
        setValidationErrors((prev) => ({
          ...prev,
          EmptyRoleError: true,
        }));
      }

      if (
        email !== "" &&
        password !== "" &&
        roleManage !== undefined &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
      ) {
        setIsLoginLoading(true);

        const response = await axios.post(
          `${apiUrl}/${roleManage}/login`,
          {
            email,
            password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = response.data;

        setIsLoginLoading(false);
        if (typeof data === "string" && data.includes("Invalid Credentials")) {
          setValidationErrors((prev) => ({
            ...prev,
            invalidPasswordError: true,
          }));
        }
        if (typeof data === "string" && data.includes("Not Found")) {
          setValidationErrors((prev) => ({
            ...prev,
            notFoundError: true,
          }));
        }
        if (data.token) {
          secureLocalStorage.setItem("Token", JSON.stringify(data.token));
          secureLocalStorage.setItem("UserID", JSON.stringify(data.id));
          secureLocalStorage.setItem("Role", JSON.stringify(data.role));
          secureLocalStorage.setItem(
            "Timezone",
            JSON.stringify(data.timezone || null)
          );
          secureLocalStorage.setItem(
            "isVerified",
            JSON.stringify(data.isVerified)
          );
          setRole(data.role);
          setTimezone(data.timezone || null);
          setSuccessState(true);
          setErrorState(false);

          if (data.isVerified === true) {
            navigate("/dashboard");
          }
          if (data.isVerified === false) {
            navigate("/confirmation");
          }

          // console.log(data.isVerified, "line 77");
        } else {
          setErrorState(true);
        }
      }
    } catch (error) {
      setIsLoginLoading(false);
      const errorMEssage = parseError(error);
      setLoginError(errorMEssage ?? "Something went wrong!");
    }
  };

  const handleRoleChange = (e) => {
    setValidationErrors({
      emptyEmailError: false,
      emptyPasswordError: false,
      InvalidEmailError: false,
      EmptyRoleError: false,
      invalidPasswordError: false,
      notFoundError: false,
    });
    setRoleManage(() => e.target.value.split(".")[0]);
    setStaffRole(() => e.target.value.split(".")[1]);
  };
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [message, setMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const [isResetPassLoader, setIsResetPassLoader] = useState(false);

  const [isTokenApiLoader, setIsTokenApiLoader] = useState(false);

  const handlePasswordChange = (event) => {
    event.preventDefault();

    if (resetPasswordEmail === "") {
      setModalErrors((prev) => ({ ...prev, emptyEmailError: true }));
      return;
    }
    if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        resetPasswordEmail
      ) === false
    ) {
      setModalErrors((prev) => ({ ...prev, InvalidEmailError: true }));
      return;
    }
    setIsResetPassLoader(true);
    axios
      .post(`${apiUrl}/admin/reset-password`, { email: resetPasswordEmail })
      .then((response) => {
        // Handle success
        toast.success("Link has been sent to your mail successfully!");
        setActiveStep(1);
        setIsResetPassLoader(false);
      })
      .catch((error) => {
        // Handle error
        toast.error(
          `The email address you entered isn't connected to an account.`
        );
        setIsResetPassLoader(false);
      });
  };

  const handleEmailModal = (event) => {
    setModalErrors({
      emptyEmailError: false,
      InvalidEmailError: false,
    });
    setResetPasswordEmail(event.target.value);
  };

  const handleNewPassword = (event) => {
    setModalStepTwoErrors({
      emptyPasswordError: false,
      emptyTokenError: false,
      invalidTokenError: false,
    });
    const { name, value } = event.target;
    if (name === "token") {
      setToken(value);
    }
    if (name === "newPassword") {
      setNewPassword(value);
    }
  };

  const handleToken = async () => {
    if (newPassword === "") {
      setModalStepTwoErrors((prev) => ({
        ...prev,
        emptyPasswordError: true,
      }));
      return;
    }
    if (token.length < 5 || token.length > 5) {
      setModalStepTwoErrors((prev) => ({
        ...prev,
        emptyTokenError: true,
      }));
      return;
    }
    setIsTokenApiLoader(true);
    axios
      .post(`${apiUrl}/admin/update-password`, {
        email: resetPasswordEmail,
        token,
        password: newPassword,
      })
      .then((response) => {
        setIsTokenApiLoader(false);
        if (
          typeof response.data === "string" &&
          response.data.includes("Incorrect OTP")
        ) {
          setModalStepTwoErrors({ invalidTokenError: true });
          return;
        }
        toast.success("Password successfully changed.");
        handleModalClose();
      })
      .catch((error) => {
        setIsTokenApiLoader(false);
        // Handle error
        console.log(error);
      });
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModalClose = () => {
    closeModal();
    setActiveStep(0);
    setNewPassword("");
    setToken("");
    setResetPasswordEmail("");

    setModalErrors({ emptyEmailError: false, InvalidEmailError: false });
    setModalStepTwoErrors({
      emptyPasswordError: false,
      emptyTokenError: false,
      invalidTokenError: false,
    });
  };

  return (
    <>
      <div className="Lat-login-main-container">
        <div className="logo-head-stip">
          <Link to="https://www.extendedtrust.ca/">
            <img
              src={publicUrl + "assets/img/logoblack.svg"}
              alt="Logo"
              className="login-logo-head"
            />
          </Link>
        </div>
        <div className="form-login-container">
          <form
            className="ltn__form-box ltn__form-box-login contact-form-box"
            onSubmit={handleSubmit}
          >
            <label>Email *</label>
            <input
              type="text"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleInputChange}
            />
            {validationErrors.notFoundError && (
              <p className="error-text ">
                The email address you entered isn't connected to an account.
              </p>
            )}
            {validationErrors.emptyEmailError && (
              <p className="error-text ">The Email field is required.</p>
            )}
            {validationErrors.InvalidEmailError && (
              <p className="error-text ">
                Please provide a valid email address.
              </p>
            )}
            <label>Password *</label>
            {validationErrors.emptyPasswordError && (
              <p className="error-text">The Password field is required.</p>
            )}
            {validationErrors.invalidPasswordError && (
              <p className="error-text" style={{ fontSize: "15px" }}>
                Incorrect password: If you've forgotten your password click on{" "}
                <span onClick={() => setModalOpen(true)}>Reset Password</span>
              </p>
            )}
            <div className="show-pass">
              <input
                type={passwordVisibility ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <i
                className={
                  passwordVisibility
                    ? "fas fa-eye eye-icon"
                    : "fas fa-eye-slash eye-icon"
                }
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
            {validationErrors.EmptyRoleError && (
              <p className="error-text">
                Please choose a role before proceeding.
              </p>
            )}
            <select
              class="form-select form-select-option mb-3"
              aria-label="Default select example"
              onChange={handleRoleChange}
            >
              <option value="" disabled selected>
                Select a Role
              </option>
              <option value="superadmin">Super Admin</option>
              <option value="admin">Admin</option>
              <option value="dealer">Dealer</option>
              <option value="manager">Manager</option>
              {/* <option value="staff">Technician</option> */}
              {/* <option value="staff">Sales Advisor</option> */}
              <option value="staff">Service Advisor</option>
            </select>

            <div className="">
              <div className="remember-me d-flex  align-items-center mb-3">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />
                <label htmlFor="rememberMe-label">Remember Me</label>
              </div>
              {loginError && loginError?.length > 0 && (
                <div className="py-2">
                  <span className="error-text">{loginError}</span>
                </div>
              )}
              <div className="btn-wrapper mt-0">
                <button
                  disabled={isLoginLoading}
                  className="theme-btn-1 btn btn-block signin-btn"
                >
                  <span style={{ fontWeight: "400" }}>Sign In</span>
                  {!isLoginLoading && (
                    <Icon
                      icon="basil:arrow-right-outline"
                      style={{ width: "35px", height: "35px" }}
                      className="rightArrow-Icon"
                    />
                  )}
                  {isLoginLoading && (
                    <Loader
                      style={{ width: "35px", height: "35px" }}
                      isSmallView={true}
                    />
                  )}
                </button>
              </div>
              <div className="loginRest go-to-btn">
                {/* <span>
                  <Link to="/register" className="createAccount-link">
                    Create Account
                  </Link>
                </span> */}
                <span onClick={() => setModalOpen(true)}>
                  <small
                    className="resetpassword"
                    style={{ fontWeight: "400", cursor: "pointer" }}
                  >
                    Reset Password?
                  </small>
                </span>
              </div>
            </div>

            {successState && (
              <p style={{ color: "green" }}>
                You are logged in. You will soon be redirected
              </p>
            )}
          </form>
        </div>
      </div>
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleModalClose}>
          <Modal.Header
            style={{
              borderBottom:
                "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
            }}
            closeButton
          >
            <Modal.Title>
              {activeStep === 0 ? "RESET PASSWORD" : "CHANGE PASSWORD?"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {activeStep === 0 && (
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-info text-center reset-modal">
                          <p
                            className="added-cart "
                            style={{
                              alignSelf: "flex-start",
                              color: "black",
                            }}
                          >
                            Enter your registered email
                          </p>
                          <input
                            type="text"
                            name="resetPasswordEmail"
                            value={resetPasswordEmail}
                            onChange={handleEmailModal}
                            placeholder=" Please enter your email address"
                            style={{ background: "white" }}
                          />
                          {modalErrors.emptyEmailError && (
                            <p
                              style={{
                                alignSelf: "flex-start",
                                color: "red",
                              }}
                            >
                              The Email field is required.
                            </p>
                          )}
                          {modalErrors.InvalidEmailError && (
                            <p
                              style={{
                                alignSelf: "flex-start",
                                color: "red",
                              }}
                            >
                              Please provide a valid email address.
                            </p>
                          )}

                          <div
                            className="btn-wrapper mt-0 "
                            style={{ width: "100%" }}
                          >
                            <CustomButton
                              isDisabled={isResetPassLoader}
                              showLoading={isResetPassLoader}
                              label="Submit"
                              type="button"
                              clickHandler={handlePasswordChange}
                              cssClass="btn d-flex justify-content-center"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>

                        {/* additional-info */}
                        <div className="additional-info d-none">
                          <p>
                            We want to give you <b>10% discount</b> for your
                            first order, <br /> Use discount code at checkout
                          </p>
                          <div className="payment-method">
                            <img
                              src={publicUrl + "assets/img/icons/payment.png"}
                              alt="#"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        {/* Update Password */}
                        <div className="modal-product-info text-center reset-modal">
                          <label style={{ color: "black" }}>
                            New Password:
                          </label>
                          <div className="newpass">
                            <input
                              type={passwordVisibility1 ? "text" : "password"}
                              name="newPassword"
                              value={newPassword}
                              onChange={handleNewPassword}
                              placeholder="Enter your new password"
                              style={{ background: "white" }}
                            />
                            <i
                              className={
                                passwordVisibility1
                                  ? "fas fa-eye eye-icon eyeicon"
                                  : "fas fa-eye-slash eye-icon eyeicon"
                              }
                              style={{
                                color: passwordVisibility1 ? "black" : "black",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setPasswordVisibility1(!passwordVisibility1)
                              }
                            ></i>

                            {modalStepTwoErrors.emptyPasswordError && (
                              <p className="error-text">
                                The Password field is required.
                              </p>
                            )}
                          </div>
                          <label style={{ color: "black" }}>
                            One-time Password (OTP):
                          </label>

                          <input
                            type="text"
                            name="token"
                            value={token}
                            maxLength={5}
                            onChange={handleNewPassword}
                            placeholder="Enter your OTP"
                            // style={{ background: "black" }}
                          />
                          {modalStepTwoErrors.emptyTokenError && (
                            <p className="error-text">
                              OTP must be of five characters.
                            </p>
                          )}
                          {modalStepTwoErrors.invalidTokenError && (
                            <p className="error-text">
                              The OTP you entered is invalid.
                            </p>
                          )}
                          <div
                            className="btn-wrapper mt-0"
                            style={{ width: "100%" }}
                          >
                            <CustomButton
                              isDisabled={isTokenApiLoader}
                              showLoading={isTokenApiLoader}
                              label="Submit"
                              type="submit"
                              clickHandler={handleToken}
                              cssClass="btn btn-full-width-2 d-flex justify-content-center"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <p>{message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Login;
