import React, { useEffect, useState } from "react";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import CustomButton from "../../common/Button/CustomButton";
import {
  DEFAULT_TIMEZONE,
  POSTALCODE_PATTERN,
  STATE_OPTIONS,
  TIMEZONES,
} from "../../../utils/constant";
import "./dealer.css";
import useServiceManager from "../../../Hooks/useServiceManager";
import CustomSelect from "../../common/Select/CustomSelect";
import CustomInput from "../../common/Input/CustomInput";

const CreateDealer = (props) => {
  const { setActiveTab } = props;

  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState("");
  const [inputData, setInputData] = useState({
    email: "",
    lastName: "",
    firstName: "",
    address: "",
    city: "",
    name: "",
    phone: "+1 ",
    postalCode: "",
    // profileImage: "",
    // region: "",
    state: "",
    // faxNumber: "",
    timezone: DEFAULT_TIMEZONE,
    service_manager_id: "",
  });

  const [isCreateLoader, setIsCreateLoader] = useState(false);

  const [isValidPostalCode, setIsValidPostalCode] = useState(false);

  const [serviceManagerOptions, setServiceManagerOptions] = useState([]);

  const { isServiceManagerListLoader, serviceManagersData } =
    useServiceManager();

  useEffect(() => {
    setServiceManagerOptions(
      serviceManagersData.map((x) => ({
        ...x,
        label: `${x.firstName} ${x.lastName}`,
        value: x._id,
      }))
    );
  }, [serviceManagersData]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    setInputData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "postalCode") {
      setIsValidPostalCode(POSTALCODE_PATTERN.test(value));
      setValidationErrors((prev) => ({
        ...prev,
        [name]: !POSTALCODE_PATTERN.test(value),
      }));
      return;
    }

    setValidationErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  // google api loookups
  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;

  function initAutocomplete() {
    // console.log("test");
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");

    // Check if the fields are found before proceeding
    if (address1Field) {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      address1Field.focus();

      autocomplete.addListener("place_changed", fillInAddress);
    } else {
      console.error("One or more required fields not found in the DOM.");
    }
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    const hasPostalCode = (component) => {
      return component?.types?.includes("postal_code");
    };
    const hasPostalCodeObject = place.address_components?.some(hasPostalCode);
    if (hasPostalCodeObject) {
    } else {
      setInputData((prev) => ({
        ...prev,
        postalCode: "",
      }));
    }
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number":
          address1 = `${component.long_name} ${address1}`;
          // console.log(address1);
          break;
        case "route":
          address1 += ` ${component.short_name}`;
          // console.log(address1);
          setInputData((prev) => ({
            ...prev,
            address: address1,
          }));
          break;
        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          setInputData((prev) => ({
            ...prev,
            postalCode: postcode?.trim(),
          }));
          setIsValidPostalCode(POSTALCODE_PATTERN.test(postcode?.trim()));
          break;
        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;
        case "locality":
          const localityElement = document.querySelector("#locality");
          if (localityElement) {
            localityElement.value = component.long_name;
            setInputData((prev) => ({
              ...prev,
              city: localityElement.value,
            }));
          }
          break;
        case "administrative_area_level_1":
          const stateElement = document.querySelector("#state");
          if (stateElement) {
            stateElement.value = component.short_name;
            setInputData((prev) => ({
              ...prev,
              state:
                STATE_OPTIONS.findIndex(
                  (op) =>
                    op.value.toLowerCase() ===
                    component.long_name?.toLowerCase()
                ) !== -1
                  ? component.long_name
                  : "",
            }));
            // console.log(component.long_name, " component.long_name");
          }
          break;
        case "country":
          const countryElement = document.querySelector("#country");
          if (countryElement) {
            countryElement.value = component.long_name;
          }
          break;
        default:
          break;
      }
    }
    if (address1Field) {
      address1Field.value = address1;
    }
    if (postalField) {
      postalField.value = postcode;
    }
    if (address2Field) {
      address2Field.focus();
    }
  }

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    postalCode: "",
    address: "",
    city: "",
    phone: "",
    state: "",
    service_manager_id: "",
    name: "",
  });

  const ValidationForm = () => {
    let errorObj = {
      firstName: false,
      lastName: false,
      email: false,
      postalCode: false,
      address: false,
      city: false,
      phone: false,
      state: false,
      service_manager_id: false,
      name: false,
    };

    // console.log("inputData", inputData);

    if (inputData.firstName === "" || inputData.firstName === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        firstName: true,
      }));
      errorObj.firstName = true;
    } else if (inputData.lastName === "" || inputData.lastName === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        lastname: true,
      }));
      errorObj.lastName = true;
    } else if (inputData.name === "" || inputData.name === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        name: true,
      }));
      errorObj.name = true;
    } else if (inputData.email === "" || inputData.email === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        email: true,
      }));
      errorObj.email = true;
    } else if (inputData.address === "" || inputData.address === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        address: true,
      }));
      errorObj.address = true;
    } else if (inputData.city === "" || inputData.city === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        city: true,
      }));
      errorObj.city = true;
    } else if (
      inputData.postalCode === "" ||
      inputData.postalCode === undefined
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        postalCode: true,
      }));
      errorObj.postalCode = true;
    } else if (
      inputData.phone?.trim() === "+1" ||
      inputData.phone === "" ||
      inputData.phone === undefined
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        phone: true,
      }));
      errorObj.phone = true;
    } else if (
      inputData.postalCode?.length > 0 &&
      !POSTALCODE_PATTERN.test(inputData.postalCode?.trim())
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        postalCode: true,
      }));
      errorObj.postalCode = true;
      setIsValidPostalCode(false);
    } else if (inputData.state === "" || inputData.state === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        state: true,
      }));
      errorObj.state = true;
    } else if (
      inputData.service_manager_id === null ||
      inputData.service_manager_id === "" ||
      inputData.service_manager_id === undefined
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        service_manager_id: true,
      }));
      errorObj.service_manager_id = true;
    }

    return errorObj;
  };

  const submitDealerBasicInfo = async (event) => {
    event.preventDefault();

    const valid = ValidationForm();
    // console.log("valid", valid);
    if (
      valid.firstName ||
      valid.lastName ||
      valid.name ||
      valid.email ||
      valid.address ||
      valid.city ||
      valid.postalCode ||
      valid.state ||
      valid.phone ||
      valid.service_manager_id
    ) {
      console.log("Form has validation errors");
      return;
    }

    setIsCreateLoader(true);
    try {
      const response = await axios.post(
        `${apiUrl}/admin/create-dealer`,
        {
          firstName: inputData.firstName,
          lastName: inputData.lastName,
          name: inputData.name,
          email: inputData.email,
          address: inputData.address,
          phone: inputData.phone,
          postalCode: inputData.postalCode,
          state: inputData.state,
          city: inputData.city,
          timezone: inputData.timezone,
          service_manager_id: inputData.service_manager_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setIsCreateLoader(false);

      if (response.data) {
        toast.success("Dealer added successfully");
        setActiveTab("ListDealer");
      }
    } catch (error) {
      setIsCreateLoader(false);
      toast.error(error?.message || "Something went wrong!");
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="ltn__form-box">
        <form action="submit" onSubmit={submitDealerBasicInfo}>
          <div className="row px-4 pt-5 mb-50">
            <h3 className="pb-4">Add New Dealer</h3>
            <div className="col-md-4">
              <CustomInput
                value={inputData.firstName ?? "-"}
                label="First Name"
                labelPosition="up"
                handleChange={handleInputChange}
                name="firstName"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.firstName && "First Name is required."}
              />
            </div>
            {/* Last Name */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.lastName ?? "-"}
                label="Last Name"
                labelPosition="up"
                handleChange={handleInputChange}
                name="lastName"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.lastName && "Last Name is required."}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                value={inputData.email ? inputData.email : ""}
                label="Email Address"
                labelPosition="up"
                handleChange={handleInputChange}
                name="email"
                type="email"
                required={true}
                isRedStar={true}
                error={validationErrors.email && "Email is required."}
              />
            </div>
            {/*Name */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.name ? inputData.name : ""}
                label="Dealership Name"
                labelPosition="up"
                handleChange={handleInputChange}
                name="name"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.name && "Dealership Name is required."}
              />
            </div>
            {/* Phone */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.phone}
                label="Phone"
                labelPosition="up"
                handleChange={(event) => {
                  const { name, value } = event.target;

                  const formattedValue = value?.trim()?.startsWith("+1")
                    ? value
                    : `+1 ${value === "+" ? "" : value}`;

                  // If the input field is for phone, validate the phone number
                  if (name === "phone") {
                    // handlePhoneNumberChange(formattedValue);

                    // Additional pattern test for Canadian phone number (for example purposes)
                    const phonePattern =
                      /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                    const isValidPhoneNumber =
                      phonePattern.test(formattedValue);

                    // Update the state for phone number validation
                    setIsValidPhoneNumber(isValidPhoneNumber);
                  }

                  setInputData((prev) => ({
                    ...prev,
                    [name]: formattedValue,
                  }));

                  setValidationErrors((prev) => ({ ...prev, phone: false }));
                }}
                name="phone"
                type="tel"
                required={true}
                isRedStar={true}
                maxLength={13}
                error={validationErrors?.phone && "Phone number is required."}
              />
            </div>
            {/* Address */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.address ? inputData.address : ""}
                label="Address"
                labelPosition="up"
                handleChange={(e) => {
                  handleInputChange(e);
                  initAutocomplete();
                }}
                name="address"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.address && "Address is required."}
                id="ship-address"
              />
            </div>
            {/* State */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.city}
                label="City"
                labelPosition="up"
                handleChange={handleInputChange}
                name="city"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.city && "City is required."}
                id="locality"
              />
            </div>
            <div className="col-md-4 ">
              <CustomInput
                value={inputData.postalCode.toUpperCase()}
                label="Postal Code"
                labelPosition="up"
                handleChange={handleInputChange}
                name="postalCode"
                type="text"
                required={true}
                isRedStar={true}
                error={
                  validationErrors.postalCode &&
                  POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) ===
                    false ? (
                    <p
                      className=""
                      style={{
                        marginTop: "-20px",
                        color:
                          POSTALCODE_PATTERN.test(
                            inputData.postalCode?.trim()
                          ) === false && "red",
                      }}
                    >
                      {validationErrors.postalCode &&
                      (inputData.postalCode === "" ||
                        inputData.postalCode === null ||
                        inputData.postalCode === undefined)
                        ? "PostalCode is required."
                        : POSTALCODE_PATTERN.test(
                            inputData.postalCode?.trim()
                          ) === false &&
                          "Invalid input: please use a 6 characters sequence following the pattern A1A1A1"}
                    </p>
                  ) : (
                    ""
                  )
                }
                id="postcode"
                maxLength={6}
              />
              {POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) ===
                true && (
                <p
                  style={{
                    // marginTop: "-20px",
                    color: "green",
                  }}
                >
                  {POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) ===
                    true && "Valid"}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Province
                <span className="required-class">*</span>
              </label>
              <div className="input-item">
                <select
                  className="nice-select"
                  style={{ marginBottom: "10px" }}
                  id="state"
                  value={inputData.state ? inputData.state : ""}
                  name="state"
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select Province
                  </option>
                  {STATE_OPTIONS.map((op, index) => (
                    <option key={index} value={op.value}>
                      {op.label}
                    </option>
                  ))}
                </select>
                {validationErrors.state && (
                  <p
                    className="error-message"
                    style={{ color: "red", marginTop: "0px" }}
                  >
                    Please select a province.
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <label>
                ET Manager
                <span className="required-class">*</span>
              </label>
              <CustomSelect
                loading={isServiceManagerListLoader}
                isDisabled={isServiceManagerListLoader}
                isSearch={true}
                placeHolder={"Select ET Manager"}
                // label="ET Manager"
                selectedValue={serviceManagerOptions.find(
                  (x) => x.value === inputData.service_manager_id
                )}
                handleChange={(e) => {
                  const data = {
                    name: "service_manager_id",
                    value: e.value ? e.value : null,
                  };
                  handleInputChange({ target: data });
                }}
                options={serviceManagerOptions}
              />
              {validationErrors.service_manager_id && (
                <p className="error-message" style={{ marginTop: "10px" }}>
                  ET Manager is required.
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label>
                Timezone
                {/* <span className="required-class">*</span> */}
              </label>
              <div className="input-item">
                <select
                  className="nice-select"
                  id="timezone"
                  value={inputData.timezone ? inputData.timezone : ""}
                  name="timezone"
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  required
                >
                  <option disabled value="" selected>
                    Select Timezone
                  </option>
                  {TIMEZONES.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="btn-wrapper px-4">
              <CustomButton
                showLoading={isCreateLoader}
                isDisabled={isCreateLoader || isServiceManagerListLoader}
                label="Add Dealer"
                type="submit"
                style={{ backgroundColor: "#000000" }}
                cssClass="btn-effect-1 text-uppercase btn1"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDealer;
