import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import _ from "lodash";
import { USER_ROLE } from "../utils/constant";
import { apiGetRequest } from "../helpers/Requests";
import { URL_DEALER_LIST } from "../service/URL";

const useDealer = () => {
  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [isDealerListLoader, setIsDealerLoader] = useState();
  const [dealersData, setDealersData] = useState([]);

  useEffect(() => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      getAllDealers();
    }
  }, []);

  const handleDataUpdateById = (data) => {
    const index = dealersData.findIndex((x) => x._id === data.id);
    if (index !== -1) {
      const prevData = _.cloneDeep(dealersData);
      prevData.splice(index, 1, {
        ...prevData[index],
        ...data,
        serviceManager: data?.service_manager_id || "",
      });
      setDealersData([...prevData]);
    }
  };

  const getAllDealers = async () => {
    try {
      setIsDealerLoader(true);
      const res = await apiGetRequest(URL_DEALER_LIST);
      if (res) {
        setDealersData(res);
      }
      setIsDealerLoader(false);
    } catch (err) {
      console.error("err while getting dealer list:", err);
      setIsDealerLoader(false);
    }
  };

  return { isDealerListLoader, dealersData, handleDataUpdateById };
};

export default useDealer;
