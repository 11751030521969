import React from "react";

const OfferInitialTerm = (props) => {
  const {
    serviceCategoryObj,
    index,
    tenure,
    checkBoxesForTerms,
    handleUnCheckedOtherTerms,
  } = props;

  return (
    <div className="offerandservice mb-3" key={index}>
      <div className="servicemain">
        <div className="offernameandprice d-flex align-center">
          <div className="servicecheckbox">
            <input
              type="checkbox"
              checked={
                checkBoxesForTerms?.[
                  `${serviceCategoryObj.categoryId}-${tenure._id}`
                ] || false
              }
              onChange={(e) => {
                const isChecked = e.target.checked;
                handleUnCheckedOtherTerms(
                  serviceCategoryObj.categoryId,
                  tenure._id,
                  isChecked
                );
              }}
            />
          </div>
          <div className="offertag">
            <h6 className="offer-name">
              <strong>{tenure?.tenureDuration}</strong>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferInitialTerm;
