import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import CustomButton from "../../../common/Button/CustomButton";
import _ from "lodash";

const CartItem = (props) => {
  const { material, cartData, setCartData } = props;

  const [counter, setCounter] = useState(material.quantity || null);

  const handleIncrementitemInCart = (counter) => {
    const prevData = _.cloneDeep(cartData);
    const index = prevData.findIndex((e) => e._id === material._id);
    if (index !== -1) {
      prevData.splice(index, 1, { ...material, quantity: counter });
      setCartData([...prevData]);
    }
  };

  const increaseCounter = () => {
    if (counter < 100) {
      setCounter(counter + 1);
      handleIncrementitemInCart(counter + 1);
    }
  };

  const decreaseCounter = () => {
    if (counter - 1 === 0) {
      handleRemoveitemFromCart(material);
      return;
    }

    if (counter > 0) {
      setCounter(counter - 1);
      handleIncrementitemInCart(counter - 1);
    }
  };

  const handleRemoveitemFromCart = (item) => {
    const prevData = _.cloneDeep(cartData);
    const index = prevData.findIndex((e) => e._id === item._id);
    if (index !== -1) {
      prevData.splice(index, 1);
      setCartData([...prevData]);
    }
  };

  return (
    <tr class="cart-item-row">
      <td>
        <div className="d-flex w-100">
          <div className="w-20 product-image d-flex justify-content-center align-items-center">
            <img
              src={material?.image?.url}
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
              }}
              alt="Preview"
              class="img-attachment"
            />
          </div>
          <div className="w-75 ">
            <div className="ml-2 px-4 product-name-div py-3">
              <div>{capitalizeFirstLetter(material.name)}</div>
              <span className="product-cateory-name">
                {capitalizeFirstLetter(material.materialCategory?.name)}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td className="td-content">${material.price}</td>
      <td className="td-content">
        ${parseFloat(material.price * material.quantity).toFixed(2)}
      </td>
      <td className="td-content">
        <div style={{ display: "flex", gap: "10px" }}>
          <button onClick={decreaseCounter}>
            <h1>
              <span class="badge bg-secondary">-</span>
            </h1>
          </button>

          <h1>
            <div class="badge bg-secondary">{counter}</div>
          </h1>
          <button onClick={increaseCounter}>
            <h1>
              <span class="badge bg-secondary">+</span>
            </h1>
          </button>
        </div>
      </td>

      <td className="text-end px-4 td-content">
        <div>
          <CustomButton
            cssClass={"btn-effect-5 btn1 btn-fit-content"}
            variant={"danger"}
            label={"Remove Item"}
            clickHandler={() => {
              handleRemoveitemFromCart(material);
            }}
          />
        </div>
      </td>
    </tr>
  );
};
export default CartItem;
