import React, { useState } from "react";
import "./styles.css";

const CustomPagination = ({
  handlePaginationLimitChange,
  handleChangePage,
  paginationObj,
}) => {
  const optionsPerPage = [5, 10, 15, 20];

  const [itemsPerPage, setItemsPerPage] = useState(paginationObj.limit);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    if (handlePaginationLimitChange) {
      handlePaginationLimitChange(Number(event.target.value));
    }
  };

  return (
    <div className="main-pagination-container">
      <div className={"rows-per-page space-x-2"}>
        <span>Rows per page: </span>
        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          {optionsPerPage.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <span className="instruction">
        {` ${1 + (paginationObj.page - 1) * itemsPerPage}-${Math.min(
          paginationObj.page * itemsPerPage,
          paginationObj.totalCount
        )} of ${paginationObj.totalCount} `}
      </span>
      <div className="arrows-div">
        <button
          className="px-2"
          onClick={() => handleChangePage(paginationObj.prevPage)}
          disabled={paginationObj.prevPage <= 0 ? true : false}
          style={paginationObj.prevPage <= 0 ? { cursor: "auto" } : {}}
        >
          {"<"}
        </button>
        <button
          className="px-2 space-x-2"
          onClick={() => handleChangePage(paginationObj.nextPage)}
          disabled={
            paginationObj.page === paginationObj.totalPages ||
            paginationObj.nextPage <= paginationObj.page
          }
          style={
            paginationObj.page === paginationObj.totalPages ||
            paginationObj.nextPage <= paginationObj.page
              ? { cursor: "auto" }
              : {}
          }
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
