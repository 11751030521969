import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import secureLocalStorage from "react-secure-storage";
import { REPAIR_CLAIM_STATUS, USER_ROLE } from "../../../../utils/constant";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import CustomButton from "../../../common/Button/CustomButton";
import parseError from "../../../../utils/ErrorParse";
import { toast } from "react-toastify";
import TabsComponent from "../../../common/Tab/Tab";
import CommentSection from "../Overview/CommentSection";
import Loader from "../../../common/Loader/Loader";
import LogsSection from "../Overview/LogsSection";
import AttachmentSection from "../Overview/AttachmentSection";
import { apiPatchRequest } from "../../../../helpers/Requests";
import { URL_SUPER_ADMIN_REVERT_CLAIM } from "../../../../service/URL";
import ConfirmationModal from "../../../common/Modal/ConfirmationModal";
import "../claimreq.css";
import PrintClaim from "./printClaim";

const ClaimButton = ({
  cssClass,
  id,
  resultData,
  claimItem,
  handleUpdateReportClaimStatus,
  role,
  handleDeleteReportClaim,
  deleteLoaderId = null,
}) => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  const [modalShow, setModalShow] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const downloadRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [note, setNote] = useState("");
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [selectedClaimFor, setSelectedClaimFor] = useState("");
  const [isClaimSubmitLoader, setIsClaimSubmitLoader] = useState(false);

  const [isConfirmationRevert, setIsConfirmationRevert] = useState(null);
  const [revertClaimLoader, setRevertClaimLoader] = useState(null);

  const handleSubmitClaim = async () => {
    try {
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;

      setIsClaimSubmitLoader(true);

      const payload = {
        reportId: claimItem._id,
        status: selectedClaimFor,
      };

      if (
        selectedClaimFor &&
        selectedClaimFor !== REPAIR_CLAIM_STATUS.ESCALATED
      ) {
        Object.assign(payload, {
          note: note,
        });
      }

      const response = await axios.post(
        `${apiUrl}/${roleApi?.toLowerCase()}/report-claim-status`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsClaimSubmitLoader(false);
      if (response?.data) {
        setSelectedClaim(null);
        setSelectedClaimFor("");
        setNote("");
        handleUpdateReportClaimStatus(response?.data);
        toast.success(
          `Claim ${capitalizeFirstLetter(selectedClaimFor)} Successfully!`
        );
      }
    } catch (err) {
      setIsClaimSubmitLoader(false);
      console.log("err", err);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  const getNoteTtile = (status) => {
    switch (status) {
      case REPAIR_CLAIM_STATUS.ACCEPT:
        return "Accecpted";
      case REPAIR_CLAIM_STATUS.REJECT:
        return "Rejected";
      case REPAIR_CLAIM_STATUS.ESCALATED:
        return "Escalated";
      case REPAIR_CLAIM_STATUS.PENDING:
        return "Pending";
      default:
        break;
    }
  };

  const [tabCurrentKey, setTabCurrentKey] = useState("");

  const claimOverviewTabs = () => {
    const data = [
      { element: OverviewJSX(), eventKey: "overview", title: "Overview" },
      {
        element: (
          <AttachmentSection
            isReFresh={tabCurrentKey === "attachments"}
            role={role}
            claimId={claimItem._id}
          />
        ),
        eventKey: "attachments",
        title: "Attachments",
      },
      {
        element: (
          <CommentSection
            isReFresh={tabCurrentKey === "comments"}
            role={role}
            claimId={claimItem._id}
          />
        ),
        eventKey: "comments",
        title: "Comments",
      },
      {
        element: (
          <LogsSection
            isReFresh={tabCurrentKey === "logs"}
            role={role}
            claimId={claimItem._id}
          />
        ),
        eventKey: "logs",
        title: "Logs",
      },
    ];
    return data;
  };

  const OverviewJSX = () => {
    return (
      <>
        {selectedUser && (
          <div className="calimReq">
            {/* <p>{selectedUser + 1}</p> */}
            <p className="selectusername text-white">
              <span className="spantext">Name: </span> {selectedUser.name}
            </p>
            <p className="selectusername text-white">
              <span className="spantext">Email: </span> {selectedUser.email}
            </p>
            <p className="selectusername text-white">
              <span className="spantext"> Phone Number: </span>
              {selectedUser.phoneNumber}
            </p>
            <p className="selectusername text-white">
              <span className="spantext">Agreement Number: </span>
              {selectedUser.agreementNumber}
            </p>
            <p className="selectusername text-white">
              <span className="spantext">Odometer Raading: </span>
              {selectedUser.odometerReading}
            </p>
            <p className="ReportDescription">
              <span className="spantext">Report Description: </span>
              {selectedUser.reportDescription}
            </p>
            {selectedUser.status !== "pending" && (
              <p className="ReportDescription">
                <span className="spantext">
                  {`${getNoteTtile(selectedUser.status)} Notes: `}
                </span>
                {selectedUser.note}
              </p>
            )}
          </div>
        )}
      </>
    );
  };

  const handleRevertClaimApi = async (claimId) => {
    try {
      setRevertClaimLoader(claimId);
      const response = await apiPatchRequest(URL_SUPER_ADMIN_REVERT_CLAIM, {
        claimId,
      });
      if (response?.data) {
        handleUpdateReportClaimStatus(response?.data);
        toast.success(`Claim Reverted Successfully!`);
      }
      setRevertClaimLoader(null);
    } catch (err) {
      console.log("err", err);
      setRevertClaimLoader(claimId);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  return (
    <>
      {(role === USER_ROLE.STAFF.SERVICE_ADVISOR ||
        role === USER_ROLE.ADMIN) && (
        <td style={{ width: "15%" }} className={cssClass}>
          {role === USER_ROLE.STAFF.SERVICE_ADVISOR && (
            <div className="mainbutton">
              {claimItem.status !== REPAIR_CLAIM_STATUS.ACCEPT &&
              claimItem.status !== REPAIR_CLAIM_STATUS.REJECT ? (
                <button
                  disabled={claimItem?.status !== REPAIR_CLAIM_STATUS.PENDING}
                  className="claimButton"
                  onClick={() => {
                    if (claimItem?.status !== REPAIR_CLAIM_STATUS.PENDING)
                      return;
                    setSelectedClaim(claimItem);
                    setSelectedClaimFor(REPAIR_CLAIM_STATUS.ESCALATED);
                  }}
                  style={{
                    backgroundColor:
                      claimItem?.status === REPAIR_CLAIM_STATUS.PENDING
                        ? "green"
                        : "#80e080",
                    cursor:
                      claimItem.status === REPAIR_CLAIM_STATUS.ESCALATED
                        ? "auto"
                        : "pointer",
                  }}
                >
                  {claimItem?.status === REPAIR_CLAIM_STATUS.PENDING
                    ? "Escalate"
                    : "Escalated"}
                </button>
              ) : (
                claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT && (
                  <button
                    disabled={claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT}
                    className="claimButton"
                    onClick={() => {
                      if (claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT)
                        return;
                    }}
                    style={{
                      backgroundColor:
                        claimItem?.status !== REPAIR_CLAIM_STATUS.ACCEPT
                          ? "green"
                          : "#80e080",
                      cursor:
                        claimItem.status === REPAIR_CLAIM_STATUS.ACCEPT
                          ? "auto"
                          : "pointer",
                    }}
                  >
                    {claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT
                      ? "Accepted"
                      : "Accept"}
                  </button>
                )
              )}
              {(claimItem.status === REPAIR_CLAIM_STATUS.PENDING ||
                (claimItem.status !== REPAIR_CLAIM_STATUS.ACCEPT &&
                  claimItem.status === REPAIR_CLAIM_STATUS.REJECT)) && (
                <button
                  disabled={claimItem.status === REPAIR_CLAIM_STATUS.REJECT}
                  className="claimButton"
                  onClick={() => {
                    if (claimItem.status === REPAIR_CLAIM_STATUS.REJECT) return;
                    setSelectedClaim(claimItem);
                    setSelectedClaimFor(REPAIR_CLAIM_STATUS.REJECT);
                  }}
                  style={{
                    backgroundColor:
                      claimItem.status === REPAIR_CLAIM_STATUS.REJECT
                        ? "#e3b1b1"
                        : "red",
                    cursor:
                      claimItem.status === REPAIR_CLAIM_STATUS.REJECT
                        ? "auto"
                        : "pointer",
                  }}
                >
                  {claimItem.status === REPAIR_CLAIM_STATUS.REJECT
                    ? "Rejected"
                    : "Reject"}
                </button>
              )}
            </div>
          )}
          {role === USER_ROLE.ADMIN && (
            <div className="mainbutton">
              {claimItem.status !== REPAIR_CLAIM_STATUS.REJECT && (
                <button
                  disabled={claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT}
                  className="claimButton"
                  onClick={() => {
                    if (claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT)
                      return;
                    setSelectedClaim(claimItem);
                    setSelectedClaimFor(REPAIR_CLAIM_STATUS.ACCEPT);
                  }}
                  style={{
                    backgroundColor:
                      claimItem?.status !== REPAIR_CLAIM_STATUS.ACCEPT
                        ? "green"
                        : "#80e080",
                    cursor:
                      claimItem.status === REPAIR_CLAIM_STATUS.ACCEPT
                        ? "auto"
                        : "pointer",
                  }}
                >
                  {claimItem?.status === REPAIR_CLAIM_STATUS.ACCEPT
                    ? "Accepted"
                    : "Accept"}
                </button>
              )}

              {claimItem.status !== REPAIR_CLAIM_STATUS.ACCEPT && (
                <button
                  disabled={claimItem.status === REPAIR_CLAIM_STATUS.REJECT}
                  className="claimButton"
                  onClick={() => {
                    if (claimItem.status === REPAIR_CLAIM_STATUS.REJECT) return;
                    setSelectedClaim(claimItem);
                    setSelectedClaimFor(REPAIR_CLAIM_STATUS.REJECT);
                  }}
                  style={{
                    backgroundColor:
                      claimItem.status === REPAIR_CLAIM_STATUS.REJECT
                        ? "#e3b1b1"
                        : "red",
                    cursor:
                      claimItem.status === REPAIR_CLAIM_STATUS.REJECT
                        ? "auto"
                        : "pointer",
                  }}
                >
                  {claimItem.status === REPAIR_CLAIM_STATUS.REJECT
                    ? "Rejected"
                    : "Reject"}
                </button>
              )}
            </div>
          )}
        </td>
      )}

      <td style={{ width: "20%" }} className={cssClass}>
        <div className="mainbutton">
          <button
            className="OverviewButton"
            onClick={() => {
              setModalShow(true);
              setSelectedUser(claimItem);
            }}
          >
            Overview
          </button>
          <PrintClaim
            handlePrint={handlePrint}
            downloadRef={downloadRef}
            getNoteTtile={getNoteTtile}
            claim={claimItem}
          />

          {role === USER_ROLE.SUPER_ADMIN && (
            <td>
              <div className="mainbutton">
                <button
                  disabled={
                    claimItem?.status === REPAIR_CLAIM_STATUS.PENDING ||
                    claimItem?.status === REPAIR_CLAIM_STATUS.ESCALATED
                  }
                  className="claimButton"
                  onClick={() => {
                    setIsConfirmationRevert(claimItem._id);
                  }}
                  style={{
                    backgroundColor:
                      claimItem?.status !== REPAIR_CLAIM_STATUS.PENDING &&
                      claimItem?.status !== REPAIR_CLAIM_STATUS.ESCALATED
                        ? "#646c73"
                        : "#97b5d0",
                    cursor:
                      claimItem?.status === REPAIR_CLAIM_STATUS.PENDING ||
                      claimItem?.status === REPAIR_CLAIM_STATUS.ESCALATED
                        ? "auto"
                        : "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Revert
                  {revertClaimLoader === claimItem._id && (
                    <Loader isSmallView={true} />
                  )}
                </button>
              </div>
            </td>
          )}
          {(role === USER_ROLE.STAFF.SERVICE_ADVISOR ||
            role === USER_ROLE.ADMIN) && (
            <button
              disabled={claimItem._id === deleteLoaderId}
              style={{
                backgroundColor: "red",
                alignItems: "center",
                cursor: claimItem._id === deleteLoaderId ? "auto" : "pointer",
              }}
              className={`PrintButton d-flex justify-content-center  ${
                claimItem._id === deleteLoaderId && "button-disabled"
              }`}
              onClick={() => {
                if (claimItem._id === deleteLoaderId) return;
                handleShow();
              }}
            >
              Delete
              {claimItem._id === deleteLoaderId && (
                <Loader cssClass={"text-white"} isSmallView={true} />
              )}
            </button>
          )}
        </div>
      </td>

      {modalShow && (
        <Modal
          className="md-content claim-req-modal"
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            setModalShow(false);
            setSelectedUser(null);
            setTabCurrentKey("");
          }}
        >
          <Modal.Header closeButton className="custom-modal-header md-header">
            <Modal.Title id="contained-modal-title-vcenter">
              Claim Request Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="md-body" style={{ height: "82vh" }}>
            <TabsComponent
              data={claimOverviewTabs()}
              handleChangeTab={(e) => setTabCurrentKey(e)}
            />
          </Modal.Body>
        </Modal>
      )}

      {/* delete MOdel */}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header
            style={{
              borderBottom:
                "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
            }}
            closeButton
          >
            <Modal.Title>Delete Claim</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this claim?</Modal.Body>
          <Modal.Footer>
            <CustomButton
              isDisabled={claimItem._id === deleteLoaderId}
              label="Cancel"
              clickHandler={() => {
                handleClose();
              }}
              cssClass="btn-effect-1 btn1"
            />
            <CustomButton
              isDisabled={claimItem._id === deleteLoaderId}
              showLoading={claimItem._id === deleteLoaderId}
              label="Yes"
              clickHandler={() => {
                handleDeleteReportClaim(
                  claimItem._id,
                  claimItem.agreementNumber
                );
                handleClose();
              }}
              cssClass="btn-effect-1 btn1"
            />
          </Modal.Footer>
        </Modal>
      )}

      {/* Claim Note */}
      {selectedClaim && (
        <Modal
          show={selectedClaim !== null}
          onHide={() => {
            setSelectedClaim(null);
            setSelectedClaimFor("");
          }}
        >
          <Modal.Header
            style={{
              borderBottom:
                "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
            }}
            closeButton
          >
            <Modal.Title>
              Claim {`${capitalizeFirstLetter(selectedClaimFor)}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {selectedClaimFor === REPAIR_CLAIM_STATUS.ESCALATED ? (
                <div className="row">
                  <div className="col-12">
                    <span>Are you sure to escalate report?</span>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <label>
                      Remarks
                      <span className="required-class">*</span>
                    </label>
                    <textarea
                      // class="form-control"
                      name="note"
                      // rows={5}
                      // style={{ resize: "none" }}
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                      required={true}
                    />

                    {/* {validationErrors?.firstName && (
                          <p className="error-message">Province is required</p>
                        )} */}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              isDisabled={isClaimSubmitLoader}
              label="Cancel"
              clickHandler={() => {
                setSelectedClaim(null);
                setSelectedClaimFor("");
                setIsClaimSubmitLoader(false);
              }}
              cssClass="btn-effect-1 btn1"
            />
            <CustomButton
              showLoading={isClaimSubmitLoader}
              isDisabled={isClaimSubmitLoader}
              label="Submit"
              clickHandler={handleSubmitClaim}
              style={{ backgroundColor: "#000000" }}
              cssClass="btn-effect-1 btn1"
            />
          </Modal.Footer>
        </Modal>
      )}

      {/* Confirmation Modal */}
      {isConfirmationRevert !== null && (
        <ConfirmationModal
          isOpen={isConfirmationRevert !== null}
          confirmationStatus={(e) => {
            if (e === true) {
              handleRevertClaimApi(isConfirmationRevert);
            }
            setIsConfirmationRevert(null);
          }}
          title={"Revert Claim Confirmation"}
          heading={"Are you sure to revert this claim ?"}
        />
      )}
    </>
  );
};

export default ClaimButton;
