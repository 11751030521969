import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const generateDayWiseTimeSeries = (baseval, count, yrange) => {
    let i = 0;
    let series = [];
    while (i < count) {
        const x = baseval;
        const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
    }
    return series;
};

const ApexChart = () => {
    const [state, setState] = useState({
        series: [
            {
                name: 'Approved',
                data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                    min: 10,
                    max: 60,
                }),
            },
            {
                name: 'Cancelled',
                data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                    min: 10,
                    max: 20,
                }),
            },
            {
                name: 'Pending',
                data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                    min: 10,
                    max: 15,
                }),
            },
        ],
        options: {
            chart: {
                type: 'area',
                height: 350,
                stacked: true,
                events: {
                    selection: function (chart, e) {
                        console.log(new Date(e.xaxis.min));
                    },
                },
            },
            colors: ['#3F5A38', '#c44646', '#00E396'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
            },
            xaxis: {
                type: 'datetime',
                grid: {
                    show: false,
                },
            },
        },
    });

    return (
        <div id="chart">
            <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
        </div>
    );
};

export default ApexChart;
