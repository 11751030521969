import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { classNames } from "../../../../utils/utils";
import { POSTALCODE_PATTERN, STATE_OPTIONS } from "../../../../utils/constant";
import _ from "lodash";
import { toast } from "react-toastify";

const CustomerInformation = (props) => {
  const {
    error,
    setError,
    customer,
    setCustomer,
    isEditReport,
    pst,
    gst,
    setGst,
    setPst,
    disableTaxRatesChange,
  } = props;

  const [tax, setTax] = useState({});

  useEffect(() => {
    setTax({
      pst: pst,
      gst: gst,
    });
  }, [gst, pst]);

  //google api looup
  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;

  const [modalShow, setModalShow] = useState(false);

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState("");

  const [isValidPostalCode, setIsValidPostalCode] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const isEmpty = value.trim() === "";

    if (isEmpty) {
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: isEmpty,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
    if (name === "postalCode") {
      setIsValidPostalCode(POSTALCODE_PATTERN.test(value.trim()));
    }

    setCustomer((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    setIsValidPostalCode(POSTALCODE_PATTERN.test(customer?.postalCode?.trim()));
  }, [customer]);

  function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");

    // Check if the fields are found before proceeding
    if (address1Field) {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      address1Field.focus();

      autocomplete.addListener("place_changed", fillInAddress);
    } else {
      console.error("One or more required fields not found in the DOM.");
    }
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";

    const hasPostalCode = (component) => {
      return component?.types?.includes("postal_code");
    };
    const hasPostalCodeObject = place.address_components?.some(hasPostalCode);

    if (hasPostalCodeObject) {
    } else {
      setCustomer((prev) => ({
        ...prev,
        postalCode: postcode.trim()?.replace(" ", ""),
      }));
      setIsValidPostalCode(
        POSTALCODE_PATTERN.test(customer?.postalCode?.trim())
      );
    }

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = `${component.long_name} ${address1}`;
          break;
        case "route":
          address1 += ` ${component.short_name}`;
          setCustomer((prev) => ({
            ...prev,
            address: address1,
          }));
          break;
        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          setCustomer((prev) => ({
            ...prev,
            postalCode: postcode.trim()?.replace(" ", ""),
          }));
          setIsValidPostalCode(POSTALCODE_PATTERN.test(postcode?.trim()));
          break;
        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;
        case "locality":
          const localityElement = document.querySelector("#locality");
          if (localityElement) {
            localityElement.value = component.long_name;
            setCustomer((prev) => ({
              ...prev,
              city: localityElement.value,
            }));
          }
          break;
        case "administrative_area_level_1":
          const stateElement = document.querySelector("#state");
          if (stateElement) {
            stateElement.value = component.short_name;
            setCustomer((prev) => ({
              ...prev,
              state:
                STATE_OPTIONS.findIndex(
                  (op) =>
                    op.value.toLowerCase() ===
                    component.long_name?.toLowerCase()
                ) !== -1
                  ? component.long_name
                  : "",
            }));
          }
          break;
        case "country":
          const countryElement = document.querySelector("#country");
          if (countryElement) {
            countryElement.value = component.long_name;
          }
          break;
        default:
          break;
      }
    }
    if (address1Field) {
      address1Field.value = address1;
    }
    if (postalField) {
      postalField.value = postcode;
    }
    if (address2Field) {
      address2Field.focus();
    }
  }

  return (
    <>
      <div className="row mt-2">
        {/* <div> */}
        <div className="col-md-4 px-sm-none">
          <label>
            First Name <span className="required-class">*</span>
          </label>
          <input
            type="text"
            name="firstName"
            value={customer.firstName}
            onChange={handleInputChange}
            required
          />
          {error.firstName && (
            <p className="error-msg">First Name is required</p>
          )}
        </div>
        <div className="col-md-4 px-sm-none">
          <label>
            Last Name <span className="required-class">*</span>
          </label>
          <input
            type="text"
            name="lastName"
            value={customer.lastName}
            onChange={handleInputChange}
            required
          />
          {error.lastName && <p className="error-msg">Last Name is required</p>}
        </div>
        <div className="col-md-4 px-sm-none">
          <label>
            Email <span className="required-class">*</span>
          </label>
          <input
            // disabled={isEditReport !== null}
            type="email"
            name="email"
            // placeholder="example@example.com"
            value={customer.email}
            onChange={(e) => {
              // if (isEditReport === null) {
              handleInputChange(e);
              // }
            }}
            required
          />
          {error.email && <p className="error-msg">Email is required</p>}
        </div>
        <div className="col-md-4 px-sm-none">
          <label>
            Phone
            <span className="required-class">*</span>
          </label>
          <input
            className="text no-spin-buttons"
            type="tel"
            name="phone"
            maxLength={13}
            value={customer.phone}
            onChange={(event) => {
              const { name, value } = event.target;
              const formattedValue = value.startsWith("+1")
                ? value
                : `+1 ${value}`;
              if (name === "phone") {
                const phonePattern =
                  /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                const isValidPhoneNumber = phonePattern.test(formattedValue);
                setIsValidPhoneNumber(isValidPhoneNumber);
              }
              setCustomer((prev) => ({
                ...prev,
                [name]: formattedValue,
              }));
            }}
            required
          />
          {/* {error.phone && (
            <p className="error-msg">Phone is required</p>
          )} */}
          {customer.phone.length > 0 &&
            !/^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(
              customer.phone
            ) && (
              <p
                className=""
                style={{
                  marginTop: "-20px",
                  color: isValidPhoneNumber ? "green" : "red",
                }}
              >
                {isValidPhoneNumber
                  ? ""
                  : "Invalid input: please enter a valid Canadian phone number"}
              </p>
            )}
        </div>
        {/* <div className="col-md-4 px-sm-none">
  <label>Phone <span className="required-class">*</span></label>
  <input
    className="text no-spin-buttons"
    type="number"
    name="phone"
    value={registrants.phone ? registrants.phone : ""}
    onChange={handleInputChange}
  />
</div> */}
        <div className="col-md-4 px-sm-none">
          <label>
            Address <span className="required-class">*</span>
          </label>
          <div>
            {/* <GooglePlacesAutocomplete
      apiKey="AIzaSyBslclXqytdDcOwzY72vQmKHtr5v-w8C_0"
      selectProps={{
        value,
        onChange: setValue,
      }}
    /> */}
            {/* <div> */}
            <input
              // className="d-none"
              id="ship-address"
              type="text"
              name="address"
              value={customer.address}
              onChange={(e) => {
                handleInputChange(e);
                initAutocomplete();
              }}
              required
            />
            {error.address && <p className="error-msg">Address is required</p>}
            {/* Your other form elements here */}
            {/* <input type="text" id="address" placeholder="Ship Address" /> */}
            {/* <input className="" type="text" id="address2" placeholder="Address 2" /> */}
            {/* <input type="text" id="postcode" placeholder="Postal Code" /> */}
            {/* <input type="text" id="locality" placeholder="Locality" /> */}
            {/* <input type="text" id="state" placeholder="State" /> */}
            {/* <input type="text" id="country" placeholder="Country" /> */}
            {/* </div> */}
          </div>
          {/* <input
    className="d-none"
    type="text"
    name="address"
    // placeholder="Ship Address"
    value={registrants.address ? registrants.address : ""}
    onChange={handleInputChange}
    required
  /> */}
        </div>
        <div className="col-md-4 px-sm-none">
          <label>
            City <span className="required-class">*</span>
          </label>
          <input
            type="text"
            name="city"
            id="locality"
            value={customer.city}
            onChange={handleInputChange}
            required
          />
          {error.city && <p className="error-msg">City is required</p>}
        </div>
        <div className="col-md-4 px-sm-none">
          <label>
            Province <span className="required-class">*</span>
          </label>
          <div className="input-item">
            <select
              style={{ marginBottom: "0px" }}
              className="form-select form-select-option selectdropdown nice-select"
              aria-label="Default select example"
              id="state"
              name="state"
              value={customer.state}
              onChange={(e) => {
                handleInputChange(e);
              }}
            >
              <option value="" disabled selected>
                Select Province
              </option>
              {STATE_OPTIONS.map((op, index) => (
                <option key={index} value={op.value}>
                  {op.label}
                </option>
              ))}
            </select>
          </div>
          {error.state && <p className="error-msg">Province is required</p>}
        </div>
        <div className="col-md-4">
          <label>
            Postal Code <span className="required-class">*</span>{" "}
          </label>
          <input
            className="text no-spin-buttons"
            id="postcode"
            type="text"
            name="postalCode"
            value={
              customer.postalCode
                ? customer.postalCode?.trim()?.toUpperCase()
                : ""
            }
            onChange={handleInputChange}
            maxLength={6}
            required
          />
          {error.postalCode ? (
            <p className="error-msg">Postal Code is required</p>
          ) : (
            customer.postalCode?.length > 0 && (
              <p
                className=""
                style={{
                  marginTop: "-20px",
                  color: isValidPostalCode ? "green" : "red",
                }}
              >
                {isValidPostalCode
                  ? "Valid"
                  : "Invalid input: please use a 6 characters sequence following the pattern A1A1A1"}
              </p>
            )
          )}
        </div>
        <div className="col-md-4 px-sm-none">
          <label>Condo/Unit Number </label>
          <input
            type="text"
            name="unitNumber"
            value={customer.unitNumber ? customer.unitNumber : ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-md-4 px-sm-none">
          <label>Company</label>
          <input
            type="text"
            name="company"
            value={customer.company ? customer.company : ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-4 px-sm-none">
          <label>Co-Applicant</label>
          <input
            type="text"
            name="coApplicant"
            value={customer.coApplicant ? customer.coApplicant : ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-md-4 px-sm-none">
          <label>Lienholder</label>
          <input
            type="text"
            name="lienholder"
            value={customer.lienholder ? customer.lienholder : ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-8">
          <label>Comments</label>
          <span className="d-flex">
            <textarea
              type="text"
              name="customerComment"
              value={customer.customerComment ? customer.customerComment : ""}
              onChange={handleInputChange}
            />
          </span>
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-center">
          { disableTaxRatesChange && (
            <button
            type="button"
            className="btn theme-btn-1 btn-effect-1 text-uppercase btn1 tax-btn"
            style={{
              padding: "15px 30px",
              backgroundColor: "rgb(255, 90, 60)",
              color: "#FFF",
              // marginBottom: "30px",
              marginLeft: "40px",
              fontSize: "16px",
              width: "350px",
              height: "70px",
            }}
            onClick={() => setModalShow(true)}
            >
              $ Customize Tax Rates
            </button>)
          }
        </div>
      </div>
      {modalShow && (
        <Modal
          show={modalShow}
          // size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            setTax({
              pst: pst,
              gst: gst,
            });
            setModalShow(false);
          }}
          className="md-content"
        >
          <Modal.Header closeButton className="md-body">
            <h4>CUSTOMIZE TAX RATES</h4>
          </Modal.Header>

          <Modal.Body style={{ paddingBottom: "0px" }} className="md-body">
            {/* <h4 className="mb-3"></h4> */}
            <div className="CustomizeTaxModal justify-content-start">
              <div className="col-md-4 px-sm-none">
                <label htmlFor="gstRate" className="text-white mb-2">
                  GST Rate:
                </label>
                <NumericFormat
                  // disabled={true}
                  defaultValue={tax?.gst}
                  allowNegative={false}
                  placeholder="Gst Rate"
                  id="numericInput"
                  min={0}
                  max={100}
                  maxLength={100}
                  className={classNames("text numericInput")}
                  value={tax?.gst || 0}
                  decimalScale={2}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;

                    // handleInputChange({
                    //   target: { name: "price", value: value },
                    // });

                    setTax((prev) => ({
                      ...prev,
                      gst: value,
                    }));
                    if (value === null || value === undefined) {
                      e.value = 0;
                      return;
                    }
                  }}
                />
              </div>
              <div className="col-md-4 px-sm-none">
                <label htmlFor="pstRate" className="text-white mb-2">
                  PST Rate:
                </label>
                <NumericFormat
                  defaultValue={tax?.pst}
                  allowNegative={false}
                  placeholder="Pst Rate"
                  id="numericInput"
                  min={0}
                  maxLength={"100"}
                  className={classNames("text numericInput")}
                  value={tax?.pst}
                  decimalScale={2}
                  // prefix={"$"}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;
                    // handleInputChange({
                    //   target: { name: "price", value: value },
                    // });
                    setTax((prev) => ({
                      ...prev,
                      pst: _.isEmpty(value) ? 0 : value,
                    }));
                  }}
                />
              </div>
              {/* <div className="col-md-4 px-sm-none">
                <span className="hstCheckBox">
                  <input
                    type="checkbox"
                    id="includeGST"
                    name="includeGST"
                    checked={includeHST}
                    onChange={handleIncludeHSTChange}
                  />
                  <label htmlFor="includeGST" className="text-white">
                    Include HST?
                  </label>
                </span>
              </div> */}
            </div>
            <div>
              <button
                className="btn-customizeTax btn theme-btn-1 btn-effect-1 text-uppercase btn1 mb-3"
                onClick={() => {
                  // console.log("tax", tax);
                  if (tax?.gst > 100) {
                    toast.error("GST cannot be greater than 100.");
                    return;
                  }
                  if (tax?.pst > 100) {
                    toast.error("PST cannot be greater than 100.");
                    return;
                  }

                  if (
                    tax?.gst === null ||
                    tax?.gst === undefined ||
                    tax?.gst === "" ||
                    tax?.pst === null ||
                    tax?.pst === undefined ||
                    tax?.pst === ""
                  ) {
                    toast.error("Fill Required Fields!");
                    return;
                  }

                  setPst(tax?.pst);
                  setGst(tax?.gst);
                  setModalShow(false);
                }}
              >
                {/* Calculate Effective Tax Rate */}
                Save
              </button>

              {/* {effectiveTaxRate !== null && (
                <div>
                  <p className="text-white mb-4">
                    <span className="font-bold">Effective Tax Rate:</span>{" "}
                    {effectiveTaxRate}%
                  </p>
                </div>
              )} */}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CustomerInformation;
