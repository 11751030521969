const extractThirdAndFourthParts = (
  firstPart,
  secondPart,
  checkBoxesForServices
) => {
  const prefix = `${firstPart}-${secondPart}`;
  const result = [];

  Object.entries(checkBoxesForServices).forEach(([key, value]) => {
    if (key.startsWith(prefix) && value === true) {
      const parts = key.substring(prefix.length + 1).split("-");
      const partObj = { programId: parts[0] };
      if (parts.length > 1) {
        partObj.priceId = parts[1];
      }
      result.push(partObj);
    }
  });

  return result;
};

export const groupByServiceCategoryId = (
  checkBoxesForTerms = {},
  checkBoxesForServices = {}
) => {
  return Object.keys(checkBoxesForTerms).reduce((acc, key) => {
    if (!checkBoxesForTerms[key]) {
      return acc;
    }

    const [firstPart, secondPart] = key.split("-");
    if (!acc[firstPart]) {
      acc[firstPart] = {};
    }

    acc[firstPart][secondPart] = extractThirdAndFourthParts(
      firstPart,
      secondPart,
      checkBoxesForServices
    );
    return acc;
  }, {});
};

const preprocessOriginalData = (originalData) => {
  const categoryMap = new Map();

  originalData.forEach((category) => {
    const programMap = new Map();

    category.programs.forEach((program) => {
      const priceMap = new Map();

      program.prices.forEach((price) => {
        priceMap.set(price._id, {
          ...price,
          programId: program.programId,
          programName: program.programName,
        });
      });

      programMap.set(program.programId, { ...program, prices: priceMap });
    });

    categoryMap.set(category._id, {
      ...category,
      programs: programMap,
      purchaseDate: category?.purchaseDate,
      retailerPrice: category?.retailerPrice || 0,
      antitheft: category?.antitheft,
      tiresize: category?.tiresize,
    });
  });

  return categoryMap;
};

export function extractDetails(
  dataStructure,
  serviceCatPrograms,
  serviceTenureOptions,
  serviceTierOptions
) {
  const preprocessedData = preprocessOriginalData(serviceCatPrograms);
  const results = [];

  Object.entries(dataStructure).forEach(([categoryId, serviceTenures]) => {
    const category = preprocessedData.get(categoryId);
    if (category) {
      const categoryResult = {
        categoryId: categoryId,
        categoryName: category.categoryName,
        purchaseDate: category?.purchaseDate || null,
        retailerPrice: category?.retailerPrice || 0,
        antitheft: category?.antitheft || null,
        tiresize: category.tiresize || null,
        programs: [],
      };

      Object.entries(serviceTenures).forEach(
        ([serviceTenureId, programPriceMappings]) => {
          if (programPriceMappings.length > 0) {
            programPriceMappings.forEach((mapping) => {
              const program = category.programs.get(mapping.programId);
              if (program) {
                const price = program.prices.get(mapping.priceId);
                if (price && price.serviceTenure === serviceTenureId) {
                  categoryResult.programs.push({
                    programId: program.programId,
                    programName: program.programName,
                    priceDetails: {
                      tenureId: price.serviceTenure,
                      serviceTenure:
                        serviceTenureOptions?.[price.serviceTenure] || null,
                      priceId: price._id,
                      price: price.hasOwnProperty("customPrice")
                        ? price.customPrice
                        : price.price,
                    },
                  });
                }
              }
            });
          }
        }
      );
      if (
        categoryResult.programs.length > 0 ||
        Object.entries(serviceTenures).length > 0
      ) {
        results.push(categoryResult);
      }
    }
  });

  return results;
}

export function findTrueValuesByCatId(prevData, catId) {
  const trueKeyEntry = Object.entries(prevData).find(
    ([key, value]) => key.startsWith(`${catId}-`) && value === true
  );
  return trueKeyEntry ? trueKeyEntry[0] : null;
}

export function preprocessCategoriesForQuickAccess(categories) {
  let quickAccessMap = {};

  categories.forEach((category) => {
    category.programs.forEach((program) => {
      program.prices.forEach((price) => {
        const key = `${category._id}-${price.serviceTenure}`;
        if (!quickAccessMap[key]) {
          quickAccessMap[key] = [];
        }
        quickAccessMap[key].push({
          newProgramId: program.programId,
          newPriceId: price._id,
          newServiceTierId: price.serviceTier || null,
        });
      });
    });
  });

  return quickAccessMap;
}
