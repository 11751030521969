export const BASE_URL = process.env.REACT_APP_API_URL;

export const IMAGE_URL = "https://extendedtrust.ca/";

// Remote production url
export const PDF_URL = "https://app-djewqp7shq-uc.a.run.app/api/pdf/";
// local test url
// export const PDF_URL = "http://127.0.0.1:5001/pdf-api-790d4/us-central1/app/api/pdf";

//Super Admin
export const URL_SUPER_ADMIN_CREATE_ADMIN = `${BASE_URL}/superadmin/create-admin`;
export const URL_SUPER_ADMIN_ADMIN_LIST = `${BASE_URL}/superadmin/admin-list`;
export const URL_SUPER_ADMIN_CHANGE_ADMIN_VERIFY = `${BASE_URL}/superadmin/verify-admin`;
export const URL_SUPER_ADMIN_REVERT_CLAIM = `${BASE_URL}/superadmin/revert-claim`;
export const URL_SUPER_ADMIN_REVERT_ORDER = `${BASE_URL}/superadmin/revert-order`;
export const URL_SUPER_ADMIN_REVERT_REMITTANCE_PAYMENT = `${BASE_URL}/superadmin/revert-remittance-payment`;

export const URL_DEALER_LIST = `${BASE_URL}/dealer/list`;

export const URL_ADMIN_SERVICE_CATEGOREIS_LIST = `${BASE_URL}/admin/service-category`;
export const URL_ADMIN_SERVICE_TENURES_LIST = `${BASE_URL}/admin/service-tenure`;
export const URL_ADMIN_SERVICE_TIERS_LIST = `${BASE_URL}/admin/service-tier`;
export const URL_ADMIN_SERVICE_PROGRAMS_LIST = `${BASE_URL}/admin/service-programs`;
export const URL_ADMIN_SERVICE_PROGRAM_PRICE = `${BASE_URL}/admin/service-program-price`;
export const URL_ADMIN_SERVICE_PROGRAM_PRICE_DEALER_SPECIFIC = `${BASE_URL}/admin/service-program-price/dealer-specific`;

//Admin
export const URL_ADMIN_SERVICE_MANAGER = `${BASE_URL}/admin/service-manager`;

export const URL_ADMIN_MATERIAL_CATEGORY = `${BASE_URL}/admin/material-category`;
export const URL_ADMIN_MATERIAL_CATALOGUE = `${BASE_URL}/admin/material-catalogue`;
export const URL_ADMIN_DEALER_MATERIAL_CATALOGUE_PRICING = `${BASE_URL}/admin/material-dealer-pricing`;

export const URL_ADMIN_GET_MANAGER_BY_DEALER_ID = `${BASE_URL}/admin/manager-by-dealer`;
export const URL_DEALER_GET_MANAGER_BY_ID = `${BASE_URL}/dealer/manager-list`;

export const URL_ADMIN_GET_REGISTRATION_REPORT = `/report-logs`;

// manager
export const URL_MANAGER_VERIFIED_LIST = `${BASE_URL}/dealer/verify-managers`;
export const URL_MANAGER_MATERIAL_CATEGORY = `${BASE_URL}/admin/material-category`;
export const URL_MANAGER_SERVICE_PROGRAMS_LIST = `${BASE_URL}/manager/service-programs`;
export const URL_STAFF_SERVICE_PROGRAMS_LIST = `${BASE_URL}/staff/service-programs`;

// Dealer
export const URL_DEALER_SERVICE_PROGRAMS_LIST = `${BASE_URL}/dealer/service-programs`;
export const URL_DEALER_MATERIAL_CATEGORY = `${BASE_URL}/admin/material-category`;

//Order
export const URL_DEALER_ORDER_PLACE = `${BASE_URL}/dealer/material/order-place`;
export const URL_ADMIN_ORDER_PAID_STATUS_CHANGE = `${BASE_URL}/admin/order/paid-status`;
export const URL_ADMIN_REPORT_PAID_STATUS_CHANGE = `${BASE_URL}/admin/report/paid-status`;
export const URL_ADMIN_CHANGE_ORDER_STATUS = `${BASE_URL}/admin/order/status-change`;
