import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import CustomButton from "../../../common/Button/CustomButton";
import {
  capitalizeFirstLetter,
  dateFormatDDMMYYYY,
  getRoleApi,
  parseBooleanVal,
} from "../../../../utils/utils";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import parseError from "../../../../utils/ErrorParse";
import _ from "lodash";
import Loader from "../../../common/Loader/Loader";
import { emptyPaginationObj } from "../../../../utils/constant";

const CommentSection = (props) => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  const { role, claimId, isReFresh } = props;

  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [isFetchLoader, setIsFetchLoader] = useState(false);

  const [scrollLoader, setScrollLoader] = useState(false);

  const [comment, setComment] = useState("");

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(emptyPaginationObj);
  const [reachedLast, setReachedLast] = useState(false);

  useEffect(() => {
    if (reachedLast && pagination.totalPages > pagination.page) {
      const obj = pagination;
      obj.page = pagination.hasOwnProperty("nextPage")
        ? pagination.nextPage
        : pagination.page + 1;
      setPagination({ ...obj });
      handleFetchComments();
    }
  }, [reachedLast]);

  const divRef = useRef();

  useEffect(() => {
    if (isReFresh) {
      setReachedLast(false);
      setScrollLoader(false);
      setData([]);
      setPagination(emptyPaginationObj);
      handleFetchComments({ page: 1 });
    }
  }, [isReFresh]);

  //   const scrollToBottom = () => {
  //     if (divRef && divRef.current) {
  //       const scrollHeight = divRef.current.scrollHeight;
  //       divRef.current.scrollTop = scrollHeight;
  //     }
  //   };

  const handleScroll = () => {
    if (divRef && divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      if (
        scrollTop + clientHeight + 10 >= scrollHeight &&
        pagination?.totalPages > pagination?.page
      ) {
        setReachedLast(true);
      }
    }
  };

  const handleFetchComments = async (obj = {}) => {
    try {
      if (reachedLast) setScrollLoader(true);
      setIsFetchLoader(true);
      const page = obj.hasOwnProperty("page") ? obj.page : pagination.page;
      const response = await axios.get(
        `${apiUrl}/${getRoleApi(
          role
        )}/repair-comment/${claimId}?page=${page}&limit=5`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        if (page === 1) {
          setData([...response.data?.comments]);
        } else {
          setData([...data, ...response.data?.comments]);
        }
        setPagination(response.data?.pagination);
      }
      setIsFetchLoader(false);
      setScrollLoader(false);
      setReachedLast(false);
    } catch (err) {
      setIsFetchLoader(false);
      setScrollLoader(false);
      setReachedLast(false);
      console.log("err", err);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!comment || comment?.length <= 0 || comment?.trim()?.length <= 0) {
        toast.error("Please add a comment.", {
          toastId: "Submit-Repair-Claim-Comment",
        });
        return;
      }

      setIsSubmitLoader(true);
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;

      const response = await axios.post(
        `${apiUrl}/${roleApi?.toLowerCase()}/repair-comment`,
        { comment, claimId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        const prevData = _.cloneDeep(data);
        if (prevData) {
          prevData.push(response.data);
          setData([...prevData]);
        }
        setComment("");
        toast.success("Comment added successfully!");
      }
      setIsSubmitLoader(false);
      if (divRef && divRef.current) {
        const scrollHeight = divRef.current.scrollHeight;
        divRef.current.scrollTop = scrollHeight + 50;
      }
    } catch (err) {
      setIsSubmitLoader(false);
      console.log("err", err);
      const errorMessage = parseError(err);
      toast.error(errorMessage ?? "Something went wrong!");
    }
  };

  return (
    <div className="px-2">
      <div
        onScroll={handleScroll}
        ref={divRef}
        className="scrollable-content pb-1"
      >
        {isFetchLoader && !parseBooleanVal(scrollLoader) ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Loader cssClass={"text-black"} isSmallView={true} />
          </div>
        ) : (
          <>
            {data.length > 0 ? (
              data.map((item, index) => (
                <div
                  style={{
                    borderRadius: "5px",
                    border: "1px solid white",
                    padding: "10px 10px 10px 10px",
                    marginTop: "0.5rem",
                  }}
                >
                  <div key={index} className="d-flex justify-content-between">
                    <span>{capitalizeFirstLetter(item.text)}</span>
                    <span style={{ fontSize: "11px" }}>
                      {dateFormatDDMMYYYY(item.createdAt)}
                    </span>
                  </div>
                  <div style={{ fontSize: "10px" }} className="text-start pt-1">
                    <span>
                      {`Created By: ${
                        capitalizeFirstLetter(
                          `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                        )?.trim()?.length <= 0
                          ? item.createdBy.details?.email
                          : capitalizeFirstLetter(
                              `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                            )
                      }`}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <span className="error-text">No comments yet.</span>
              </div>
            )}
            {isFetchLoader &&
              pagination.totalPages >= pagination.page &&
              scrollLoader && (
                <div className="d-flex justify-content-center align-items-center pt-2">
                  <CustomButton
                    label="Loading..."
                    showLoading={true}
                    isDisabled={true}
                  />
                </div>
              )}
          </>
        )}
      </div>
      <div className="mt-4 pt-2" style={{ borderTop: "1px solid black" }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label className="text-white">Comment</Form.Label>
            <Form.Control
              name="comment"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              as="textarea"
              rows={6}
              style={{ resize: "none", height: "80px" }}
              placeholder="Enter comment..."
            />
          </Form.Group>
          <div className={"d-flex justify-content-end pt-2"}>
            <CustomButton
              isDisabled={isFetchLoader || isSubmitLoader}
              showLoading={isSubmitLoader}
              label="Submit"
              style={{ backgroundColor: "#000000" }}
              cssClass="btn-effect-1 btn1"
              type="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CommentSection;
