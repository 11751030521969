import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { apiGetRequest } from "../../helpers/Requests";
import {
  URL_ADMIN_SERVICE_PROGRAMS_LIST,
  URL_DEALER_SERVICE_PROGRAMS_LIST,
  URL_MANAGER_SERVICE_PROGRAMS_LIST,
  URL_STAFF_SERVICE_PROGRAMS_LIST,
} from "../../service/URL";
import { USER_ROLE } from "../../utils/constant";

var apiUrl = process.env.REACT_APP_API_URL;

const generateReport = async (
  dealerId,
  managerId = null,
  services,
  addOns,
  customerAndVehicleFormData,
  structuredData,
  isDraft,
  reportId = null,
  selectedReason = null
) => {
  return new Promise(async (resolve, reject) => {
    const token = JSON.parse(secureLocalStorage.getItem("Token"));
    const role = JSON.parse(secureLocalStorage.getItem("Role"));
    const roleApi =
      role === "Technician" ||
      role === "Sales Advisor" ||
      role === "Service Advisor"
        ? "staff"
        : role;

    // console.log("structuredData", structuredData);

    try {
      const reportData = {
        dealerId,
        managerId,
        services,
        addOns,
        details: JSON.stringify(structuredData),
        customer: customerAndVehicleFormData,
      };

      //````````````````````Generating Reports````````````````````
      if (reportId !== null && selectedReason !== null) {
        Object.assign(reportData, {
          updateReason: selectedReason,
        });
      }
      const reportconfig = {
        method: "POST",
        maxBodyLength: Infinity,
        url:
          reportId !== null
            ? `${apiUrl}/${roleApi?.toLowerCase()}/edit-report/${reportId}`
            : `${apiUrl}/${roleApi?.toLowerCase()}/report`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: reportData,
      };
      axios
        .request(reportconfig)
        .then((reportRes) => {
          resolve({
            data: reportRes.data?.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    } catch (err) {
      console.log("error", err);
      reject(err);
    }
  });
};

function getServicePrograms(role, selectedDealerOption) {
  return new Promise((resolve, reject) => {
    try {
      let url = "";
      if (role === USER_ROLE.DEALER) {
        url = URL_DEALER_SERVICE_PROGRAMS_LIST;
      } else if (role === USER_ROLE.MANAGER) {
        url = URL_MANAGER_SERVICE_PROGRAMS_LIST;
      } else if (role === USER_ROLE.STAFF.SERVICE_ADVISOR) {
        url = URL_STAFF_SERVICE_PROGRAMS_LIST;
      } else {
        url = `${URL_ADMIN_SERVICE_PROGRAMS_LIST}?dealer_id=${selectedDealerOption}`;
      }

      apiGetRequest(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (err) {
      console.log("error", err);
      reject(err);
    }
  });
}

export { generateReport, getServicePrograms };
