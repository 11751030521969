import React from "react";

const TermCondition = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "2pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          READ CAREFULLY BEFORE SIGNING
        </h1>
        <h1
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          This Contract effective on the date given below (the “Contract
          Purchase Date”)
        </h1>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          This agreement started on the Contract Purchase Date mentioned above,
          pending approval from the Selling Dealer and payment of the Contract
          Price. Please review this contract for complete details.
        </p>
        <p
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          Extended Trust Ltd. retains the right to cancel this contract at any
          time, providing a refund (as outlined in this contract), if any
          information on the Contract Registration Page is found to be
          inaccurate or falsified, if there is misrepresentation in obtaining
          this contract, intentional abuse of a service by you or your
          authorized designate, or if the listed vehicle (or its use) is
          ineligible for reasons outlined herein and in the Terms and
          Conditions.
        </p>
        <h1
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          Important Points:
        </h1>
        <ul id="l1">
          <li>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "42pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              No oral representation or statement can be relied upon by you.
            </p>
          </li>
          <li>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "42pt",
                textIndent: "-18pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              The Selling Dealer or any sales representative has no authority to
              change any part of this contract.
            </p>
          </li>
          <li>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "42pt",
                textIndent: "-18pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              This contract becomes void if the Contract Purchase Date is more
              than thirty (30) days after the date you purchased or leased the
              vehicle from the Selling Dealer.
            </p>
            <h1
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Your Agreement:
            </h1>
          </li>
          <li>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "42pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              You agree to purchase this Vehicle Service Contract.
            </p>
          </li>
          <li>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "42pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              This is not an insurance contract.
            </p>
          </li>
          <li>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "41pt",
                textIndent: "-18pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              This contract includes exclusions, restrictions, and limitations,
              which you acknowledge as reasonable.
            </p>
            <h1
              style={{
                paddingTop: "7pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Your Acknowledgment:
            </h1>
          </li>
          <li>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "42pt",
                textIndent: "-18pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              You confirm that you have read, understood, and agreed to the
              provisions of this contract, including the terms and conditions on
              the following pages.
            </p>
          </li>
        </ul>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "6pt" }}
          cellspacing="0"
        >
          <tr style={{ height: "47pt" }}>
            <td
              style={{
                width: "228pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
              }}
            >
              <p
                style={{
                  textIndent: "0pt",
                  lineHeight: "11pt",
                  textAlign: "right",
                }}
              >
                CON
              </p>
            </td>
            <td
              style={{
                width: "240pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
              }}
            >
              <p
                style={{
                  textIndent: "0pt",
                  lineHeight: "11pt",
                  textAlign: "left",
                }}
              >
                TRACT PURCHASE DATE
                <u>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </u>
                , 20<u> &nbsp; </u>
              </p>
            </td>
          </tr>
          <tr style={{ height: "54pt" }}>
            <td
              style={{
                width: "228pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
              }}
            >
              <p
                style={{
                  paddingLeft: "5pt",
                  paddingRight: "72pt",
                  textIndent: "0pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                SIGNATURE OF AUTHORIZED REPRESENTATIVE OF SELLING DEALER ON
                BEHALF OF EXTENDED TRUST LTD.
              </p>
            </td>
            <td
              style={{
                width: "240pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
              }}
            >
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "101pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                CUSTOMER SIGNATURE
              </p>
            </td>
          </tr>
        </table>
        <h2
          style={{
            paddingTop: "2pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </h2>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <ol id="l2">
          <li data-list-text="1.">
            <h2
              style={{
                paddingTop: "3pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Agreement Overview
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              This document, together with your completed Registration Page and
              any Schedules, contains all the important details of our
              agreement. The headings in this contract are for convenience only
              and do not change its meaning.
            </p>
          </li>
          <li data-list-text="2.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Definitions
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ul id="l3">
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Chemical Causes:
                  <span className="p">
                    Damage caused by exposure to chemicals.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Contract Purchase Date:
                  <span className="p">The date on the Registration Page.</span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Contract Price:
                  <span className="p">
                    The Price agreed for the Services purchased.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Detailing:
                  <span className="p">
                    Services for Panel Imperfections, Interior Imperfections,
                    and Cosmetic Rim Imperfections if purchased. Does not cover
                    issues beyond these.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Environmental Causes:{" "}
                  <span className="p">
                    Damage from exposure to the elements.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left",
                  }}
                >
                  Extended Trust:{" "}
                  <span className="p">
                    Extended Trust Ltd., is a body corporate duly registered in
                    and operating out of the Province of Alberta.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Extended Trust Program:
                  <span className="p">
                    The Tradename under which Extended trust Ltd. Operated this
                    Program.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Fee:
                  <span className="p">
                    The amount paid to Extended Trust by the Dealer, excluding
                    taxes.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Pre-Existing Imperfections:
                  <span className="p">
                    Issues existing before the contract starts.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Registration Page:
                  <span className="p">
                    The document listing your information, vehicle details, and
                    purchased services.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Services:
                  <span className="p">
                    The services you've chosen and paid for, as listed on the
                    Registration Page described in the relevant Schedules.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Term:
                  <span className="p">
                    The period from Contract Purchase Date to Expiration Date
                    for each service.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Vehicle:
                  <span className="p">
                    Your vehicle mentioned on the Registration Page.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Warranty:
                  <span className="p">
                    Any original manufacturer, seller's warranty, insurance, or
                    third-party warranty, excluding this Contract.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  We, us:
                  <span className="p">Extended Trust Ltd.</span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  You, your:
                  <span className="p">
                    The customer on the Registration Page or the person to whom
                    the contract is transferred.
                  </span>
                </h2>
              </li>
            </ul>
          </li>
          <li data-list-text="3.">
            <h2
              style={{
                paddingTop: "8pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Contract Term
            </h2>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              This contract begins on the Contract Purchase Date and ends on the
              specified Expiration Date for the selected service(s).
            </p>
          </li>
          <li data-list-text="4.">
            <h2
              style={{
                paddingTop: "8pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Services
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Refer to the Services Information on the Registration Page and any
              Schedule(s) of Services.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="5.">
            <h2
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Limits of Liability; Disclaimers
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              We're not responsible for personal injuries or deaths. Except as
              stated in this contract, we disclaim any warranties. We won't be
              liable for indirect or consequential damages.
            </p>
          </li>
          <li data-list-text="6.">
            <h2
              style={{
                paddingTop: "8pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Territory
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              This contract covers losses, damages, and repairs only in Canada
              and the contiguous United States of America and Alaska.
            </p>
          </li>
          <li data-list-text="7.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Contract Exclusions
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              We won't cover issues existing before the Contract Purchase Date,
              or damages after the contract ends. Your contract does not cover
              any of the following situations:
            </p>
            <ol id="l4">
              <li data-list-text="A.">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Any loss, damage, or condition that existed before the
                  Contract Purchase Date.
                </p>
              </li>
              <li data-list-text="B.">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Loss or damage occurring after the expiration or earlier
                  termination of this Contract or the Expiration Date on the
                  Registration Page for the particular Service, whichever comes
                  first.
                </p>
              </li>
              <li data-list-text="C.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Loss or damage if the information you provided or from the
                  service facility cannot be verified as accurate.
                </p>
              </li>
              <li data-list-text="D.">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Loss or damage if the Contract Purchase Date is more than
                  thirty (30) days after you bought or leased the Vehicle from
                  the Selling Dealer.
                </p>
              </li>
              <li data-list-text="E.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Any repair or replacement involving the Vehicle when the
                  damage or condition existed before the Contract Purchase Date.
                </p>
              </li>
              <li data-list-text="F.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Repairs or replacements not explicitly covered in the Schedule
                  of Services or for those covered in the Schedule of Services
                  but not purchased as identified on the Registration Page.
                </p>
              </li>
              <li data-list-text="G.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Loss or damage occurring after the expiration or earlier
                  termination of this Contract.
                </p>
              </li>
              <li data-list-text="H.">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Loss or damage resulting from any single or multi-Vehicle
                  motor Vehicle accident or collision, regardless of cause or
                  fault, or driver error.
                </p>
              </li>
              <li data-list-text="I.">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Loss or damage caused by fire, vandalism, riot, theft,
                  protest, terrorism, explosion, lightning, earthquake,
                  freezing, rust or corrosion, windstorm, tornado, hail, water,
                  flood, sinkhole, or mudslide.
                </p>
              </li>
              <li data-list-text="J.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Repairs or replacements necessitated by manufacturer defects.
                </p>
              </li>
              <li data-list-text="K.">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage caused by willful or intentional damage,
                  misuse, abuse, negligence, racing, competitive driving, lack
                  of normal maintenance required by the manufacturer's
                  maintenance schedule for the Vehicle, improper servicing by
                  you or any other repairer; or loss or damage not considered
                  accidental as determined by Extended Trust.
                </p>
              </li>
              <li data-list-text="L.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage resulting from alterations made to the Vehicle
                  or if you are using or have used the Vehicle in a manner not
                  recommended by Extended Trust or the manufacturer.
                </p>
              </li>
              <li data-list-text="M.">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage to parts not originally equipped by the
                  manufacturer or recommended for the Vehicle, including but not
                  limited to custom wheels/rims, aftermarket accessory kits, or
                  seat protectors.
                </p>
              </li>
              <li data-list-text="N.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Liability for property damage or injury to or death of any
                  person arising from the operation, maintenance, or use of the
                  Vehicle, whether or not related to the covered parts or for
                  loss of use, time, profit, inconvenience, or any other
                  consequential loss or damage.
                </p>
              </li>
              <li data-list-text="O.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage when the responsibility for repair or
                  replacement is covered under the original warranty from the
                  manufacturer or any insurance policy or parts warranty, or a
                  repair's guarantee/warranty.
                </p>
              </li>
              <li data-list-text="P.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage if the information provided by you or the
                  service facility cannot be verified as accurate.
                </p>
              </li>
              <li data-list-text="Q.">
                <p
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Loss or damage if the Contract Purchase Date is more than
                  thirty (30) days after the date that you purchased or leased
                  the Vehicle from the Selling Dealer.
                </p>
              </li>
              <li data-list-text="R.">
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "justify",
                  }}
                >
                  If the Vehicle is a used Vehicle on the Contract Purchase
                  Date, loss or damage if the Contract Purchase Date is more
                  than ten (10) years after the date the original purchaser or
                  lessee first bought or leased the Vehicle.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="8.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "15pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Transferring This Contract
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify",
              }}
            >
              Only the original customer can transfer this contract in the case
              of selling the vehicle to a private party. Contact an Extended
              Trust Agent with the new owner's details within 30 days of the
              sale. Information on the new owner must include:
            </p>
            <ul id="l5">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's First and Last Name;
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Address, including City, Province, and Postal
                  Code;
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Phone Number; and
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Email Address
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="9.">
            <h2
              style={{
                paddingTop: "9pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Cancelling This Contract
            </h2>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify",
              }}
            >
              You can cancel within 30 days of the Effective Date for a full
              refund if no services are performed. After 30 days, a pro-rated
              refund may be issued. The contract ends if the vehicle is written
              off, repossessed, sold to a third party without notifying Extended
              Trust, or at the last Expiration Date.
            </p>
          </li>
          <li data-list-text="10.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "21pt",
                textIndent: "-15pt",
                textAlign: "left",
              }}
            >
              Schedule of Services
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              This contract covers only the services listed in the attached
              Schedule.
            </p>
          </li>
          <li data-list-text="11.">
            <h2
              style={{
                paddingTop: "8pt",
                paddingLeft: "21pt",
                textIndent: "-15pt",
                textAlign: "left",
              }}
            >
              Procedure for Service
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Contact your original selling dealer to get the services.
            </p>
          </li>

          <li data-list-text="12.">
            <h2
              style={{
                paddingTop: "9pt",
                paddingLeft: "21pt",
                textIndent: "-15pt",
                textAlign: "left",
              }}
            >
              Conditions of Service
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              When obtaining services, you understand and accept that:
            </p>
            <ul id="l6">
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Some services may need multiple appointments to address
                  imperfections fully.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  The completion of certain Detailing services might require you
                  to be without the use of your Vehicle for up to one day.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  It may be impossible to resolve every imperfection you bring
                  in for servicing. Extended Trust will be considered to have
                  fulfilled its obligation under this Contract if, according to
                  an Extended Trust Ltd. technician's judgment, the best efforts
                  have been made to provide services using the techniques
                  employed by Extended Trust in their normal business
                  operations.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Extended Trust Ltd. does not include washing/vacuuming the
                  Vehicle in their Detailing services. Therefore, you must
                  clean/vacuum the area to be serviced before bringing the
                  Vehicle in for servicing. Extended Trust Ltd. is not
                  responsible for any charges related to detailing, cleaning, or
                  vacuuming, as these are your responsibility.
                </p>
              </li>
            </ul>
          </li>

          <li data-list-text="13.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "21pt",
                textIndent: "-15pt",
                textAlign: "left",
              }}
            >
              General
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ul id="l7">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  This agreement can be signed by any party involved. Each party
                  can sign a separate copy (electronic PDF, or in person), and
                  each of these signed copies will be considered an original.
                  When put together, these signed copies will form a complete
                  and single agreement.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Headings are for reference.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  All amounts are in Canadian currency.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Changes to this Contract need to be in writing and signed by
                  Extended Trust.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  This Contract follows the laws of the province where the
                  Selling Dealer is located.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left",
                  }}
                >
                  Extended Trust provides a prepaid service, not insurance.
                  Maintain comprehensive automotive insurance at all times.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left",
                  }}
                >
                  This agreement, including any schedules, is the entire
                  contract between parties and can only be changed in writing,
                  signed by both parties.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  );
};

export default TermCondition;
