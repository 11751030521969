import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import ApprovalButton from "./approvalButton";
import secureLocalStorage from "react-secure-storage";
const Request = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var token = JSON.parse(secureLocalStorage.getItem("Token"));
  const role = JSON.parse(secureLocalStorage.getItem("Role"));

  const [employees, setEmployees] = useState([]);
  const [shouldRerender, setShouldRerender] = useState(false);

  const handleVerifyCallback = () => {
    setShouldRerender(!shouldRerender);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/${role?.toLowerCase()}/employee`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployees(response.data);
        // console.log("Fetched employees:", response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [shouldRerender]);

  // var lowerCase = role.toLowerCase();

  // const HandleDelete = () => {
  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   fetch(`${apiUrl}/verify-${lowerCase}/${id}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         console.log(`${role} approved successfully`);
  //       } else {
  //         console.error("Failed to approve dealer");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  return (
    <div className="mt-3">
      {" "}
      {/* <div className="tab-pane px-4 fade " id="approvalrequestlist"> */}
      <div>
        {role === "Admin" ? (
          <div>
            <h3 style={{ fontWeight: "bolder" }} className="px-4 pt-3">
              Dealers List
            </h3>

            {/* <h1 style={{ fontWeight: "bolder" }} className="px-4"></h1> */}
            <div className="table checkoverflow p-4">
              <Table striped bordered hover className="overflow-set">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees.dealer?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.firstName || item.name}</td>
                        <td>{item.contactEmail}</td>
                        <td
                          style={{
                            display: "flex",
                            gap: "20px",
                            borderRadius: "5px",
                          }}
                        >
                          <ApprovalButton
                            id={item._id}
                            isVerified={item.isVerified}
                            role={item.role}
                            MainRoles={"dealer"}
                            onVerify={handleVerifyCallback}
                          />
                          {/* {item.isVerified ? "True" : "False"} */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : null}

        {role === "Admin" || role === "Dealer" ? (
          <div>
            <h3 style={{ fontWeight: "bolder" }} className="px-4 pt-4">
              Managers List
            </h3>
            <div className="table checkoverflow px-4  p-2">
              <Table striped bordered hover className="overflow-set">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees.manager?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.firstName}</td>
                        <td>{item.email}</td>
                        <td
                          style={{
                            display: "flex",
                            gap: "20px",
                            // borderRadius: "5px",
                          }}
                        >
                          <ApprovalButton
                            id={item._id}
                            isVerified={item.isVerified}
                            role={item.role}
                            MainRoles={"manager"}
                            onVerify={handleVerifyCallback}
                          />
                          {/* {item.isVerified ? "True" : "False"} */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : null}

        {role === "Admin" || "Dealer" || "Manager" ? (
          <div>
            {/* <h3 style={{ fontWeight: "bolder" }} className="px-4 ">
              Sales Advisor Table
            </h3>
            <div className="table px-4  p-2 customTable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees.staff
                      ?.filter((item) => item.role === "Sales Advisor")
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.firstName}</td>
                          <td>{item.email}</td>
                          <td
                            style={{
                              display: "flex",
                              gap: "20px",
                              // borderRadius: "5px",
                            }}
                          >
                            <ApprovalButton
                              id={item._id}
                              isVerified={item.isVerified}
                              role={item.role}
                              onVerify={handleVerifyCallback}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div> */}

            <h3 style={{ fontWeight: "bolder" }} className="px-4  ">
              Service Advisors List
            </h3>
            <div className="table px-4  p-2 customTable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees.staff
                      ?.filter((item) => item.role === "Service Advisor")
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.firstName}</td>
                          <td>{item.email}</td>
                          <td
                            style={{
                              display: "flex",
                              gap: "20px",
                              // borderRadius: "5px",
                            }}
                          >
                            <ApprovalButton
                              id={item._id}
                              isVerified={item.isVerified}
                              role={item.role}
                              onVerify={handleVerifyCallback}
                            />
                            {/* {item.isVerified ? "True" : "False"} */}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>

            {/* <h3 style={{ fontWeight: "bolder" }} className="px-4">
              Technician Table
            </h3>
            <div className="table px-4  p-2 customTable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees.staff
                      ?.filter((item) => item.role === "Technician")
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.firstName}</td>
                          <td>{item.email}</td>
                          <td
                            style={{
                              display: "flex",
                              gap: "20px",
                              // borderRadius: "5px",
                            }}
                          >
                            <ApprovalButton
                              id={item._id}
                              isVerified={item.isVerified}
                              role={item.role}
                              onVerify={handleVerifyCallback}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div> */}
          </div>
        ) : null}

        {/* <p style={{ fontWeight: "bold" }}>
        Check all the materials you would like to request
        from the available materials below:
      </p> */}
        {/* <form>
        <p style={{ margin: 0 }}>Ceramx</p>
        <p>Brochure PDF’s</p>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            class="form-check-label"
            for="flexCheckDefault"
          >
            Test
          </label>
        </div>
        <p style={{ margin: 0 }}>Extended Care</p>
        <p>Brochure PDF's</p>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">
            Message/Additional Notes
          </label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            style={{ minHeight: "150px" }}
          ></textarea>
        </div>
      </form> */}
      </div>
    </div>
    // </div>
  );
};

export default Request;
