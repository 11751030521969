import React from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import secureLocalStorage from "react-secure-storage";

export const Confirmation = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const navigate = useNavigate();
  const logoutUser = () => {
    secureLocalStorage.removeItem("Token");
    secureLocalStorage.removeItem("UserID");
    secureLocalStorage.removeItem("Role");
    secureLocalStorage.removeItem("isVerified");
    secureLocalStorage.clear();
    navigate("/");
    // console.log('asd')
  };
  return (
    <div className="Lat-login-main-container">
      <div className="logo-head-stip">
        <Link to="/">
          <img
            src={publicUrl + "assets/img/logoblack.svg"}
            alt="Logo"
            className="login-logo-head"
          />
        </Link>
      </div>
      <div className="form-login-container">
        <div class="jumbotron">
          <h1 class="display-4">Waiting For Confirmation....</h1>
          <p class="lead">This user hasn't been approved by an admin yet.</p>
          <hr class="my-4" />
          <p>Click on the button below to go back to the login page.</p>
          <p class="lead">
            <a
              class="btn btn-primary btn-lg theme-btn-1 btn btn-block signin-btn"
              href="#"
              role="button"
              style={{ maxWidth: "180px", marginTop: "20px" }}
              onClick={logoutUser}
            >
              Logout{" "}
              <Icon
                icon="ri:arrow-go-back-fill"
                style={{ width: "35px", height: "35px" }}
                className="rightArrow-Icon"
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
