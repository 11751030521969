import React, { useEffect, useState } from "react";
import ApexChart from "../../ApexChart";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { Icon } from "@iconify/react";
import Loader from "../../common/Loader/Loader";
import RegistrantionReportTable from "../reports/Tables/RegistrationReportTable";
import { USER_ROLE } from "../../../utils/constant";
import moment from "moment";
import { Table } from "react-bootstrap";
// import ApexChart from "../../ApexChart";

const DashboardDefault = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [employeeData, setEmployeeData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  const [isLoading, setISLoading] = useState(false);
  const [isFetchDashbordLoading, setIsFetchDasboardLoading] = useState(false);

  const fetchData = async () => {
    try {
      setISLoading(true);
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;
      const response = await axios.get(
        `${apiUrl}/${roleApi?.toLowerCase()}/employee`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setEmployeeData(response.data);
      setISLoading(false);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setISLoading(false);
    }
  };

  const roleCode =
    role === "Technician" ||
    role === "Sales Advisor" ||
    role === "Service Advisor"
      ? "staff"
      : role;

  const fetchDashoardData = async () => {
    try {
      setIsFetchDasboardLoading(true);
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;
      const response = await axios.get(
        `${apiUrl}/${roleApi?.toLowerCase()}/dashboard`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data) {
        setDashboardData(response.data.data?.dashboarData || []);
      }
      setIsFetchDasboardLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsFetchDasboardLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDashoardData();
  }, []);

  const employeeDataSortedKeys = ["dealer", "manager", "staff"];

  // console.log("roleCode", roleCode);
  // console.log("dasboard DAta", dashboardData);
  return (
    <div>
      {/* Admin and Dealer Dashboard */}
      {(role === USER_ROLE.ADMIN || role === USER_ROLE.DEALER) && (
        <div className="Maingraph-Dash-box">
          <div className="MainDash-table-box mobileTable">
            <table
              style={{ width: "100% !important" }}
              className="table222 mobileTable"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <Loader cssClass={"text-black"} isSmallView={true} />
                )}
                {!isLoading &&
                  employeeDataSortedKeys.map((key) => {
                    const value = employeeData[key];
                    return (
                      <React.Fragment key={key}>
                        {Array.isArray(value) && value.length > 0 ? (
                          value.map((item, index) => (
                            <tr key={`${key}-${index}`}>
                              <td>
                                {key === "staff"
                                  ? "Service Advisor"
                                  : key.toUpperCase()}
                              </td>
                              <td>
                                {item?.firstName?.toUpperCase()}{" "}
                                {item?.lastName?.toUpperCase()}
                              </td>
                              <td>
                                {item?.isVerified ? (
                                  <Icon
                                    icon="oi:check"
                                    style={{ color: "#65B56B" }}
                                  />
                                ) : (
                                  <Icon
                                    icon="gridicons:cross"
                                    style={{ color: "#ff0000" }}
                                  />
                                )}
                              </td>
                              {/* <td>{item?.isVerified || "N/A"}</td> */}
                              <td>
                                {item?.createdAt
                                  ? new Date(
                                      item.createdAt
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4">No data available for {key}</td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}

                {/* {empldoyeeData && employeeData.length > 0 ? (
              employeeData.map(employee => (
                <tr key={employee.id}>
                  <td>{employee.id}</td>
                  <td>{employee.dealer?.firstName} {employee.dealer?.lastName}</td>
                  <td>{employee?.status || "N/A"}</td>
                  <td>{employee?.date || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No data available</td>
              </tr>
            )} */}
              </tbody>
            </table>
          </div>

          <div className="MainDash-graph-box">
            <ApexChart />
          </div>
        </div>
      )}
      <ol className="d-none">
        <li style={{ fontWeight: "bold" }}>
          Unrivaled <span class="color-new">INTRODUCTIONS:</span>
        </li>
        <p>
          Experience the best in vehicle protection with Extended Trust. Our
          comprehensive programs shield your car from unexpected life events,
          giving you peace of mind.
        </p>
        <li style={{ fontWeight: "bold" }}>
          VERSATILE <span class="color-new">CHOICES:</span>
        </li>
        <p>
          Say goodbye to anxieties about wear and tear. Extended Trust
          guarantees hassle-free maintenance, ensuring your car stays in peak
          condition for longer.
        </p>
        <li style={{ fontWeight: "bold" }}>
          TAILORED <span class="color-new">PRESENTATIONS:</span>
        </li>
        <p>
          Preserve both the performance and appearance of your car with Extended
          Trust's tailored solutions. We offer the perfect fit for your needs,
          ensuring your vehicle remains a joy to drive and own.
        </p>
        <li style={{ fontWeight: "bold" }}>
          COMPREHENSIVE <span class="color-new">CONTENT:</span>
        </li>
        <p>
          Benefit from programs designed specifically for the Canadian market.
          Extended Trust understands the unique needs of Canadian drivers,
          providing coverage that adapts to your environment.
        </p>
        <li style={{ fontWeight: "bold" }}>
          TIME <span class="color-new">EFFICIENCY:</span>
        </li>
        <p>
          Drive with confidence knowing your car is protected. Extended Trust
          gives you the freedom to enjoy the journey without worrying about
          unexpected costs and breakdowns.
        </p>
        <li style={{ fontWeight: "bold" }}>
          QUICK <span class="color-new">ADAPTATION:</span>
        </li>
        <p>
          Choose Extended Trust and make a wise investment in your car's
          longevity and value. Our comprehensive programs ensure you get the
          most out of your vehicle, protecting its performance and appearance
          for years to come.
        </p>
        <p style={{ fontWeight: "bold" }}>
          Take control of your car's future and experience the unrivaled
          protection of Extended Trust.
        </p>
      </ol>
      {/* Manager Dashboard */}
      {role === USER_ROLE.MANAGER && (
        <div className="Maingraph-Dash-box px-4">
          {isFetchDashbordLoading ? (
            <Loader cssClass={"text-black"} isSmallView={true} />
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <h5 style={{ textDecoration: "underline" }}>
                  Registration Report of {moment().format("MMMM YYYY")}
                </h5>
              </div>
              <RegistrantionReportTable data={dashboardData} />
            </div>
          )}
        </div>
      )}
      {/* Service Advisor Dashboard */}
      {role === USER_ROLE.STAFF.SERVICE_ADVISOR && (
        <div className="Maingraph-Dash-box px-4 d-block">
          {isFetchDashbordLoading ? (
            <Loader cssClass={"text-black"} isSmallView={true} />
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <h5 style={{ textDecoration: "underline" }}>
                  Claims In {moment().format("MMMM YYYY")}
                </h5>
              </div>
              <div className="mobileTable pt-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Agreement Number</th>
                      <th>Status</th>
                      <th>Create At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.length > 0 ? (
                      dashboardData.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.agreementNumber}</td>
                          <td>{item.status}</td>
                          <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                        </tr>
                      ))
                    ) : dashboardData?.length === 0 ? (
                      <tr>
                        <td colSpan={9}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100%" }}
                          >
                            <span className="text-black">No Claims found!</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Super Admin Dashboard */}
      {role === USER_ROLE.SUPER_ADMIN && (
        // <div className="Maingraph-Dash-box px-4">
        <div className="pt-4 px-4">
          <div className="d-flex justify-content-center align-items-center">
            <h5 style={{ textDecoration: "underline" }}>
              Super Admin Dashboard
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardDefault;
