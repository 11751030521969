import React, { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  dateFormatDDMMYYYY,
  getRoleApi,
} from "../../../../utils/utils";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import parseError from "../../../../utils/ErrorParse";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader/Loader";

const LogsSection = (props) => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  const { role, claimId, isReFresh } = props;
  const [isFetchLoader, setIsFetchLoader] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (isReFresh) {
      handleFetchLogs();
    }
  }, [isReFresh]);

  const handleFetchLogs = async () => {
    try {
      setIsFetchLoader(true);
      const response = await axios.get(
        `${apiUrl}/${getRoleApi(role)}/repair-log/${claimId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        setData([...response.data]);
      }
      setIsFetchLoader(false);
    } catch (err) {
      setIsFetchLoader(false);
      console.log("err", err);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  return (
    <div className="px-2">
      <div className="scrollable-content pb-1" style={{ height: "450px" }}>
        {isFetchLoader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Loader cssClass={"text-black"} isSmallView={true} />
          </div>
        ) : (
          <>
            {data.length > 0 ? (
              data.map((item, index) => (
                <div
                  style={{
                    borderRadius: "5px",
                    border: "1px solid white",
                    padding: "10px 10px 10px 10px",
                    marginTop: "0.5rem",
                  }}
                >
                  <div key={index} className="d-flex justify-content-between">
                    <span>{capitalizeFirstLetter(item.action)}</span>
                    <span style={{ fontSize: "10px" }}>
                      {dateFormatDDMMYYYY(item.createdAt)}
                    </span>
                  </div>
                  <div style={{ fontSize: "11px" }} className="text-start pt-1">
                    <span>
                      {`Created By: ${
                        capitalizeFirstLetter(
                          `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                        )?.trim()?.length <= 0
                          ? item.createdBy.details?.email
                          : capitalizeFirstLetter(
                              `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                            )
                      }`}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <span className="error-text">No activity logged yet.</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LogsSection;
