import React from "react";
import "./styles.css";
import { classNames } from "../../../utils/utils";
import _ from "lodash";

const CustomInput = (props) => {
  const {
    type,
    error = "",
    label,
    handleChange,
    placeholder,
    cssClass,
    name,
    id,
    value,
    tooltip = "",
    isDisabled = false,
    required,
    labelPosition = "down",
    isRedStar = false,
    maxLength,
  } = props;

  return (
    <div
      className={classNames(
        labelPosition === "down" && "custom-input-container",
        !_.isEmpty(error) ? "error-input" : "input-border-color"
      )}
    >
      {tooltip.length > 0 && (
        <span className="tool-tip-custom-input" title={tooltip}>
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </span>
      )}
      {labelPosition !== "down" && (
        <label>
          {label}
          {isRedStar && <span className="required-class">*</span>}
        </label>
      )}
      <input
        maxLength={maxLength}
        required={required}
        disabled={isDisabled}
        value={value}
        id={id}
        name={name}
        className={classNames("text", cssClass && cssClass)}
        type={type}
        onChange={(e) => handleChange && handleChange(e)}
        placeholder={placeholder}
      />
      {labelPosition === "down" && (
        <label>
          {label}
          {isRedStar && <span className="required-class">*</span>}
        </label>
      )}
      {!_.isEmpty(error) && <p className="error-message pb-2">{error}</p>}
    </div>
  );
};

export default CustomInput;
