import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Accordion, Card, Table } from "react-bootstrap";
import { apiGetRequest } from "../../helpers/Requests";
import { BASE_URL, URL_ADMIN_GET_REGISTRATION_REPORT } from "../../service/URL";
import {
  capitalizeFirstLetter,
  classNames,
  convertDateByTimezone,
  getRoleApi,
} from "../../utils/utils";
import Style from "./reportLog.module.css";
import Loader from "../../components/common/Loader/Loader";
import secureLocalStorage from "react-secure-storage";
import useDealer from "../../Hooks/useDealer";
import { USER_ROLE } from "../../utils/constant";
import CustomSelect from "../../components/common/Select/CustomSelect";
import AuthContext from "../../helpers/authContext";

const ReportLog = ({ selectedReportLogShow = null }) => {
  const { timezone } = useContext(AuthContext);

  const [activeKey, setActiveKey] = useState(null);
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [isFetchLoader, setIsFetchLoader] = useState(false);
  const [logs, setLogs] = useState([]);

  const [dealerOptions, setDealerOptions] = useState([]);
  const { isDealerListLoader, dealersData = [] } = useDealer();
  const [selectedDealerOption, setSelectedDealerOption] = useState(null);

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        ...x,
        label:
          // x?.firstName && x.firstName?.length > 0
          //   ? `${x.firstName} ${x.lastName}`
          //   : x?.name || "",
          x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  const handleToggle = (selectedKey) => {
    setActiveKey(activeKey === selectedKey ? null : selectedKey);
  };

  useEffect(() => {
    getRegistrationLogs();
  }, [selectedReportLogShow, selectedDealerOption]);

  const getRegistrationLogs = async () => {
    try {
      setIsFetchLoader(true);
      const baseUrlApi = `${BASE_URL}/${getRoleApi(
        role
      )}${URL_ADMIN_GET_REGISTRATION_REPORT}`;
      const url =
        selectedReportLogShow !== null
          ? `${baseUrlApi}?report_id=${selectedReportLogShow}${
              selectedDealerOption?.value
                ? `&dealer_id=${selectedDealerOption?.value}`
                : ""
            }`
          : `${baseUrlApi}${
              selectedDealerOption && selectedDealerOption?.value
                ? `?dealer_id=${selectedDealerOption?.value}`
                : ""
            }`;
      const response = await apiGetRequest(url);
      if (response && response.data) {
        setLogs(response.data);
      }
      if (response.data?.length > 0) {
        handleToggle(response.data[0].agreementNumber);
      }
      setIsFetchLoader(false);
    } catch (err) {
      setIsFetchLoader(false);
      console.log("err", err);
    }
  };

  const renderValue = (fieldName, value) => {
    switch (fieldName) {
      case "year":
        return moment.utc(value).format("YYYY");

      default:
        return value;
    }
  };

  return (
    <div
      className={classNames(
        selectedReportLogShow === null && "row px-4 pt-5 mb-20"
      )}
    >
      {selectedReportLogShow === null && (
        <h3 className="pb-4" style={{ fontWeight: "bolder" }}>
          Registration Logs Report
        </h3>
      )}
      {role === USER_ROLE.ADMIN && selectedReportLogShow === null && (
        <div className="py-1 pb-3 row col-4 customSelect">
          <CustomSelect
            isClear={true}
            isDisabled={isDealerListLoader}
            loading={isDealerListLoader}
            placeHolder={"Select Dealer"}
            label="Dealer"
            selectedValue={selectedDealerOption ? selectedDealerOption : ""}
            handleChange={setSelectedDealerOption}
            options={dealerOptions}
          />
        </div>
      )}

      {isFetchLoader ? (
        <div className="d-flex justify-content-start align-items-center vertical-align-center">
          <Loader cssClass={"text-black"} isSmallView={true} />
        </div>
      ) : (
        <Accordion
          className={
            selectedReportLogShow !== null
              ? `${Style.isSelectedReportLog} ${Style.scrollableContent}`
              : ""
          }
          defaultActiveKey={activeKey}
        >
          {logs?.length > 0
            ? logs.map((parentItem, index) => (
                <Accordion.Item
                  eventKey={parentItem.agreementNumber}
                  key={`${parentItem.agreementNumber}-${index}`}
                >
                  <Card key={parentItem.agreementNumber}>
                    {selectedReportLogShow === null && (
                      <Accordion.Header
                        onClick={() => handleToggle(parentItem.agreementNumber)}
                        className="accord-Header dropBtn-after"
                      >
                        {parentItem.agreementNumber}
                      </Accordion.Header>
                    )}
                    <Accordion.Body
                      eventKey={parentItem.agreementNumber}
                      className={classNames(
                        "ac-Body",
                        selectedReportLogShow !== null && Style.zeroPadding
                      )}
                    >
                      {parentItem.logs.map((childItem, index) => (
                        <Card.Body
                          key={index}
                          className={classNames(
                            "py-3"
                            // selectedReportLogShow !== null && Style.zeroPadding
                          )}
                        >
                          <Card.Header>
                            <div className="d-flex justify-content-between align-items-top">
                              <div>
                                <h5>
                                  Edited By:
                                  <span>{` ${
                                    capitalizeFirstLetter(
                                      `${childItem.editedBy.details?.firstName} ${childItem.editedBy.details?.lastName}`
                                    )?.trim()?.length <= 0
                                      ? childItem.editedBy.details?.email
                                      : capitalizeFirstLetter(
                                          `${childItem.editedBy.details?.firstName} ${childItem.editedBy.details?.lastName}`
                                        )
                                  }`}</span>
                                </h5>
                                <span>
                                  <span className="font-weight-bold">
                                    Role:
                                  </span>
                                  {` ${capitalizeFirstLetter(
                                    childItem.editedBy.role
                                  )}`}
                                </span>
                              </div>
                              <div>
                                <h6>
                                  Edited at:
                                  {` ${moment(
                                    convertDateByTimezone(
                                      childItem.editedAt,
                                      timezone
                                    )
                                  ).format("DD-MM-YYYY hh:mm:ss A")}`}
                                </h6>
                              </div>
                            </div>
                          </Card.Header>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Field</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {childItem.changes.map((change, index) => (
                                <tr key={index}>
                                  <td>{change.field}</td>
                                  <td>
                                    {renderValue(change.field, change.oldValue)}
                                  </td>
                                  <td>
                                    {renderValue(change.field, change.newValue)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      ))}
                    </Accordion.Body>
                  </Card>
                </Accordion.Item>
              ))
            : logs?.length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  <span
                    className={
                      selectedReportLogShow !== null
                        ? "text-white"
                        : "text-black"
                    }
                    style={{ fontSize: "25px", textDecoration: "underline" }}
                  >
                    No Log found!
                  </span>
                </div>
              )}
        </Accordion>
      )}
    </div>
  );
};

export default ReportLog;
