import moment from "moment";
import React, { useContext } from "react";
import AuthContext from "../../../../helpers/authContext";
import { convertDateByTimezone } from "../../../../utils/utils";

const RegistrantionReportTable = (props) => {
  const { data, setShow } = props;

  const { timezone } = useContext(AuthContext);

  return (
    <div className="mobileTable">
      <table class="table overflow-set" style={{ margin: "20px 0px 20px 0px" }}>
        <thead>
          {/* <tr style={{ backgroundColor: "#3cb7ff" }}> */}
          <tr style={{ backgroundColor: "#4A6543" }}>
            <th scope="col">Agreement No.</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Sp Effective Date</th>
            <th scope="col">Dealer</th>
            <th scope="col">Manager</th>
            <th scope="col">VIN</th>
            <th scope="col">Make</th>
            <th scope="col">Model</th>
            <th scope="col">Year</th>
            {/* <th scope="col">Offers</th> */}
            {setShow && <th scope="col">View All</th>}
            {/* <th scope="col">Services</th>
          <th scope="col">Status</th> */}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan={11}>
                <div className="d-flex justify-content-center align-items-center">
                  No Report Found
                </div>
              </td>
            </tr>
          ) : data && data.length > 0 ? (
            data.map((item) => (
              <tr>
                <th scope="row">{item.agreementNumber || "-"}</th>
                <td>{item?.customer?.firstName}</td>
                <td>{item?.customer?.lastName}</td>
                <td>
                  {item?.createdAt
                    ? moment(
                        convertDateByTimezone(item.createdAt, timezone)
                      ).format("DD/MM/YY")
                    : null}
                </td>
                <td>
                  {item?.dealer?.firstName} {item?.dealer?.lastName}
                </td>
                <td>
                  {item?.manager?.firstName} {item?.manager?.lastName}
                </td>
                <td>{item?.customer?.vehical?.vin}</td>
                {/* <td>{item?.customer?.vehical?.vin}</td> */}
                <td>{item?.customer?.vehical?.make}</td>
                <td>{item?.customer?.vehical?.modal}</td>
                <td>
                  {moment.utc(item.customer.vehical?.year).format("YYYY")}
                </td>

                {/* <td>
                <select size="6" className="reportslider" >

                  {item.services.map((service) => [
                    <option className="service-option" key={service?.serviceName}>{service?.serviceName}</option>,
                  ])}
                </select>

              </td>
              <td>
                <select size='6' className="reportslider">

                  {item.offers.map((offer, index) => (
                    <option className="service-option" key={offer?.offerName}>{offer?.offerName}</option>
                  ))}
                </select>
              </td> */}
                {/* <td>{item.manager.isActive ? "active" : "inactive"}</td> */}
                {/* <td><button className="OverviewButton" >ViewAll</button></td> */}
                {setShow && (
                  <td>
                    <button
                      type="button"
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      onClick={() => setShow(item)}
                    >
                      View All
                    </button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrantionReportTable;
