import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import useDealer from "../../../Hooks/useDealer";
import {
  POSTALCODE_PATTERN,
  STAFF_EMPTY_OBJ,
  STATE_OPTIONS,
  USER_ROLE,
} from "../../../utils/constant";
import { capitalizeFirstLetter, classNames } from "../../../utils/utils";
import CustomButton from "../../common/Button/CustomButton";
import CustomInput from "../../common/Input/CustomInput";
import _ from "lodash";
import { apiPostRequest } from "../../../helpers/Requests";

const CreateStaff = (props) => {
  const {
    handleClose,
    selectedStaff = null,
    handleDataUpdateById,
    isCallFrom,
    setActiveTab,
  } = props;

  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);

  const { isDealerListLoader, dealersData = [] } = useDealer();
  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        label:
          // x?.firstName && x.firstName?.length > 0
          //   ? `${x.firstName} ${x.lastName}`
          //   : x?.name || "",
          x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  useEffect(() => {
    if (selectedStaff !== null) {
      setInputData({
        ...selectedStaff,
        dealer_id: selectedStaff?.dealer?._id ?? null,
        phone: selectedStaff?.phone?.trim(),
      });
      setSelectedDealerOption(selectedStaff?.dealer?._id);
    }
  }, [selectedStaff]);

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState("");
  const [inputData, setInputData] = useState(STAFF_EMPTY_OBJ);

  const [isCreateLoader, setIsCreateLoader] = useState(false);

  useEffect(() => {
    if (validationErrors["dealer_id"]) {
      const error = validateField("dealer_id", selectedDealerOption);
      setValidationErrors((prev) => ({
        ...prev,
        [`${"dealer_id"}`]: error,
      }));
    }
    setInputData((prev) => ({
      ...prev,
      dealer_id: selectedDealerOption,
    }));
  }, [selectedDealerOption]);

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    postalCode: "",
    address: "",
    city: "",
    phone: "",
    state: "",
    dealer_id: "",
  });

  const validateField = (field, value) => {
    if (field === "phone") {
      return value === "" || value === "+1" || value.trim() === "+1";
    } else if (field === "postalCode") {
      if (value === "" || value === undefined || _.isEmpty(value?.trim())) {
        return false;
      } else if (
        value?.length > 0 &&
        POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) === false
      ) {
        return true;
      }
    } else {
      return value === "" || value === undefined;
    }
  };

  const ValidationForm = () => {
    const errorObj = {};
    const notInclude = ["address", "city", "state"];

    if (role === USER_ROLE.DEALER) {
      notInclude.unshift("dealer_id");
    }

    for (const [field, value] of Object.entries(inputData)) {
      if (notInclude.findIndex((x) => x === field) === -1) {
        if (validateField(field, value)) {
          errorObj[field] = true;
        }
      }
    }
    if (
      (selectedDealerOption === "" ||
        selectedDealerOption === null ||
        selectedDealerOption === undefined) &&
      role === USER_ROLE.ADMIN
    ) {
      errorObj.dealer_id = true;
    }
    setValidationErrors((prev) => ({ ...prev, ...errorObj }));
    return Object.keys(errorObj).length > 0;
  };

  const submitDealerBasicInfo = async (event) => {
    event.preventDefault();

    const hasErrors = ValidationForm();
    if (hasErrors) {
      console.log("Form has validation errors", validationErrors);
      return;
    }

    try {
      setIsCreateLoader(true);

      const payload = {
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        email: inputData.email,
        address: inputData.address,
        phone: inputData.phone,
        postalCode: inputData.postalCode,
        city: inputData.city,
        state: inputData.state,
      };

      if (selectedStaff !== null) {
        Object.assign(payload, {
          id: selectedStaff._id,
        });
      } else {
        Object.assign(payload, {
          role: inputData.role,
        });
      }

      if (role === USER_ROLE.ADMIN) {
        Object.assign(payload, {
          dealer_id: selectedDealerOption,
        });
      }

      const endPointUrl =
        selectedStaff === null ? "/create-staff" : "/update-staff";

      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;

      const response = await apiPostRequest(
        `${apiUrl}/${roleApi?.toLowerCase()}${endPointUrl}`,
        payload
      );
      if (response) {
        toast.success(
          selectedStaff === null
            ? "Service Advisor Added Successfully!"
            : "Service Advisor Updated Successfully!"
        );
        if (selectedStaff === null) {
          setActiveTab("ListStaff");
        } else {
          handleDataUpdateById({ ...payload });
          handleClose();
        }
      }
      setIsCreateLoader(false);
    } catch (error) {
      setIsCreateLoader(false);
      toast.error(error ?? "Something went wrong!");
    }
  };

  const [isValidPostalCode, setIsValidPostalCode] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setInputData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (name === "postalCode") {
      setIsValidPostalCode(POSTALCODE_PATTERN.test(value));
      if (
        event.target.value === null ||
        event.target.value?.trim() === "" ||
        event.target.value === undefined ||
        _.isEmpty(event.target.value?.trim())
      ) {
        setValidationErrors((prev) => ({
          ...prev,
          [name]: false,
        }));
      } else if (
        POSTALCODE_PATTERN.test(event.target.value?.trim()) === false
      ) {
        setValidationErrors((prev) => ({
          ...prev,
          [name]: true,
        }));
      }
      return;
    }
    if (validationErrors[name] && name !== "postalCode") {
      const error = validateField(name, value);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  // google api loookups
  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;

  function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");

    // Check if the fields are found before proceeding
    if (address1Field) {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      address1Field.focus();

      autocomplete.addListener("place_changed", fillInAddress);
    } else {
      console.error("One or more required fields not found in the DOM.");
    }
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    const hasPostalCode = (component) => {
      return component?.types?.includes("postal_code");
    };
    const hasPostalCodeObject = place.address_components?.some(hasPostalCode);
    if (hasPostalCodeObject) {
    } else {
      setInputData((prev) => ({
        ...prev,
        postalCode: "",
      }));
    }
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number":
          address1 = `${component.long_name} ${address1}`;
          break;
        case "route":
          address1 += ` ${component.short_name}`;
          setInputData((prev) => ({
            ...prev,
            address: address1,
          }));
          break;
        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          setInputData((prev) => ({
            ...prev,
            postalCode: postcode?.trim(),
          }));
          setIsValidPostalCode(POSTALCODE_PATTERN.test(postcode?.trim()));
          break;
        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;
        case "locality":
          const localityElement = document.querySelector("#locality");
          if (localityElement) {
            localityElement.value = component.long_name;
            setInputData((prev) => ({
              ...prev,
              city: localityElement.value,
            }));
          }
          break;
        case "administrative_area_level_1":
          const stateElement = document.querySelector("#state");
          if (stateElement) {
            stateElement.value = component.short_name;
            setInputData((prev) => ({
              ...prev,
              state:
                STATE_OPTIONS.findIndex(
                  (op) =>
                    op.value.toLowerCase() ===
                    component.long_name?.toLowerCase()
                ) !== -1
                  ? component.long_name
                  : "",
            }));
            // console.log(component.long_name, " component.long_name");
          }
          break;
        case "country":
          const countryElement = document.querySelector("#country");
          if (countryElement) {
            countryElement.value = component.long_name;
          }
          break;
        default:
          break;
      }
    }
    if (address1Field) {
      address1Field.value = address1;
    }
    if (postalField) {
      postalField.value = postcode;
    }
    if (address2Field) {
      address2Field.focus();
    }
  }
  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="ltn__form-box">
        <form action="submit" onSubmit={submitDealerBasicInfo}>
          <div
            className={classNames(
              "row",
              selectedStaff === null && "px-4 pt-5 mb-50"
            )}
          >
            {selectedStaff === null && (
              <h3 className="pb-4">Add New Service Advisor</h3>
            )}
            {role === USER_ROLE.ADMIN && (
              <>
                <div className="col-md-4">
                  <label className="pb-2">Dealer</label>
                  <div className="input-item pb-2">
                    <select
                      disabled={selectedStaff !== null}
                      className="nice-select"
                      style={{ marginBottom: "5px", marginTop: "5px" }}
                      value={selectedDealerOption}
                      onChange={(e) => {
                        setSelectedDealerOption(e.target.value);
                      }}
                    >
                      <option value="" selected disabled>
                        Select Dealer
                      </option>
                      {dealerOptions.map((item, index) => (
                        <option key={index} value={item.value}>
                          {capitalizeFirstLetter(item.label)}
                        </option>
                      ))}
                    </select>
                    {validationErrors?.dealer_id && (
                      <p className="error-message py-2">Dealer is required!</p>
                    )}
                  </div>
                </div>
                <div className="col-md-8" />
              </>
            )}
            {/* Email */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.email ? inputData.email : ""}
                label="Email Address"
                labelPosition="up"
                handleChange={(e) =>
                  selectedStaff === null && handleInputChange(e)
                }
                name="email"
                type="email"
                required={true}
                isRedStar={true}
                error={validationErrors.email && "Email is required."}
              />
            </div>
            {/* First Name */}

            <div className="col-md-4">
              <CustomInput
                value={inputData.firstName ? inputData.firstName : ""}
                label="First Name"
                labelPosition="up"
                handleChange={handleInputChange}
                name="firstName"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.firstName && "First Name is required."}
              />
            </div>
            {/* Last Name */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.lastName ? inputData.lastName : ""}
                label="Last Name"
                labelPosition="up"
                handleChange={handleInputChange}
                name="lastName"
                type="text"
                required={true}
                isRedStar={true}
                error={validationErrors.lastName && "First Name is required."}
              />
            </div>

            {/* Phone */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.phone}
                label="Phone"
                labelPosition="up"
                handleChange={(event) => {
                  const { name, value } = event.target;

                  const formattedValue = value?.trim()?.startsWith("+1")
                    ? value
                    : `+1 ${value === "+" ? "" : value}`;

                  if (name === "phone") {
                    const phonePattern =
                      /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                    const isValidPhoneNumber =
                      phonePattern.test(formattedValue);
                    setIsValidPhoneNumber(isValidPhoneNumber);
                  }

                  setInputData((prev) => ({
                    ...prev,
                    [name]: formattedValue,
                  }));

                  setValidationErrors((prev) => ({ ...prev, phone: false }));
                }}
                name="phone"
                type="tel"
                required={true}
                isRedStar={true}
                maxLength={13}
                error={validationErrors?.phone && "Phone number is required."}
              />
            </div>
            {/* Address */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.address ? inputData.address : ""}
                label="Address"
                labelPosition="up"
                handleChange={(e) => {
                  handleInputChange(e);
                  initAutocomplete();
                }}
                name="address"
                type="text"
                // required={true}
                // isRedStar={true}
                // error={validationErrors.address && "Address is required."}
                id="ship-address"
              />
            </div>
            {/* State */}
            <div className="col-md-4">
              <CustomInput
                value={inputData.city}
                label="City"
                labelPosition="up"
                handleChange={handleInputChange}
                name="city"
                type="text"
                // required={true}
                // isRedStar={true}
                // error={validationErrors.city && "City is required."}
                id="locality"
              />
            </div>

            <div className="col-md-4 ">
              <CustomInput
                value={inputData.postalCode.toUpperCase()}
                label="Postal Code"
                labelPosition="up"
                handleChange={handleInputChange}
                name="postalCode"
                type="text"
                // required={true}
                // isRedStar={true}
                error={
                  validationErrors.postalCode &&
                  POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) ===
                    false ? (
                    <p
                      className=""
                      style={{
                        marginTop: "-20px",
                        color: "red",
                      }}
                    >
                      Invalid input: please use a 6 characters sequence
                      following the pattern A1A1A1
                    </p>
                  ) : (
                    ""
                  )
                }
                id="postcode"
                maxLength={6}
              />
              {POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) ===
                true && (
                <p
                  style={{
                    // marginTop: "-20px",
                    color: "green",
                  }}
                >
                  {POSTALCODE_PATTERN.test(inputData.postalCode?.trim()) ===
                    true && "Valid"}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Province
                {/* <span className="required-class">*</span> */}
              </label>
              <div className="input-item">
                <select
                  className="nice-select"
                  // value={
                  //   inputData.dealerState ? inputData.dealerState : ""
                  // }
                  id="state"
                  value={inputData.state ? inputData.state : ""}
                  name="state"
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  // required
                >
                  <option value="" disabled selected>
                    Select Province
                  </option>
                  {STATE_OPTIONS.map((op, index) => (
                    <option key={index} value={op.value}>
                      {op.label}
                    </option>
                  ))}
                </select>
                {validationErrors.state && (
                  <p
                    className="error-message"
                    style={{ color: "red", marginTop: "0px" }}
                  >
                    Please select a province.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="btn-wrapper px-4">
            <div className="px-2">
              <CustomButton
                showLoading={isCreateLoader}
                isDisabled={isDealerListLoader || isCreateLoader}
                label={
                  selectedStaff === null
                    ? "Add Service Advisor"
                    : "Udpate Service Advisor"
                }
                type="submit"
                style={{ backgroundColor: "#000000" }}
                cssClass="btn-effect-1 text-uppercase btn1"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateStaff;
