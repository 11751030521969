import { saveAs } from "file-saver";
import moment from "moment-timezone";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const capitalizeFirstLetter = (string) => {
  // console.log('strng',string);
  if (!string || string === undefined) return "";
  return string?.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllFirstLetters = (string) => {
  if (!string || string === undefined) return "";
  return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};

export const parseBooleanVal = (val) => {
  if (val === "true" || val === true || val === 1) return true;
  return false;
};

export const getRoleApi = (role) => {
  if (!role) return "";
  const roleApi =
    role === "Technician" ||
    role === "Sales Advisor" ||
    role === "Service Advisor"
      ? "staff"
      : role;

  return roleApi?.toLowerCase();
};

export const dateFormatDDMMYYYY = (date) => {
  if (!date) return "";
  return moment(date).format("DD-MM-YYYY");
};

export const textTruncate = (
  str,
  isGreaterThanRequiredCharacters,
  returnCharactersNeeded
) => {
  if (!str) return "";
  return str.length > isGreaterThanRequiredCharacters
    ? str.substring(0, returnCharactersNeeded) + "..."
    : str;
};

export const isValidFile = (file, type) => {
  const fileName = file.name;
  let exts = "";
  if (type === "any") {
    exts = [
      "png",
      "jpg",
      "jpeg",
      "gif",
      "pdf",
      "doc",
      "docx",
      // "xlsx",
      // "csv",
      // "xls",
      "mp4",
      "mov",
      "wmv",
      "avi",
    ];
  } else {
    exts = type === "image" ? ["png", "jpg", "jpeg", "gif"] : ["pdf"];
  }

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();
    if (!exts.includes(getExt[0].toLowerCase())) {
      if (type === "any") {
        // return "only Pdf/Doc/Excel/Image/Video files are allowed";
        return "only Pdf/Doc/Image/Video files are allowed";
      }
      return type === "image"
        ? "only Image files are allowed"
        : "only Pdf/Video files are allowed";
    }

    // if (file.size / 1024 / 1024 > 10) {
    //   return "max. 10MB file size allow";
    // }

    return "";
  }
  return "";
};

export const maxFileSize = (file, size) => {
  if (file.size === 0) return `file size 0 byte please upload another one`;
  if (file.size / (1024 * 1024).toFixed(2) > size) {
    return `max. ${size}MB file size allow`;
  }
  return "";
};

export const getFileExtenson = (fileName) => {
  if (fileName) {
    return fileName.split(".").pop();
  }
  return "invalid";
};

export const setSericeTenureOptions = (tenures) => {
  const indexedByTenureId = tenures.reduce((acc, tenure) => {
    const transformedTenure = {
      ...tenure,
      label: tenure.tenureDuration,
      value: tenure._id,
      [`${tenure._id}`]: tenure.tenureDuration,
    };
    acc[tenure._id] = transformedTenure;
    return acc;
  }, {});
  return indexedByTenureId;
};

export const sumOfProgramsPrices = (data = []) => {
  return data.reduce((acc, current) => acc + current.priceDetails.price, 0);
};

export const sleep = (ms = 650) => {
  console.log("Kindly remember to remove `sleep`");
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const downloadFile = async (url, filename) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, filename);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const getMonthithYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const startYear = 2019;
  const endYear = currentYear;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let options = [];

  for (let year = endYear; year >= startYear; year--) {
    const endMonth = year === currentYear ? currentMonthIndex : 11;
    for (let monthIndex = endMonth; monthIndex >= 0; monthIndex--) {
      const formattedMonth = (monthIndex + 1).toString().padStart(2, "0");
      options.push({
        value: `${year}-${formattedMonth}-01`,
        label: `${months[monthIndex]} ${year}`,
      });
    }
  }

  return options;
};

export const convertDateByTimezone = (date, timezone = null) => {
  if (timezone === null) return date;
  // console.log("moment(date).tz(timezone)", moment(date).tz(timezone));
  return moment(date).tz(timezone);
};

export const formatDateToDDMMMYYYY = (date) => {
  if (!date) return "-";
  return moment(date).format("DD MMM, YYYY");
};
