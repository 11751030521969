import React, { useEffect, useState } from "react";
import CustomSelect from "../../../common/Select/CustomSelect";
import { NumericFormat } from "react-number-format";
import { STATUS_SELECT_OPTIONS } from "../../../../utils/constant";
import { isNumber } from "lodash";
import { parseBooleanVal } from "../../../../utils/utils";

const ServiceProgramItem = (props) => {
  const {
    tenure,
    handleModifyTenureRow,
    priceObj,
    isTieredService,
    serviceTierOptions,
  } = props;

  // console.log("priceObj", priceObj);

  const [inputData, setInputData] = useState(null);

  const [isRefresh, setIsRefresh] = useState(0);

  useEffect(() => {
    if (isTieredService) {
      handleModifyTenureRow(inputData, true);
    } else {
      handleModifyTenureRow(inputData);
    }
  }, [inputData, isRefresh]);

  const findPriceByTierId = (tierId, key = "status") => {
    const index = priceObj.findIndex((p) => p.serviceTier === tierId);
    if (index !== -1) {
      if (key === "status") {
        return priceObj[index][`${key}`] || "active";
      }
      if (key === "isAdminPrice") {
        return priceObj[index][`${key}`];
      }
      return isNumber(priceObj[index][`${key}`])
        ? priceObj[index][`${key}`]
        : null;
    }
    return null;
  };

  useEffect(() => {
    if (isTieredService) {
      const tierInputData = serviceTierOptions.reduce((acc, t) => {
        const tierId = t._id;
        acc[tierId] = {
          tierName: t.tierName,
          status: findPriceByTierId(t._id, "status")
            ? findPriceByTierId(t._id, "status") === "active"
              ? "active"
              : "inactive"
            : "active",
          price: findPriceByTierId(t._id, "price"),
          serviceTenure: tenure._id,
          serviceTier: t._id,
        };
        return acc;
      }, {});
      setInputData(tierInputData);
    } else {
      setInputData({
        status: priceObj?.status
          ? priceObj?.status === "active"
            ? "active"
            : "inactive"
          : "active",
        price: priceObj?.price || null,
        serviceTenure: tenure._id,
      });
    }
  }, [isTieredService]);

  if (isTieredService) {
    return (
      <>
        {/* <div className="container-tier-screen row"> */}
        <div className="col-md-3">
          <input
            className="text no-spin-buttons"
            type="text"
            name={`tenure-${tenure.tenureDuration}`}
            value={tenure.tenureDuration}
            disabled={true}
            // onChange={() => {}}
          />
        </div>
        <div className="col-md-9 pb-4">
          {serviceTierOptions.map((tierItem, index) => (
            <div className="row" key={index}>
              <div className="col-md-4">
                <input
                  className="text no-spin-buttons"
                  type="text"
                  name={`tier-${tierItem.tierName}`}
                  value={tierItem.tierName}
                  disabled={true}
                  // onChange={() => {}}
                />
              </div>
              <div className="col-md-4 custom-numericInput-container">
                {/* {parseBooleanVal(
                  findPriceByTierId(tierItem._id, "isAdminPrice")
                ) === true && (
                  <span title={"Default Price By Admin"}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  </span>
                )} */}
                <NumericFormat
                  allowNegative={false}
                  placeholder="Default Price"
                  id="numericInput"
                  min={0}
                  //   type="text"
                  maxLength={"13"}
                  className={"numericInput isTierScreen"}
                  value={inputData?.[tierItem._id].price}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  valueIsNumericString={true}
                  decimalScale={2}
                  prefix={"$"}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;
                    setInputData((prev) => ({
                      ...prev,
                      [tierItem._id]: {
                        ...prev[tierItem._id],
                        price: value ? parseFloat(value) : null,
                      },
                    }));
                    setIsRefresh((prev) => prev + 1);
                  }}
                />
                <label htmlFor="numericInput">Default Price</label>
              </div>
              <div className="col-md-4">
                <CustomSelect
                  label="Status"
                  placeHolder={"Select Status"}
                  selectedValue={STATUS_SELECT_OPTIONS.find(
                    (x) => x.value === inputData?.[tierItem._id].status
                  )}
                  handleChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      [tierItem._id]: {
                        ...prev[tierItem._id],
                        status: e.value,
                      },
                    }));
                    setIsRefresh((prev) => prev + 1);
                  }}
                  options={STATUS_SELECT_OPTIONS}
                />
              </div>
            </div>
          ))}
        </div>
        <hr />
        {/* </div> */}
      </>
    );
  }

  return (
    <>
      <div className="col-md-4">
        {/* <CustomSelect
          isDisabled={true}
          loading={isFetchServiceTenureLoader}
          label="Tenure"
          placeHolder={"Select Tenure"}
          selectedValue={""}
          handleChange={() => {}}
          options={serviceTenureOptions}
          error={
            //   validationErrors?.dealer_id ? "Dealer is required!" : ""
            ""
          }
        /> */}
        <input
          className="text no-spin-buttons"
          type="text"
          name={`tenure-${tenure.tenureDuration}`}
          value={tenure.tenureDuration}
          disabled={true}
          // onChange={() => {}}
        />
      </div>
      <div className="col-md-4 custom-numericInput-container">
        <NumericFormat
          allowNegative={false}
          placeholder="Default Price"
          id="numericInput"
          min={1}
          //   type="text"
          maxLength={"13"}
          className={"numericInput"}
          value={inputData?.price}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          valueIsNumericString={true}
          decimalScale={2}
          prefix={"$"}
          allowLeadingZeros={false}
          onValueChange={(e) => {
            const { value } = e;
            setInputData((prev) => ({
              ...prev,
              price: value ? parseFloat(value) : null,
            }));
            setIsRefresh((prev) => prev + 1);
          }}
        />
        <label htmlFor="numericInput">Default Price</label>
        {/* {false && (
          <span className="error-message py-1">This field is required!</span>
        )} */}
      </div>
      <div className="col-md-4">
        <CustomSelect
          label="Status"
          placeHolder={"Select Status"}
          selectedValue={STATUS_SELECT_OPTIONS.find(
            (x) => x.value === inputData?.status
          )}
          handleChange={(e) => {
            setInputData((prev) => ({
              ...prev,
              status: e.value,
            }));
            setIsRefresh((prev) => prev + 1);
          }}
          options={STATUS_SELECT_OPTIONS}
          // error={
          //   //   validationErrors?.dealer_id ? "Dealer is required!" : ""
          //   ""
          // }
        />
      </div>
    </>
  );
};

export default ServiceProgramItem;
