import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../common/Button/CustomButton";
import CustomInput from "../../common/Input/CustomInput";
import CustomSelect from "../../common/Select/CustomSelect";
import { NumericFormat } from "react-number-format";
import { classNames, isValidFile, maxFileSize } from "../../../utils/utils";
import { toast } from "react-toastify";
import {
  apiFormDataRequest,
  apiGetRequest,
  apiPostRequest,
} from "../../../helpers/Requests";
import {
  URL_ADMIN_DEALER_MATERIAL_CATALOGUE_PRICING,
  URL_ADMIN_MATERIAL_CATALOGUE,
} from "../../../service/URL";
import DummyImage from "../../../asset/image_placeholder.png";
import parseError from "../../../utils/ErrorParse";
import SwitchInput from "../../common/SwitchInput/SwitchInput";
import useDealer from "../../../Hooks/useDealer";

const AddMaterial = (props) => {
  const {
    selectedItem,
    isAddMaterialShow,
    setIsAddMaterialShow,
    handleAddMaterialLocally,
    isFetchMaterialCategoryLoader,
    materialCaregoriesOptions,
  } = props;
  const imageRef = useRef();
  const size = 2;

  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);
  const { isDealerListLoader, dealersData = [] } = useDealer();

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        ...x,
        label:
          x?.firstName && x.firstName?.length > 0
            ? `${x.firstName} ${x.lastName}`
            : x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  const [material, setMaterial] = useState({
    file: null,
    name: "",
    category: null,
    price: null,
    description: null,
    status: "active",
  });

  const [validationErrors, setValidationErrors] = useState({
    file: "",
    name: "",
    category: "",
    price: "",
    description: "",
  });

  const [isSubmitLoader, setIsSubmitLoader] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setMaterial({
        preview: selectedItem?.image?.url || "",
        file: "",
        name: selectedItem.name,
        category: selectedItem.materialCategory?._id || "",
        price: selectedItem.price,
        description: selectedItem.description,
        status: selectedItem.status,
      });
    }
  }, [selectedItem]);

  const validateField = (field, value) => {
    if (field === "phone") {
      return value === "" || value === "+1" || value.trim() === "+1";
    } else {
      return value === null || value === "" || value === undefined;
    }
  };

  const ValidationForm = () => {
    const errorObj = {};
    const notInclude = ["address", "city", "status"];
    if (
      selectedItem !== null &&
      selectedItem._id &&
      material.file === "" &&
      selectedItem.image
    ) {
      notInclude.push("file");
    }

    for (const [field, value] of Object.entries(material)) {
      if (notInclude.findIndex((x) => x === field) === -1) {
        if (validateField(field, value)) {
          errorObj[field] = true;
        }
      }
    }
    setValidationErrors((prev) => ({ ...prev, ...errorObj }));
    return Object.keys(errorObj).length > 0;
  };

  useEffect(() => {
    return () => {
      if (imageRef?.current) {
        imageRef.current = null;
      }
    };
  }, []);

  const handleChangeImage = (e) => {
    if (e.target.files.length === 0) return;
    const uploadFile = e.target.files[0];

    if (uploadFile) {
      let message = isValidFile(uploadFile, "image");
      if (message.length > 0) {
        toast.error(message);
        return;
      }
      message = maxFileSize(uploadFile, size ? size : 10);
      if (message.length > 0) {
        toast.error(message);
        return;
      }
      if (uploadFile.name.length > 50) {
        toast.error("File name exceed by 50 letters!");
        return;
      }

      setMaterial((prev) => ({
        ...prev,
        file: uploadFile,
        preview: URL.createObjectURL(uploadFile),
      }));
      if (validationErrors["file"]) {
        const error = validateField("file", uploadFile);
        setValidationErrors((prev) => ({
          ...prev,
          file: error,
        }));
      }
    }

    // if (imageRef?.current) {
    //   imageRef.current = null;
    // }
  };

  const HandleSubmit = async () => {
    try {
      const hasErrors = ValidationForm();
      if (hasErrors) {
        console.log("Form has validation errors", validationErrors);
        return;
      }

      let formData = new FormData();
      formData.append("name", material.name);
      formData.append("description", material.description);
      if (material.file) formData.append("image", material.file);
      formData.append("price", material.price);
      formData.append("category", material.category);
      formData.append(
        "status",
        material.status === true || material.status === "active"
          ? "active"
          : "inactive"
      );

      setIsSubmitLoader(true);
      const response = await apiFormDataRequest(
        selectedItem !== null
          ? ` ${URL_ADMIN_MATERIAL_CATALOGUE}/${selectedItem._id}`
          : URL_ADMIN_MATERIAL_CATALOGUE,
        formData,
        selectedItem !== null ? "PUT" : "POST"
      );
      if (response.data.materialCatalogue) {
        handleAddMaterialLocally(
          response.data.materialCatalogue,
          selectedItem !== null ? "update" : "add"
        );
        setIsAddMaterialShow(false);
        toast.success(
          `Material Catalogue ${
            selectedItem === null ? "Created" : "Updated"
          } Successfully!`
        );
      }
      setIsSubmitLoader(false);
    } catch (err) {
      setIsSubmitLoader(false);
      const errorMessage = parseError(err);
      toast.error(errorMessage ?? "Something went wrong!");
      console.log("err", err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setMaterial((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (validationErrors[name]) {
      const error = validateField(name, value);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const isLockedField = useMemo(() => {
    if (selectedItem) {
      setMaterial({
        preview: selectedItem?.image?.url || "",
        file: "",
        name: selectedItem.name,
        category: selectedItem.materialCategory?._id || "",
        price: selectedItem.price,
        description: selectedItem.description,
        status: selectedItem.status,
      });
    }
    if (selectedDealerOption && selectedDealerOption?.value) {
      return true;
    }
    return false;
  }, [selectedDealerOption]);

  const [isFetchDealerPricingMAterial, setIsFetchDealerPricingMaterial] =
    useState(null);

  const [customPrice, setCustomPrice] = useState(null);

  const hanleDealerPricingSubmit = async () => {
    try {
      if (customPrice === null || !customPrice) {
        toast.error("Custom Price is required!");
        return;
      }

      setIsSubmitLoader(true);
      const response = await apiPostRequest(
        URL_ADMIN_DEALER_MATERIAL_CATALOGUE_PRICING,
        {
          dealer: selectedDealerOption.value,
          material: selectedItem._id,
          customPrice,
        }
      );

      if (response.data && response.data.dealerPricing) {
        toast.success("Dealer Price Updated Successfully");
      }
      setIsSubmitLoader(false);
    } catch (err) {
      setIsSubmitLoader(false);
      console.log("err", err);
    }
  };

  const getDealerMaterialData = async () => {
    try {
      setCustomPrice(null);
      setIsFetchDealerPricingMaterial(true);
      const response = await apiGetRequest(
        `${URL_ADMIN_DEALER_MATERIAL_CATALOGUE_PRICING}/${selectedDealerOption?.value}/${selectedItem._id}`
      );
      if (response.data && response.data.dealerPricing) {
        setCustomPrice(response.data.dealerPricing.customPrice);
      }
      setIsFetchDealerPricingMaterial(false);
    } catch (err) {
      setIsFetchDealerPricingMaterial(false);
      console.log("err", err);
    }
  };

  useEffect(() => {
    setCustomPrice(null);
    if (
      selectedDealerOption &&
      selectedDealerOption.value &&
      selectedItem !== null &&
      selectedItem._id
    ) {
      getDealerMaterialData();
    }
  }, [selectedDealerOption]);

  return (
    <>
      <Modal
        show={isAddMaterialShow}
        onHide={() => {
          setIsAddMaterialShow(false);
        }}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom:
              "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
          }}
          closeButton
        >
          <Modal.Title>{`${
            selectedItem === null ? "Add" : "Update"
          } Material`}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "10px", overflowY: "scroll" }}>
          <div className="main-container-add-material">
            {selectedItem !== null && (
              <div className="row col-12 customSelect">
                <CustomSelect
                  isClear={true}
                  isDisabled={isDealerListLoader}
                  loading={isDealerListLoader}
                  placeHolder={"Select Dealer"}
                  label="Dealer"
                  selectedValue={
                    selectedDealerOption ? selectedDealerOption : ""
                  }
                  handleChange={setSelectedDealerOption}
                  options={dealerOptions}
                  error={
                    validationErrors?.dealer_id ? "Dealer is required!" : ""
                  }
                />
              </div>
            )}
            <div className="row py-4">
              <div className="col-md-2 add-product-image d-flex justify-content-center align-items-center">
                <img
                  src={
                    material.file && material.hasOwnProperty("preview")
                      ? material.preview
                      : selectedItem !== null &&
                        material.hasOwnProperty("preview")
                      ? material.preview
                      : DummyImage
                  }
                  style={{
                    maxWidth: "120px",
                    maxHeight: "120px",
                  }}
                  alt="Preview_material_image"
                  class="img-attachment"
                />
              </div>
              <div className="col-md-10">
                <input
                  isDisabled={isLockedField}
                  className="d-none"
                  type="file"
                  ref={imageRef}
                  onChange={(e) => handleChangeImage(e)}
                  accept="image/*"
                />
                <CustomButton
                  isDisabled={isLockedField}
                  cssClass="change-image-btn"
                  variant="tertiary"
                  label="Change image"
                  clickHandler={() => {
                    if (imageRef && imageRef?.current) {
                      imageRef.current.click();
                    }
                  }}
                />
              </div>
              {validationErrors?.file && (
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    paddingLeft: "5px",
                    paddingTop: "6px",
                  }}
                >
                  Material Image is required!
                </p>
              )}
            </div>
            <div className="row col-12 pt-2">
              <CustomInput
                isDisabled={isLockedField}
                error={
                  validationErrors?.name > 0 && "Material Name is required!"
                }
                name="name"
                label="Material Name"
                value={material.name}
                handleChange={handleInputChange}
                placeholder="Enter Material Name..."
              />
            </div>
            <div className="row gap-x-2">
              <div className="col-md-6">
                <CustomSelect
                  error={
                    validationErrors?.category > 0 && "Category is required!"
                  }
                  isSearch={true}
                  isDisabled={isFetchMaterialCategoryLoader || isLockedField}
                  loading={isFetchMaterialCategoryLoader}
                  placeHolder={"Select Category"}
                  label="Category"
                  selectedValue={
                    material?.category
                      ? materialCaregoriesOptions?.find(
                          (x) => x.value === material?.category
                        )
                      : null
                  }
                  handleChange={(e) => {
                    handleInputChange({
                      target: { name: "category", value: e.value },
                    });
                  }}
                  options={materialCaregoriesOptions}
                />
              </div>
              <div className="col-md-6 custom-numericInput-container">
                <NumericFormat
                  allowNegative={false}
                  disabled={isLockedField}
                  placeholder="Default Price"
                  id="numericInput"
                  min={1}
                  //   type="text"
                  maxLength={"13"}
                  className={classNames(
                    "text numericInput",
                    validationErrors?.price && "error-number-border"
                  )}
                  value={material.price}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  valueIsNumericString={true}
                  decimalScale={2}
                  prefix={"$"}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;
                    handleInputChange({
                      target: { name: "price", value: value },
                    });
                  }}
                />
                <label htmlFor="numericInput">Price</label>
                {validationErrors?.price && (
                  <p
                    className="error-message"
                    style={{ marginTop: "-15 !important" }}
                  >
                    Price is required!
                  </p>
                )}
              </div>

              {!isFetchDealerPricingMAterial &&
                selectedItem !== null &&
                selectedDealerOption &&
                selectedDealerOption?.value && (
                  <div className="col-md-6 custom-numericInput-container">
                    <NumericFormat
                      allowNegative={false}
                      defaultValue={customPrice ? customPrice : null}
                      disabled={isFetchDealerPricingMAterial}
                      placeholder="Dealer Custom Price"
                      id="numericInput"
                      min={1}
                      //   type="text"
                      maxLength={"13"}
                      className={classNames(
                        "text numericInput",
                        validationErrors?.customPrice && "error-number-border"
                      )}
                      // value={
                      //   // material?.customPrice
                      //   //   ? material?.customPrice
                      //   //   : material.price
                      //   customPrice ? customPrice : null
                      // }
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      valueIsNumericString={true}
                      decimalScale={2}
                      prefix={"$"}
                      allowLeadingZeros={false}
                      onValueChange={(e) => {
                        const { value } = e;
                        console.log("value value", value);
                        setCustomPrice(value);
                        // handleInputChange({
                        //   target: { name: "customPrice", value: value },
                        // });
                      }}
                    />
                    <label htmlFor="numericInput">Custom Price</label>
                  </div>
                )}
            </div>
            <div className="row">
              <div className="col-12">
                <label className="text-black">
                  Description
                  {/* <span className="required-class">*</span> */}
                </label>
                <textarea
                  disabled={isLockedField}
                  class={classNames(
                    "textarea-input",
                    validationErrors?.description && "error-number-border"
                  )}
                  name="description"
                  rows={5}
                  style={{ resize: "none", borderRadius: "5px !important" }}
                  value={material.description}
                  onChange={handleInputChange}
                  required={true}
                />
                {validationErrors?.description && (
                  <p className="error-message">Description is required!</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label className="text-black">Status</label>
                <SwitchInput
                  isDisabled={isLockedField}
                  label={"Material Status"}
                  checked={material.status === "active"}
                  handleChange={(e) => {
                    handleInputChange({
                      target: {
                        name: "status",
                        value: e === true ? "active" : "inactive",
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            isDisabled={isSubmitLoader}
            label="Cancel"
            clickHandler={() => {
              setIsAddMaterialShow(false);
            }}
            cssClass="btn-effect-1 btn1"
          />
          <CustomButton
            showLoading={isSubmitLoader}
            isDisabled={isFetchMaterialCategoryLoader || isSubmitLoader}
            label={`${
              selectedItem === null
                ? "Add"
                : `Update ${
                    selectedItem !== null &&
                    selectedDealerOption &&
                    selectedDealerOption?.value
                      ? "Dealer"
                      : ""
                  }`
            } Material`}
            clickHandler={() => {
              if (isLockedField) {
                hanleDealerPricingSubmit();
              } else {
                HandleSubmit();
              }
            }}
            style={{ backgroundColor: "#000000" }}
            cssClass="btn-effect-1 btn1"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMaterial;
