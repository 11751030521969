import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../common/Button/CustomButton";
import Loader from "../../common/Loader/Loader";
import useManager from "../../../Hooks/useManager";
import { capitalizeAllFirstLetters } from "../../../utils/utils";
import CreateManager from "./CreateManager";

const ListManager = ({ setActiveTab }) => {
  const [selectedShowManager, setSelectedShowManager] = useState(null);

  const {
    isManagerListLoader,
    managersData = [],
    handleDataUpdateById,
  } = useManager();

  return (
    <>
      <div className="checkoverflow " style={{ paddingRight: "20px" }}>
        <div className="row mb-50 mt-3">
          <h3 className="pb-3">Managers List</h3>
          <div className="mobileTable">
            <table
              class="table overflow-set"
              style={{ margin: "20px 0px 20px 0px" }}
            >
              <thead>
                <tr style={{ backgroundColor: "#4A6543" }}>
                  <th scope="col">Dealer Name</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">Postal Code</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                {isManagerListLoader ? (
                  <Loader
                    style={{ width: "35px", height: "35px" }}
                    isSmallView={true}
                  />
                ) : managersData.length > 0 ? (
                  managersData.map((item) => (
                    <tr>
                      <td>
                        {item?.dealer
                          ? capitalizeAllFirstLetters(
                              `${item?.dealer?.firstName} ${item?.dealer?.lastName}`
                            )
                          : "-"}
                      </td>
                      <td>
                        {capitalizeAllFirstLetters(item?.firstName) ?? "-"}
                      </td>
                      <td>
                        {capitalizeAllFirstLetters(item?.lastName) ?? "-"}
                      </td>
                      <td>{item?.email ?? "-"}</td>
                      <td>{item?.phone ?? "-"}</td>
                      <td>{item?.address || "-"}</td>
                      <td>{item?.postalCode || "-"}</td>
                      <td>{item?.city || "-"}</td>
                      <td>{item?.state || "-"}</td>
                      <td>
                        <CustomButton
                          label="View"
                          type="button"
                          cssClass="btn-effect-1 text-uppercase"
                          clickHandler={() => {
                            setSelectedShowManager(item);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                      >
                        <span className="error-text">No data found!</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={selectedShowManager !== null}
        onHide={() => {
          setSelectedShowManager(null);
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Update Manager
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", height: "80vh" }}>
          <CreateManager
            setActiveTab={setActiveTab}
            handleClose={() => {
              setSelectedShowManager(null);
            }}
            selectedManager={selectedShowManager}
            handleDataUpdateById={handleDataUpdateById}
            isCallFrom="managerList"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListManager;
