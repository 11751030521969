import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import _ from "lodash";
import { USER_ROLE } from "../utils/constant";
import { apiGetRequest } from "../helpers/Requests";
import { URL_ADMIN_SERVICE_MANAGER } from "../service/URL";

const useServiceManager = () => {
  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [isServiceManagerListLoader, setIsServiceManagerLoader] = useState();
  const [serviceManagersData, setServiceManagersData] = useState([]);

  useEffect(() => {
    if (
      role === USER_ROLE.ADMIN ||
      role === USER_ROLE.SUPER_ADMIN ||
      role === USER_ROLE.DEALER
    ) {
      getAllServiceManagers();
    }
  }, []);

  const handleDataUpdateById = (data) => {
    const index = serviceManagersData.findIndex((x) => x._id === data.id);
    if (index !== -1) {
      const prevData = _.cloneDeep(serviceManagersData);
      prevData.splice(index, 1, {
        ...prevData[index],
        ...data,
      });
      setServiceManagersData([...prevData]);
    }
  };

  const handleRemoveById = (id) => {
    const index = serviceManagersData.findIndex((x) => x._id === id);
    if (index !== -1) {
      const prevData = _.cloneDeep(serviceManagersData);
      prevData.splice(index, 1);
      setServiceManagersData([...prevData]);
    }
  };

  const getAllServiceManagers = async () => {
    try {
      setIsServiceManagerLoader(true);
      const res = await apiGetRequest(URL_ADMIN_SERVICE_MANAGER);
      if (res?.data) {
        setServiceManagersData([...res.data]);
      }
      setIsServiceManagerLoader(false);
    } catch (err) {
      console.error("err while getting ET managers list:", err);
      setIsServiceManagerLoader(false);
    }
  };

  return {
    isServiceManagerListLoader,
    serviceManagersData,
    handleDataUpdateById,
    handleRemoveById,
  };
};

export default useServiceManager;
