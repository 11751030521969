import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../common/Loader/Loader";
import CreateAdmin from "./CreateAdmin";
import { apiGetRequest } from "../../../helpers/Requests";
import { URL_SUPER_ADMIN_ADMIN_LIST } from "../../../service/URL";
import "./style.css";
import _ from "lodash";
import ItemAdmin from "./ItemAdmin";

const ListAdmin = ({ setActiveTab }) => {
  const [selectedShowAdmin, setSelectedShowAdmin] = useState(null);

  const [isAdminListLoader, setIsAdminListLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    try {
      setIsAdminListLoader(true);
      const response = await apiGetRequest(URL_SUPER_ADMIN_ADMIN_LIST);
      if (response.data) {
        setData(response.data || []);
      }
      setIsAdminListLoader(false);
    } catch (err) {
      setIsAdminListLoader(false);
      console.log("err", err);
    }
  };

  const handleDataUpdateById = (item) => {
    const index = data.findIndex((x) => x._id === item._id);
    if (index !== -1) {
      const prevData = _.cloneDeep(data);
      prevData.splice(index, 1, {
        ...prevData[index],
        ...item,
      });
      setData([...prevData]);
    }
  };

  return (
    <>
      <div className="checkoverflow " style={{ paddingRight: "20px" }}>
        <div className="row mb-50 mt-3">
          <h3 className="pb-3">Administrators List</h3>
          <div className="mobileTable">
            <table
              class="table overflow-set"
              style={{ margin: "20px 0px 20px 0px" }}
            >
              <thead>
                <tr style={{ backgroundColor: "#4A6543" }}>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {isAdminListLoader ? (
                  <Loader
                    style={{ width: "35px", height: "35px" }}
                    isSmallView={true}
                  />
                ) : data.length > 0 ? (
                  data.map((item, index) => (
                    <ItemAdmin
                      item={item}
                      key={index}
                      setSelectedShowAdmin={setSelectedShowAdmin}
                      handleDataUpdateById={handleDataUpdateById}
                    />
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={selectedShowAdmin !== null}
        onHide={() => {
          setSelectedShowAdmin(null);
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Update Admin Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", height: "80vh" }}>
          <CreateAdmin
            setActiveTab={setActiveTab}
            handleClose={() => {
              setSelectedShowAdmin(null);
            }}
            selectedManager={selectedShowAdmin}
            handleDataUpdateById={handleDataUpdateById}
            isCallFrom="adminList"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListAdmin;
