import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const CeramxCarfax = () => {
  const addCarFaxRef = useRef();
  var apiUrl = process.env.REACT_APP_API_URL;
  const [showCarfaxEntries, setShowCarfaxEntries] = useState(false);
  // const redirectToAddCarFax = () => {
  //   addCarFaxRef.current.click();
  // };
  const navigate = useNavigate();
  const [CeramxId, setCeramxId] = useState();
  // States to get CarFax Entries
  const [populateCarFax, setPopulateCarFax] = useState([]);
  const handleCarfaxEdit = (
    itemId,
    date,
    description,
    make,
    model,
    state,
    vin,
    year
  ) => {
    const dateObject = new Date(date);
    const yearObject = new Date(year);

    const formattedDate = dateObject.toISOString().split("T")[0];

    const fullYear = yearObject.getFullYear();

    setEditVehical((prevState) => ({
      ...prevState,
      make: make,
      model: model,
      year: fullYear,
      vin: vin,
      province: state,
      desc: description,
      date: formattedDate,
    }));
    console.log(itemId);
    setCeramxId(itemId);
    setShowCarfaxEntries(true);
  };
  //   Add Ceramx
  const [addVehical, setAddVehical] = useState({
    make: "",
    model: "",
    year: "",
    vin: "",
    province: "Alberta",
    date: "",
    desc: "Ceramic Paint Protection",
  });
  //Edit Ceramx
  const [editVehical, setEditVehical] = useState({
    make: "",
    model: "",
    year: "",
    vin: "",
    province: "",
    date: "",
    desc: "",
  });
  const [showForm, setShowForm] = useState(false);
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setAddVehical((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const redirectToAddCarFax = () => {
    setShowCarfaxEntries(true);
  };

  const handleAddCarFax = (event) => {
    event.preventDefault();

    const accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

    fetch(`${apiUrl}/dealer/carfax`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        serviceDate: addVehical.date,
        serviceDescription: addVehical.desc,
        vehical: {
          make: addVehical.make,
          modal: addVehical.model,
          state: addVehical.province,
          vin: addVehical.vin,
          year: addVehical.year,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.length);
        if (data.vehical._id) {
          console.log("done");
          const updatedData = [...populateCarFax, data];
          setPopulateCarFax(updatedData);
          setShowCarfaxEntries(false);
        }
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const carfaxEntries = `${apiUrl}/dealer/carfax-entries`;
    const accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);
    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(carfaxEntries, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setPopulateCarFax(data);
        }

        console.log(data, "carfax");
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div>
      {/* <div className="tab-pane fade" id="ceramxcarfax"> */}
      {showCarfaxEntries ? (
        <div>
          {/* // {showForm && */}
          {/* //  add Carfax Services * */}
          <div
            onSubmit={handleAddCarFax}
            // className="tab-pane fade"
            id="addcarfaxservices"
          >
            <form action="submit">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1>Vehicle Details</h1>
              </div>
              <div className="row mb-50">
                <div className="col-md-4">
                  <label>Make:</label>
                  <input
                    type="text"
                    name="make"
                    value={addVehical.make}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-4">
                  <label>Model:</label>
                  <input
                    type="text"
                    name="model"
                    value={addVehical.model}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label>Year:</label>
                  <input
                    className="text no-spin-buttons"
                    type="number"
                    name="year"
                    value={addVehical.year}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label>VIN:</label>
                  <input
                    className="text no-spin-buttons"
                    type="text"
                    name="vin"
                    value={addVehical.vin}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label>Province/State</label>
                  <div className="input-item">
                    <select
                      onChange={handleInputChange}
                      name="province"
                      value={addVehical.province}
                      className="nice-select"
                    >
                      <option>Alberta</option>
                      <option>British Colombia </option>
                      <option>Manitoba </option>
                      <option>New Brunswick </option>
                      <option>Newfoundland and Labrador </option>
                      <option>Northwest Territories</option>
                      <option>Nova Scotia </option>
                      <option>Nunavut </option>
                      <option>Ontario </option>
                      <option>Prince Edward Island </option>
                      <option>Quebec </option>
                      <option>Saskatchewan </option>
                      <option>Yukon</option>
                    </select>
                  </div>
                </div>
                <h1>Service Details</h1>
                <div className="col-md-4">
                  <label>Service Date:</label>
                  <input
                    className="text"
                    type="date"
                    name="date"
                    value={addVehical.date}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label>Service Description:</label>
                  <div className="input-item">
                    <select
                      name="desc"
                      value={addVehical.desc}
                      onChange={handleInputChange}
                      className="nice-select"
                    >
                      <option>Ceramic Paint Protection</option>
                      <option>Ceramic Interior Protection </option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                style={{
                  padding: "15px 30px",
                  backgroundColor: "rgb(255, 90, 60)",
                  color: "#FFF",
                  marginBottom: "30px",
                  fontSize: "16px",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <button
              onClick={redirectToAddCarFax}
              className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
              style={{
                alignSelf: "flex-end",
                padding: "5px 10px",
                backgroundColor: "rgb(255, 90, 60)",
                color: "#FFF",
                fontSize: "0.8rem",
                borderRadius: "4px",
                marginLeft: "20px",
              }}
            >
              <FaPlus />
              Add New
            </button>
          </div>
          <div
            class="accordion"
            id="accordionExample"
            style={{ padding: "20px" }}
          >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#ceramxcarfax"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  CARFAX SERVICES
                </button>
              </h2>
              <div
                id="ceramxcarfax"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body mobileTable" style={{ padding: 0 }}>
                  <table class="table">
                    <thead>
                      <tr className="product-resources-row">
                        <th scope="col" className="tablecol">
                          VIN
                        </th>
                        <th scope="col">INVOICE#</th>
                        <th scope="col">SERVICE</th>
                        <th scope="col">SERVICE DATE</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {populateCarFax.map((row) => (
                        <tr key={row._id}>
                          <td className="tablecol">{row.vehical.vin}</td>
                          <td>SPCF-1C4RJFBG</td>
                          <td>{row.serviceDescription}</td>
                          <td>
                            {row.serviceDate && (
                              <span>
                                {new Date(row.serviceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </span>
                            )}
                          </td>

                          <td>
                            <button
                              onClick={() =>
                                handleCarfaxEdit(
                                  row._id,
                                  row.serviceDate,
                                  row.serviceDescription,
                                  row.vehical.make,
                                  row.vehical.modal,
                                  row.vehical.state,
                                  row.vehical.vin,
                                  row.vehical.year
                                )
                              }
                              className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                              style={{
                                padding: "1px 6px",
                                backgroundColor: "#171B2A",
                                color: "#FFF",
                              }}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* edit car fax services */}
      {/* <div
        // onSubmit={handleEditCarFax}
        className="tab-pane fade"
        id="editcarfaxservices"
      >
        <form action="submit">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Vehicle Details</h1>
          </div>
          <div className="row mb-50">
            <div className="col-md-4">
              <label>Make:</label>
              <input
                type="text"
                name="editVehicalMake"
                value={editVehical.make}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-4">
              <label>Model:</label>
              <input
                type="text"
                name="editVehicalModel"
                value={editVehical.model}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>Year:</label>
              <input
                type="text"
                name="editVehicalYear"
                value={editVehical.year}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>VIN:</label>
              <input
                type="text"
                name="editVehicalVin"
                value={editVehical.vin}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>Province/State</label>
              <div className="input-item">
                <select
                  onChange={handleInputChange}
                  value={editVehical.province}
                  name="editVehicalProvince"
                  className="nice-select"
                >
                  <option>Alberta</option>
                  <option>British Colombia </option>
                  <option>Manitoba </option>
                  <option>New Brunswick </option>
                  <option>Newfoundland and Labrador </option>
                  <option>Northwest Territories</option>
                  <option>Nova Scotia </option>
                  <option>Nunavut </option>
                  <option>Ontario </option>
                  <option>Prince Edward Island </option>
                  <option>Quebec </option>
                  <option>Saskatchewan </option>
                  <option>Yukon</option>
                </select>
              </div>
            </div>
            <h1>Service Details</h1>
            <div className="col-md-4">
              <label>Service Date:</label>
              <input
                className="text"
                type="date"
                name="editVehicalDate"
                value={editVehical.date}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>Service Description:</label>
              <div className="input-item">
                <select
                  value={editVehical.desc}
                  name="editVehicalDesc"
                  onChange={handleInputChange}
                  className="nice-select"
                >
                  <option>Ceramic Paint Protection</option>
                  <option>Ceramic Interior Protection </option>
                </select>
              </div>
            </div>
          </div>
          <button
            // onClick={navigate(-1)}
            className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
            style={{
              padding: "15px 30px",
              backgroundColor: "rgb(255, 90, 60)",
              color: "#FFF",
              marginBottom: "30px",
              fontSize: "16px",
            }}
          >
            Submit
          </button>
        </form>
      </div> */}
    </div>
    // </div>
  );
};

export default CeramxCarfax;
