import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Modal } from "react-bootstrap";
import {
  capitalizeFirstLetter,
  convertDateByTimezone,
  getMonthithYearOptions,
  capitalizeAllFirstLetters
} from "../../../../utils/utils";
import CustomButton from "../../../common/Button/CustomButton";
import { SERVICE_CATEGORIES, USER_ROLE } from "../../../../utils/constant";
import useDealer from "../../../../Hooks/useDealer";
import RegistrantionReportTable from "../Tables/RegistrationReportTable";
import { apiGetRequest } from "../../../../helpers/Requests";
import {
  URL_ADMIN_GET_MANAGER_BY_DEALER_ID,
  URL_DEALER_GET_MANAGER_BY_ID,
} from "../../../../service/URL";
import "../report.css";
import CsvGenerator from "../../../csvGenerator/CsvGenerator";
import moment from "moment";
import AuthContext from "../../../../helpers/authContext";

const RegistrationReports = (props) => {
  const { role } = props;

  const { timezone } = useContext(AuthContext);

  var apiUrl = process.env.REACT_APP_API_URL;
  var token = JSON.parse(secureLocalStorage.getItem("Token"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isFetchLoader, setIsFetchLoader] = useState(false);

  const options = getMonthithYearOptions();

  const downloadRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
  });

  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);

  const { isDealerListLoader, dealersData = [] } = useDealer();

  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedManagerOption, setSelectedManagerOption] = useState(null);

  const [isFetchManagerByDealerLoader, setIsFetchManagerByDealerLoader] =
    useState(false);

  const userId = JSON.parse(secureLocalStorage.getItem("UserID"));
  useEffect(() => {
    if (role === USER_ROLE.DEALER) {
      handleDealerChange(userId);
    }
  }, []);

  useEffect(() => {
    if (
      selectedDealerOption &&
      selectedDealerOption !== "all" &&
      role === USER_ROLE.ADMIN
    ) {
      handleDealerChange();
    } else if (selectedDealerOption && selectedDealerOption === "all") {
      setSelectedManagerOption(null);
      setManagerOptions([]);
    }
  }, [selectedDealerOption]);

  const handleDealerChange = async (dealerId = null) => {
    try {
      const id = dealerId !== null ? dealerId : selectedDealerOption;
      setIsFetchManagerByDealerLoader(true);
      const response = await apiGetRequest(
        `${
          role === USER_ROLE.ADMIN
            ? `${URL_ADMIN_GET_MANAGER_BY_DEALER_ID}/${id}`
            : URL_DEALER_GET_MANAGER_BY_ID
        }`
      );
      if (response) {
        setManagerOptions(
          response.data?.map((x) => ({
            label:
              x?.firstName && x.firstName?.length > 0
                ? `${x.firstName} ${x.lastName}`
                : x?.name || "",
            value: x._id,
          }))
        );
      }
      setIsFetchManagerByDealerLoader(false);
    } catch (err) {
      setIsFetchManagerByDealerLoader(false);
      setManagerOptions([]);
      console.log("err");
    }
  };

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });

  const Validation = (e) => {
    let errObj = {
      startDate: false,
      endDate: false,
    };
    if (startDate === "" || startDate === undefined) {
      setError((prev) => ({
        ...prev,
        startDate: true,
      }));
      errObj.startDate = true;
    } else if (endDate === "" || endDate === undefined) {
      setError((prev) => ({
        ...prev,
        endDate: true,
      }));
      errObj.endDate = true;
    }
    return errObj;
  };
  const [filteredRemittanceReport, setFilteredRemittanceReport] = useState([]);

  const clear = () => {
    setEndDate("");
    setStartDate("");
    setSelectedDealerOption("");
  };

  const Getreport = (isFirstCall = false) => {
    if (isFirstCall === false) {
      const valid = Validation();
      if (valid.startDate || valid.endDate) {
        return;
      }
    }

    const managerId =
      role !== USER_ROLE.DEALER &&
      selectedManagerOption !== null &&
      selectedManagerOption !== "all"
        ? selectedManagerOption
        : role === USER_ROLE.DEALER &&
          selectedManagerOption !== null &&
          selectedManagerOption !== "all"
        ? selectedManagerOption
        : null;

    const getReports = `${apiUrl}/dealer/reports`;
    setIsFetchLoader(true);
    axios
      .post(
        getReports,
        {
          dealer_id:
            selectedDealerOption !== null && selectedDealerOption !== "all"
              ? selectedDealerOption
              : null,
          isDetail: true,
          manager_id: managerId,
          start_date: startDate,
          end_date: endDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        setIsFetchLoader(false);
        // console.log("report data: ", data);

        const filteredReport = data.map((x) => ({
          ...x,
          details: JSON.parse(x?.details || "[]"),
        }));

        // console.log("date filter :", filteredReport);
        setFilteredRemittanceReport(filteredReport);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsFetchLoader(false);
      });
  };

  const [show, setShow] = useState(null);

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        label:
          // x?.firstName && x.firstName?.length > 0
          //   ? `${x.firstName} ${x.lastName}`
          //   : x?.name || "",
          x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  const getProgramNamesForAllCategories = (item) => {
    const detailsMap = new Map(
      item.details.map((detail) => [
        detail.categoryId,
        detail.programs
          .map((program) => program.programName?.replace('"', "'"))
          .join(", "),
      ])
    );
    const programNames = SERVICE_CATEGORIES.map((category) => {
      return detailsMap.get(category.value) || "-";
    });

    return programNames;
  };


 
  const dataForCsv = useMemo(() => {
    const dynamicHeadingRows = [
      [
        `Start Date: ${startDate}`,
        `End Date: ${endDate}`,
        `Dealer: ${
          selectedDealerOption !== null && selectedDealerOption !== "all"
            ? dealerOptions.find((item) => item.value === selectedDealerOption)
                ?.label
            : "All"
        }`,
        `Manager: ${
          selectedManagerOption !== null && selectedManagerOption !== "all"
            ? managerOptions.find(
                (item) => item.value === selectedManagerOption
              )?.label
            : "All"
        }`,
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      // [
      //   `Dealer: ${
      //     selectedDealerOption !== null && selectedDealerOption !== "all"
      //       ? dealerOptions.find((item) => item.value === selectedDealerOption)
      //           ?.label
      //       : "All"
      //   }`,
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      // ],
      // [
      //   `Manager: ${
      //     selectedManagerOption !== null && selectedManagerOption !== "all"
      //       ? managerOptions.find(
      //           (item) => item.value === selectedManagerOption
      //         )?.label
      //       : "All"
      //   }`,
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      // ],
    ];

    const columnTitles = [
      [
        "Agreement No.",
        "Customer First Name",
        "Customer Last Name",
        // "Customer Email",
        // "Customer City",
        // "Customer Postal",
        // "Customer State",
        // "Customer Address",
        // "Customer CoApplicant",
        // "Customer Company",
        // "Customer Comment",
        // "Customer Lienholder",
        // "Customer Phone",
        // "Customer Unit Number",
        // "GST",
        // "PST",
        //Vehicle
        "Vehicle VIN",
        // "Vehicle Category",
        // "Vehicle Color",
        // "Vehicle Damage",
        "Vehicle Make",
        "Vehicle Model",
        "Vehicle Year",
        // "Vehicle Modification",
        // "Vehicle Odometer Reading",
        // "Vehicle Product Type",
        "Vehicle Purchase Date",
        // "Vehicle Stock",
        // "Vehicle Trim",
        // "Vehicle Type",
        // "Vehicle Comment",
        //Dealer
        // "Dealership Name",
        // "Dealer First Name",
        // "Dealer Last Name",
        // "Dealer Email",
        // "Dealer Phone",
        // "Dealer City",
        // "Dealer State",
        // "Dealer Postal Code",
        // "Dealer Address",
        // "Dealer Timezone",
        //
        "Manager Name",
        // "Manager Last Name",
        // "Manager Email",
        // "Manager Phone",
        // "Manager City",
        // "Manager State",
        // "Manager Postal Code",
        // "Manager Address",
        //
        // ...SERVICE_CATEGORIES.map((i) => i.label),
        "Payment",
        "Created At",
      ],
    ];
    const dataRows = filteredRemittanceReport.map((item) => {
      const programNamesForAllCategories =
        getProgramNamesForAllCategories(item);
      return [
        item.agreementNumber || "-",
        item?.customer?.firstName || "-",
        item?.customer?.lastName || "-",
        // item?.customer?.email || "-",
        // item?.customer?.city || "-",
        // item?.customer?.postalCode || "-",
        // item?.customer?.state || "-",
        // item?.customer?.address || "-",
        // item?.customer?.coApplicant || "-",
        // item?.customer?.company || "-",
        // item?.customer?.customerComment || "-",
        // item?.customer?.lienholder || "-",
        // item?.customer?.phone || "-",
        // item?.customer?.unitNumber || "-",
        // item?.customer?.gst,
        // item?.customer?.pst,
        //
        item?.customer?.vehical?.vin || "-",
        // item?.customer?.vehical?.category || "-",
        // item?.customer?.vehical?.color || "-",
        // item?.customer?.vehical?.damage || "-",
        item?.customer?.vehical?.make || "-",
        item?.customer?.vehical?.modal || "-",
        item?.customer?.vehical?.year
          ? moment.utc(item?.customer?.vehical?.year).format("YYYY")
          : "-",
        // item?.customer?.vehical?.modification || "-",
        // item?.customer?.vehical?.odometerReading || "-",
        // item?.customer?.vehical?.productType || "-",
        item?.customer?.vehical?.purchaseDate || "-",
        // item?.customer?.vehical?.stock || "-",
        // item?.customer?.vehical?.trim || "-",
        // item?.customer?.vehical?.type || "-",
        // item?.customer?.vehical?.vehicalComment || "-",
        //
        // item?.dealer?.name || "-",
        // item?.dealer?.firstName || "-",
        // item?.dealer?.lastName || "-",
        // item?.dealer?.email || "-",
        // item?.dealer?.phone || "-",
        // item?.dealer?.city || "-",
        // item?.dealer?.state || "-",
        // item?.dealer?.postalCode || "-",
        // item?.dealer?.address || "-",
        // item?.dealer?.timezone || "-",
        //
        `${capitalizeAllFirstLetters(`${item?.manager?.firstName || ""} ${item?.manager?.lastName || ""}`)}`,
        // item?.manager?.lastName || "-",
        // item?.manager?.email || "-",
        // item?.manager?.phone || "-",
        // item?.manager?.city || "-",
        // item?.manager?.state || "-",
        // item?.manager?.postalCode || "-",
        // item?.manager?.address || "-",
        //
        // ...programNamesForAllCategories,
        //
        item?.isPaid === true ? "Paid" : "Unpaid",
        item?.createdAt
          ? moment(convertDateByTimezone(item.createdAt, timezone)).format(
              "DD/MM/YYYY"
            )
          : "-",
      ];
    });

    return [...dynamicHeadingRows, ...columnTitles, ...dataRows];
  }, [
    filteredRemittanceReport,
    startDate,
    endDate,
    selectedDealerOption,
    selectedManagerOption,
  ]);

  return (
    <>
      <div className="checkoverflow " style={{ paddingRight: "20px" }}>
        <div className="row mb-50 mt-3">
          <div className="d-flex justify-content-between align-items-center py-2">
            <div>
              <h3 className="pb-3">Registration Report</h3>
            </div>
            {(role === USER_ROLE.ADMIN ||
              role === USER_ROLE.SUPER_ADMIN ||
              role === USER_ROLE.DEALER) && (
              <div>
                <CsvGenerator data={dataForCsv} />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-4">
              <label className="pb-2">
                Start Month
                {/* <span style={{ fontSize: "9px", marginLeft: "8px" }}>
                  (from the first day of this month)
                </span> */}
              </label>
              {error.startDate ? (
                <p className="error-messagee mb-2"> Select Start Date</p>
              ) : (
                <></>
              )}
              <div className="input-item">
                <select
                  className="nice-select"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      startDate: e.target.value === "",
                    }));
                  }}
                >
                  <option value="" selected disabled>
                    Select Start Month
                  </option>
                  {options.map((option) => (
                    <option key={option} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <label className="pb-2">
                End Month
                {/* <span
                  className=""
                  style={{ fontSize: "9px", marginLeft: "8px" }}
                >
                  (to the last day of this month)
                </span> */}
              </label>
              {error.endDate && (
                <p className="error-messagee mb-2"> Select end Date</p>
              )}
              <div className="input-item">
                <select
                  className="nice-select"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      endDate: e.target.value === "",
                    }));
                  }}
                >
                  <option value="" selected disabled>
                    Select End Month
                  </option>
                  {options.map((option) => (
                    <option key={option} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {role === USER_ROLE.ADMIN && (
              <div className="col-md-4">
                <label className="pb-2">Dealer</label>
                {/* {error.startDate ? (
                <p className="error-messagee mb-2"> Select Start Date</p>
              ) : (
                <></>
              )} */}
                <div className="input-item">
                  <select
                    className="nice-select"
                    value={selectedDealerOption}
                    onChange={(e) => {
                      setSelectedDealerOption(e.target.value);
                    }}
                  >
                    <option value="all">All Dealer</option>
                    {dealerOptions.map((item, index) => (
                      <option key={index} value={item.value}>
                        {capitalizeFirstLetter(item.label)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {(role === USER_ROLE.ADMIN || role === USER_ROLE.DEALER) && (
              <div className="col-md-4">
                <label className="pb-2">Manager</label>
                <div className="input-item">
                  <select
                    className="nice-select"
                    value={selectedManagerOption}
                    onChange={(e) => {
                      setSelectedManagerOption(e.target.value);
                    }}
                  >
                    <option value="all">All Manager</option>
                    {managerOptions.map((item, index) => (
                      <option key={index} value={item.value}>
                        {capitalizeFirstLetter(item.label)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {/* <div className="col-md-3">
              <label className="pb-2">status</label>
              {error.startDate ? (
                <p className="error-messagee mb-2"> Select Start Date</p>
              ) : (
                <></>
              )}
              <div className="input-item">
                <select
                  className="nice-select"
                  value={startDate}

                  // onChange={(e) => {
                  //   setStartDate(e.target.value);
                  //   setError((prev) => ({ ...prev, startDate: e.target.value === "" }))
                  // }}
                >
                  <option value="" selected disabled>
                    Select Status
                  </option>
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="expired">Expired</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className="btn-wrapper px-2 d-flex justify-content-between">
            <CustomButton
              showLoading={isFetchLoader}
              isDisabled={
                isDealerListLoader ||
                isFetchLoader ||
                isFetchManagerByDealerLoader
              }
              type="submit"
              cssClass="btn-effect-1 text-uppercase"
              clickHandler={() => Getreport(false)}
              label="Get Report"
            />

            <div className="">
              <button
                type="button"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={clear}
              >
                Clear
              </button>

              <button
                type="button"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={handlePrint}
              >
                Print
              </button>
            </div>
          </div>
          <section className="print-landscape px-4 py-4" ref={downloadRef}>
            <p className="text-center">
              ExtendedTrust Inc. | Content Management System
            </p>
            <p className="date-range mt-3">
              {new Date(Date.now()).toLocaleString()}
            </p>

            <h1 className="report-title mt-2 mb-3">Registration Report</h1>
            <p className="date-range mt-1">
              <span style={{ fontWeight: "600", color: "black" }}>From:</span>
              {` ${startDate} To ${endDate}`}
            </p>
            <p className="dealer-info mb-2">
              <span style={{ fontWeight: "600", color: "black" }}>Dealer:</span>
              {` ${filteredRemittanceReport[0]?.dealer?.firstName || ""} ${
                filteredRemittanceReport[0]?.dealer?.lastName || ""
              }`}
            </p>
            <p className="mt-5 mb-5">
              Complete report as of {new Date(Date.now()).toLocaleString()}
            </p>

            <table class="table overflow-set mobileTable">
              <thead>
                {/* <tr style={{ backgroundColor: "#3cb7ff" }}> */}
                <tr style={{ backgroundColor: "#4A6543" }}>
                  <th scope="col">Agreement No.</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Sp Effective Date</th>
                  <th scope="col">Dealer</th>
                  <th scope="col">Manager</th>
                  <th scope="col">VIN</th>
                  <th scope="col">Make</th>
                  <th scope="col">Model</th>
                  <th scope="col">Year</th>
                  {/* <th scope="col">Offers</th> */}
                  {/* <th scope="col">Services</th> */}
                  {/* <th scope="col">Status</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredRemittanceReport &&
                filteredRemittanceReport.length > 0 ? (
                  filteredRemittanceReport.map((item) => (
                    <tr>
                      <th scope="row">{item.agreementNumber || "-"}</th>
                      <td>{item?.customer?.firstName}</td>
                      <td>{item?.customer?.lastName}</td>
                      <td>
                        {item?.createdAt
                          ? moment(
                              convertDateByTimezone(item.createdAt, timezone)
                            ).format("DD/MM/YY")
                          : null}
                      </td>
                      <td>
                        {item?.dealer?.firstName} {item?.dealer?.lastName}
                      </td>

                      <td>
                        {item?.manager?.firstName} {item?.manager?.lastName}
                      </td>
                      <td>{item?.customer?.vehical?.vin}</td>
                      <td>{item?.customer?.vehical?.make}</td>
                      <td>{item?.customer?.vehical?.modal}</td>
                      <td>
                        {moment.utc(item.customer.vehical?.year).format("YYYY")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </section>
          {/* <div className="tab-pane fade" id="registrationreport"> */}
          <RegistrantionReportTable data={filteredRemittanceReport} />
        </div>
      </div>
      {show !== null && (
        <Modal
          show={show !== null ? true : false}
          onHide={() => setShow(null)}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Registration Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <div className="row">
              <div className="col-md-6">
                <p className="p-tag-padding">
                  <span className="reportSpan">{`Registrant No.: ${show.agreementNumber}`}</span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    First Name: {show?.customer?.firstName}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Last Name: {show?.customer?.lastName}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Sp Effective Date:
                    {show?.createdAt
                      ? moment(
                          convertDateByTimezone(show.createdAt, timezone)
                        ).format("DD/MM/YY")
                      : null}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Dealer: {show?.dealer?.firstName} {show?.dealer?.lastName}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Manager: {show?.manager?.firstName}{" "}
                    {show?.manager?.lastName}
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    VIN: {show?.customer?.vehical?.vin}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Make: {show?.customer?.vehical?.make}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Model: {show?.customer?.vehical?.modal}
                  </span>
                </p>
                <p className="p-tag-padding">
                  <span className="reportSpan">
                    Year:
                    {moment.utc(show?.customer?.vehical?.year).format("YYYY")}
                  </span>
                </p>
              </div>
            </div>
            <div className="reportTable pt-4" style={{ width: "100%" }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Programs</th>
                    <th>Cost</th>
                    <th>Retail</th>
                  </tr>
                </thead>
                <tbody>
                  {show?.details?.map((detail) => (
                    <React.Fragment key={detail.categoryId}>
                      {detail.programs.map((program, index) => (
                        <tr key={program.programId}>
                          {index === 0 && (
                            <td rowSpan={detail.programs.length}>
                              {detail.categoryName}
                            </td>
                          )}
                          <td>{program.programName}</td>
                          <td>${program.priceDetails.price}</td>
                          {index === 0 && (
                            <td rowSpan={detail.programs.length}>
                              {detail.retailerPrice
                                ? `$${detail.retailerPrice}`
                                : "N/A"}
                            </td>
                          )}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default RegistrationReports;
