import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useReactToPrint } from "react-to-print";
import {
  capitalizeFirstLetter,
  formatDateToDDMMMYYYY,
  getMonthithYearOptions,
} from "../../../../utils/utils";
import CustomButton from "../../../common/Button/CustomButton";
import {
  EXTENDED_GST,
  SERVICE_CATEGORIES,
  POWERSPORTS_SERVICE_CATEGORIES,
  USER_ROLE,
} from "../../../../utils/constant";
import useDealer from "../../../../Hooks/useDealer";
import SummaryCards from "./ReportSummaryCard";
import parseError from "../../../../utils/ErrorParse";
import { toast } from "react-toastify";
import {
  URL_ADMIN_GET_MANAGER_BY_DEALER_ID,
  URL_ADMIN_REPORT_PAID_STATUS_CHANGE,
  URL_DEALER_GET_MANAGER_BY_ID,
  URL_SUPER_ADMIN_REVERT_REMITTANCE_PAYMENT,
} from "../../../../service/URL";
import {
  apiGetRequest,
  apiPatchRequest,
  apiPostRequest,
} from "../../../../helpers/Requests";
import _, { isArray, isEmpty } from "lodash";
import ConfirmationModal from "../../../common/Modal/ConfirmationModal";
import Loader from "../../../common/Loader/Loader";
import axios from "axios";
import AddRegistrants from "../../registrants/AddRegistrants";
import ScrollToTop from "../../../../Hooks/scrollToTop";

const RemittanceReport = ({ role, setActiveTab, managerObj }) => {
  ScrollToTop();
  const options = getMonthithYearOptions();

  const [showAccordion, setShowAccordion] = useState(false);
  var apiUrl = process.env.REACT_APP_API_URL;
  var token = JSON.parse(secureLocalStorage.getItem("Token"));

  const [isFetchLoader, setIsFetchLoader] = useState(false);
  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);
  const { isDealerListLoader, dealersData = [] } = useDealer();

  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedManagerOption, setSelectedManagerOption] = useState(null);

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        label:
          // x?.firstName && x.firstName?.length > 0
          //   ? `${x.firstName} ${x.lastName}`
          //   : x?.name || "",
          x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  const userId = JSON.parse(secureLocalStorage.getItem("UserID"));
  useEffect(() => {
    if (role === USER_ROLE.DEALER) {
      handleDealerChange(userId);
    }
  }, []);

  useEffect(() => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      if (selectedDealerOption && selectedDealerOption !== "all") {
        handleDealerChange();
      }
    } else if (selectedDealerOption && selectedDealerOption === "all") {
      setSelectedManagerOption(null);
      setManagerOptions([]);
    }
  }, [selectedDealerOption]);

  const [isFetchManagerByDealerLoader, setIsFetchManagerByDealerLoader] =
    useState(false);

  const handleDealerChange = async (dealerId = null) => {
    try {
      const id = dealerId !== null ? dealerId : selectedDealerOption;
      setIsFetchManagerByDealerLoader(true);
      const response = await apiGetRequest(
        `${
          role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN
            ? `${URL_ADMIN_GET_MANAGER_BY_DEALER_ID}/${id}`
            : URL_DEALER_GET_MANAGER_BY_ID
        }`
      );
      // console.log("response", response);
      if (response) {
        setManagerOptions(
          response.data?.map((x) => ({
            label:
              x?.firstName && x.firstName?.length > 0
                ? `${x.firstName} ${x.lastName}`
                : x?.name || "",
            value: x._id,
          }))
        );
      }
      setIsFetchManagerByDealerLoader(false);
    } catch (err) {
      setIsFetchManagerByDealerLoader(false);
      setManagerOptions([]);
      console.log("err");
    }
  };

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("allvehicles");
  const [selectedAllServices, setSelectedAllServices] = useState("");

  const handleCheckboxChange = (serviceName) => {
    setCheckedItems((prevSelectedServices) => {
      if (prevSelectedServices.includes(serviceName)) {
        return prevSelectedServices.filter(
          (service) => service !== serviceName
        );
      } else {
        return [...prevSelectedServices, serviceName];
      }
    });
  };

  const handleSelectAllChange = () => {
    if (checkedItems.length > 0) {
      setCheckedItems([]);
      setSelectedAllServices("");
    } else {
      if(selectedVehicle === "allvehicles"){
        setCheckedItems([...SERVICE_CATEGORIES.map((x) => x.value)]);
        setSelectedAllServices("allvehicles");
      } else {
        setCheckedItems([...SERVICE_CATEGORIES.filter(item => item.category === selectedVehicle).map((x) => x.value)]);
        setSelectedAllServices(selectedVehicle);
      }
    }
  };

  const handleSelectVehicle = (e) => {
    setSelectedVehicle(e.target.value);
    setSelectedAllServices("");
    setCheckedItems([]);
  }

  const [filteredRemittanceReport, setFilteredRemittanceReport] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });

  const Validation = (e) => {
    let errObj = {
      startDate: false,
      endDate: false,
    };
    if (startDate === "" || startDate === undefined) {
      setError((prev) => ({
        ...prev,
        startDate: true,
      }));
      errObj.startDate = true;
    } else if (endDate === "" || endDate === undefined) {
      setError((prev) => ({
        ...prev,
        endDate: true,
      }));
      errObj.endDate = true;
    }
    return errObj;
  };

  const handleFormSubmit = (event) => {
    const valid = Validation();
    if (valid.startDate || valid.endDate) {
    } else {
      event.preventDefault();

      let apiEndpoint = `${apiUrl}/dealer/filtered-reports`;
      if (
        (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) &&
        selectedDealerOption !== null &&
        selectedDealerOption !== "all"
      ) {
        apiEndpoint = `${apiEndpoint}?dealer_id=${selectedDealerOption}`;
      }

      if (selectedManagerOption !== null && selectedManagerOption !== "all") {
        apiEndpoint = `${apiEndpoint}${
          apiEndpoint.includes("?") ? "&" : "?"
        }manager_id=${selectedManagerOption}`;
      }

      if (startDate && startDate !== null) {
        apiEndpoint = `${apiEndpoint}${
          apiEndpoint.includes("?") ? "&" : "?"
        }start_date=${startDate}`;
      }
      if (endDate && endDate !== null) {
        apiEndpoint = `${apiEndpoint}${
          apiEndpoint.includes("?") ? "&" : "?"
        }end_date=${endDate}`;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      setIsFetchLoader(true);

      fetch(apiEndpoint, requestOptions)
        .then((response) => {
          setIsFetchLoader(false);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setIsFetchLoader(false);
          setFilteredRemittanceReport(data);
          setShowAccordion(true);
          // console.log("Remmitance Report :", data);
          const filteredData =
            checkedItems.length > 0
              ? data.filter((item) => {
                  const serviceKeys = Object.keys(item.services);
                  return serviceKeys.some((serviceKey) => {
                    if (serviceKey.includes("-")) {
                      const categoryId = serviceKey.split("-")[0];
                      return checkedItems.includes(categoryId);
                    }
                    return true;
                  });
                })
              : data;
          return filteredData.map((x) => ({
            ...x,
            details: isEmpty(x?.details) ? [] : JSON.parse(x?.details || "[]"),
          }));
        })
        .then((data) => {
          setIsFetchLoader(false);
          setFilteredRemittanceReport([...data]);
        })
        .catch((error) => {
          console.log("Error:", error);
          setIsFetchLoader(false);
        });
    }
  };

  const downloadRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => downloadRef?.current,
  });

  const calculateCategoryTotal = (programs) =>
    programs.reduce((total, { priceDetails }) => total + priceDetails.price, 0);

  const calculateCategoryRetailTotal = (retailerPrice) =>
    retailerPrice ? parseFloat(retailerPrice) : 0;

  const [isPaidReportLoader, setIsPaidReportLoader] = useState(null);

  const [
    isConfirmRevertRemittancePayment,
    setIsConfirmRevertRemittancePayment,
  ] = useState(null);
  const [isRevertRemittancePaymentLoader, setIsRevertRemittancePaymentLoader] =
    useState(null);

  const handlePaymentChange = async (reportId) => {
    try {
      setIsPaidReportLoader(reportId);
      const response = await apiPostRequest(
        `${URL_ADMIN_REPORT_PAID_STATUS_CHANGE}/${reportId}`
      );
      if (response) {
        toast.success("Report Paid Successfully");
        const prevData = _.cloneDeep(filteredRemittanceReport);
        const index = prevData.findIndex((x) => x._id === reportId);
        if (index !== -1) {
          prevData.splice(index, 1, {
            ...prevData[index],
            isPaid: true,
          });
          setFilteredRemittanceReport([...prevData]);
        }
      }
      setIsPaidReportLoader(null);
    } catch (err) {
      setIsPaidReportLoader(null);
      const errorMessage = parseError(err);
      toast.error(errorMessage ?? "Something went wrong!");
      console.log("err", err);
    }
  };

  const handleRevertRemittancePayment = async (reportId) => {
    try {
      if (!reportId) return;
      setIsRevertRemittancePaymentLoader(reportId);
      const response = await apiPatchRequest(
        URL_SUPER_ADMIN_REVERT_REMITTANCE_PAYMENT,
        { reportId }
      );
      if (response?.data) {
        const prevData = _.cloneDeep(filteredRemittanceReport);
        const index = prevData.findIndex((x) => x._id === reportId);
        if (index !== -1) {
          prevData.splice(index, 1, {
            ...prevData[index],
            isPaid: false,
          });
          setFilteredRemittanceReport([...prevData]);
        }
        toast.success(`Remittance Payment Reverted Successfully!`);
      }
      setIsRevertRemittancePaymentLoader(null);
    } catch (err) {
      console.log("err", err);
      setIsRevertRemittancePaymentLoader(null);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  const grandTotalTr = (registration) => {
    const gstRate = registration?.customer?.gst; // Default GST rate is 5%
    const pstRate = registration?.customer?.pst || 0; // Default PST rate is 0%

    const subtotalCost = registration?.details.reduce(
      (total, detail) =>
        total + Number(calculateCategoryTotal(detail.programs)),
      0
    );

    const subtotalRetail = registration?.details.reduce(
      (total, detail) =>
        total +
        (detail.retailerPrice
          ? Number(calculateCategoryRetailTotal(detail.retailerPrice))
          : Number(calculateCategoryTotal(detail.programs))),
      0
    );

    const gstCost = subtotalCost * (EXTENDED_GST / 100);
    const gstRetail = subtotalRetail * (gstRate / 100);

    const pstRetail = subtotalRetail * (pstRate / 100);

    const grandTotalCost = subtotalCost + gstCost;
    const grandTotalRetail = subtotalRetail + gstRetail + pstRetail;

    return (
      <>
        <tr className="table-info">
          <td colSpan="2">Subtotal</td>
          <td>${subtotalCost?.toFixed(2)}</td>
          <td>${subtotalRetail?.toFixed(2)}</td>
          <td></td>
        </tr>
        <tr className="table-info">
          <td colSpan="2">GST ({gstRate}%)</td>
          <td>${gstCost?.toFixed(2)}</td>
          <td>${gstRetail?.toFixed(2)}</td>
          <td></td>
        </tr>
        <tr className="table-info">
          <td colSpan="2">PST ({pstRate}%)</td>
          <td>-</td>
          <td>${pstRetail?.toFixed(2)}</td>
          <td></td>
        </tr>
        <tr className="table-primary">
          <td colSpan="2">Grand Total</td>
          <td>${grandTotalCost?.toFixed(2)}</td>
          <td>${grandTotalRetail?.toFixed(2)}</td>
          <td>
            ${((grandTotalRetail || 0) - (grandTotalCost || 0))?.toFixed(2)}
          </td>
        </tr>
      </>
    );
  };

  const [reportData, setReportData] = useState(null);
  const [isGetReportLoader, setIsGetReportLoader] = useState(null);
  const [showEditRegistrants, setShowEditRegistrants] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter > 0) {
      handleFormSubmit();
    }
  }, [counter]);

  const fetchReportByCustomerIdData = async (
    customerId,
    reportId,
    dealerId,
    managerId
  ) => {
    const roleApi =
      role === "Technician" ||
      role === "Sales Advisor" ||
      role === "Service Advisor"
        ? "staff"
        : role;

    let url = `${apiUrl}/${roleApi?.toLowerCase()}/customerReport/${customerId}`;

    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      url = `${url}/${dealerId}/${managerId}`;
    }

    const getCustomerReportUrl = url;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      setIsGetReportLoader(reportId);
      const response = await axios.get(getCustomerReportUrl, { headers });
      // console.log("Response Customer Report: ", response);
      const data = response.data;
      setIsGetReportLoader(null);
      setReportData(data);
      setShowEditRegistrants(true);
    } catch (error) {
      setIsGetReportLoader(null);
      setReportData(null);
      const errorMessage = parseError(error);
      toast.error(errorMessage ?? "Something went wrong!");
      console.log("error while getting customer Report:  ", error);
    }
  };

  if (reportData !== null && showEditRegistrants === true) {
    return (
      <AddRegistrants
        managerObj={managerObj}
        setCounter={setCounter}
        setShowEditRegistrants={() => {
          setShowEditRegistrants(null);
          setReportData(null);
        }}
        setEditReport={setReportData}
        editReport={reportData}
        setActiveTab={setActiveTab}
      />
    );
  }

  // console.log("filteredRemittanceReport", filteredRemittanceReport);

  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner px-2 py-4">
        <div className="ltn__form-box">
          <form action="submit">
            <div className="row mb-50">
              <h3 className="pb-3">Remittance Report</h3>
              <div className="col-md-4">
                <label className="pb-2">Start Month</label>
                {error.startDate && (
                  <p className="error-message mb-2"> Select Start Date</p>
                )}
                <div className="input-item">
                  <select
                    className="nice-select"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setError((prev) => ({
                        ...prev,
                        startDate: e.target.value === "",
                      }));
                    }}
                  >
                    <option value="" selected disabled>
                      Select Start Month
                    </option>
                    {options.map((option) => (
                      <option key={option} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <label className="pb-2">End Month</label>
                {error.endDate && (
                  <p className="error-message mb-2"> Select end Date</p>
                )}
                <div className="input-item">
                  <select
                    className="nice-select"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setError((prev) => ({
                        ...prev,
                        endDate: e.target.value === "",
                      }));
                    }}
                  >
                    <option value="" selected disabled>
                      Select End Month
                    </option>
                    {options.map((option) => (
                      <option key={option} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <>
                  <div className="col-md-4">
                    <label className="pb-2">Dealer</label>
                    <div className="input-item">
                      <select
                        className="nice-select"
                        value={selectedDealerOption}
                        onChange={(e) => {
                          setSelectedDealerOption(e.target.value);
                        }}
                      >
                        <option value="all">All Dealer</option>
                        {dealerOptions.map((item, index) => (
                          <option key={index} value={item.value}>
                            {capitalizeFirstLetter(item.label)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
              {(role === USER_ROLE.DEALER ||
                role === USER_ROLE.ADMIN ||
                role === USER_ROLE.SUPER_ADMIN) && (
                <div className="col-md-4">
                  <label className="pb-2">Manager</label>
                  <div className="input-item">
                    <select
                      className="nice-select"
                      value={selectedManagerOption}
                      onChange={(e) => {
                        setSelectedManagerOption(e.target.value);
                      }}
                    >
                      <option value="all">All Manager</option>
                      {managerOptions.map((item, index) => (
                        <option key={index} value={item.value}>
                          {capitalizeFirstLetter(item.label)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <h3 className="pb-4">Filter by Specific Service(s)</h3>
              <div className="d-flex gap-3 mb-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="allvehicles"
                    value="allvehicles"
                    checked={selectedVehicle === "allvehicles"}
                    onChange={handleSelectVehicle}
                  />
                  <label className="form-check-label" htmlFor="selectAll">
                    All Vehicle
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="automotive"
                    value="automotive"
                    checked={selectedVehicle === "automotive"}
                    onChange={handleSelectVehicle}
                  />
                  <label>Automotive</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="powersports"
                    value="powersports"
                    checked={selectedVehicle === "powersports"}
                    onChange={handleSelectVehicle}
                  />
                  <label>Powersports</label>
                </div>
              </div>
              <div>
                <div className="mb-2">
                  <h4>Services:</h4>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="selectAll"
                    checked={selectedAllServices !== ""}
                    onChange={handleSelectAllChange}
                  />
                  <label className="form-check-label" htmlFor="selectAll">
                    All services
                  </label>
                </div>
                { selectedVehicle === "allvehicles"
                  ? SERVICE_CATEGORIES.map((item, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`checkbox-${index}`}
                        value={item.value}
                        checked={checkedItems.includes(item.value)}
                        onChange={() => handleCheckboxChange(item.value)}
                      />
                      <label
                        className="form-check-label "
                        style={{ textTransform: "capitalize" }}
                        htmlFor={`checkbox-${index}`}
                      >
                        {item.label}
                      </label>
                    </div>))
                  : SERVICE_CATEGORIES.filter((item) => item.category === selectedVehicle)
                    .map((item, index) => (
                      <div key={index} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`checkbox-${index}`}
                          value={item.value}
                          checked={checkedItems.includes(item.value)}
                          onChange={() => handleCheckboxChange(item.value)}
                        />
                        <label
                          className="form-check-label"
                          style={{ textTransform: "capitalize" }}
                          htmlFor={`checkbox-${index}`}
                        >
                          {item.label}
                        </label>
                      </div>
                    ))
                }
              </div>
            </div>
            <div className="btn-wrapper px-2 mt-3" style={{ display: "flex" }}>
              <CustomButton
                showLoading={isFetchLoader}
                isDisabled={
                  isDealerListLoader ||
                  isFetchLoader ||
                  isFetchManagerByDealerLoader
                }
                label="Get Report"
                type="button"
                clickHandler={handleFormSubmit}
                cssClass="btn-effect-1 text-uppercase"
              />
              {/* <button
                type="button"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={exportToPDF}
              >
                Export to PDF
              </button> */}
              {showAccordion && (
                <button
                  type="button"
                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}

              <section className="printpdf-sec py-4 px-4" ref={downloadRef}>
                <p className="text-center">
                  ExtendedTrust Inc. | Content Management System
                </p>
                <p className="date-range mt-3">
                  {new Date(Date.now()).toLocaleString()}
                </p>

                <h1 className="report-title mt-2 mb-3">Remittance Report</h1>
                <p className="date-range mt-1">
                  <span style={{ fontWeight: "600", color: "black" }}>
                    From:
                  </span>
                  {` ${startDate} To ${endDate}`}
                </p>
                <p className="dealer-info mb-2">
                  <span style={{ fontWeight: "600", color: "black" }}>
                    Dealer:
                  </span>
                  {` ${filteredRemittanceReport[0]?.dealer?.firstName || ""} ${
                    filteredRemittanceReport[0]?.dealer?.lastName || ""
                  }`}
                </p>
                <p className="mt-5 mb-5">
                  Complete report as of {new Date(Date.now()).toLocaleString()}
                </p>
                <div>
                  {filteredRemittanceReport?.map((registration) => (
                    <div key={registration._id} className="card mb-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="pt-3 d-flex justify-content-between align-items-center">
                          <h5 className="card-title mb-0 d-flex justify-content-start align-items-center">
                              <div className='d-block'>
                    <div className='d-flex'><span className="mr-4">Registration ID:</span>
                              <div className="d-flex align-items-center">
                                  <span>{registration.agreementNumber}</span>
                              </div>
                            </div>
                              <div className='mt-2'>
                                <h6 className='d-flex'>
                                  <span className="mr-4">VIN:</span>
                                    <div className="d-flex align-items-center">
                                  <span>{registration?.customer?.vehical?.vin || "-"}</span>
                                  </div>  
                                </h6>

                              </div>

                              </div>
                              
                            </h5>
                            <div className="form-check form-switch">
                              <input
                                disabled={
                                  registration?.isPaid ||
                                  isPaidReportLoader ||
                                  role !== USER_ROLE.ADMIN
                                }
                                className="form-check-input"
                                type="checkbox"
                                id={`paymentReceived-${registration._id}`}
                                checked={registration?.isPaid}
                                onChange={() => {
                                  if (role !== USER_ROLE.ADMIN) return;
                                  if (registration?.isPaid) {
                                  } else if (role === USER_ROLE.ADMIN) {
                                    handlePaymentChange(registration._id);
                                  }
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`paymentReceived-${registration._id}`}
                              >
                                Payment Received
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2"
                          style={{
                            paddingLeft: "12px",
                            fontWeight: "lighter",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h6>
                              Customer:
                              <span>
                                {capitalizeFirstLetter(
                                  ` ${registration.customer.firstName} ${registration.customer.lastName}`
                                )}
                              </span>
                            </h6>
                          </div>

                          <div>
                            <h6>
                              Date:{" "}
                              <span>
                                {formatDateToDDMMMYYYY(registration.createdAt)}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <table className="table">
                          <thead className="thead-print">
                            <tr>
                              <th>Category</th>
                              <th>Programs</th>
                              <th>Cost</th>
                              <th>Retail</th>
                              <th>Profit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {registration.hasOwnProperty("details") &&
                              isArray(registration?.details) &&
                              registration?.details?.map((detail) => (
                                <React.Fragment key={detail.categoryId}>
                                  {detail.programs.map(
                                    (program, programIndex) => (
                                      <tr key={program.programId}>
                                        {programIndex === 0 && (
                                          <td rowSpan={detail.programs.length}>
                                            {detail.categoryName}
                                          </td>
                                        )}
                                        <td>{program.programName}</td>
                                        <td>${program.priceDetails.price}</td>
                                        {programIndex === 0 && (
                                          <td
                                            rowSpan={detail.programs.length}
                                          ></td>
                                        )}
                                      </tr>
                                    )
                                  )}
                                  <tr className="table-secondary">
                                    <td colSpan="2">Total</td>
                                    <td>
                                      ${calculateCategoryTotal(detail.programs)}
                                    </td>
                                    <td>
                                      {detail.retailerPrice
                                        ? `$${calculateCategoryRetailTotal(
                                            detail.retailerPrice
                                          )}`
                                        : ""}
                                    </td>
                                    <td>
                                      {detail?.retailerPrice !== null
                                        ? `$${
                                            calculateCategoryRetailTotal(
                                              detail.retailerPrice
                                            ) -
                                            calculateCategoryTotal(
                                              detail.programs
                                            )
                                          }`
                                        : "$0"}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            {/* Summary */}
                            {registration.hasOwnProperty("details") &&
                              Array.isArray(registration.details) && (
                                <>{grandTotalTr(registration)}</>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </form>
        </div>
        {filteredRemittanceReport?.length > 0 && (
          <SummaryCards registrations={filteredRemittanceReport} />
        )}
        {showAccordion && (
          <Accordion defaultActiveKey="0" className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>PRODUCT TOTAL BREAKDOWN</Accordion.Header>
              <Accordion.Body style={{ background: "white" }}>
                <div>
                  {filteredRemittanceReport?.map((registration) => (
                    <div key={registration._id} className="card mb-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="pt-3 d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0 d-flex justify-content-start align-items-center">
                              <div className='d-block'>
                    <div className='d-flex'><span className="mr-4">Registration ID:</span>
                              <div className="d-flex align-items-center">
                                {role === USER_ROLE.SUPER_ADMIN ? (
                                  <span>{registration.agreementNumber}</span>
                                ) : (
                                  <>
                                    <span
                                      className="registration-number-clickable"
                                      onClick={() => {
                                        if (isGetReportLoader !== null) return;
                                        fetchReportByCustomerIdData(
                                          registration.customer._id,
                                          registration._id,
                                          registration.dealer._id,
                                          registration.manager._id
                                        );
                                      }}
                                    >
                                      {registration.agreementNumber}
                                    </span>
                                    {isGetReportLoader &&
                                      isGetReportLoader ===
                                        registration._id && (
                                        <Loader
                                          isSmallView={true}
                                          cssClass={"text-black"}
                                        />
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                              <div className='mt-2'>
                                <h6 className='d-flex'>
                                  <span className="mr-4">VIN:</span>
                                    <div className="d-flex align-items-center">
                                  <span>{registration?.customer?.vehical?.vin || "-"}</span>
                                  </div>  
                                </h6>

                              </div>

                              </div>
                              
                            </h5>
                            <div className="form-check form-switch">
                              {isRevertRemittancePaymentLoader &&
                              isRevertRemittancePaymentLoader ===
                                registration._id ? (
                                <div className="d-flex justify-content-start align-items-center">
                                  <Loader
                                    cssClass={"text-black"}
                                    isSmallView={true}
                                  />
                                </div>
                              ) : (
                                <>
                                  <input
                                    disabled={() => {
                                      if (role !== USER_ROLE.SUPER_ADMIN) {
                                        if (
                                          registration.isPaid ||
                                          isPaidReportLoader ||
                                          role !== USER_ROLE.ADMIN
                                        ) {
                                          return true;
                                        }
                                      } else if (
                                        role === USER_ROLE.SUPER_ADMIN
                                      ) {
                                        return registration.isPaid === false;
                                      }
                                      return true;
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`paymentReceived-${registration._id}`}
                                    checked={registration?.isPaid}
                                    onChange={() => {
                                      if (
                                        role !== USER_ROLE.ADMIN &&
                                        role !== USER_ROLE.SUPER_ADMIN
                                      ) {
                                        return;
                                      }
                                      if (registration?.isPaid) {
                                        if (role === USER_ROLE.SUPER_ADMIN) {
                                          setIsConfirmRevertRemittancePayment(
                                            registration._id
                                          );
                                        }
                                      } else if (role === USER_ROLE.ADMIN) {
                                        handlePaymentChange(registration._id);
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`paymentReceived-${registration._id}`}
                                  >
                                    Payment Received
                                  </label>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2"
                          style={{
                            paddingLeft: "12px",
                            fontWeight: "lighter",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h6>
                              Customer:
                              <span>
                                {capitalizeFirstLetter(
                                  ` ${registration.customer.firstName} ${registration.customer.lastName}`
                                )}
                              </span>
                            </h6>
                          </div>

                          <div>
                            <h6>
                              Date:{" "}
                              <span>
                                {formatDateToDDMMMYYYY(registration.createdAt)}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Programs</th>
                              <th>Cost</th>
                              <th>Retail</th>
                              <th>Profit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {registration.hasOwnProperty("details") &&
                              Array.isArray(registration.details) &&
                              registration.details.map((detail) => (
                                <React.Fragment key={detail.categoryId}>
                                  {detail.programs.map(
                                    (program, programIndex) => (
                                      <tr key={program.programId}>
                                        {programIndex === 0 && (
                                          <td rowSpan={detail.programs.length}>
                                            {detail.categoryName}
                                          </td>
                                        )}
                                        <td>{program.programName}</td>
                                        <td>${program.priceDetails.price}</td>
                                        {programIndex === 0 && (
                                          <td
                                            rowSpan={detail.programs.length}
                                          ></td>
                                        )}
                                      </tr>
                                    )
                                  )}
                                  <tr className="table-secondary">
                                    <td colSpan="2">Total</td>
                                    <td>
                                      ${calculateCategoryTotal(detail.programs)}
                                    </td>
                                    <td>
                                      $
                                      {detail.retailerPrice
                                        ? calculateCategoryRetailTotal(
                                            detail.retailerPrice
                                          )
                                        : 0}
                                    </td>
                                    <td>
                                      $
                                      {detail.retailerPrice !== null
                                        ? calculateCategoryRetailTotal(
                                            detail.retailerPrice
                                          ) -
                                          calculateCategoryTotal(
                                            detail.programs
                                          )
                                        : 0}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}

                            {/* Ensure arithmetic operations are correctly calculated */}
                            {registration.hasOwnProperty("details") &&
                              Array.isArray(registration.details) && (
                                <>{grandTotalTr(registration)}</>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
      {/* Confirmation Modal */}
      {isConfirmRevertRemittancePayment !== null && (
        <ConfirmationModal
          isOpen={isConfirmRevertRemittancePayment !== null}
          confirmationStatus={(e) => {
            if (e === true) {
              handleRevertRemittancePayment(isConfirmRevertRemittancePayment);
            }
            setIsConfirmRevertRemittancePayment(null);
          }}
          title={"Revert Remittance Payment Confirmation"}
          heading={"Are you sure to revert this Remittance Payment ?"}
        />
      )}
    </div>
  );
};

export default RemittanceReport;
