import React, { useState } from "react";
import CustomSelect from "../../common/Select/CustomSelect";
import moment from "moment";
import "./materialupdatedstyle.css";
import { capitalizeFirstLetter } from "../../../utils/utils";
import {
  ORDER_STATUS_SELECT_OPTIONS,
  USER_ROLE,
} from "../../../utils/constant";
import CustomButton from "../../common/Button/CustomButton";
import { Modal } from "react-bootstrap";
import { apiPostRequest } from "../../../helpers/Requests";
import { URL_ADMIN_CHANGE_ORDER_STATUS } from "../../../service/URL";
import parseError from "../../../utils/ErrorParse";
import { toast } from "react-toastify";

const OrderSummary = ({
  order,
  handleClose,
  role,
  getStatusBadge,
  handleOrderStatusUpdateLocally,
}) => {
  const [statusChange, setStatusChange] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [statusChangeLoader, setStatusChangeLoader] = useState(false);

  const calculateTotalCostOfMaterials = (materials) => {
    return materials.reduce((total, material) => {
      return total + material.price * material.quantity;
    }, 0);
  };
  const handleStatusChange = async () => {
    if (role !== USER_ROLE.ADMIN) return;
    try {
      setStatusChangeLoader(true);
      const response = await apiPostRequest(URL_ADMIN_CHANGE_ORDER_STATUS, {
        orderId: order._id,
        status: statusChange,
      });

      if (response.data) {
        toast.success("Order Status Updated Successfully!");
        handleOrderStatusUpdateLocally({
          orderId: order._id,
          status: statusChange,
        });
      }
      setStatusChangeLoader(false);
    } catch (err) {
      setStatusChangeLoader(false);
      console.log("err", err);
      const errorMessage = parseError(err);
      toast.error(errorMessage ?? "Something went wrong!");
    }
  };

  const findStatusOption = () => {
    const options = ORDER_STATUS_SELECT_OPTIONS.filter(
      (x) => x.value !== "all"
    );
    if (statusChange) {
      return options.find((y) => statusChange && y.value === statusChange);
    }
    return options.find((y) => y.value === order.status);
  };

  return (
    <>
      <div className="MainDiv">
        <div className="w-full-updated">
          <div className="secDiv">
            <div>
              <i
                role="button"
                onClick={() => handleClose()}
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </div>
            <div>All Orders</div>
          </div>
        </div>

        <div className="w-full-updated">
          <div className="pt-4 thirdDiv">
            <div className="FontSizeWeight">
              Order <span>#{order.number}</span>
            </div>
            <div className="fourDiv">
              <p>Placed on</p>
              <p>
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </p>
              <p>{moment(order.createdAt).format("DD/MM/YYYY HH:MM")}</p>
            </div>
          </div>
        </div>

        <div className="w-full-updated">
          <div className="cutsombox">
            <div className="cutsomboxpadding">
              <div className="FontSizeWeight">
                {role === USER_ROLE.ADMIN
                  ? "Customer Details"
                  : "Order Details"}
              </div>

              <div className="pt-4 ddlex">
                {role === USER_ROLE.ADMIN && (
                  <div className="rowdlex">
                    <div className="width20">Customer</div>

                    <div className="width80">
                      <div className="gapdlex">
                        <div>
                          <p>
                            {capitalizeFirstLetter(
                              `${order.createdBy?.details?.firstName} ${order.createdBy?.details?.lastName}`
                            )}
                          </p>
                        </div>
                        <div>
                          <p>
                            {capitalizeFirstLetter(
                              `${order.createdBy?.details?.address} ${
                                order.createdBy?.details?.city
                                  ? `,${order.createdBy?.details?.city}`
                                  : ""
                              }`
                            )}
                          </p>
                        </div>
                        <div>
                          <p>
                            {capitalizeFirstLetter(
                              order.createdBy?.details?.state
                            )}
                          </p>
                        </div>
                        {/* <div>
                        <p> USA</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="py-4 flexwidth">
                  <div className="width20"> ID</div>
                  <div className="width80">
                    <div>
                      <div>
                        <p>#1234</p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="py-4 flexwidth">
                  <div className="width20">Total Amout</div>

                  <div className="width80">
                    <div>
                      <p>${calculateTotalCostOfMaterials(order.material)}</p>
                    </div>
                  </div>
                </div>

                <div className="pt-2 flexwidth">
                  <div className="width20 d-flex aign-items-center">Status</div>
                  <div className="width70">
                    {role === USER_ROLE.ADMIN ? (
                      <div>
                        <CustomSelect
                          isDisabled={
                            role !== USER_ROLE.ADMIN ||
                            order.status !== "pending"
                          }
                          // isDisabled={isFetchMaterialCatLoader}
                          // loading={isFetchMaterialCatLoader}
                          placeHolder={"Select Status"}
                          label="Status"
                          selectedValue={findStatusOption()}
                          handleChange={(e) => setStatusChange(e.value)}
                          options={ORDER_STATUS_SELECT_OPTIONS.filter(
                            (x) => x.value !== "all"
                          )}
                        />
                      </div>
                    ) : (
                      <div>{getStatusBadge(order.status)}</div>
                    )}
                  </div>
                  {role === USER_ROLE.ADMIN && (
                    <div className="grid-vertical-center">
                      <CustomButton
                        showLoading={statusChangeLoader}
                        style={{ height: "50px" }}
                        isDisabled={
                          statusChange === order.status ||
                          statusChange === null ||
                          statusChangeLoader
                        }
                        label="Save"
                        clickHandler={() => {
                          statusChange !== order.status &&
                            statusChange !== null &&
                            setShowModal(true);
                        }}
                        cssClass="btn-effect-1 btn1"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full-updated">
          <div className="cutsombox">
            <div className="cutsomboxpadding">
              <div className="FontSizeWeight">Order Items</div>
              <div className="pt-4 flexwidth">
                <div className="width55">
                  <div className="fontsizeweighsm">DESCRIPTION</div>
                </div>
                <div className="width15">
                  <div className="fontsizeweighsm">AMOUNT</div>
                </div>
                <div className="width15">
                  <div className="fontsizeweighsm">QUANTITY</div>
                </div>
                <div className="width15">
                  <div className="fontsizeweighsm">TOTAL</div>
                </div>
              </div>
              {order.material?.map((item, index) => (
                <div className="flexwidth" key={index}>
                  <div className="width55">
                    <div className="d-flex justfy-content-start align-items-center">
                      <img
                        src={item?.image?.url}
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                        }}
                        alt="Preview"
                        class="img-attachment"
                      />
                      <div className="pb-3 px-2">{item.description}</div>
                    </div>
                  </div>
                  <div className="width15">
                    <div className="py-3">${item.price}</div>
                  </div>
                  <div className="width15">
                    <div className="py-3">{item.quantity}</div>
                  </div>
                  <div className="width15">
                    <div className="py-3">
                      ${parseFloat(item.price * item.quantity).toFixed(2)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header
            style={{
              borderBottom:
                "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
            }}
            closeButton
          >
            <Modal.Title>Update Order Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`Are You Sure! You Want To Change Order Status To ${capitalizeFirstLetter(
            statusChange
          )}`}</Modal.Body>
          <Modal.Footer>
            <CustomButton
              label="Cancel"
              clickHandler={() => {
                setShowModal(false);
              }}
              cssClass="btn-effect-1 btn1"
            />
            <CustomButton
              label="Yes"
              clickHandler={() => {
                handleStatusChange();
                setShowModal(false);
              }}
              cssClass="btn-effect-1 btn1"
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default OrderSummary;
