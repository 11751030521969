import React, { useState } from "react";
import CryptoJS from "crypto-js";
import CustomButton from "../../../common/Button/CustomButton";
import SwitchInput from "../../../common/SwitchInput/SwitchInput";
import { toast } from "react-toastify";
import { classNames } from "../../../../utils/utils";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { USER_ROLE } from "../../../../utils/constant";

const DecodeVechicle = (props) => {
  const {
    setVehical,
    vehical,
    vehicleVin,
    error,
    setError,
    setvehicleVin,
    isEditReport
  } = props;

  const [isDecodeLoader, setIsDecodeLoader] = useState(false);
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const apiPrefix = "https://api.vindecoder.eu/3.2";
  const apiKey = "a1ea2259220d";
  const secretKey = "da6b406918";
  const id = "decode";
  // const vin = "1C4JJXFG1PW519760";

  const fetchData = async () => {
    try {
      if (!vehical.vin) {
        toast.error("Vechicle Vin is required!", {
          toastId: "vechicleVinDecodeError",
        });
        return;
      }
      const controlSum = CryptoJS.SHA1(
        `${vehical.vin}|${id}|${apiKey}|${secretKey}`
      ).toString(CryptoJS.enc.Hex);
      const truncatedControlSum = controlSum.slice(0, 10);
      const url = `${apiPrefix}/${apiKey}/${truncatedControlSum}/decode/${vehical.vin}.json`;
      setIsDecodeLoader(true);
      const response = await fetch(url);
      const data = await response.json();

      data.decode.forEach((data) => {
        if (data.label === "Model Year") {
          setVehical((prev) => ({ ...prev, year: data.value?.toString() }));
        } else if (data.label === "Make") {
          setVehical((prev) => ({ ...prev, make: data.value }));
        } else if (data.label === "Model") {
          setVehical((prev) => ({ ...prev, modal: data.value }));
        } else if (data.label === "Product Type") {
          setVehical((prev) => ({ ...prev, vehicalType: data.value }));
        } else if (data.label === "Series") {
          setVehical((prev) => ({ ...prev, trim: data.value }));
        } 
      });
      setvehicleVin(
        data.decode.map((i) => ({
          ...i,
          [`${i.label}`]: i.value,
        }))
      );
      setIsDecodeLoader(false);
    } catch (error) {
      setIsDecodeLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const isEmpty = value.trim() === "";

    if (isEmpty) {
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: isEmpty,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [name]: false,
      }));
    }

    setVehical((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  // console.log("vechicle Data", vehical);

  const [inputYear, setInputYear] = useState("");

  const handleChange = (e) => {
    setInputYear(e.target.value);
    handleInputChange(e);
  };

  const handleBlur = () => {
    const formattedYear = moment.utc(inputYear, "YYYY", true).isValid()
      ? moment.utc(inputYear).format("YYYY")
      : inputYear;
    if (vehicleVin) {
      vehicleVin["Model Year"] = formattedYear;
    }
  };

  const [isPowerSport, setIsPowerSport] = useState(false);
  // const [isUTV, setIsUTV] = useState(true);

  const handleIsPowerSportChanged = () => {
    setVehical({...vehical, isPowerSport: !isPowerSport});
    setIsPowerSport(!isPowerSport)
  }

  const handleVehicalTypeNoteChanged = (e) => {
    setVehical({...vehical, vehicalTypeNote: e.target.name});
    console.log("e.target.name", e.target.name, vehical.vehicalTypeNote);
  }

  const formattedPurchaseDate = vehical?.purchaseDate
    ? moment(vehical.purchaseDate, "MMMM DD, YYYY").format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  return (
    <div className="row mt-2">
      <div className="col-md-5 mb-4">
        <label>
          VIN <span className="required-class">*</span>
        </label>
        <span className="d-flex">
          <input
            disabled={
              isEditReport !== null || role === USER_ROLE.STAFF.SERVICE_ADVISOR
            }
            className="m-0"
            type="text"
            name="vin"
            value={vehical?.vin ? vehical.vin : vehicleVin?.VIN}
            onChange={(e) => {
              handleInputChange(e);
              if (vehicleVin) {
                vehicleVin["VIN"] = e.target.value;
              }
            }}
            required
          />
          {isEditReport === null && (
            <CustomButton
              showLoading={isDecodeLoader}
              isDisabled={isDecodeLoader}
              label="Decode"
              clickHandler={fetchData}
              cssClass="btn-effect-1 text-uppercase btn1"
              style={{
                height: "50px !important",
                marginLeft: "10px",
                alignItems: "center",
              }}
            />
          )}
        </span>
        {error.vin && <p className="error-msg">Vin Number is required</p>}
      </div>
      {/* Is Power Sport */}
      <div className="col-md-5 mb-4 ms-4">
        <label>
          Select Vehicle Category <span className="required-class"></span>
        </label>
        <div className="d-flex mt-3 align-items-center gap-2 flex-wrap">
          <div className="d-flex align-items-center me-4">
            <input
              type="checkbox"
              name="isAutomative"
              checked={!vehical.isPowerSport} // Check if isPowerSport is false (meaning Automative is true)
              onChange={handleIsPowerSportChanged}
              style={{
                width: '25px',
                height: '25px',
                accentColor: '#2a4025'
              }}
            />
            <label className="ms-2 fs-5">Automotive</label>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                name="isPowerSport"
                checked={vehical.isPowerSport}
                onChange={handleIsPowerSportChanged}
                style={{
                  width: '25px',
                  height: '25px',
                  accentColor: '#2a4025'
                }}
              />
              <label className="ms-2 fs-5">Powersports{vehical.isPowerSport && ":"}</label>
            </div>
            {vehical.isPowerSport && (
              <div className="d-flex gap-2 ms-3">
                <div className="d-flex align-items-center">                  
                  <input 
                    type="checkbox"
                    name="UTV"
                    checked={vehical.vehicalTypeNote === "UTV"}
                    onChange={handleVehicalTypeNoteChanged}
                    style={{
                      width: '15px',
                      height: '15px',
                      accentColor: '#2a4025'
                    }}
                  />
                  <label className="ms-2 fs-6">UTV</label>
                </div>
                <div className="d-flex align-items-center">
                  <input 
                    type="checkbox"
                    name="ATV"
                    checked={vehical.vehicalTypeNote === "ATV"}
                    onChange={handleVehicalTypeNoteChanged}
                    style={{
                      width: '15px',
                      height: '15px',
                      accentColor: '#2a4025'
                    }}
                  />
                  <label className="ms-2 fs-6">ATV</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className="col-md-5 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          name="isPowerSport"
          checked={vehical.isPowerSport || isPowerSport}
          onChange={handleIsPowerSportChanged}
          style={{
            width:'30px',
            height: '30px',
            accentColor: '#2a4025'
          }}
        />
        <label className="ms-3 fs-5">Is Power Sports</label>
      </div> */}

      {/* Vehicle type note radios */}
      {/* <div className="col-md-5 d-flex justify-content-center align-items-center gap-4">
        <div className="d-flex align-items-center">
          <input 
            type="radio" 
            id="automative" 
            value="vehicle"
            name="vehicleTypeNote"
            checked= {vehical?.vehicleTypeNote === "automative"}
            onChange={handleVehicleTypeNoteChanged}
            style={{
              width:'25px',
              height: '25px',
              accentColor: '#2a4025'
            }}
          />
          <label className="ms-2 fs-6" htmlFor="automative">Automative</label>
        </div>
        <div className="d-flex align-items-center">
          <input 
            type="radio" 
            id="powersport" 
            name="vehicleTypeNote" 
            value="power sport"
            checked= {vehical?.vehicleTypeNote === "powersport"}
            onChange={handleVehicleTypeNoteChanged}
            style={{
              width:'25px',
              height: '25px',
              accentColor: '#2a4025'
            }}
          />
          <label className="ms-2 fs-6" htmlFor="truck">Power Sport</label>
        </div>
        <div className="d-flex align-items-center">
          <input 
            type="radio" 
            id="rv" 
            name="vehicleTypeNote" 
            value="rv"
            checked= {vehical?.vehicleTypeNote === "rv"}
            onChange={handleVehicleTypeNoteChanged}
            disabled={true}
            style={{
              width:'25px',
              height: '25px',
              accentColor: '#2a4025'
            }}
          />
          <label className="ms-2 fs-6 text-black-50" htmlFor="truck">R.V.</label>
        </div>
      </div> */}


      {/* Make */}
      <div className="col-md-4 px-sm-none">
        <label>
          Make <span className="required-class">*</span>
        </label>
        <input
          disabled={isEditReport !== null}
          type="text"
          name="make"
          value={vehical?.make ? vehical?.make : vehical?.Make}
          onChange={handleInputChange}
          required
        />
        {error.make && <p className="error-msg">Make is required</p>}
      </div>
      {/* Model */}
      <div className="col-md-4 px-sm-none">
        <label>
          Model <span className="required-class">*</span>
        </label>
        <input
          disabled={isEditReport !== null}
          type="text"
          name="modal"
          value={vehical?.modal ? vehical?.modal : vehical?.Model}
          onChange={handleInputChange}
          required
        />
        {error.modal && <p className="error-msg">Modal is required</p>}
      </div>

      {/* Purchase Date */}
      <div className="col-md-4 px-sm-none">
        <label>
          Purchase Date <span className="required-class">*</span>
        </label>

        <input
          className="text"
          type="date"
          // type="text"
          name="purchaseDate"
          placeholder="Todays Date"
          value={
            formattedPurchaseDate
            // vehical?.purchaseDate?.toString() ||
            // new Date().toISOString()?.split("T")[0]?.toString()
          }
          onChange={(e) => {
            const selectedDate = e.target.value;
            const currentDate = new Date().toISOString().split("T")[0];
            const newPurchaseDate = selectedDate || currentDate;
            setVehical((prev) => ({
              ...prev,
              purchaseDate: newPurchaseDate,
            }));
          }}
          required
        />
        {/* {error.purchaseDate && (
                      <p className="error-msg">PurchaseDate is required</p>
                    )} */}
      </div>

      {/* Year */}
      <div className="col-md-4 px-sm-none">
        <label>
          Year <span className="required-class">*</span>
        </label>
        <input
          className="text no-spin-buttons"
          type="number"
          name="year"
          value={
            inputYear ||
            (vehical?.year ? moment.utc(vehical.year).format("YYYY") : "") ||
            (vehicleVin && vehicleVin["Model Year"]
              ? moment.utc(vehicleVin["Model Year"]).format("YYYY")
              : "")
          }
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {error.year && <p className="error-msg">Year is required</p>}
      </div>

      {/* Trim */}
      <div className="col-md-4 px-sm-none">
        <label>Trim</label>
        <input
          type="text"
          name="trim"
          value={vehical?.trim ? vehical?.trim : vehical?.Series}
          onChange={(e) => {
            handleInputChange(e);
            if (vehicleVin) {
              vehicleVin.Series = e.target.value;
            }
          }}
          // required
        />
      </div>
      {/* Product Type */}
      <div className="col-md-4 d-none">
        <label>Type</label>
        <input
          disabled={isEditReport !== null}
          type="text"
          name="type"
          value={
            vehical && vehical?.type
              ? vehical?.type
              : vehical && vehical["Product Type"]
              ? vehical["Product Type"]
              : ""
          }
          onChange={(e) => {
            // handleInputChange(e);
            // if (vehicleVin) {
            //   setvehicleVin({ ...vehicleVin, "Product Type": e.target.value });
            // }
          }}
          // required
        />
      </div>
      {/* Type */}
      <div className="col-md-4 ">
        <label>
          Type<span className="required-class">*</span>
        </label>
        <select
          class="form-select form-select-option nice-select h-50 "
          aria-label="Default select example"
          name="productType"
          value={vehical?.productType ? vehical?.productType : ""}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        >
          <option value="" disabled selected>
            Select Type
          </option>
          <option value="New">New</option>
          <option value="Used">Used</option>
          <option value="CPO">CPO</option>
          <option value="Demo">Demo</option>
        </select>
        {error.productType && <p className="error-msg">Type is required</p>}
      </div>
      {/* Odometer Reading */}
      <div className="col-md-4 ">
        <label>
          Odometer Reading <span className="required-class">*</span>
        </label>
        {/* <input
          type="text"
          name="odometerReading"
          value={vehical?.odometerReading ? vehical?.odometerReading : 0}
          onChange={handleInputChange}
          required={true}
        /> */}
        <NumericFormat
          allowNegative={false}
          required={true}
          placeholder="Odometer Reading"
          id="numericInput"
          min={1}
          maxLength={"15"}
          className={classNames("text numericInput")}
          value={vehical?.odometerReading ? vehical?.odometerReading : null}
          valueIsNumericString={true}
          decimalScale={2}
          allowLeadingZeros={false}
          onValueChange={(e) => {
            const { value } = e;
            handleInputChange({
              target: { name: "odometerReading", value: value },
            });
          }}
        />
        {error?.odometerReading && (
          <p className="error-msg">Odometer Reading is required</p>
        )}
      </div>
      {/* Category */}
      <div className="col-md-4 d-none">
        <label>
          PRoduct Type<span className="required-class">*</span>
        </label>
        <select
          disabled={isEditReport !== null}
          class="form-select form-select-option nice-select h-50 "
          aria-label="Default select example"
          name="productType"
          value={vehical?.productType ? vehical?.productType : ""}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        >
          <option value="" disabled selected>
            Select Category
          </option>
          <option value="SUV">SUV</option>
          <option value="Truck">Truck</option>
          <option value="Car">Car</option>
          <option value="ATV">ATV</option>
        </select>
        {error.productType && (
          <p className="error-msg">Priduct Type is required</p>
        )}
      </div>

      {/* Color */}
      <div className="col-md-4 px-sm-none">
        <label>Color</label>
        <input
          type="text"
          name="color"
          value={vehical?.color ? vehical.color : ""}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </div>
      {/* Modification */}
      <div className="col-md-4 px-sm-none">
        <label>Modification</label>
        <input
          type="text"
          name="modification"
          value={
            vehical?.modification
              ? vehical?.modification
              : vehical?.trims?.modified
          }
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </div>
      {/* Stock */}
      <div className="col-md-4 px-sm-none">
        <label>Stock</label>
        <input
          type="text"
          name="stock"
          value={vehical?.stock ? vehical?.stock : ""}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </div>

      {/* Pre-Existing Damage */}
      <div className="col-md-4 px-sm-none">
        <label>Pre-Existing Damage </label>
        <select
          id="damage"
          name="damage"
          class="form-select form-select-option nice-select "
          style={{ height: "50px" }}
          aria-label="Default select example"
          onChange={handleInputChange}
          value={vehical?.damage || ""}
        >
          <option value="" disabled selected>
            Select Pre-Existing Damage
          </option>
          {/* <option value="admin">Yes</option>
          <option value="manager">No</option> */}
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      {/* Customer# */}
      <div className="col-md-4 px-sm-none">
        <label>Deal # </label>
        <input
          className="text no-spin-buttons"
          type="text"
          name="customerRandomId"
          value={vehical?.customerRandomId ? vehical?.customerRandomId : ""}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </div>
      {/* Vehicle Comment */}
      <div className="col-md-4 px-sm-none">
        <label>Vehicle Comment</label>
        <textarea
          type="text"
          name="vehicalComment"
          value={vehical?.vehicalComment ? vehical?.vehicalComment : ""}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default DecodeVechicle;
