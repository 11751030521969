import React, { useContext, useEffect, useState } from "react";
import CustomSelect from "../../common/Select/CustomSelect";
import "./styles.css";
import { Badge, Table } from "react-bootstrap";
import CustomPagination from "../../common/Pagination";
import { apiGetRequest } from "../../../helpers/Requests";
import { BASE_URL } from "../../../service/URL";
import {
  capitalizeFirstLetter,
  convertDateByTimezone,
  dateFormatDDMMYYYY,
} from "../../../utils/utils";
import {
  ORDER_STATUS_SELECT_OPTIONS,
  PAGINATION_OBJ,
  USER_ROLE,
} from "../../../utils/constant";
import OrderSummary from "./orderSummary";
import secureLocalStorage from "react-secure-storage";
import _ from "lodash";
import AuthContext from "../../../helpers/authContext";

const OrderManagement = () => {
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const { timezone } = useContext(AuthContext);

  const [ordersData, setOrdersData] = useState([]);
  const [isFetchMaterialCatLoader, setIsFetchOrdersDataLoader] =
    useState(false);

  const [search, setSearch] = useState(null);
  const [selectedOrderStatus, setselectedOrderStatus] = useState(
    ORDER_STATUS_SELECT_OPTIONS[0].value
  );

  const [paginationObj, setPaginationObj] = useState(PAGINATION_OBJ);

  const addFiltersAndPagination = (url, params) => {
    let str = "";
    if (params.page) {
      str = `page=${params.page}`;
    }
    if (params.limit) {
      str = `${str}${str ? "&" : ""}limit=${params.limit}`;
    }
    if (params.search !== null) {
      str = `${str}${str ? "&" : ""}search=${params.search}`;
    }
    if (
      params.selectedOrderStatus !== null &&
      params.selectedOrderStatus !== undefined
    ) {
      str = `${str}${str ? "&" : ""}status=${
        params.selectedOrderStatus === "all" ? "" : params.selectedOrderStatus
      }`;
    }
    return str ? `${url}?${str}` : url;
  };

  const getOrdersApi = async (obj = {}) => {
    try {
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;
      const url = addFiltersAndPagination(
        `${BASE_URL}/${roleApi?.toLowerCase()}/orders-list`,
        obj
      );
      setIsFetchOrdersDataLoader(true);
      const response = await apiGetRequest(url);
      if (response) {
        if (response?.pagination) {
          setPaginationObj({ ...response.pagination });
        }
        setOrdersData([...(response.data?.orders || [])]);
      }
      setIsFetchOrdersDataLoader(false);
    } catch (err) {
      setIsFetchOrdersDataLoader(false);
      console.log("err");
    }
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        getOrdersApi({
          ...paginationObj,
          page: 1,
          search,
          selectedOrderStatus,
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    const filter = { ...paginationObj, page: 1, search };
    if (selectedOrderStatus !== null && selectedOrderStatus !== undefined) {
      Object.assign(filter, {
        selectedOrderStatus,
      });
    }
    getOrdersApi(filter);
  }, [selectedOrderStatus]);

  const calculateTotalCostOfMaterials = (materials) => {
    return materials.reduce((total, material) => {
      return total + material.price * material.quantity;
    }, 0);
  };

  const getStatusBadge = (status) => {
    if (status === "pending") {
      return (
        <Badge pill className="status-badge pending-badge">
          Pending
        </Badge>
      );
    } else if (status === "rejected") {
      return (
        <Badge pill className="status-badge rejected-badge">
          Rejected
        </Badge>
      );
    } else if (status === "complete") {
      return (
        <Badge pill className="status-badge complete-badge">
          Complete
        </Badge>
      );
    }
  };

  const handleOrderStatusUpdateLocally = ({ orderId, status }) => {
    const prevData = _.cloneDeep(ordersData);
    const index = prevData.findIndex((i) => i._id === orderId);
    if (index !== -1) {
      prevData.splice(index, 1, {
        ...prevData[index],
        status: status,
      });
      setOrdersData([...prevData]);
    }

    if (selectedOrder && selectedOrder._id === orderId) {
      setSelectedOrder((prev) => ({
        ...prev,
        status: status,
      }));
    }
  };

  const [selectedOrder, setSelectedOrder] = useState(null);

  if (selectedOrder !== null && selectedOrder.hasOwnProperty("_id")) {
    return (
      <OrderSummary
        handleOrderStatusUpdateLocally={handleOrderStatusUpdateLocally}
        getStatusBadge={getStatusBadge}
        role={role}
        handleClose={() => setSelectedOrder(null)}
        order={selectedOrder}
      />
    );
  }

  return (
    <>
      <div className="px-4 pt-3 mb-20">
        <div className="d-flex justify-content-between mt-3 text-start align-items-center">
          <div>
            <h3>Orders Management</h3>
          </div>
        </div>
        <div className="body-container py-4">
          <div className="body-filter">
            <div className={"w-80"}>
              <div className="w-100 search-input">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input
                  className=""
                  id="search"
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={
                    role === USER_ROLE.ADMIN
                      ? // ? "Search by order number or customer name"
                        // : "Search by order number or material name"
                        "Search by order number"
                      : "Search by order number"
                  }
                />
              </div>
            </div>
            <div className="w-20 space-x-2">
              <CustomSelect
                // isClear={true}
                // isSearch={true}
                isDisabled={isFetchMaterialCatLoader}
                loading={isFetchMaterialCatLoader}
                placeHolder={"Select Status"}
                label="Status"
                selectedValue={
                  ORDER_STATUS_SELECT_OPTIONS?.find(
                    (x) => x.value === selectedOrderStatus
                  ) || null
                }
                handleChange={(e) => setselectedOrderStatus(e?.value || "")}
                options={ORDER_STATUS_SELECT_OPTIONS}
              />
            </div>
          </div>
          <div className="checkoverflow">
            <div className="mobileTable">
              {/* bordered */}
              <Table striped hover className="overflow-table">
                <thead>
                  <tr>
                    <th className="w-20">Order Number</th>
                    {role === USER_ROLE.ADMIN && (
                      <th className="w-15">Customer</th>
                    )}
                    <th className="w-15">Total Value</th>
                    <th className="w-15">Order Date</th>
                    <th className="w-15">Status</th>
                    <th className="w-20 text-center px-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isFetchMaterialCatLoader ? (
                    <>
                      <div className="loader-container-claim">
                        <div className="loader-claim"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {ordersData.length === 0 ? (
                        <tr>
                          <td colSpan={6}>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "100%" }}
                            >
                              <span className="error-text">No data found!</span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ordersData.map((order, index) => (
                          <tr key={index}>
                            <td className="td-content">
                              <span>#{order.number}</span>
                            </td>
                            {role === USER_ROLE.ADMIN && (
                              <td className="td-content">
                                <span>
                                  {capitalizeFirstLetter(
                                    `${order.createdBy?.details?.firstName} ${order.createdBy?.details?.lastName}`
                                  )}
                                </span>
                              </td>
                            )}
                            <td className="td-content">
                              <span>{`$${calculateTotalCostOfMaterials(
                                order.material
                              )}`}</span>
                            </td>
                            <td className="td-content">
                              <span>
                                {dateFormatDDMMYYYY(
                                  convertDateByTimezone(
                                    order.createdAt,
                                    timezone
                                  )
                                )}
                              </span>
                            </td>
                            <td className="td-content">
                              <span>{getStatusBadge(order.status)}</span>
                            </td>
                            <td className="text-center px-4 td-content">
                              <div>
                                <i
                                  onClick={() => {
                                    setSelectedOrder(order);
                                    // setIsAddMaterialShow(true);
                                  }}
                                  role="button"
                                  className={
                                    role === USER_ROLE.ADMIN
                                      ? "fa fa-edit"
                                      : "fa fa-eye"
                                  }
                                  aria-hidden="true"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </Table>
              <div className="custom-pagination">
                <CustomPagination
                  handleChangePage={(page) => {
                    setPaginationObj((prev) => ({
                      ...prev,
                      page,
                    }));
                    getOrdersApi({
                      ...paginationObj,
                      page,
                      search,
                      selectedOrderStatus,
                    });
                  }}
                  handlePaginationLimitChange={(limit) => {
                    setPaginationObj((prev) => ({
                      ...prev,
                      page: 1,
                      limit,
                    }));
                    getOrdersApi({
                      ...paginationObj,
                      page: 1,
                      limit,
                      search,
                      selectedOrderStatus,
                    });
                  }}
                  paginationObj={paginationObj}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderManagement;
