import secureLocalStorage from "react-secure-storage";

// export const setToken = (token) => {
//   return localStorage.setItem("accessToken", token);
// };

export const getToken = () => {
  return secureLocalStorage.getItem("Token")
    ? JSON.parse(secureLocalStorage.getItem("Token"))
    : null;
};

// export const getCurrentUser = () => {
//   return localStorage.getItem("user")
//     ? JSON.parse(localStorage.getItem("user"))
//     : null;
// };
