import React from "react";
import { classNames } from "../../../utils/utils";
import Loader from "../Loader/Loader";
import "./CustomButton.css";

const CustomButton = (props) => {
  const {
    variant = "primary",
    loaderCssClass,
    type,
    cssClass,
    clickHandler,
    label,
    icon,
    showLoading,
    isDisabled,
    style = {},
  } = props;

  const getVariant = () => {
    switch (variant) {
      case "primary":
        return "btn theme-btn-1";
      case "secondary":
        return "btn theme-btn-4";
      case "tertiary":
        return "btn";
      case "danger":
        return "btn theme-btn-1 danger-btn";
      default:
        return "btn theme-btn-1";
    }
  };
  return (
    <button
      style={{ ...style, alignItems: "center" }}
      type={type}
      disabled={isDisabled}
      onClick={clickHandler}
      className={classNames(
        `${getVariant()} custom-button-class d-flex`,
        isDisabled && "button-disabled",
        cssClass && cssClass
      )}
    >
      {label}
      {showLoading && <Loader cssClass={loaderCssClass} isSmallView={true} />}
      {icon && !showLoading && (
        <span
          className="px-2"
          style={{ display: "inline-flex", fontSize: "1rem" }}
        >
          {icon}
        </span>
      )}
    </button>
  );
};

export default CustomButton;
