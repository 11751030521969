import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { apiPostRequest } from "../../helpers/Requests";
import { BASE_URL } from "../../service/URL";
import CustomButton from "../../components/common/Button/CustomButton";

const ServiceRequestModal = ({ modalShow, handleClose }) => {
  const [serviceReq, setServiceReq] = useState({
    subject: "",
    message: "",
  });

  const [isSubmitLoader, setIsSubmitLoader] = useState(false);

  const ServiceReq = async () => {
    try {
      if (!serviceReq.subject || !serviceReq.message) {
        toast.error("Please fill missing fields!");
        return;
      }
      setIsSubmitLoader(true);
      const response = await apiPostRequest(`${BASE_URL}/support-request`, {
        subject: serviceReq.subject,
        message: serviceReq.message,
      });
      if (response?.message) {
        toast.success("Email Sent");
      }
      setServiceReq("");
      setIsSubmitLoader(false);
      handleClose();
    } catch (error) {
      setIsSubmitLoader(false);
      toast.error(error??"something went wrong");
      // console.log(
      //   "Error:",
      //   error.response ? error.response.data : error.message
      // );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setServiceReq((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          SUPPORT REQUEST
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-20">
        <>
          <div className="row">
            <div className="col-md-12">
              <label className="text-black">
                Subject <span className="required-class">*</span>
              </label>
              <input
                type="text"
                name="subject"
                value={serviceReq.subject ? serviceReq.subject : ""}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label className="text-black">
                Message <span className="required-class">*</span>
              </label>
              <textarea
                type="text"
                name="message"
                value={serviceReq.message ? serviceReq.message : ""}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <CustomButton
            isDisabled={isSubmitLoader}
            showLoading={isSubmitLoader}
            label="Submit Request"
            style={{
              padding: "15px 30px",
              backgroundColor: "rgb(255, 90, 60)",
              color: "#FFF",
              marginLeft: "12px",
              fontSize: "16px",
            }}
            clickHandler={ServiceReq}
            cssClass="btn-effect-1 text-uppercase btn1 mt-4"
          />
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceRequestModal;
