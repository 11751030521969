import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const TabsComponent = (props) => {
  const { data, handleChangeTab } = props;

  const [activeTab, setActiveTab] = useState(data?.[0]?.eventKey || "");

  const handleSelect = (key) => {
    setActiveTab(key);
    if (handleChangeTab) {
      handleChangeTab(key);
    }
  };

  return (
    <Tabs
      defaultActiveKey={activeTab}
      id="uncontrolled-tab-example"
      className="mb-3"
      onSelect={handleSelect}
    >
      {data &&
        data?.map((item, index) => (
          <Tab key={index} eventKey={item.eventKey} title={item.title}>
            {item.element}
          </Tab>
        ))}
    </Tabs>
  );
};

export default TabsComponent;
