import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { SERVICE_MANAGER_EMPTY_OBJ } from "../../../utils/constant";
import { classNames } from "../../../utils/utils";
import CustomButton from "../../common/Button/CustomButton";
import { apiPostRequest, apiPutRequest } from "../../../helpers/Requests";
import parseError from "../../../utils/ErrorParse";
import "./serviceManager.css";

const CreateServiceManager = (props) => {
  const {
    handleClose,
    selectedServiceManager = null,
    handleDataUpdateById,
    setActiveTab,
  } = props;

  var apiUrl = process.env.REACT_APP_API_URL;

  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState("");
  const [inputData, setInputData] = useState(SERVICE_MANAGER_EMPTY_OBJ);

  const [isCreateLoader, setIsCreateLoader] = useState(false);

  useEffect(() => {
    if (selectedServiceManager !== null) {
      setInputData({
        ...selectedServiceManager,
        phone: selectedServiceManager?.phone?.trim(),
      });
    }
  }, [selectedServiceManager]);

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    notes: "",
    phone: "",
  });

  const validateField = (field, value) => {
    if (field === "phone") {
      return value === "" || value === "+1" || value.trim() === "+1";
    } else {
      return value === "" || value === undefined;
    }
  };

  const ValidationForm = () => {
    const errorObj = {};
    const notInclude = ["address", "city", "state", "postalCode"];

    for (const [field, value] of Object.entries(inputData)) {
      if (notInclude.findIndex((x) => x === field) === -1) {
        if (validateField(field, value)) {
          errorObj[field] = true;
        }
      }
    }

    setValidationErrors((prev) => ({ ...prev, ...errorObj }));
    // console.log("errorObj", errorObj);
    return Object.keys(errorObj).length > 0;
  };

  const submitServiceManager = async (event) => {
    event.preventDefault();

    const hasErrors = ValidationForm();
    if (hasErrors) {
      console.log("Form has validation errors", hasErrors);
      return;
    }

    const payload = {
      firstName: inputData.firstName,
      lastName: inputData.lastName,
      phone: inputData.phone,
      notes: inputData.notes,
    };

    if (selectedServiceManager !== null && selectedServiceManager?._id) {
      Object.assign(payload, {
        service_manager_id: selectedServiceManager?._id,
      });
    } else {
      Object.assign(payload, {
        email: inputData.email,
      });
    }

    try {
      setIsCreateLoader(true);
      const response =
        selectedServiceManager === null
          ? await apiPostRequest(
              `${apiUrl}/${role?.toLowerCase()}/service-manager`,
              { ...payload }
            )
          : await apiPutRequest(
              `${apiUrl}/${role?.toLowerCase()}/service-manager`,
              { ...payload }
            );

      if (response.data) {
        toast.success(
          selectedServiceManager === null
            ? "ET Manager Added Successfully!"
            : "ET Manager Updated Successfully!"
        );
        if (selectedServiceManager === null) {
          setActiveTab("ListServiceManager");
        } else {
          handleDataUpdateById({ ...payload, id: payload.service_manager_id });
          handleClose();
        }
      }
      setIsCreateLoader(false);
    } catch (error) {
      setIsCreateLoader(false);
      toast.error(error ?? "Something went wrong!");
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setInputData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));

    if (validationErrors[name]) {
      const error = validateField(name, value);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="ltn__form-box">
        <form action="submit" onSubmit={submitServiceManager}>
          <div
            className={classNames(
              "row",
              selectedServiceManager === null && "px-4 pt-5 mb-50"
            )}
          >
            {selectedServiceManager === null && (
              <h3 className="pb-4">Add New ET Manager</h3>
            )}
            <div className="col-md-6">
              <label>
                First Name:<span className="required-class">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={inputData.firstName ? inputData.firstName : ""}
                onChange={handleInputChange}
                required
              />
              {validationErrors?.firstName && (
                <p className="error-message">First Name is required!</p>
              )}
            </div>
            <div className="col-md-6">
              <label>
                Last Name:<span className="required-class">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                value={inputData.lastName ? inputData.lastName : ""}
                onChange={handleInputChange}
                required
              />
              {validationErrors?.lastName && (
                <p className="error-message">Last Name is required!</p>
              )}
            </div>
            <div className="col-md-6">
              <label>
                Email Address:<span className="required-class">*</span>
              </label>
              <input
                disabled={selectedServiceManager !== null}
                type="email"
                name="email"
                value={inputData.email ? inputData.email : ""}
                onChange={(e) =>
                  selectedServiceManager === null && handleInputChange(e)
                }
                required
              />
              {validationErrors?.email && (
                <p className="error-message">Email is required!</p>
              )}
            </div>
            <div className="col-md-6">
              <label>
                Phone
                <span className="required-class">*</span>
              </label>
              <input
                className="text no-spin-buttons"
                type="tel"
                name="phone"
                maxLength={13}
                value={inputData.phone}
                onChange={(event) => {
                  const { name, value } = event.target;
                  const formattedValue = value.startsWith("+1")
                    ? value
                    : `+1 ${value}`;
                  if (name === "phone") {
                    const phonePattern =
                      /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                    const isValidPhoneNumber =
                      phonePattern.test(formattedValue);
                    setIsValidPhoneNumber(isValidPhoneNumber);
                  }
                  setInputData((prev) => ({
                    ...prev,
                    [name]: formattedValue,
                  }));
                }}
                required
              />
              {inputData.phone?.trim()?.replace("+1", "")?.length <= 0 &&
                validationErrors?.phone && (
                  <p className="error-message">Phone number is required.</p>
                )}
              {inputData.phone?.length === 13 &&
                !/^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(
                  inputData.phone
                ) && (
                  <p
                    className=""
                    style={{
                      marginTop: "-20px",
                      color: isValidPhoneNumber ? "green" : "red",
                    }}
                  >
                    {isValidPhoneNumber
                      ? ""
                      : "Invalid input: please enter a valid Canadian phone number"}
                  </p>
                )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <label class="label" for="msg-comments">
                  Notes <span class="required">*</span>
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  value={inputData.notes ? inputData.notes : ""}
                  onChange={handleInputChange}
                  required
                ></textarea>
                {validationErrors.notes && (
                  <p className="pt-3 error-message">Notes is required</p>
                )}
              </div>
            </div>
          </div>

          <div className="btn-wrapper px-4 pl-1">
            <div className="px-2">
              <CustomButton
                showLoading={isCreateLoader}
                isDisabled={isCreateLoader}
                label={
                  selectedServiceManager === null
                    ? "Add ET Manager"
                    : "Update ET Manager"
                }
                type="submit"
                style={{ backgroundColor: "#000000" }}
                cssClass="btn-effect-1 text-uppercase btn1"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateServiceManager;
