import React from "react";
import Select from "react-select";
import { classNames } from "../../../utils/utils";
import "./select.css";

const CustomSelect = (props) => {
  const {
    selectedValue,
    handleChange,
    options,
    error = "",
    label,
    placeHolder,
    loading = false,
    isDisabled = false,
    isSearch = false,
    isClear = false,
    isRequired = false,
    isMulti = false,
    name = "",
    noOptionsMessage = "No Options",
  } = props;

  if (isMulti) {
    return (
      <div
        className={classNames(
          "custom-select-container multi-select",
          !label && "no-label-in-select"
        )}
      >
        <Select
          defaultValue={selectedValue}
          value={selectedValue}
          closeMenuOnSelect={false}
          isMulti
          options={options}
          onChange={(e) => {
            handleChange(e);
          }}
          isDisabled={isDisabled}
          name={name}
          isClearable={isClear}
          isSearchable={isSearch}
          placeholder={placeHolder}
          className={classNames(error.length > 0 && "error-border")}
          noOptionsMessage={() => noOptionsMessage}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(
          "custom-select-container",
          !label && "no-label-in-select"
        )}
      >
        <Select
          required={isRequired}
          className={classNames(error.length > 0 && "error-border")}
          isClearable={isClear}
          style={{
            width: "100%",
          }}
          // className="nice-select"
          isDisabled={isDisabled}
          isLoading={loading}
          placeholder={placeHolder}
          value={selectedValue}
          onChange={handleChange}
          options={options}
          isSearchable={isSearch}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {label && <label htmlFor="select">{label}</label>}
      </div>
      {error.length > 0 && <p className="error-select-message">{error}</p>}
    </>
  );
};

export default CustomSelect;
