import React from "react";

const BrakesRotors = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "1pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          Schedule G: Brakes and Rotors
        </h1>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
          This service covers the replacement of brake pads and rotors according
          to the manufacturer's guidelines. The coverage is up to $1,500
          (import), $1,800 (domestic/luxury) per service, with brake assembly
          not included. Over the lifetime of the contract, the maximum coverage
          is up to $2,500 (import) and $3,300 (domestic/luxury). This coverage
          applies to both new and used vehicles.
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <h1
          style={{
            paddingLeft: "6pt",
            textIndent: "0pt",
            lineHeight: "12pt",
            textAlign: "left",
          }}
        >
          Qualifications for Service:{" "}
          <span>To qualify for this service, vehicle owners must:</span>
        </h1>
        <ol id="l1">
          <li>
            <p
              style={{
                paddingLeft: "42pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Provide proof of predelivery inspection ("PDI") for new vehicles.
            </p>
          </li>
          <li>
            <p
              style={{
                paddingLeft: "42pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Ensure mechanical fitness assessment compliance within 36 months /
              60 months.
            </p>
            <p
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              This service is void if these conditions are not met, unless
              required by any other Federal or Provincial laws. The provided
              products may be aftermarket or refurbished.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h1
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Procedure for Service:{" "}
              <span>
                To obtain services from the Extended Trust Program, contact your
                original selling dealer.
              </span>
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h1
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Conditions of Service:{" "}
              <span>You acknowledge and agree that:</span>
            </h1>
            <ul id="l2">
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Some services may require multiple appointments to resolve an
                  imperfection.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  OEM Parts may be included if cost does not exceed 25% of
                  lowest cost aftermarket equivalent part. See Terms and
                  Conditions for details.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  The completion of certain services may require that you go
                  without the use of the vehicle for up to one (1) day.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  It may be impossible to resolve every imperfection, but the
                  Extended Trust Program will fulfill its obligation if best
                  efforts have been made by its technicians.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  As the Extended Trust Program does not include the
                  washing/vacuuming of a vehicle in their detailing services,
                  you must clean/vacuum the area to be serviced before bringing
                  in your vehicle.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <li>
                  <h1
                    style={{
                      paddingLeft: "15pt",
                      textIndent: "-10pt",
                      lineHeight: "12pt",
                      textAlign: "left",
                    }}
                  >
                    Transferring This Contract
                  </h1>
                  <p
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Only the original customer may transfer or assign this
                    contract in the case of a sale to a private party. This
                    contract is specific to the vehicle and cannot be
                    transferred to another vehicle. The term will not be
                    extended by the transfer. The original customer may transfer
                    or assign this contract to a private party by providing the
                    new owner's details to the dealer within 30 days of the
                    sale. Details must include:
                  </p>
                  <ul id="l5">
                    <li>
                      <p
                        style={{
                          paddingLeft: "41pt",
                          textIndent: "-18pt",
                          textAlign: "left",
                        }}
                      >
                        New Owner's First and Last Name;
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          paddingLeft: "41pt",
                          textIndent: "-18pt",
                          textAlign: "left",
                        }}
                      >
                        New Owner's Address, including City, Province, and
                        Postal Code;
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          paddingLeft: "41pt",
                          textIndent: "-18pt",
                          textAlign: "left",
                        }}
                      >
                        New Owner's Phone Number; and
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          paddingLeft: "41pt",
                          textIndent: "-18pt",
                          textAlign: "left",
                        }}
                      >
                        New Owner's Email Address
                      </p>
                    </li>
                  </ul>
                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                  </p>
                </li>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's First and Last Name;
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Address, including City, Province, and Postal
                  Code;
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Phone Number; and
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Email Address
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <li>
          <h1
            style={{
              paddingLeft: "16pt",
              textIndent: "-10pt",
              textAlign: "justify",
            }}
          >
            Cancelling This Contract
          </h1>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            You can cancel this contract within thirty (30) days of the
            Effective Date and receive a 100% refund if no services have been
            performed. If not canceled within 30 days, the full fee is deemed
            earned by Extended Trust Ltd.
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Extended Trust Ltd. may cancel at any time by refunding you a
            pro-rated amount based on the remaining term. The contract
            terminates automatically if the vehicle is written off, repossessed,
            or sold to a third party without contacting Extended Trust Ltd., or
            at the expiry of the term.
          </p>
        </li>
      </div>
    </>
  );
};

export default BrakesRotors;
