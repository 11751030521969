import  React, {useEffect} from "react";
import { Accordion } from "react-bootstrap";
import OfferItem from "./OfferItem/OfferItem";
import { sumOfProgramsPrices } from "../../../../utils/utils";

const Offers = (props) => {
  const {
    index,
    serviceWithCatProgram,
    isPricingVisible,
    serviceTenureOptions,
    serviceTierOptions,
    checkBoxesForTerms,
    checkBoxesForServices,
    handleUnCheckedOtherTerms,
    handleCheckedServicesByTenure,
    vehical,
    structuredData,
    handleInputServicesCatDetails,
  } = props;

  const structureDataForService = () => {
    const obj = structuredData.find(
      (x) => x.categoryId === serviceWithCatProgram._id
    );
    if (obj) {
      return obj;
    }
    return null;
  };

  const findCarTier = () => {
    const tier = serviceTierOptions.find(
      (tier) =>
        tier.match.findIndex((x) => x === vehical?.make?.toUpperCase()) !== -1
    );
    return tier || null;
  };


  return (
    <div key={`${serviceWithCatProgram._id}-${index}`}>
      <div className="headeroffer">
        <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
          <Accordion.Item eventKey="0" className="">
            <Accordion.Header className="accord-Header dropBtn-after">
              <h4 className=" heading-service-type text-black ">
                {serviceWithCatProgram.categoryName}
              </h4>
              {findCarTier() !== null &&
              serviceWithCatProgram.categoryName.includes("Trust Shield") &&
              isPricingVisible &&
              Number(
                sumOfProgramsPrices(structureDataForService()?.programs || [])
              ) > 0 ? (
                <div className="headingbottom">
                  <h1 className="heading-service-type text-black">
                    {`COST: $${sumOfProgramsPrices(
                      structureDataForService()?.programs || []
                    )?.toFixed(2)}`}
                  </h1>
                </div>
              ) : !serviceWithCatProgram.categoryName.includes("Trust Shield") &&
                isPricingVisible &&
                Number(
                  sumOfProgramsPrices(structureDataForService()?.programs || [])
                ) > 0 ? (
                <div className="headingbottom">
                  <h1 className="heading-service-type text-black">
                    {`COST: $${sumOfProgramsPrices(
                      structureDataForService()?.programs || []
                    )?.toFixed(2)}`}
                  </h1>
                </div>
              ) : (
                <></>
              )}
            </Accordion.Header>
            <Accordion.Body style={{ background: "white" }}>
              <>
                {vehical?.make !== "" &&
                vehical?.make !== null &&
                findCarTier() === null &&
                serviceWithCatProgram.categoryName.includes("Trust Shield") ? (
                  <span className="d-flex justify-content-center align-items-center">
                    <strong>
                      This car doesn't qualify for the Trust Shield Service.!
                    </strong>
                  </span>
                ) : (
                  <OfferItem
                    handleInputServicesCatDetails={
                      handleInputServicesCatDetails
                    }
                    vehical={vehical}
                    serviceWithCatProgram={serviceWithCatProgram}
                    isPricingVisible={isPricingVisible}
                    serviceTenureOptions={serviceTenureOptions}
                    serviceTierOptions={serviceTierOptions}
                    checkBoxesForTerms={checkBoxesForTerms}
                    handleUnCheckedOtherTerms={handleUnCheckedOtherTerms}
                    handleCheckedServicesByTenure={
                      handleCheckedServicesByTenure
                    }
                    checkBoxesForServices={checkBoxesForServices}
                  />
                )}
              </>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Offers;
