import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Accordion } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import {
  DEFAULT_TIMEZONE,
  POSTALCODE_PATTERN,
  STATE_OPTIONS,
  TIMEZONES,
  USER_ROLE,
} from "../../../utils/constant";
import CustomButton from "../../common/Button/CustomButton";
import { classNames } from "../../../utils/utils";
import CustomSelect from "../../common/Select/CustomSelect";
import useServiceManager from "../../../Hooks/useServiceManager";
import CustomInput from "../../common/Input/CustomInput";

const AccountDetails = (props) => {
  const {
    inputData,
    setInputData,
    role,
    isCallFrom,
    handleDataUpdateById,
    handleClose,
  } = props;

  const [serviceManagerOptions, setServiceManagerOptions] = useState([]);

  const { isServiceManagerListLoader, serviceManagersData } =
    useServiceManager();

  useEffect(() => {
    setServiceManagerOptions(
      serviceManagersData.map((x) => ({
        ...x,
        label: `${x.firstName} ${x.lastName}`,
        value: x._id,
      }))
    );
  }, [serviceManagersData]);

  const [passwordVisibility1, setPasswordVisibility1] = useState(false);
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    if (typeof cloudinaryRef.current !== "undefined") {
      widgetRef.current = cloudinaryRef.current.createUploadWidget(
        {
          cloudName: "dvhp99frp",
          uploadPreset: "oz0okrve",
        },
        function (error, result) {
          //   console.log("result cloud", result);
          if (result.event === "success") {
            setDealerImageToShow(result.info.secure_url);
            setInputData((prev) => ({
              ...prev,
              profileImage: result.info.secure_url,
            }));
          }
        }
      );
    }
  }, []);

  const isNonEditable = () => {
    if (role && role === USER_ROLE.DEALER) return true;
    return false;
  };

  const [dealerImageToShow, setDealerImageToShow] = useState(
    inputData?.profileImage !== "" ? inputData.profileImage : ""
  );
  const [dealerUpdateCounter, setDealerUpdateCounter] = useState(0);
  const [isSubmitDataLoader, setIsSubmitDataLoader] = useState(false);

  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    postalCode: "",
    address: "",
    city: "",
    phone: "",
    state: "",
    vin: "",
    make: "",
    modal: "",
    purchaseDate: "",
    type: "",
    year: "",
    timezone: "",
  });

  const ValidationForm = () => {
    let errorObj = {
      firstName: false,
      lastName: false,
      email: false,
      postalCode: false,
      address: false,
      city: false,
      phone: false,
      state: false,
      service_manager_id: false,
      name: false,
    };

    // console.log("inputData", inputData);

    if (inputData.firstName === "" || inputData.firstName === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        firstName: true,
      }));
      errorObj.firstName = true;
    }
    if (inputData.lastName === "" || inputData.lastName === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        lastname: true,
      }));
      errorObj.lastName = true;
    }
    if (inputData.name === "" || inputData.name === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        name: true,
      }));
      errorObj.name = true;
    }
    if (inputData.email === "" || inputData.email === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        email: true,
      }));
      errorObj.email = true;
    }
    if (inputData.address === "" || inputData.address === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        address: true,
      }));
      errorObj.address = true;
    }
    if (inputData.city === "" || inputData.city === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        city: true,
      }));
      errorObj.city = true;
    }
    if (inputData.postalCode === "" || inputData.postalCode === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        postalCode: true,
      }));
      errorObj.postalCode = true;
    }
    if (
      inputData.phone?.trim() === "+1" ||
      inputData.phone === "" ||
      inputData.phone === undefined
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        phone: true,
      }));
      errorObj.phone = true;
    }
    if (
      inputData.postalCode?.length > 0 &&
      !POSTALCODE_PATTERN.test(inputData.postalCode?.trim())
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        postalCode: true,
      }));
      errorObj.postalCode = true;
      setIsValidPostalCode(false);
    }
    if (inputData.state === "" || inputData.state === undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        state: true,
      }));
      errorObj.state = true;
    }
    if (
      inputData.service_manager_id === null ||
      inputData.service_manager_id === "" ||
      inputData.service_manager_id === undefined
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        service_manager_id: true,
      }));
      errorObj.service_manager_id = true;
    }

    return errorObj;
  };

  useEffect(() => {
    if (role === USER_ROLE.DEALER) {
      getDealerUpdatedData();
    }
  }, [dealerUpdateCounter]);

  const getDealerUpdatedData = () => {
    fetch(`${apiUrl}/dealer/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        setInputData({
          email: data.contactEmail,
          address: data.address,
          city: data.city,
          name: data.name,
          phone: data.phone,
          postalCode: data.postalCode,
          firstName: data.firstName,
          lastName: data.lastName,
          region: data.region,
          state: data.state,
          faxNumber: data.faxNumber,
          timezone: data.timezone ? data.timezone : DEFAULT_TIMEZONE,
          service_manager_id: data.serviceManager?.hasOwnProperty("_id")
            ? data.serviceManager?._id
            : data.serviceManager,
        });

        setDealerImageToShow(data.profileImage);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const submitDealerBasicInfo = async (event) => {
    try {
      event.preventDefault();
      if (isNonEditable() && role === USER_ROLE.DEALER) {
        changePasswordApi();
        return;
      }
      const valid = ValidationForm();
      if (
        valid.firstName ||
        valid.lastName ||
        valid.email ||
        valid.address ||
        valid.city ||
        valid.postalCode ||
        valid.state ||
        valid.phone ||
        valid.faxNumber ||
        //   valid.password ||
        //   valid.newPassword ||
        valid.name ||
        valid.profileImage ||
        valid.state ||
        // valid.timezone ||
        valid.service_manager_id
      ) {
        console.log("Form has validation errors", validationErrors);
      } else {
        const payload = {
          address: inputData.address,
          city: inputData.city,
          name: inputData.name,
          phone: inputData.phone,
          firstName: inputData.firstName,
          lastName: inputData.lastName,
          postalCode: inputData.postalCode,
          region: inputData.region,
          state: inputData.state,
          timezone: inputData.timezone,
          faxNumber: inputData.faxNumber,
          service_manager_id: inputData.service_manager_id,
          // password: inputData.password,
        };

        if (role === USER_ROLE.ADMIN && inputData.id) {
          Object.assign(payload, { id: inputData.id });
        }

        if (
          inputData.profileImage !== "" &&
          inputData.profileImage !== undefined
        ) {
          Object.assign(payload, { profileImage: inputData.profileImage });
        }

        setIsSubmitDataLoader(true);

        const ApiUrl =
          role === USER_ROLE.DEALER
            ? `${apiUrl}/dealer/update-account-details`
            : `${apiUrl}/admin/update-dealer`;
        // console.log("accessToken", accessToken);
        const response = await axios.post(
          ApiUrl,
          { ...payload },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // console.log("response.data", response);
        if (response) {
          setIsSubmitDataLoader(true);
          toast.success("Changes Saved Sucessfully");
          if (
            response.data.hasOwnProperty("address") &&
            role === USER_ROLE.DEALER
          ) {
            setDealerUpdateCounter(dealerUpdateCounter + 1);
          }
          if (role === USER_ROLE.ADMIN) {
            handleDataUpdateById({
              ...inputData,
              service_manager_id: inputData.service_manager_id,
            });
            handleClose();
            return;
          }
        }
      }
    } catch (err) {
      toast.error("Failed to save changes. Please try again.");
      setIsSubmitDataLoader(false);
      console.error("Error:", err);
    }
  };

  const [isPasswordLoader, setIsPasswordLoader] = useState(false);

  const [passwordErrors, setPasswordErrors] = useState({
    password: "",
    newPassword: "",
  });

  const validatorForPassword = () => {
    let errorObj = {
      password: false,
      newPassword: false,
    };

    if (
      inputData.hasOwnProperty("password") &&
      (inputData?.password === "" || inputData.password === undefined)
    ) {
      setPasswordErrors((prev) => ({
        ...prev,
        password: "Password is required!",
      }));
      errorObj.password = true;
    } else {
      setPasswordErrors((prev) => ({
        ...prev,
        password: "",
      }));
      errorObj.password = false;
    }
    if (
      inputData.hasOwnProperty("newPassword") &&
      (inputData?.newPassword === "" || inputData?.newPassword === undefined)
    ) {
      setPasswordErrors((prev) => ({
        ...prev,
        newPassword: "New Password is required!",
      }));
      errorObj.newPassword = true;
    } else {
      setPasswordErrors((prev) => ({
        ...prev,
        newPassword: "",
      }));
      errorObj.newPassword = false;
    }

    if (
      inputData?.password?.length > 0 &&
      inputData?.newPassword?.length > 0 &&
      inputData?.password !== inputData?.newPassword
    ) {
      setPasswordErrors((prev) => ({
        ...prev,
        newPassword: "Password do no match!",
      }));
      errorObj.newPassword = true;
    } else {
      setPasswordErrors((prev) => ({
        ...prev,
        newPassword: "",
      }));
      errorObj.newPassword = false;
    }

    return errorObj;
  };

  const changePasswordApi = async () => {
    try {
      const valid = validatorForPassword();
      if (valid.password || valid.newPassword) {
        return;
      }
      if (!inputData.password || inputData.password === "") return;
      setIsPasswordLoader(true);
      const updateDealerPasswordUrl = `${apiUrl}/dealer/change-password`;
      const response = await axios.post(
        updateDealerPasswordUrl,
        { password: inputData.password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        toast.success(
          response.data?.message || "Password Updated Successfully!"
        );

        setInputData((prev) => ({
          ...prev,
          password: "",
          newPassword: "",
        }));
        setPasswordErrors({
          password: "",
          newPassword: "",
        });
      }

      setIsPasswordLoader(false);
    } catch (err) {
      console.log("err", err);
      setIsPasswordLoader(false);
    }
  };

  const [isValidPostalCode, setIsValidPostalCode] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "postalCode") {
      setIsValidPostalCode(POSTALCODE_PATTERN.test(value));
      setValidationErrors((prev) => ({
        ...prev,
        [name]: !POSTALCODE_PATTERN.test(value),
      }));
      return;
    }

    setValidationErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;

  function initAutocomplete() {
    // console.log("test");
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");

    // Check if the fields are found before proceeding
    if (address1Field) {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      address1Field.focus();

      autocomplete.addListener("place_changed", fillInAddress);
    } else {
      console.error("One or more required fields not found in the DOM.");
    }
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    const hasPostalCode = (component) => {
      return component?.types?.includes("postal_code");
    };
    const hasPostalCodeObject = place.address_components?.some(hasPostalCode);
    if (hasPostalCodeObject) {
    } else {
      setInputData((prev) => ({
        ...prev,
        postalCode: "",
      }));
    }
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number":
          address1 = `${component.long_name} ${address1}`;
          // console.log(address1);
          break;
        case "route":
          address1 += ` ${component.short_name}`;
          // console.log(address1);
          setInputData((prev) => ({
            ...prev,
            address: address1,
          }));
          break;
        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          setInputData((prev) => ({
            ...prev,
            postalCode: postcode?.trim(),
          }));
          setIsValidPostalCode(POSTALCODE_PATTERN.test(postcode?.trim()));
          break;
        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;
        case "locality":
          const localityElement = document.querySelector("#locality");
          if (localityElement) {
            localityElement.value = component.long_name;
            setInputData((prev) => ({
              ...prev,
              city: localityElement.value,
            }));
          }
          break;
        case "administrative_area_level_1":
          const stateElement = document.querySelector("#state");
          if (stateElement) {
            stateElement.value = component.short_name;
            setInputData((prev) => ({
              ...prev,
              state:
                STATE_OPTIONS.findIndex(
                  (op) =>
                    op.value.toLowerCase() ===
                    component.long_name?.toLowerCase()
                ) !== -1
                  ? component.long_name
                  : "",
            }));
            // console.log(component.long_name, " component.long_name");
          }
          break;
        case "country":
          const countryElement = document.querySelector("#country");
          if (countryElement) {
            countryElement.value = component.long_name;
          }
          break;
        default:
          break;
      }
    }
    if (address1Field) {
      address1Field.value = address1;
    }
    if (postalField) {
      postalField.value = postcode;
    }
    if (address2Field) {
      address2Field.focus();
    }
  }

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  return (
    <div>
      {/* <div className="tab-content">
      </div> */}

      {/* <div className="tab-pane" id="dealer"> */}
      <div className="ltn__myaccount-tab-content-inner">
        <div className="ltn__form-box">
          <form action="submit" onSubmit={submitDealerBasicInfo}>
            <div
              className={classNames(
                "row mb-50",
                isCallFrom && isCallFrom === "dealerList" ? "pt-0" : "pt-5"
              )}
            >
              <h3 className="pb-4">Basic Information</h3>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accord-Header dropBtn-after">
                    ACCOUNT DETAILS
                  </Accordion.Header>
                  <Accordion.Body className="ac-Body mt-3 px-sm-none">
                    <div className="row">
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.firstName ?? "-"}
                          label="First Name"
                          labelPosition="up"
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          name="firstName"
                          type="text"
                          required={true}
                          isRedStar={true}
                          error={
                            validationErrors.firstName &&
                            "First Name is required."
                          }
                          isDisabled={isNonEditable()}
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.lastName ?? "-"}
                          label="Last Name"
                          labelPosition="up"
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          name="lastName"
                          type="text"
                          required={true}
                          isRedStar={true}
                          error={
                            validationErrors.lastName &&
                            "Last Name is required."
                          }
                          isDisabled={isNonEditable()}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Email Address:
                          <span className="required-class">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={inputData?.email ? inputData?.email : ""}
                          disabled={isNonEditable() || role === USER_ROLE.ADMIN}
                          onChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          required
                          //   disabled
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="accord-Header dropBtn-after">
                    DEALER DETAILS
                  </Accordion.Header>
                  <Accordion.Body className="ac-Body mt-3">
                    <div className="row">
                      {/* Email */}
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.email ? inputData.email : ""}
                          label="Email Address"
                          labelPosition="up"
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          name="email"
                          type="email"
                          required={true}
                          isRedStar={true}
                          error={validationErrors.email && "Email is required."}
                          isDisabled={
                            isNonEditable() || role === USER_ROLE.ADMIN
                          }
                        />
                      </div>
                      {/*Name */}
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.name ? inputData.name : ""}
                          label="Dealership Name"
                          labelPosition="up"
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          name="name"
                          type="text"
                          required={true}
                          isRedStar={true}
                          error={
                            validationErrors.name &&
                            "Dealership Name is required."
                          }
                          isDisabled={isNonEditable()}
                        />
                      </div>
                      {/* Phone */}
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.phone}
                          label="Phone"
                          labelPosition="up"
                          handleChange={(event) => {
                            if (isNonEditable()) return;
                            const { name, value } = event.target;

                            const formattedValue = value
                              ?.trim()
                              ?.startsWith("+1")
                              ? value
                              : `+1 ${value === "+" ? "" : value}`;

                            // If the input field is for phone, validate the phone number
                            if (name === "phone") {
                              // handlePhoneNumberChange(formattedValue);

                              // Additional pattern test for Canadian phone number (for example purposes)
                              const phonePattern =
                                /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                              const isValidPhoneNumber =
                                phonePattern.test(formattedValue);

                              // Update the state for phone number validation
                              setIsValidPhoneNumber(isValidPhoneNumber);
                            }

                            setInputData((prev) => ({
                              ...prev,
                              [name]: formattedValue,
                            }));

                            setValidationErrors((prev) => ({
                              ...prev,
                              phone: false,
                            }));
                          }}
                          name="phone"
                          type="tel"
                          required={true}
                          isRedStar={true}
                          maxLength={13}
                          error={
                            validationErrors?.phone &&
                            "Phone number is required."
                          }
                          isDisabled={isNonEditable()}
                        />
                      </div>
                      {/* Address */}
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.address ? inputData.address : ""}
                          label="Address"
                          labelPosition="up"
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                            initAutocomplete();
                          }}
                          name="address"
                          type="text"
                          required={true}
                          isRedStar={true}
                          error={
                            validationErrors.address && "Address is required."
                          }
                          id="ship-address"
                          isDisabled={isNonEditable()}
                        />
                      </div>
                      {/* State */}
                      <div className="col-md-4">
                        <CustomInput
                          value={inputData.city}
                          label="City"
                          labelPosition="up"
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          name="city"
                          type="text"
                          required={true}
                          isRedStar={true}
                          error={validationErrors.city && "City is required."}
                          id="locality"
                          isDisabled={isNonEditable()}
                        />
                      </div>
                      {/* PostalCode */}
                      <div className="col-md-4 ">
                        <CustomInput
                          value={inputData?.postalCode?.toUpperCase()}
                          isDisabled={isNonEditable()}
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          label="Postal Code"
                          labelPosition="up"
                          name="postalCode"
                          type="text"
                          required={true}
                          isRedStar={true}
                          error={
                            validationErrors.postalCode &&
                            POSTALCODE_PATTERN.test(
                              inputData.postalCode?.trim()
                            ) === false ? (
                              <p
                                className=""
                                style={{
                                  marginTop: "-20px",
                                  color:
                                    POSTALCODE_PATTERN.test(
                                      inputData.postalCode?.trim()
                                    ) === false && "red",
                                }}
                              >
                                {validationErrors.postalCode &&
                                (inputData.postalCode === "" ||
                                  inputData.postalCode === null ||
                                  inputData.postalCode === undefined)
                                  ? "PostalCode is required."
                                  : POSTALCODE_PATTERN.test(
                                      inputData.postalCode?.trim()
                                    ) === false &&
                                    "Invalid input: please use a 6 characters sequence following the pattern A1A1A1"}
                              </p>
                            ) : (
                              ""
                            )
                          }
                          id="postcode"
                          maxLength={6}
                        />
                        {POSTALCODE_PATTERN.test(
                          inputData.postalCode?.trim()
                        ) === true && (
                          <p
                            style={{
                              // marginTop: "-20px",
                              color: "green",
                            }}
                          >
                            {POSTALCODE_PATTERN.test(
                              inputData.postalCode?.trim()
                            ) === true && "Valid"}
                          </p>
                        )}
                      </div>
                      {/* Province */}
                      <div className="col-md-4">
                        <label>
                          Province
                          <span className="required-class">*</span>
                        </label>
                        <div className="input-item">
                          <select
                            className="nice-select"
                            id="state"
                            value={inputData?.state ? inputData?.state : ""}
                            name="state"
                            isDisabled={isNonEditable()}
                            onChange={(e) => {
                              if (isNonEditable()) return;
                              setInputData((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                            required
                          >
                            <option value="" disabled selected>
                              Select Province
                            </option>
                            {STATE_OPTIONS.map((op, index) => (
                              <option key={index} value={op.value}>
                                {op.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* Region */}
                      <div className="col-md-4 ">
                        <label>
                          Region
                          {/* <span className="required-class">*</span> */}
                        </label>
                        <input
                          className="text no-spin-buttons"
                          type="text"
                          id="region"
                          name="region"
                          value={inputData?.region ?? ""}
                          disabled={isNonEditable()}
                          onChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          // required
                        />
                      </div>

                      {/* FaxNumber */}
                      <div className="col-md-4">
                        <label>
                          Fax Number
                          {/* <span className="required-class">*</span> */}
                        </label>
                        <input
                          className="text no-spin-buttons"
                          type="text"
                          name="faxNumber"
                          value={inputData?.faxNumber}
                          // value={
                          //   inputData.dealerFaxNumber
                          //     ? inputData.dealerFaxNumber
                          //     : ""
                          // }
                          disabled={isNonEditable()}
                          onChange={(e) => {
                            if (isNonEditable()) return;
                            handleInputChange(e);
                          }}
                          required={false}
                        />
                      </div>

                      <div className="col-md-6">
                        <label>
                          ET Manager
                          <span className="required-class">*</span>
                        </label>
                        <CustomSelect
                          loading={isServiceManagerListLoader}
                          isDisabled={
                            isServiceManagerListLoader || isNonEditable()
                          }
                          isSearch={true}
                          placeHolder={"Select ET Manager"}
                          selectedValue={serviceManagerOptions.find(
                            (x) => x.value === inputData.service_manager_id
                          )}
                          handleChange={(e) => {
                            if (isNonEditable()) return;
                            const data = {
                              name: "service_manager_id",
                              value: e.value ? e.value : null,
                            };
                            handleInputChange({ target: data });
                          }}
                          options={serviceManagerOptions}
                        />
                        {validationErrors.service_manager_id && (
                          <p
                            className="error-message"
                            style={{ marginTop: "10px" }}
                          >
                            ET Manager is required.
                          </p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label>
                          Timezone
                          {/* <span className="required-class">*</span> */}
                        </label>
                        <div className="input-item">
                          <select
                            disabled={isNonEditable()}
                            className="nice-select"
                            id="timezone"
                            value={inputData.timezone ? inputData.timezone : ""}
                            name="timezone"
                            onChange={(e) => {
                              if (isNonEditable()) return;
                              setInputData((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                            required={false}
                          >
                            <option value="" selected disabled>
                              Select Timezone
                            </option>
                            {TIMEZONES.map((item, index) => (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {role === USER_ROLE.DEALER && (
                <Accordion defaultActiveKey="2">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="accord-Header dropBtn-after">
                      CHANGE PASSWORD
                    </Accordion.Header>
                    <Accordion.Body className="ac-Body">
                      <div className="row mt-3">
                        {/* {inputData.password !== inputData.newPassword ? (
                                            toast.error("Passwords do not match")
                                        ) : <></>} */}
                        <div
                          className={classNames(
                            "mainpass",
                            isCallFrom && isCallFrom === "dealerList"
                              ? "col-md-6"
                              : "col-md-4"
                          )}
                        >
                          <label>Password</label>
                          <input
                            type={passwordVisibility1 ? "text" : "password"}
                            name="password"
                            value={inputData.password}
                            onChange={(e) => {
                              setInputData((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                            placeholder="Enter your new password"
                            style={{ background: "white" }}
                            required
                          />
                          {passwordErrors.password !== "" && (
                            <p className="error-message">
                              {/* Passwords do not match */}
                              {passwordErrors.password}
                            </p>
                          )}

                          <i
                            className={
                              passwordVisibility1
                                ? "fas fa-eye eye-icon eyeiconn"
                                : "fas fa-eye-slash eye-icon eyeiconn"
                            }
                            style={{
                              color: passwordVisibility1 ? "black" : "black",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setPasswordVisibility1(!passwordVisibility1)
                            }
                          ></i>
                        </div>
                        <div
                          className={classNames(
                            "mainpass",
                            isCallFrom && isCallFrom === "dealerList"
                              ? "col-md-6"
                              : "col-md-4"
                          )}
                        >
                          <label>Re-Enter Password</label>
                          <input
                            type={passwordVisibility1 ? "text" : "password"}
                            name="newPassword"
                            value={inputData.newPassword}
                            onChange={(e) => {
                              setInputData((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                            placeholder="Enter your new password"
                            style={{ background: "white" }}
                            required
                          />
                          {passwordErrors.newPassword !== "" && (
                            <p className="error-message">
                              {/* Passwords do not match */}
                              {passwordErrors.newPassword}
                            </p>
                          )}
                          <i
                            className={
                              passwordVisibility1
                                ? "fas fa-eye eye-icon eyeiconn"
                                : "fas fa-eye-slash eye-icon eyeiconn"
                            }
                            style={{
                              color: passwordVisibility1 ? "black" : "black",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setPasswordVisibility1(!passwordVisibility1)
                            }
                          ></i>
                        </div>
                      </div>
                      {role === USER_ROLE.DEALER && (
                        <div className="w-100 btn-wrapper d-flex justify-content-end mt-0">
                          <CustomButton
                            showLoading={isPasswordLoader}
                            isDisabled={isPasswordLoader}
                            style={{ backgroundColor: "#000000" }}
                            cssClass="btn-effect-1 text-uppercase btn1"
                            label="Update Password"
                            type="submit"
                          />
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}

              {/* <Accordion defaultActiveKey="3">
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="accord-Header dropBtn-after">
                    PROFILE IMAGE
                  </Accordion.Header>
                  <Accordion.Body className="ac-Body">
                    <div
                      className="col-md-12 d-flex align-items-center"
                      style={{ gap: "50px" }}
                    >
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                        }}
                        src={
                          dealerImageToShow === ""
                            ? "/assets/img/avatar.png"
                            : dealerImageToShow
                        }
                        alt=""
                        class="img-thumbnail"
                        // value={inputData.profileImage}
                      />
                      {!isNonEditable() && (
                        <div className="btn-wrapper">
                          <button
                            type="button"
                            onClick={() => widgetRef.current.open()}
                            className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                          >
                            Upload
                          </button>
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </div>

            {!isNonEditable() && (
              <div className="btn-wrapper px-4">
                <CustomButton
                  style={{ backgroundColor: "#000000" }}
                  type="submit"
                  cssClass="btn-effect-1 text-uppercase btn1"
                  label={"Save Changes"}
                  showLoading={isSubmitDataLoader}
                  isDisabled={isSubmitDataLoader || isServiceManagerListLoader}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
