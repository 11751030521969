import React, { useEffect, useState } from "react";
import { REPAIR_ATTACHMENT_CATEGORIES } from "../../../../utils/constant";
import axios from "axios";
import { BASE_URL } from "../../../../service/URL";
import {
  capitalizeFirstLetter,
  classNames,
  dateFormatDDMMYYYY,
  formatDateToDDMMMYYYY,
  getRoleApi,
} from "../../../../utils/utils";
import secureLocalStorage from "react-secure-storage";
import Loader from "../../../common/Loader/Loader";

const PrintClaim = ({ downloadRef, claim, getNoteTtile, handlePrint }) => {
  const [isDataFetch, setIsDataFetch] = useState(null);

  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [allData, setAllData] = useState(null);

  useEffect(() => {
    if (allData !== null) {
      handlePrint();
      setAllData(null);
    }
  }, [allData]);

  const handleFetchAttachments = () =>
    axios
      .get(`${BASE_URL}/${getRoleApi(role)}/repair-attachment/${claim._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) =>
        response.data?.filter((x) => x.fileType?.includes("image"))
      )
      .catch((err) => {
        console.log("err", err);
        return [];
      });

  const handleFetchComments = () =>
    axios
      .get(
        `${BASE_URL}/${getRoleApi(role)}/repair-comment/${
          claim?._id
        }?isAll=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        return response.data?.comments || [];
      })
      .catch((err) => {
        console.log("err", err);
        return [];
      });

  const getClaimCommentAndAttachments = () => {
    setIsDataFetch(claim._id);
    Promise.all([handleFetchAttachments(), handleFetchComments()])
      .then(([attachmentsData, commentsData]) => {
        setAllData({ attachments: attachmentsData, comments: commentsData });
        setIsDataFetch(null);
      })
      .catch((error) => {
        setIsDataFetch(null);
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <button
        className={classNames(
          "PrintButton d-flex justify-content-center align-items-center",
          claim._id === isDataFetch && "button-disabled"
        )}
        onClick={() => {
          getClaimCommentAndAttachments();
        }}
      >
        Print
        {claim._id === isDataFetch && (
          <Loader cssClass={"text-white"} isSmallView={true} />
        )}
      </button>
      <section className="printpdf-sec" ref={downloadRef}>
        {claim && (
          <div>
            <div className="print-req-heading">
              <h2>{`Agreement No# ${claim.agreementNumber}`}</h2>
            </div>
            <div className="printReq">
              <p className="printname">
                <span className="spantextn">{`Name: `}</span> {claim.name}
              </p>
              <p className="printname">
                <span className="spantextn">{`Email: `}</span> {claim.email}
              </p>
              <p className="printname">
                <span className="spantextn">{`Phone Number: `}</span>
                {claim.phoneNumber}
              </p>
              <p className="printname">
                <span className="spantextn">{`Agreement Number: `}</span>
                {claim.agreementNumber}
              </p>
              <p className="printname">
                <span className="spantextn">{`Odometer Reading: `}</span>
                {claim.odometerReading}
              </p>

              <p className="ReportprintDescription">
                <span className="spantextn">{`Report Description: `}</span>
                {claim.reportDescription}
              </p>
              {claim.status && claim.note?.length > 0 && (
                <p className="ReportprintDescription">
                  <span className="spantext">
                    {`${getNoteTtile(claim.status)} Notes: `}
                  </span>
                  {claim.note}
                </p>
              )}
              <p className="ReportprintDescription">
                <span className="spantextn">{`Claim Date: `}</span>
                {formatDateToDDMMMYYYY(claim.createdAt)}
              </p>
              <div className="py-4">
                <h3>Comments</h3>
                {allData?.comments?.length > 0 ? (
                  allData?.comments?.map((item, index) => (
                    <div
                      style={{
                        borderRadius: "5px",
                        border: "1px solid white",
                        padding: "10px 10px 10px 10px",
                        marginTop: "0.5rem",
                      }}
                    >
                      <div
                        key={index}
                        className="d-flex justify-content-between"
                      >
                        <span>{capitalizeFirstLetter(item.text)}</span>
                        <span style={{ fontSize: "11px" }}>
                          {dateFormatDDMMYYYY(item.createdAt)}
                        </span>
                      </div>
                      <div
                        style={{ fontSize: "10px" }}
                        className="text-start pt-1"
                      >
                        <span>
                          {`Created By: ${
                            capitalizeFirstLetter(
                              `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                            )?.trim()?.length <= 0
                              ? item.createdBy.details?.email
                              : capitalizeFirstLetter(
                                  `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                                )
                          }`}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <span className="error-text">No Comment Found!</span>
                  </div>
                )}
              </div>
              <div className="py-4">
                <h3>Attachments</h3>
                <div className="row">
                  {allData?.attachments?.length > 0 ? (
                    allData?.attachments.map((item, index) => (
                      <div
                        key={index}
                        className="col-4 space-y-2"
                        style={{ columnGap: "0.25rem", borderRadius: "10px" }}
                      >
                        <div className="repair-attachment-container shadow-sm ">
                          <div className="d-flex justify-content-center">
                            {item.fileType.includes("image") && (
                              <img
                                src={item.url}
                                alt={item.originalName}
                                class="repair-attachment"
                              />
                            )}
                          </div>
                          <div
                            className="d-flex justify-content-between pb-1 pt-3 align-items-center"
                            style={{ fontSize: "11px" }}
                          >
                            <div>
                              <span
                                style={{ fontSize: "11px" }}
                                title={capitalizeFirstLetter(
                                  `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                                )}
                              >
                                Created By:
                                {item.createdBy.role !== "customer"
                                  ? ` ${
                                      capitalizeFirstLetter(
                                        `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                                      )?.trim()?.length <= 0
                                        ? item.createdBy.details?.email
                                        : capitalizeFirstLetter(
                                            `${item.createdBy.details?.firstName} ${item.createdBy.details?.lastName}`
                                          )
                                    }`
                                  : ` Customer`}
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "11px" }}
                                title={
                                  REPAIR_ATTACHMENT_CATEGORIES.find(
                                    (x) => x.value === item?.categoryName
                                  )?.label || ""
                                }
                              >
                                Category:
                                {` ${
                                  REPAIR_ATTACHMENT_CATEGORIES.find(
                                    (x) => x.value === item?.categoryName
                                  )?.label || ""
                                }`}
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "11px" }}
                              >{`Date: ${dateFormatDDMMYYYY(
                                item.createdAt
                              )}`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center col-12"
                      style={{ height: "100%" }}
                    >
                      <span className="error-text">No Attachment Found!</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default PrintClaim;
