import React, { useEffect, useState } from "react";
import axios from "axios";
import { OffersTable } from "./OffersTable";
import secureLocalStorage from "react-secure-storage";

const AddServices = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  const [selectedManagerId, setSelectedManagerId] = useState(0);
  const [uniqueServices, setUniqueServices] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [checkedServices, setCheckedServices] = useState([]);
  const [offerName, setOfferName] = useState("");
  const [price, setPrice] = useState("");
  const [counter, setCounter] = useState(0);
  const [counter1, setcounter1] = useState(0);
  const [counter2, setcounter2] = useState(0);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);
  useEffect(() => {
    const managerProfileUrl = `${apiUrl}/manager/profile`;

    axios
      .get(managerProfileUrl, { headers })
      .then((response) => {
        console.log("Response:", response.data._id);
        setSelectedManagerId(response.data._id);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }, []);

  useEffect(() => {
    if (selectedManagerId !== 0) {
      const getServicesEndpoint = `${apiUrl}/manager/services/${selectedManagerId}`;
      axios
        .get(getServicesEndpoint, { headers })
        .then((response) => {
          if (response.data.length > 0) {
            setManagerServices(response.data);
            setUniqueServices(
              Array.from(new Set(response.data.map((item) => item.serviceType)))
            );
            setcounter1(counter1 + 1);
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    }
  }, [selectedManagerId, counter]);

  useEffect(() => {
    console.log("filter");
    if (selectedServiceType !== null) {
      setFilteredServices(
        managerServices.filter(
          (item) => item.serviceType === selectedServiceType
        )
      );
      setCheckedServices([]);
    }
  }, [selectedServiceType, counter1]);

  const [serviceData, setServiceData] = useState({
    serviceType: "",
    serviceName: "",
    price: "",
  });

  const [filteredServices, setFilteredServices] = useState([]);

  const [managerServices, setManagerServices] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(event.target.value);
    seterror1(false);
    setServiceData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddServices = (event) => {
    event.preventDefault();

    if (serviceData.serviceType === "") {
      seterror1(true);
    }

    const accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

    if (serviceData.serviceType !== "") {
      fetch(`${apiUrl}/manager/service/${selectedManagerId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          serviceType: serviceData.serviceType,
          serviceName: serviceData.serviceName,
          price: serviceData.price,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setCounter(counter + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleServicesInOffers = (event) => {
    seterror2(false);
    if (event.target.checked) {
      setCheckedServices([...checkedServices, event.target.value]);
      console.log(checkedServices);
    }
    if (!event.target.checked) {
      const updatedCheckedServices = checkedServices.filter(
        (serviceId) => serviceId !== event.target.value
      );

      setCheckedServices(updatedCheckedServices);
    }
  };

  const createOffers = (event) => {
    event.preventDefault();
    if (checkedServices.length < 1) {
      seterror2(true);
    }

    if (checkedServices.length !== 0) {
      fetch(`${apiUrl}/manager/add-offer/${selectedManagerId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          serviceType: selectedServiceType,
          offerName,
          price,
          services: checkedServices,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setcounter2(counter2 + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <form action="submit" onSubmit={handleAddServices}>
        <div className="row py-4 px-3 mb-50" style={{ marginBottom: "0" }}>
          <h2 className="mb-3">Add Services</h2>

          <div className="col-md-4">
            <label>Service Type</label>
            <div className="input-item">
              <select
                className="nice-select"
                onChange={handleInputChange}
                value={serviceData.serviceType}
                name="serviceType"
                required
              >
                <option value="">Please Select A Service</option>
                <option>Oil Changes For Life</option>
                <option>Trust Care</option>
                <option>Trust Shield</option>
                <option>Tire & Rim</option>
                <option>Key Fob</option>
                <option>Brakes</option>
                <option>Appearance Trust</option>
                <option>Anti Theft</option>
                <option>Essential</option>
                <option>Premium</option>
                <option>Ultimate</option>
                <option>Maint Protection Plan</option>
                <option>Appearance Protection Plan</option>
              </select>
            </div>
          </div>

          <div className="col-md-4">
            <label>Service Name</label>
            <input
              type="text"
              value={serviceData.serviceName}
              name="serviceName"
              onChange={handleInputChange}
            // required
            />
          </div>

          <div className="col-md-4 ">
            <label>Price</label>
            <input
              type="text"
              className="text no-spin-buttons"
              // type="number"
              name="price"
              value={serviceData.price}
              onChange={handleInputChange}
            // required
            />
          </div>

          <div className="col-md-4 " id="addservices">
            <button
              className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
              style={{
                // marginTop: "1.7em",
                padding: "15px 30px",
                backgroundColor: "rgb(255, 90, 60)",
                color: "#FFF",
                fontSize: "16px",
              }}
            >
              Submit
            </button>
          </div>
          {error1 && (
            <p style={{ color: "red" }}>You need to select a service type.</p>
          )}
        </div>
      </form>
      <form action="submit" className="px-3" onSubmit={createOffers}>
        <div className="">
          <h2 className="px-3 pb-3">Add Offers</h2>
        </div>

        <div className="row mb-50">
          <div>
            <div className="input-item Add-Offers-drop-div">
              <select
                className="nice-select"
                onChange={(e) => setSelectedServiceType(e.target.value)}
              >
                <option value={null}>Select Offer Type</option>
                {uniqueServices.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div className="mobileTable">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Add</th>
                  </tr>
                </thead>
                <tbody className="table-scroll">
                  {filteredServices.map((service, index) => (
                    <tr key={index}>
                      <td>{service.serviceName}</td>
                      <td>{service.price}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={checkedServices.includes(service._id)}
                          value={service._id}
                          onClick={handleServicesInOffers}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div>

          <div className="col-md-4">
            <label>Offer Name</label>
            <input
              style={{ marginBottom: "0px" }}
              type="text"
              value={offerName}
              name="offers"
              onChange={(event) => setOfferName(event.target.value)}
              required
            />
          </div>
          <div className="col-md-4">
            <label>Price</label>
            <input
              style={{ marginBottom: "0px" }}
              type="text"
              value={price}
              name="offerPrice"
              onChange={(event) => setPrice(event.target.value)}
              required
            />
          </div>

          <div className="col-md-4 pt-3" id="addservices">
            <button
              className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
              style={{
                marginTop: "0.8em",
                padding: "15px 30px",
                backgroundColor: "rgb(255, 90, 60)",
                color: "#FFF",
                fontSize: "16px",
              }}
            >
              Submit
            </button>
          </div>
          {error2 && (
            <p style={{ color: "red" }}>you need to select atleast one offer</p>
          )}
        </div>
      </form>
      <OffersTable selectedManagerId={selectedManagerId} counter2={counter2} />
    </>
  );
};

export default AddServices;
