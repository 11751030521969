import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useReactToPrint } from "react-to-print";
import {
  capitalizeFirstLetter,
  convertDateByTimezone,
} from "../../../../utils/utils";
import CustomButton from "../../../common/Button/CustomButton";
import { USER_ROLE } from "../../../../utils/constant";
import useDealer from "../../../../Hooks/useDealer";
import {
  URL_ADMIN_ORDER_PAID_STATUS_CHANGE,
  URL_SUPER_ADMIN_REVERT_ORDER,
} from "../../../../service/URL";
import { apiPatchRequest, apiPostRequest } from "../../../../helpers/Requests";
import parseError from "../../../../utils/ErrorParse";
import ConfirmationModal from "../../../common/Modal/ConfirmationModal";
import Loader from "../../../common/Loader/Loader";
import CsvGenerator from "../../../csvGenerator/CsvGenerator";
import "../report.css";
import AuthContext from "../../../../helpers/authContext";

export function convertDate(inputDate) {
  const [month, year] = inputDate.split(" ");
  const monthIndex = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].indexOf(month);

  const dateObject = new Date(year, monthIndex);

  const formattedDate = dateObject.toISOString();

  return formattedDate;
}

const OrderReports = ({ role }) => {
  const { timezone } = useContext(AuthContext);

  const [showAccordion, setShowAccordion] = useState(false);
  var apiUrl = process.env.REACT_APP_API_URL;
  var token = JSON.parse(secureLocalStorage.getItem("Token"));

  const [isFetchLoader, setIsFetchLoader] = useState(false);

  const [ordersData, setOrdersData] = useState([]);

  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);
  const { isDealerListLoader, dealersData = [] } = useDealer();

  useEffect(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        ...x,
        label:
          // x?.firstName && x.firstName?.length > 0
          //   ? `${x.firstName} ${x.lastName}`
          //   : x?.name || "",
          x?.name || "",
        value: x._id,
      }))
    );
  }, [dealersData]);

  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const startYear = 2019;
  const endYear = currentYear;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let options = [];

  for (let year = endYear; year >= startYear; year--) {
    const endMonth = year === currentYear ? currentMonthIndex : 11;
    for (let monthIndex = endMonth; monthIndex >= 0; monthIndex--) {
      const formattedMonth = (monthIndex + 1).toString().padStart(2, "0");
      options.push({
        value: `${year}-${formattedMonth}-01`,
        label: `${months[monthIndex]} ${year}`,
      });
    }
  }

  const [isRevertOrderLoader, setIsRevertOrderLoader] = useState(null);
  const [isConfirmRevertOrder, setIsConfirmRevertOrder] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });

  const Validation = (e) => {
    let errObj = {
      startDate: false,
      endDate: false,
    };
    if (startDate === null || startDate === "" || startDate === undefined) {
      setError((prev) => ({
        ...prev,
        startDate: true,
      }));
      errObj.startDate = true;
    } else if (endDate === null || endDate === "" || endDate === undefined) {
      setError((prev) => ({
        ...prev,
        endDate: true,
      }));
      errObj.endDate = true;
    }
    return errObj;
  };

  const handleFormSubmit = (event) => {
    const valid = Validation();
    if (valid.startDate || valid.endDate) {
    } else {
      event.preventDefault();

      let apiEndpoint = `${apiUrl}/dealer/filtered-orders?startDate=${startDate}&endDate=${endDate}`;

      if (selectedDealerOption !== null && selectedDealerOption !== "all") {
        apiEndpoint = `${apiEndpoint}&dealer_id=${selectedDealerOption}`;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      setIsFetchLoader(true);

      fetch(`${apiEndpoint}`, requestOptions)
        .then((response) => {
          setIsFetchLoader(false);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setIsFetchLoader(false);
          setOrdersData(data);
          setShowAccordion(true);
          // console.log("Order Report :", data);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsFetchLoader(false);
        });
    }
  };

  const exportToPDF = () => {
    const pdf = new jsPDF();

    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");

    pdf.text("Remittance Report", 20, 20);

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "normal");

    pdf.text(`From ${startDate} To ${endDate}`, 20, 35);

    // pdf.text(
    //   `Dealer: ${
    //     (filteredRemittanceReport[0]?.dealer?.firstName || "") +
    //     " " +
    //     (filteredRemittanceReport[0]?.dealer?.lastName || "")
    //   }`,
    //   20,
    //   50
    // );

    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");

    // pdf.autoTable({
    //   head: [
    //     ["Customer", "Dealer", "Vehicle", "Services & Addons", "Owing ($)"],
    //   ],
    //   body: filteredRemittanceReport.map((reportItem) => [
    //     reportItem.customer.firstName + " " + reportItem.customer.lastName,
    //     (reportItem.dealer?.firstName || "") +
    //       " " +
    //       (reportItem.dealer?.lastName || ""),
    //     `${reportItem.customer.vehical?.modal} (${reportItem.customer.vehical?.vin})`,
    //     reportItem.offers.map((offer) => offer.offerName).join("\n"),
    //     reportItem.offers.map((offer) => `$${offer.price}`).join("\n"),
    //   ]),
    //   startY: 65, // Adjust the startY for the table
    // });

    // Save the PDF
    pdf.save("Remittance_Report.pdf");
  };

  const downloadRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => downloadRef?.current,
  });

  const calculateTotalValue = (materials) => {
    return materials
      .reduce(
        (total, material) => total + material.price * material.quantity,
        0
      )
      .toFixed(2);
  };

  const getStatusBadge = (status) => {
    if (status === "pending") {
      return (
        <Badge pill className="status-badge pending-badge">
          Pending
        </Badge>
      );
    } else if (status === "rejected") {
      return (
        <Badge pill className="status-badge rejected-badge">
          Rejected
        </Badge>
      );
    } else if (status === "complete") {
      return (
        <Badge pill className="status-badge complete-badge">
          Complete
        </Badge>
      );
    }
  };

  const dealerObj = useMemo(() => {
    if (
      selectedDealerOption !== null &&
      selectedDealerOption &&
      selectedDealerOption !== "all"
    ) {
      return (
        dealerOptions.find((x) => x.value === selectedDealerOption) || null
      );
    }
    return null;
  }, [selectedDealerOption]);

  const [isPaidOrderLoader, setIsPaidOrderLoader] = useState(null);

  const handleOrderPaid = async (orderId) => {
    try {
      setIsPaidOrderLoader(orderId);
      const response = await apiPostRequest(
        `${URL_ADMIN_ORDER_PAID_STATUS_CHANGE}/${orderId}`
      );
      if (response.data) {
        toast.success("Order Paid Successfully");
        const prevData = _.cloneDeep(ordersData);
        const index = prevData.findIndex((x) => x._id === orderId);
        if (index !== -1) {
          prevData.splice(index, 1, {
            ...prevData[index],
            isPaid: true,
          });
          setOrdersData([...prevData]);
        }
      }
      setIsPaidOrderLoader(null);
    } catch (err) {
      setIsPaidOrderLoader(null);
      const errorMessage = parseError(err);
      toast.error(errorMessage ?? "Something went wrong!");
      console.log("err", err);
    }
  };

  const handleRevertOrder = async (orderId) => {
    try {
      if (!orderId) return;
      setIsRevertOrderLoader(orderId);
      const response = await apiPatchRequest(URL_SUPER_ADMIN_REVERT_ORDER, {
        orderId,
      });
      if (response?.data) {
        const prevData = _.cloneDeep(ordersData);
        const index = prevData.findIndex((x) => x._id === orderId);
        if (index !== -1) {
          prevData.splice(index, 1, {
            ...prevData[index],
            isPaid: false,
          });
          setOrdersData([...prevData]);
        }
        toast.success(`Order Payment Reverted Successfully!`);
      }
      setIsRevertOrderLoader(null);
    } catch (err) {
      setIsRevertOrderLoader(null);
      console.log("err", err);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
    }
  };

  const getMaterialDetails = (order) => {
    return order?.material
      ?.map((item) => `(${item.name}, ${item.price}, ${item.quantity})`)
      .join(", ");
  };

  const dataForCsv = useMemo(() => {
    const dynamicHeadingRows = [
      [
        `Start Date: ${startDate}`,
        `End Date: ${endDate}`,
        `Dealer: ${
          selectedDealerOption !== null && selectedDealerOption !== "all"
            ? dealerOptions.find((item) => item.value === selectedDealerOption)
                ?.label
            : "All"
        }`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      // [
      //   `Dealer: ${
      //     selectedDealerOption !== null && selectedDealerOption !== "all"
      //       ? dealerOptions.find((item) => item.value === selectedDealerOption)
      //           ?.label
      //       : "All"
      //   }`,
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      // ],
      // [
      //   `Manager: ${
      //     selectedManagerOption !== null && selectedManagerOption !== "all"
      //       ? managerOptions.find(
      //           (item) => item.value === selectedManagerOption
      //         )?.label
      //       : "All"
      //   }`,
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      //   "",
      // ],
    ];

    const columnTitles = [
      [
        "Order Number",
        "Customer First Name",
        "Customer Last Name",
        "Customer Email",
        "Customer City",
        "Customer Postal",
        "Customer State",
        "Customer Address",
        "Role",
        //
        "Materials (Name, Price, Quantity)",
        //
        "Net Total",
        "GST",
        "Grand Total",
        "Order Status",
        "Order Payment",
        "Created At",
      ],
    ];

    const dataRows = ordersData.map((order) => {
      const materialDetails = getMaterialDetails(order);
      return [
        `#${order.number}` || "-",
        order.createdBy?.details?.firstName || "-",
        order.createdBy?.details?.lastName || "-",
        order.createdBy?.details?.email || "-",
        order.createdBy?.details?.city || "-",
        order.createdBy?.details?.postalCode || "-",
        order.createdBy?.details?.state || "-",
        order.createdBy?.details?.address || "-",
        order.createdBy?.role || "-",
        //
        materialDetails,
        //
        `$${(Number(calculateTotalValue(order.material)) * 1.0).toFixed(2)}`,
        `$${(Number(calculateTotalValue(order.material)) * 0.05).toFixed(2)}`,
        `$${(Number(calculateTotalValue(order.material)) * 1.05).toFixed(2)}`,
        order?.status || "-",
        order?.isPaid === true ? "Paid" : "Unpaid",
        order?.createdAt
          ? moment(convertDateByTimezone(order.createdAt, timezone)).format(
              "DD/MM/YYYY"
            )
          : "-",
      ];
    });

    return [...dynamicHeadingRows, ...columnTitles, ...dataRows];
  }, [ordersData, startDate, endDate, selectedDealerOption]);

  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner px-2 py-4">
        <div className="ltn__form-box">
          <div className="row mb-50">
            <div className="d-flex justify-content-between align-items-center py-2">
              <div>
                <h3 className="pb-3">Material Order Report</h3>
              </div>
              {(role === USER_ROLE.ADMIN ||
                role === USER_ROLE.SUPER_ADMIN ||
                role === USER_ROLE.DEALER) && (
                <div>
                  <CsvGenerator data={dataForCsv} />
                </div>
              )}
            </div>

            <div className="col-md-4">
              <label className="pb-2">
                Start Month
                {/* <span style={{ fontSize: "12px", marginLeft: "8px" }}>
                  (from the first day of this month)
                </span> */}
              </label>
              {error.startDate && (
                <p className="error-message mb-2"> Select Start Date</p>
              )}
              <div className="input-item">
                <select
                  className="nice-select"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      startDate: e.target.value === "",
                    }));
                  }}
                >
                  <option value="" selected disabled>
                    Select Start Month
                  </option>
                  {options.map((option) => (
                    <option key={option} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <label className="pb-2">
                End Month
                {/* <span
                  className=""
                  style={{ fontSize: "12px", marginLeft: "8px" }}
                >
                  (to the last day of this month)
                </span> */}
              </label>
              {error.endDate && (
                <p className="error-message mb-2"> Select end Date</p>
              )}
              <div className="input-item">
                <select
                  className="nice-select"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      endDate: e.target.value === "",
                    }));
                  }}
                >
                  <option value="" selected disabled>
                    Select End Month
                  </option>
                  {options.map((option) => (
                    <option key={option} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
              <div className="col-md-4">
                <label className="pb-2">Dealer</label>
                <div className="input-item">
                  <select
                    className="nice-select"
                    value={selectedDealerOption}
                    onChange={(e) => {
                      setSelectedDealerOption(e.target.value);
                    }}
                  >
                    <option value="all">All Dealer</option>
                    {dealerOptions.map((item, index) => (
                      <option key={index} value={item.value}>
                        {capitalizeFirstLetter(item.label)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
          <div
            className="btn-wrapper px-2"
            style={{ display: "flex", marginTop: "0px" }}
          >
            <CustomButton
              showLoading={isFetchLoader}
              isDisabled={isDealerListLoader || isFetchLoader}
              label="Get Order"
              type="button"
              clickHandler={handleFormSubmit}
              cssClass="btn-effect-1 text-uppercase"
            />
            {/* <button
                type="button"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={exportToPDF}
              >
                Export to PDF
              </button> */}
            {showAccordion && (
              <button
                type="button"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={handlePrint}
              >
                Print
              </button>
            )}

            <section className="printpdf-sec py-4 px-4" ref={downloadRef}>
              <p className="text-center">
                ExtendedTrust Inc. | Content Management System
              </p>
              <p className="date-range mt-3">
                {new Date(Date.now()).toLocaleString()}
              </p>

              <h1 className="report-title mt-2 mb-3">Material Order Report</h1>
              <p className="date-range mt-1">
                <span style={{ fontWeight: "600", color: "black" }}>From:</span>
                {` ${startDate} To ${endDate}`}
              </p>
              {dealerObj !== null && (
                <p className="dealer-info mb-2">
                  <span style={{ fontWeight: "600", color: "black" }}>
                    Dealer:
                  </span>
                  {` ${dealerObj?.label}`}
                </p>
              )}
              <p className="mt-5 mb-5">
                Complete report as of {new Date(Date.now()).toLocaleString()}
              </p>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Customer</th>
                      <th>Total Value</th>
                      <th>Status</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersData.map((order) => (
                      <tr key={order._id}>
                        <td>#{order.number}</td>
                        <td>
                          {order.createdBy.details.firstName +
                            " " +
                            order.createdBy.details.lastName}
                        </td>
                        <td>${calculateTotalValue(order.material)}</td>
                        <td>
                          <span>{getStatusBadge(order.status)}</span>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              disabled={order.isPaid || isPaidOrderLoader}
                              checked={order.isPaid}
                              className="form-check-input"
                              type="checkbox"
                              id={`paymentStatus-${order._id}`}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
        {/* {filteredRemittanceReport?.length > 0 && (
          <SummaryCards registrations={filteredRemittanceReport} />
        )} */}
        {showAccordion && (
          <Accordion defaultActiveKey="0" className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Order TOTAL BREAKDOWN</Accordion.Header>
              <Accordion.Body style={{ background: "white" }}>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Order Number</th>
                        <th>Customer</th>
                        <th>Total Value</th>
                        <th>Status</th>
                        <th>Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersData.map((order) => (
                        <tr key={order._id}>
                          <td>#{order.number}</td>
                          <td>
                            {order.createdBy.details.firstName +
                              " " +
                              order.createdBy.details.lastName}
                          </td>
                          <td>${calculateTotalValue(order.material)}</td>
                          <td>
                            <span>{getStatusBadge(order.status)}</span>
                          </td>
                          <td className="text-center">
                            <div className="form-check form-switch">
                              {isRevertOrderLoader &&
                              isRevertOrderLoader === order._id ? (
                                <div className="d-flex justify-content-start align-items-center">
                                  <Loader
                                    cssClass={"text-black"}
                                    isSmallView={true}
                                  />
                                </div>
                              ) : (
                                <input
                                  disabled={() => {
                                    if (role !== USER_ROLE.SUPER_ADMIN) {
                                      if (
                                        order.isPaid ||
                                        isPaidOrderLoader ||
                                        role !== USER_ROLE.ADMIN
                                      ) {
                                        return true;
                                      }
                                    } else if (role === USER_ROLE.SUPER_ADMIN) {
                                      return order.isPaid === false;
                                    }
                                    return true;
                                  }}
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`paymentStatus-${order._id}`}
                                  checked={order.isPaid}
                                  onChange={() => {
                                    if (
                                      role !== USER_ROLE.ADMIN &&
                                      role !== USER_ROLE.SUPER_ADMIN
                                    ) {
                                      return;
                                    }
                                    if (order.isPaid) {
                                      if (role === USER_ROLE.SUPER_ADMIN) {
                                        setIsConfirmRevertOrder(order._id);
                                      }
                                    } else if (role === USER_ROLE.ADMIN) {
                                      handleOrderPaid(order._id);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
      {/* Confirmation Modal */}
      {isConfirmRevertOrder !== null && (
        <ConfirmationModal
          isOpen={isConfirmRevertOrder !== null}
          confirmationStatus={(e) => {
            if (e === true) {
              handleRevertOrder(isConfirmRevertOrder);
            }
            setIsConfirmRevertOrder(null);
          }}
          title={"Revert Order Payment Confirmation"}
          heading={"Are you sure to revert this order Payment ?"}
        />
      )}
    </div>
  );
};

export default OrderReports;
