import React from "react";

const KeyFob = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "2pt",
            paddingLeft: "141pt",
            textIndent: "0pt",
            lineHeight: "12pt",
            textAlign: "left",
          }}
        >
          Schedule B: Key/FOB Exchange Service
        </h1>
        <ol id="l1">
          <li style={{ listStyleType: "decimal" }}>
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Definitions:
            </h1>
            <ul id="l2">
              <li style={{ listStyleType: "square" }}>
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Disqualified Key/FOB:{" "}
                  <span className="p">
                    Any key or remote that belongs to a vehicle not manufactured
                    in the current or previous ten model years or is used for a
                    non-OEM vehicle starter.
                  </span>
                </h1>
              </li>
              <li style={{ listStyleType: "square" }}>
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Disqualified Vehicle:{" "}
                  <span className="p">
                    A vehicle not made in the last ten model years.
                  </span>
                </h1>
              </li>
              <li style={{ listStyleType: "square" }}>
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Exchange Services:{" "}
                  <span className="p">
                    Services outlined in Section 2 for your key/remote.
                  </span>
                </h1>
              </li>
            </ul>
          </li>
          <li style={{ listStyleType: "decimal" }}>
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Services:
            </h1>
            <ul id="l3">
              <li style={{ listStyleType: "square" }}>
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Obligation:{" "}
                  <span className="p">
                    We'll arrange Exchange Services for a malfunctioning
                    key/remote if you selected this service on the Registration
                    Page and paid the charges.
                  </span>
                </h1>
              </li>
              <li style={{ listStyleType: "square" }}>
                <h1
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Exchange Services include:
                </h1>
                <ul id="l4">
                  <li style={{ listStyleType: "square" }}>
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Deprogramming/deactivation of the key/remote.
                    </p>
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Reprogramming or replacing the key/remote for vehicle
                      access.
                    </p>
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Key/remote repair.
                    </p>
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Purchase of a replacement key/remote.
                    </p>
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Providing you with the repaired or replacement key/remote.
                    </p>
                  </li>
                </ul>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Whether we repair or replace the key/remote is at our
                  discretion.
                </p>
                <h1
                  style={{
                    paddingLeft: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  CAUTION: For Your security and safety, all keys and remotes
                  that are reported destroyed must be reprogrammed by the dealer
                  or service facility prior to replacement.
                </h1>
              </li>
            </ul>
          </li>
          <li style={{ listStyleType: "decimal" }}>
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              Limits of Liability:
            </h1>
            <ul id="l5">
              <li style={{ listStyleType: "square" }}>
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Our liability under this schedule will not exceed $2,000.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  This schedule covers only repair or replacement of the
                  key/remote.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="4.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Additional Exclusions:
            </h1>
            <ul id="l6">
              <li style={{ listStyleType: "square" }}>
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Replacing the key/remote's battery and costs associated with
                  upgrading or complying with the law are not covered
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  We won't cover certain losses or damages, including those from
                  accidents, natural disasters, intentional damage, misuse, or
                  if the key/remote is disqualified.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Additionally, this contract does not apply, and we are not
                  liable for, and services will not be provided for the
                  following:
                </p>
                <ol id="l7">
                  <li data-list-text="i.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage caused by or resulting from any motor
                      vehicle accident or collision, regardless of cause, fault,
                      or driver error.
                    </p>
                  </li>
                  <li data-list-text="ii.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage caused by or resulting from fire,
                      theft, explosion, falling objects, lightning, earthquake,
                      volcanic eruption, freezing, rust or corrosion, windstorm,
                      tornado, hail, water, flood, sinkhole, mudslide,
                      vandalism, civil commotion, civil unrest, riot, protest,
                      terrorism, war, nuclear radiation, or radioactive
                      contamination.
                    </p>
                  </li>
                  <li data-list-text="iii.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage caused by or resulting from willful or
                      intentional damage, misuse, abuse, or criminal acts by you
                      or anyone acting with your knowledge and consent.
                    </p>
                  </li>
                  <li data-list-text="iv.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage when the responsibility for repair or
                      replacement is covered or required to be covered under the
                      original manufacturer's warranty, seller's warranty, parts
                      warranty, or any other third-party warranty or service
                      contract, or any insurance contract. This also applies to
                      loss or damage for which the manufacturer has acknowledged
                      responsibility through public recall or other means.
                    </p>
                  </li>
                  <li data-list-text="v.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        lineHeight: "14pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage caused by or resulting from a
                      manufacturer's defect.
                    </p>
                  </li>
                  <li data-list-text="vi.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        lineHeight: "14pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage if the key/FOB is disqualified.
                    </p>
                  </li>
                  <li data-list-text="vii.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage if two (2) sets of keys and keyless
                      entry remotes (or smart keys or laser-cut keys with
                      built-in transponder chip(s)) for the vehicle were not
                      provided to you and in your possession at the time of the
                      vehicle's original purchase and on the contract purchase
                      date.
                    </p>
                  </li>
                  <li data-list-text="viii.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        lineHeight: "14pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage resulting from cybercrime.
                    </p>
                  </li>
                  <li data-list-text="ix.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage if the key/FOB contains radioactive
                      material or other hazardous substances.
                    </p>
                  </li>
                  <li data-list-text="x.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage to the vehicle itself, including costs
                      associated with repairing,
                    </p>
                    <p
                      style={{
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      replacing, reprogramming, or servicing the vehicle's
                      locks, locksets, ignition system, immobilizer, main body
                      engine control unit, alarm, or infra-red handset attached
                      to the key/FOB. This also includes costs associated with
                      installing equipment, devices, or other items in the
                      vehicle in connection with an exchange service.
                    </p>
                  </li>
                  <li data-list-text="xi.">
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any costs associated with upgrading the key/FOB from the
                      model in your possession on the contract purchase date.
                    </p>
                  </li>
                  <li data-list-text="xii.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any costs incurred in connection with an exchange service
                      required to comply with the law.
                    </p>
                  </li>
                  <li data-list-text="xiii.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        lineHeight: "14pt",
                        textAlign: "left",
                      }}
                    >
                      Any key/FOB repair or replacement made without our prior
                      authorization.
                    </p>
                  </li>
                  <li data-list-text="xiv.">
                    <p
                      style={{
                        paddingLeft: "95pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Any loss or damage if you or anyone on your behalf
                      provides false information or commits fraud regarding any
                      claim.
                    </p>
                  </li>
                  <li data-list-text="xv.">
                    <p
                      style={{
                        paddingLeft: "96pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      If the vehicle is used for commercial purposes, including
                      pick-up and/or delivery service, rentals, livery, carrying
                      passengers for hire (taxi, limousine, or shuttle
                      services), ride-share vehicles, towing or road service
                      operations, government/military use, law enforcement,
                      fire, ambulance, or other emergency services, company pool
                      use, or if the vehicle is both registered in the company's
                      name and may be used by multiple drivers.
                    </p>
                  </li>
                </ol>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="5.">
            <h1
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              Procedure for Service:
            </h1>
            <ul id="l8">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  To get service, contact the Selling Dealer for service or
                  permission to use another Authorized Repair Facility.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Take the key/remote and the vehicle to the Selling Dealer or
                  authorized service facility.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Identify the contract and obtain prior authorization from
                  Extended Trust.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Provide proof of cost and work performed.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Pay for costs not covered by us.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "59pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Emergency repairs need approval within the next available
                  business day.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  );
};

export default KeyFob;
