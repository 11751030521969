import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../Button/CustomButton";

const ConfirmationModal = ({ confirmationStatus, isOpen, title, heading }) => {
  const [open, setOpen] = useState(isOpen);

  const clickHandler = (val) => {
    confirmationStatus(val);
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={() => {}}>
      <Modal.Header
        style={{
          borderBottom:
            "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
        }}
        closeButton
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{heading}</Modal.Body>
      <Modal.Footer>
        <CustomButton
          label="Cancel"
          clickHandler={() => {
            clickHandler(false);
          }}
          cssClass="btn-effect-1 btn1"
        />
        <CustomButton
          label="Yes"
          clickHandler={() => {
            clickHandler(true);
          }}
          cssClass="btn-effect-1 btn1"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
