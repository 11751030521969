import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../common/Button/CustomButton";
import Loader from "../../common/Loader/Loader";
import AccountDetails from "../registrants/accountDetails";
import {
  DEALER_EMPTY_OBJ,
  DEFAULT_TIMEZONE,
  USER_ROLE,
} from "../../../utils/constant";
import useDealer from "../../../Hooks/useDealer";

const ListDealer = () => {
  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);

  const { isDealerListLoader, dealersData, handleDataUpdateById } = useDealer();

  const [selectedShowDealer, setSelectedShowDealer] = useState(null);

  const [inputData, setInputData] = useState(DEALER_EMPTY_OBJ);

  const dealersToMap = useMemo(() => {
    setDealerOptions(
      dealersData?.map((x) => ({
        label:
          x?.firstName && x.firstName?.length > 0
            ? `${x.firstName} ${x.lastName}`
            : x?.name || "",
        value: x._id,
      }))
    );
    if (selectedDealerOption !== null && selectedDealerOption !== "all") {
      return dealersData.filter((x) => x._id === selectedDealerOption);
    }
    return dealersData;
  }, [dealersData, selectedDealerOption]);

  return (
    <>
      <div className="checkoverflow " style={{ paddingRight: "20px" }}>
        <div className="row mb-50 mt-3">
          <h3 className="pb-3">Dealer List</h3>
          {/* <div className="row">
            <div className="col-md-3">
              <label className="pb-2">Dealer</label>
              <div className="input-item">
                <select
                  disabled={isDealerListLoader}
                  className="nice-select"
                  value={selectedDealerOption}
                  onChange={(e) => {
                    setSelectedDealerOption(e.target.value);
                  }}
                >
                  <option value="all" selected>
                    All Dealers
                  </option>
                  {dealerOptions.map((item, index) => (
                    <option key={index} value={item.value}>
                      {capitalizeFirstLetter(item.label)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}

          <div className="mobileTable">
            <table
              class="table overflow-set"
              style={{ margin: "20px 0px 20px 0px" }}
            >
              <thead>
                <tr style={{ backgroundColor: "#4A6543" }}>
                  <th scope="col">Dealership Name</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Region</th>
                  <th scope="col">State</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                {isDealerListLoader ? (
                  <Loader
                    style={{ width: "35px", height: "35px" }}
                    isSmallView={true}
                  />
                ) : dealersToMap.length > 0 ? (
                  dealersToMap.map((item) => (
                    <tr>
                      <td>{item?.name ?? "-"}</td>
                      <td>{item?.firstName ?? "-"}</td>
                      <td>{item?.lastName ?? "-"}</td>
                      <td>{item?.contactEmail ?? "-"}</td>
                      <td>{item?.phone ?? "-"}</td>
                      <td>{item?.region ?? "-"}</td>
                      <td>{item?.state ?? "-"}</td>
                      <td>
                        <CustomButton
                          label="View"
                          type="button"
                          cssClass="btn-effect-1 text-uppercase"
                          clickHandler={() => {
                            setInputData({
                              email: item.contactEmail,
                              address: item.address,
                              city: item.city,
                              name: item.name,
                              phone: item.phone,
                              firstName: item.firstName,
                              lastName: item.lastName,
                              postalCode: item.postalCode,
                              profileImage: item.profileImage,
                              region: item.region,
                              state: item.state,
                              faxNumber: item.faxNumber,
                              id: item._id,
                              timezone: item.timezone
                                ? item.timezone
                                : DEFAULT_TIMEZONE,
                              service_manager_id: item.serviceManager,
                            });
                            setSelectedShowDealer(item);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={selectedShowDealer !== null}
        onHide={() => {
          setSelectedShowDealer(null);
          setInputData(DEALER_EMPTY_OBJ);
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Update Dealer Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custom-scroller"
          style={{ overflowY: "auto", height: "80vh" }}
        >
          <AccountDetails
            handleClose={() => {
              setSelectedShowDealer(null);
              setInputData(DEALER_EMPTY_OBJ);
            }}
            handleDataUpdateById={handleDataUpdateById}
            isCallFrom="dealerList"
            role={USER_ROLE.ADMIN}
            inputData={inputData}
            setInputData={setInputData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListDealer;
