import React from "react";

const OilChange = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "2pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          Schedule H: Oil Change and Tire Storage Program
        </h1>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <ol id="l1">
          <li>
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Qualification
            </h1>
            <p
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              To stay qualified for oil service, follow these guidelines:
            </p>
            <ol id="l2">
              <li>
                <p
                  style={{
                    paddingLeft: "30pt",
                    textIndent: "-10pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Adhere to the service program outlined in Appendix 1 (oil
                  change cards).
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "19pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Follow the oil change program card matching your
                  manufacturer's requirements; bring in your vehicle for
                  maintenance either every:
                </p>
                <ul id="l3">
                  <li>
                    <p
                      style={{
                        paddingLeft: "51pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Six (6) months or 6,000 KM (Import)
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        paddingLeft: "51pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Six (6) months or 8,000 KM (Domestic)
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        paddingLeft: "51pt",
                        textIndent: "-18pt",
                        lineHeight: "13pt",
                        textAlign: "left",
                      }}
                    >
                      Twelve (12) months or 16,000 KM (Luxury)
                    </p>
                    <p
                      style={{
                        paddingLeft: "19pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Whichever comes first, starting from the time of the Bill
                      of Sale. There's a 10-day grace period for oil changes or
                      500 KM.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "29pt",
                    textIndent: "-10pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Bring your vehicle to the original seller for oil change
                  services.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "30pt",
                    textIndent: "-11pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  This service is nontransferable to any other unit and holds no
                  cash value.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Procedure for Service
            </h1>
            <p
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              To access services from the Extended Trust Program, contact your
              original selling dealer.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li>
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Conditions of Service
            </h1>
            <p
              style={{
                paddingLeft: "6pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              You acknowledge and agree that:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ul id="l4">
              <li>
                <p
                  style={{
                    paddingLeft: "46pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Some services may require multiple appointments to resolve an
                  imperfection.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "46pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  Completion of certain services may necessitate you being
                  without the use of the vehicle for up to one (1) day.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "46pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  It may be impossible to resolve every imperfection; the
                  Extended Trust Program will fulfill its obligation under this
                  Contract if, in the judgment of the Extended Trust Program
                  technician, best efforts have been made to provide services
                  using the techniques employed by the program.
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "46pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  As the Extended Trust Program does not include
                  washing/vacuuming of a vehicle in their detailing services,
                  you must clean/vacuum the area to be serviced before bringing
                  the vehicle in for servicing. Extended Trust Ltd. is not
                  liable for any charges for detailing, cleaning, or vacuuming,
                  which are the client’s responsibility.
                </p>
              </li>
            </ul>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li>
            <h1
              style={{
                paddingLeft: "15pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Transferring This Contract
            </h1>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Only the original customer may transfer or assign this contract in
              the case of a sale to a private party. This contract is specific
              to the vehicle and cannot be transferred to another vehicle. The
              term will not be extended by the transfer. The original customer
              may transfer or assign this contract to a private party by
              providing the new owner's details to the dealer within 30 days of
              the sale. Details must include:
            </p>
            <ul id="l5">
              <li>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's First and Last Name;
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Address, including City, Province, and Postal
                  Code;
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Phone Number; and
                </p>
              </li>
              <li>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  New Owner's Email Address
                </p>
              </li>
            </ul>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li>
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "justify",
              }}
            >
              Cancelling This Contract
            </h1>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify",
              }}
            >
              You can cancel this contract within thirty (30) days of the
              Effective Date and receive a 100% refund if no services have been
              performed. If not canceled within 30 days, the full fee is deemed
              earned by Extended Trust Ltd.
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify",
              }}
            >
              Extended Trust Ltd. may cancel at any time by refunding you a
              pro-rated amount based on the remaining term. The contract
              terminates automatically if the vehicle is written off,
              repossessed, or sold to a third party without contacting Extended
              Trust Ltd., or at the expiry of the term.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

export default OilChange;
