import React from "react";

const Tiny = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "1pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          Schedule A: Tire &amp; Rim Service
        </h1>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <ol id="l1">
          <li data-list-text="1.">
            <h2
              style={{
                paddingTop: "3pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Definitions
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              In this contract, the following terms have specific meanings:
            </p>
            <ul id="l2">
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Canadian Black Book:{" "}
                  <span className="p">
                    Refers to the Wholesale & Retail Guide published by Hearst
                    Business Media Corporation or its successor. Extended Trust
                    will determine its equivalent guide at its discretion.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Oversized Tires:{" "}
                  <span className="p">
                    Pneumatic motor vehicle tires with an internal diameter
                    exceeding 20 inches.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Road Hazard:{" "}
                  <span className="p">
                    For tires, it includes potholes, road cracks, nails, screws,
                    glass, rocks, animals, or other debris causing tire failure
                    during driving. For rims, it involves damage due to road
                    hazards affecting the rim's sealing, causing excessive
                    runout, or impacting the vehicle's safety or performance.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Run-flat Tires:{" "}
                  <span className="p">
                    Motor vehicle tires designed to resist deflation and allow
                    limited-speed driving after puncture.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Speed-rated Tires:{" "}
                  <span className="p">
                    Pneumatic motor vehicle tires rated 'Z' or higher (including
                    ‘W’ and ‘Y’).
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Standard Tires:{" "}
                  <span className="p">
                    Non-run-flat, non-speed-rated, and non-oversized pneumatic
                    motor vehicle tires.
                  </span>
                </h2>
              </li>
              <li data-list-text="">
                <h2
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Vehicle Value:
                  <span className="p">
                    The "Retail Clean" value published in the Canadian Black
                    Book for a similar make, model, trim, year, and
                    specifications, adjusted for mileage and certain equipment,
                    subject to Canadian Black Book evaluation.
                  </span>
                </h2>
              </li>
            </ul>
          </li>
          <li data-list-text="2.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "15pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Services
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s1"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify",
              }}
            >
              General:
              <span className="p">
                If you've selected Tire & Rim Service and paid the applicable
                charges, Extended Trust provides services until the Term's
                expiry or earlier termination. Contact Extended Trust for
                service claims; unauthorized repairs or replacements are not our
                responsibility.
              </span>
            </p>
            <p
              className="s1"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify",
              }}
            >
              Description:
              <span className="p">
                Subject to the contract terms, we'll repair or replace, at our
                discretion, the vehicle's tires or rims damaged by a Road Hazard
                during the Term. Curb scuff repair services are available for
                rims damaged during routine parking. We provide new replacement
                tires or rims, similar to the manufacturer's specifications.
              </span>
            </p>
            <p
              className="s1"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "12pt",
                textAlign: "justify",
              }}
            >
              Service Limits:{" "}
              <span className="p">
                Reimbursement limits for tire or rim repair/replacement are
                specified:
              </span>
            </p>
            <ul id="l3">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Tire Repair: Up to $200 per tire
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Tire Replacement: Up to the replacement cost of the original
                  tire
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Rim Replacement: Up to the replacement cost of the original
                  rim
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Curb Scuff Rim Repair: Up to $400 per rim
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Our aggregate liability is detailed in Section 3 of this
                  Schedule.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="3.">
            <h2
              style={{
                paddingLeft: "15pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Limits of Liability; Disclaimers
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "109%",
                textAlign: "left",
              }}
            >
              To the maximum extent permitted by law, our liability is capped at
              the lesser of $5,<span className="s2">5</span>00 or the Vehicle
              Value prior to the event causing the loss or damage. The Schedule
              covers repair/replacement and is the sole remedy. We're not liable
              for injury, death, or property damage beyond the Vehicle.
            </p>
          </li>
          <li data-list-text="4.">
            <h2
              style={{
                paddingTop: "7pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Additional Exclusions
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}
            >
              In addition to contract exclusions, this Schedule excludes
              specific conditions detailed in Section 6 of the Terms and
              Conditions. Specific exclusions include the following:
            </p>
            <ol id="l4">
              <li data-list-text="A.">
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  If a repair or replacement isn't listed in this plan, or if
                  it's listed but you didn't buy the services mentioned on the
                  Registration Page, it's not covered.
                </p>
              </li>
              <li data-list-text="B.">
                <p
                  style={{
                    paddingLeft: "16pt",
                    textIndent: "-10pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  We won't cover any loss or damage from a car accident, no
                  matter who's at fault, or from driver errors.
                </p>
              </li>
              <li data-list-text="C.">
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  We don't cover losses from fire, theft, explosions, natural
                  disasters, vandalism, civil unrest, war, or other similar
                  events.
                </p>
              </li>
              <li data-list-text="D.">
                <p
                  style={{
                    paddingLeft: "16pt",
                    textIndent: "-11pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  We won't cover repairs due to manufacturer defects.
                </p>
              </li>
              <li data-list-text="E.">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "15pt",
                    textIndent: "-9pt",
                    textAlign: "left",
                  }}
                >
                  We won't cover intentional damage, misuse, neglect, racing,
                  lack of regular maintenance, or improper servicing.
                </p>
              </li>
              <li data-list-text="F.">
                <p
                  style={{
                    paddingLeft: "15pt",
                    textIndent: "-9pt",
                    textAlign: "left",
                  }}
                >
                  Modifications to the vehicle or using it in ways not
                  recommended by us or the manufacturer aren't covered.
                </p>
              </li>
              <li data-list-text="G.">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "17pt",
                    textIndent: "-11pt",
                    textAlign: "left",
                  }}
                >
                  Custom parts not originally from the manufacturer aren't
                  covered.
                </p>
              </li>
              <li data-list-text="H.">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  If the repair is under the original manufacturer's warranty,
                  seller's warranty, or any third-party warranty, we won't cover
                  it.
                </p>
              </li>
              <li data-list-text="I.">
                <p
                  style={{
                    paddingLeft: "13pt",
                    textIndent: "-7pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  If the vehicle is more than ten years old on the Contract
                  Purchase Date, we won't cover losses.
                </p>
              </li>
              <li data-list-text="J.">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "13pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}
                >
                  Regarding tires or rims:
                </p>
                <ul id="l5">
                  <li data-list-text="">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      We won't cover normal wear and tear or damage that doesn't
                      affect performance.
                    </p>
                  </li>
                  <li data-list-text="">
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      If the tire or rim isn't recommended by the manufacturer,
                      it's not covered.
                    </p>
                  </li>
                  <li data-list-text="">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Damage from off-road use, overloading, or improper
                      maintenance is excluded.
                    </p>
                  </li>
                  <li data-list-text="">
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                      }}
                    >
                      Curb scuffs, overloading, and damage to the spare tire are
                      not covered.
                    </p>
                  </li>
                  <li data-list-text="">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left",
                      }}
                    >
                      Various conditions related to tire wear, tread separation,
                      and specific tire issues are excluded from coverage.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li data-list-text="5.">
            <h2
              style={{
                paddingTop: "8pt",
                paddingLeft: "15pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Your Responsibilities
            </h2>
            <ul id="l6">
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Regularly inspect tires and rims.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Follow safety and maintenance guidelines.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Obtain consent for tire type changes.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Pay excess costs not covered by the Schedule.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.">
            <h2
              style={{
                paddingTop: "9pt",
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Conditions of Service
            </h2>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              If you've selected Tire & Rim Service:
            </p>
            <ul id="l7">
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Some services may require multiple appointments.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Vehicle non-use may be required for up to three (3) days.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Best efforts are made to resolve issues.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="7.">
            <h2
              style={{
                paddingTop: "8pt",
                paddingLeft: "15pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Procedure for Service
            </h2>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              If damage occurs, follow these steps to obtain service:
            </p>
            <ul id="l8">
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Contact Selling Dealer.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  If unable to attend Selling Dealer you must Preclear an
                  Authorized Repair Facility.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Identify the contract and obtain prior authorization.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Provide proof of cost and work performed.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Pay amounts for which you are responsible.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Emergency repairs require contact with the Selling Dealer the
                  next business day.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h2
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          ONCE AUTHORIZATION IS OBTAINED, AND THE REPAIR/REPLACEMENT IS
          COMPLETED, ALL REPAIR ORDERS, DOCUMENTATION AND RECEIPTS MUST BE
          SUBMITTED TO US WITHIN NINETY (90) DAYS OF THE AUTHORIZED REPAIR OR
          REPLACEMENT IN ORDER TO BE ELIGIBLE FOR PAYMENT. IN ADDITION, YOU ARE
          REQUIRED TO BEAR THE COST OF THE APPLICABLE REPAIR OR REPLACEMENT OVER
          AND ABOVE THE AMOUNT THAT WE EXPRESSLY AGREED TO BE RESPONSIBLE FOR
          UNDER THIS SCHEDULE.
        </h2>
      </div>
    </>
  );
};

export default Tiny;
