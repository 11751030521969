import axios from "axios";
import parseError from "../utils/ErrorParse";
import getItemFromLocalStorage from "../utils/GetItemFromLocalstorage";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { sleep } from "../utils/utils";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const Token = getItemFromLocalStorage("Token");
    const response = error?.response;
    if (response && response.status && response.status === 401) {
      if (Token) {
        toast.info("Session Expired!");
        sleep(2500).then();
        secureLocalStorage.clear();
        window.location.reload();
      }
    }
    return Promise.reject(parseError(error));
  }
);

export default axios;
