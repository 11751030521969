import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/shared/footer";
import Login from "./components/account/login";
import Dashboard from "./page/dashboard";
import { Confirmation } from "./components/account/confirmation";
import Contractpdf from "./components/dashboardComponent/contractpdf/contractpdf";
import { useEffect, useState } from "react";
import { apiGetRequest } from "./helpers/Requests";
import { BASE_URL } from "./service/URL";
import getItemFromLocalStorage from "./utils/GetItemFromLocalstorage";
import { getToken } from "./helpers/Tokens";
import AuthContext from "./helpers/authContext";
import { USER_ROLE } from "./utils/constant";

// chrome.runtime.onStartup.addListener(function () {
//   // Check if it's the user's first-time browser open
//   // localStorage.removeItem("Token")
//   // localStorage.removeItem("UserID")
//   // localStorage.removeItem("Role")
//   // localStorage.removeItem("isVerified")
//   console.log("Inside Event Listner");

// });
function App() {
  const navigate = useNavigate();
  const [timezone, setTimezone] = useState(getItemFromLocalStorage("Timezone"));
  const [role, setRole] = useState(getItemFromLocalStorage("Role"));

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // var RememberMe = localStorage.getItem("RememberMe")
  // console.log("RememberMe", RememberMe);

  // // if (RememberMe) {
  // chrome.runtime.onStartup.addListener(function () {
  //   // Check if it's the user's first-time browser open
  //   // localStorage.removeItem("Token")
  //   // localStorage.removeItem("UserID")
  //   // localStorage.removeItem("Role")
  //   // localStorage.removeItem("isVerified")
  //   console.log("Inside Event Listner");

  // });

  // }

  const Token = getToken();

  useEffect(() => {
    if (!Token || Token === null) {
      navigate("/");
    }
    if (Token) {
      checkAuthentication();
      getProfile();
      setTimezone(getItemFromLocalStorage("Timezone"));
    }
  }, [Token]);

  const checkAuthentication = async () => {
    try {
      await apiGetRequest(`${BASE_URL}/checkAuthentication`);
    } catch (err) {
      console.log("response", err);
    }
  };

  const getProfile = async () => {
    try {
      const roleApi = [
        "Technician",
        "Sales Advisor",
        "Service Advisor",
      ].includes(role)
        ? "staff"
        : role;
      setIsLoadingProfile(true);
      const response = await apiGetRequest(
        `${BASE_URL}/${roleApi?.toLowerCase()}/profile`
      );
      if (response) {
        setCurrentUser(response);
      }
      setIsLoadingProfile(false);
    } catch (err) {
      setIsLoadingProfile(false);
    }
  };

  return (
    <div className="App">
      <AuthContext.Provider
        value={{
          timezone,
          setTimezone,
          setRole,
          role,
          isLoadingProfile,
          serviceManagerProfile:
            currentUser?.role === USER_ROLE.ADMIN ||
            currentUser?.role === USER_ROLE.SUPER_ADMIN
              ? null
              : currentUser?.role === USER_ROLE.DEALER
              ? currentUser?.serviceManager
              : currentUser?.dealer?.serviceManager,
        }}
      >
        <ToastContainer />
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/contract" element={<Contractpdf />} />
        </Routes>
        <Footer />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
