import React, { useEffect, useState } from "react";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const OffersTable = ({ selectedManagerId, counter2 }) => {
  const [tableData, setTableData] = useState([]);
  var apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    if (selectedManagerId !== 0) {
      const getOffersUrl = `${apiUrl}/manager/offers/${selectedManagerId}`;
      axios
        .get(getOffersUrl, { headers })
        .then((response) => {
          console.log("asd");
          console.log("Response:", response.data);
          console.log(response.data)
          if (response.data.length > 0) {
            setTableData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    }
  }, [selectedManagerId, counter2]);

  return (
    <div className="mx-4 mobileTable">
      <table style={{ marginTop: "15px" }} class="table border ">
        <thead>
          <tr>
            <th scope="col">Service Type</th>
            <th scope="col">Offer</th>
            <th scope="col">Price</th>
            <th scope="col">Services</th>
          </tr>
        </thead>
        <tbody>

          {tableData.map((item) => (
            <tr>
              <td>{item.serviceType}</td>
              <td>{item.offerName}</td>
              <td>{item.price}</td>
              <td>
                {item.services.map((service) => service.serviceName).join(",")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
