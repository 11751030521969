import React, { useEffect, useState } from "react";
import ServiceProgramItem from "./ServiceProgramItem";
import "./serviceProgram.css";
import CustomButton from "../../../common/Button/CustomButton";
import { capitalizeFirstLetter, classNames } from "../../../../utils/utils";
import _, { isNumber } from "lodash";
import { apiPatchRequest } from "../../../../helpers/Requests";
import {
  URL_ADMIN_SERVICE_PROGRAM_PRICE,
  URL_ADMIN_SERVICE_PROGRAM_PRICE_DEALER_SPECIFIC,
} from "../../../../service/URL";
import { toast } from "react-toastify";

const ServiceProgram = (props) => {
  const {
    isLastIndex,
    dealerId,
    updateProgramPricesLocally,
    serviceCategoryId,
    program,
    srNumber,
    serviceTenureOptions,
    isFetchServiceTenureLoader,
    isTieredService,
    serviceTierOptions,
  } = props;

  const [showPrograms, setShowPrograms] = useState(false);

  const [updateProgramPriceLoader, setUpdateProgramPriceLoader] =
    useState(false);

  const [pricesData, setPricesData] = useState([]);

  useEffect(() => {
    setPricesData([...program.prices]);
  }, []);

  // console.log("pricesData", pricesData);

  const handleModifyTenureRow = (e, isTier = false) => {
    const prevData = _.cloneDeep(pricesData);
    if (isTier) {
      const data = _.cloneDeep(e);
      serviceTierOptions.forEach((element, index) => {
        if (data?.[element._id]) {
          const payloadData = { ...data[element._id] };

          const priceObjIndex = prevData.findIndex(
            (x) =>
              x.serviceTenure === payloadData?.serviceTenure &&
              x.serviceTier === payloadData?.serviceTier
          );
          if (priceObjIndex !== -1) {
            const priceId =
              pricesData[priceObjIndex]?.priceId ||
              pricesData[priceObjIndex]?._id;
            if (
              prevData[priceObjIndex].price &&
              prevData[priceObjIndex].price > 0 &&
              data[element._id].price === null
            ) {
              prevData.splice(priceObjIndex, 1, {
                ...payloadData,
                priceId,
              });
            } else {
              prevData.splice(priceObjIndex, 1, {
                ...prevData[priceObjIndex],
                ...payloadData,
                priceId,
              });
            }
          } else if (priceObjIndex === -1 && data[element._id].price > 0) {
            prevData.push({ ...payloadData });
          }
        }
      });
      setPricesData([...prevData]);
      return;
    }
    if (e && e.serviceTenure) {
      const index = pricesData.findIndex(
        (m) => m.serviceTenure === e.serviceTenure
      );

      if (index !== -1) {
        const priceId = pricesData[index]?.priceId || pricesData[index]?._id;
        if (
          prevData[index].price &&
          prevData[index].price > 0 &&
          e.price === null
        ) {
          prevData.splice(index, 1, {
            ...e,
            priceId,
          });
        } else {
          prevData.splice(index, 1, {
            ...prevData[index],
            ...e,
            priceId,
          });
        }
      } else if (index === -1 && e.price > 0) {
        prevData.push({ ...e });
      }
      setPricesData([...prevData]);
    }
  };

  const handleUpdateProgramPrice = async () => {
    try {
      const updatePrices = pricesData
        .filter(
          (u) =>
            u.price !== null ||
            (u?.hasOwnProperty("priceId") && u.priceId !== undefined)
        )
        .map((p) => {
          const obj = {
            price: p.price,
            status: p.status,
            serviceTenure: p.serviceTenure,
          };
          if (p.serviceTier && isTieredService) {
            Object.assign(obj, {
              serviceTier: p.serviceTier,
            });
          }
          if (
            p.hasOwnProperty("priceId") &&
            p.priceId !== undefined &&
            p.price === null
          ) {
            Object.assign(obj, {
              priceId: p.priceId,
            });
          }
          return obj;
        });

      const payload = {
        programId: program._id,
        prices: [...updatePrices],
        serviceCategoryId,
      };

      console.log("payload", payload);
      // return;

      if (dealerId !== null) {
        Object.assign(payload, {
          dealerId,
        });
      }

      setUpdateProgramPriceLoader(true);
      const response = await apiPatchRequest(
        dealerId === null
          ? URL_ADMIN_SERVICE_PROGRAM_PRICE
          : URL_ADMIN_SERVICE_PROGRAM_PRICE_DEALER_SPECIFIC,
        payload
      );
      if (response.data.program) {
        updateProgramPricesLocally(response.data.program);
        toast.success("Service Program Prices Updated Successfully!", {
          toastId: "service-program-prices-update",
        });
      } else if (response.data.dealerPricing) {
        updateProgramPricesLocally(response.data.dealerPricing);
        toast.success(
          "Service Program Dealer Specific Prices Updated Successfully!",
          {
            toastId: "service-program--dealer-specific-prices-update",
          }
        );
      }
      setUpdateProgramPriceLoader(false);
    } catch (err) {
      setUpdateProgramPriceLoader(false);
      console.log("err", err);
    }
  };

  const getPriceObjectForTenure = (tenureId) => {
    if (isTieredService) {
      const filteredPrices = program.prices
        .filter((p) => p.serviceTenure === tenureId)
        .map((x) => ({
          ...x,
          price: isNumber(
            x?.hasOwnProperty("customPrice") ? x.customPrice : x?.price
          )
            ? x?.hasOwnProperty("customPrice")
              ? x.customPrice
              : x?.price
            : null,
          isAdminPrice: x.hasOwnProperty("isAdminPrice")
            ? x.isAdminPrice
            : !x.hasOwnProperty("customPrice"),
        }));
      return filteredPrices;
    }

    // For Non Tier Services
    const index = program.prices.findIndex((p) => p.serviceTenure === tenureId);
    if (index !== -1) {
      return {
        ...program.prices[index],
        price: isNumber(
          program.prices[index].hasOwnProperty("customPrice")
            ? program.prices[index].customPrice
            : program.prices[index]?.price
        )
          ? program.prices[index].hasOwnProperty("customPrice")
            ? program.prices[index].customPrice
            : program.prices[index]?.price
          : null,
      };
    }
    return null;
  };

  return (
    <>
      <div
        className="py-2"
        style={isLastIndex ? {} : { borderBottom: "1px solid hsl(0, 0%, 80%)" }}
      >
        <div className="row gap-x-2 px-2 py-2">
          <div className={showPrograms ? "col-md-3" : "col-md-12"}>
            <div
              className={classNames(
                "d-flex justify-content-start align-items-center"
              )}
              role="button"
              tabIndex={0}
              onClick={() => setShowPrograms(!showPrograms)}
            >
              <span
                className={classNames(
                  "rounded service_program_number",
                  !showPrograms && "non-ctive-program"
                )}
              >
                {srNumber}
              </span>
              <span className="space-x-2">
                {capitalizeFirstLetter(program.programName)}
              </span>
            </div>
          </div>
          {showPrograms && (
            <div className="col-md-9 row">
              {serviceTenureOptions.map((tenure, index) => (
                <ServiceProgramItem
                  isTieredService={isTieredService}
                  serviceTierOptions={serviceTierOptions}
                  handleModifyTenureRow={handleModifyTenureRow}
                  key={index}
                  tenure={tenure}
                  priceObj={getPriceObjectForTenure(tenure._id)}
                  serviceTenureOptions={serviceTenureOptions}
                  isFetchServiceTenureLoader={isFetchServiceTenureLoader}
                />
              ))}
            </div>
          )}
        </div>
        {showPrograms && (
          <div className="row pt-2">
            <div className="col-md-3" />
            <div className="col-md-9">
              <CustomButton
                isDisabled={updateProgramPriceLoader}
                showLoading={updateProgramPriceLoader}
                label="Update Prices"
                cssClass="btn-effect-1 btn1"
                clickHandler={handleUpdateProgramPrice}
              />
            </div>
          </div>
        )}
      </div>
      {/* <Modal
        show={selectedClaim !== null}
        onHide={() => {
          setSelectedClaim(null);
          setSelectedClaimFor("");
        }}
      >
        <Modal.Header
          style={{
            borderBottom:
              "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
          }}
          closeButton
        >
          <Modal.Title>
            Claim {`${capitalizeFirstLetter(selectedClaimFor)}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {selectedClaimFor === REPAIR_CLAIM_STATUS.ESCALATED ? (
              <div className="row">
                <div className="col-12">
                  <span>Are you sure to escalate report?</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <label>
                    Remarks
                    <span className="required-class">*</span>
                  </label>
                  <textarea
                    name="note"
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    required={true}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            isDisabled={isClaimSubmitLoader}
            label="Cancel"
            clickHandler={() => {
              setSelectedClaim(null);
              setSelectedClaimFor("");
              setIsClaimSubmitLoader(false);
            }}
            cssClass="btn-effect-1 btn1"
          />
          <CustomButton
            showLoading={isClaimSubmitLoader}
            isDisabled={isClaimSubmitLoader}
            label="Submit"
            clickHandler={handleSubmitClaim}
            style={{ backgroundColor: "#000000" }}
            cssClass="btn-effect-1 btn1"
          />
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default ServiceProgram;
