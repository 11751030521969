import React, { useEffect, useState } from "react";
import "./styles.css";
import _ from "lodash";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomButton from "../../common/Button/CustomButton";
import CustomSelect from "../../common/Select/CustomSelect";
import CustomPagination from "../../common/Pagination";
import { apiGetRequest } from "../../../helpers/Requests";
import {
  URL_ADMIN_MATERIAL_CATALOGUE,
  URL_ADMIN_MATERIAL_CATEGORY,
  URL_DEALER_MATERIAL_CATEGORY,
  URL_MANAGER_MATERIAL_CATEGORY,
  URL_MANAGER_SERVICE_PROGRAMS_LIST,
} from "../../../service/URL";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { PAGINATION_OBJ, USER_ROLE } from "../../../utils/constant";
import CatalogueMaterialCart from "./cart";
import secureLocalStorage from "react-secure-storage";

const RequestMaterial = () => {
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));
  const [showCart, setShowCart] = useState(false);
  const [cartData, setCartData] = useState([]);

  const [materialCatalogues, setMaterialCatalogues] = useState([]);
  const [isFetchMaterialCatLoader, setIsFetchMaterialCatLoader] =
    useState(false);

  const [isFetchMaterialCategoryLoader, setIsFetchMaterialCategoryLoader] =
    useState(false);
  const [materialCaregoriesOptions, setMaterialCategoriesOptions] = useState(
    []
  );

  const [search, setSearch] = useState(null);
  const [selectedMaterialCat, setselectedMaterialCat] = useState(null);

  const [paginationObj, setPaginationObj] = useState(PAGINATION_OBJ);

  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    getMaterialCategories();
  }, []);

  const addFiltersAndPagination = (url, params) => {
    let str = "";
    if (params.page) {
      str = `page=${params.page}`;
    }
    if (params.limit) {
      str = `${str}${str ? "&" : ""}limit=${params.limit}`;
    }
    if (params.search !== null) {
      str = `${str}${str ? "&" : ""}search=${params.search}`;
    }
    if (
      params.selectedMaterialCat !== null &&
      params.selectedMaterialCat !== undefined
    ) {
      str = `${str}${str ? "&" : ""}category=${params.selectedMaterialCat}`;
    }
    return str ? `${url}?${str}` : url;
  };

  const getMaterialCategories = async () => {
    try {
      const url =
        role === USER_ROLE.DEALER
          ? URL_DEALER_MATERIAL_CATEGORY
          : role === USER_ROLE.MANAGER
          ? URL_MANAGER_MATERIAL_CATEGORY
          : URL_ADMIN_MATERIAL_CATEGORY;
      setIsFetchMaterialCategoryLoader(true);
      const response = await apiGetRequest(url);
      if (response.data && response.data.categories) {
        setMaterialCategoriesOptions(
          response.data.categories.map((x) => ({
            ...x,
            label: x.name,
            value: x._id,
          }))
        );
      }
      setIsFetchMaterialCategoryLoader(false);
    } catch (err) {
      setIsFetchMaterialCategoryLoader(false);
      console.log("err", err);
    }
  };

  const getMaterialCatalogues = async (obj = {}) => {
    try {
      const url = addFiltersAndPagination(URL_ADMIN_MATERIAL_CATALOGUE, obj);
      setIsFetchMaterialCatLoader(true);
      const response = await apiGetRequest(url);
      if (response) {
        if (response?.pagination) {
          setPaginationObj({ ...response.pagination });
        }
        setMaterialCatalogues([...(response.data?.catalogues || [])]);
      }
      setIsFetchMaterialCatLoader(false);
    } catch (err) {
      setIsFetchMaterialCatLoader(false);
      console.log("err");
    }
  };

  const handleAddMaterialLocally = (material, method = "add") => {
    const prevData = _.cloneDeep(materialCatalogues || []);
    if (method === "add") {
      prevData.push(material);
    } else if (method === "update") {
      const index = prevData.findIndex((x) => x._id === material._id);
      if (index !== -1) {
        prevData.splice(index, 1, {
          ...material,
        });
      }
    }
    setMaterialCatalogues([...prevData]);
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        getMaterialCatalogues({
          ...paginationObj,
          page: 1,
          search,
          selectedMaterialCat,
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    const filter = { ...paginationObj, page: 1, search };
    if (selectedMaterialCat !== null && selectedMaterialCat !== undefined) {
      Object.assign(filter, {
        selectedMaterialCat,
      });
    }
    getMaterialCatalogues(filter);
  }, [selectedMaterialCat]);

  const handleAddInCart = (data) => {
    const prevData = _.cloneDeep(cartData || []);
    const index = prevData.findIndex((i) => i._id === data._id);
    if (index !== -1) {
      prevData.splice(index, 1);
      toast.info(`${capitalizeFirstLetter(data.name)} is removed from cart.`);
    } else {
      prevData.unshift({ ...data, quantity: 1 });
      toast.info(`${capitalizeFirstLetter(data.name)} added to cart.`);
    }
    setCartData([...prevData]);
  };

  return (
    <>
      <div className="px-4 pt-3 mb-20">
        <div className="d-flex justify-content-between mt-3 text-start align-items-center">
          <div>
            <h3>Request Material</h3>
          </div>
          <div>
            <i
              role="button"
              onClick={() => setShowCart(true)}
              class="fa fa-shopping-cart"
              aria-hidden="true"
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        </div>
        <div className="body-container py-4">
          <div className="body-filter">
            <div className={"w-80 "}>
              <div className="w-100 search-input">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input
                  className=""
                  id="search"
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search by name..."
                />
              </div>
            </div>
            <div className="w-20 space-x-2">
              <CustomSelect
                isClear={true}
                isSearch={true}
                isDisabled={isFetchMaterialCatLoader}
                loading={isFetchMaterialCatLoader}
                placeHolder={"Select Category"}
                label="Category"
                selectedValue={
                  materialCaregoriesOptions?.find(
                    (x) => x.value === selectedMaterialCat
                  ) || null
                }
                handleChange={(e) => setselectedMaterialCat(e?.value || "")}
                options={materialCaregoriesOptions}
              />
            </div>
          </div>
          <div className="checkoverflow">
            <div className="mobileTable">
              {/* bordered */}
              <Table striped hover className="overflow-table">
                <thead>
                  <tr>
                    <th className="w-30">Name</th>
                    <th className="w-30">Description</th>
                    <th className="w-10">Price</th>
                    <th className="w-15 text-end px-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetchMaterialCatLoader ? (
                    <>
                      <div className="loader-container-claim">
                        <div className="loader-claim"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {materialCatalogues.map((material, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex w-100">
                              <div className="w-20 product-image d-flex justify-content-center align-items-center">
                                <img
                                  src={material?.image?.url}
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                  alt="Preview"
                                  class="img-attachment"
                                />
                              </div>
                              <div className="w-75 ">
                                <div className="px-2 product-name-div py-3">
                                  <div>
                                    {capitalizeFirstLetter(material.name)}
                                    {false && (
                                      <span className="out-of-stock-badge space-x-2">
                                        Out of Stock
                                      </span>
                                    )}
                                  </div>
                                  <span className="product-cateory-name">
                                    {capitalizeFirstLetter(
                                      material.materialCategory?.name
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="td-content">
                            <span>
                              {capitalizeFirstLetter(material.description)}
                            </span>
                          </td>
                          <td className="td-content">
                            <span>{`$${material.price}`}</span>
                          </td>
                          <td className="text-center px-4 td-content">
                            <div>
                              <CustomButton
                                cssClass={
                                  cartData.find((c) => c._id === material._id)
                                    ? "btn-effect-5 btn1"
                                    : "btn-effect-1 btn1"
                                }
                                variant={
                                  cartData.find((c) => c._id === material._id)
                                    ? "danger"
                                    : "primary"
                                }
                                label={
                                  cartData.find((c) => c._id === material._id)
                                    ? "Remove from cart"
                                    : "Add to cart"
                                }
                                clickHandler={() => handleAddInCart(material)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
              <div className="custom-pagination">
                <CustomPagination
                  handleChangePage={(page) => {
                    setPaginationObj((prev) => ({
                      ...prev,
                      page,
                    }));
                    getMaterialCatalogues({
                      ...paginationObj,
                      page,
                      search,
                      selectedMaterialCat,
                    });
                  }}
                  handlePaginationLimitChange={(limit) => {
                    setPaginationObj((prev) => ({
                      ...prev,
                      page: 1,
                      limit,
                    }));
                    getMaterialCatalogues({
                      ...paginationObj,
                      page: 1,
                      limit,
                      search,
                      selectedMaterialCat,
                    });
                  }}
                  paginationObj={paginationObj}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCart && (
        <CatalogueMaterialCart
          setCartData={setCartData}
          cartData={cartData}
          show={showCart}
          handleClose={() => {
            setShowCart(false);
          }}
        />
      )}
    </>
  );
};

export default RequestMaterial;
