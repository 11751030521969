import React from "react";

const TrustSheild = () => {
  return (
    <>
      <div class="break">
        <h1
          style={{
            paddingTop: "1pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          Schedule D - Trust Shield All-Window Warranty Program
        </h1>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
          This is a warranty program for your vehicle's windows (including the
          sunroof, if applicable), covering chips and cracks caused by rocks or
          debris. Here's a simple breakdown:
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <ol id="l1">
          <li data-list-text="1.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Coverage Details:
            </h1>
            <ul id="l2">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Maximum coverage: $1500 per claim, up to $4000 for all
                  windows.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  One window replacement claim per year.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Provincial coverage only for window replacement if repair is
                  not possible.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Replacement authorized for damages over 6 inches as determined
                  by a technician.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="2.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Warranty Program Basics:
            </h1>
            <ul id="l3">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  This is a product warranty, not insurance. Buying it is
                  optional.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  The warranty strengthens and protects windows from chips and
                  cracks caused by debris.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  A $50 service fee is charged for reapplying the window
                  protection coating during replacement.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Maximum combined limit for replacements: $4000 during the
                  term.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="3.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Terms and Conditions:
            </h1>
            <ul id="l4">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Purchase only available at the time of the vehicle sale.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Maximum warranty term: 8 years, starting from the Contract
                  Purchase Date.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Attempted repair for damages smaller than a Canadian
                  two-dollar coin or cracks less than 6 inches.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  If deemed unrepairable, replacement with like kind and quality
                  glass.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Maximum combined limit: $4000 throughout the term, varies with
                  the chosen plan duration.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="4.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Contract Exclusions:
            </h1>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              This contract does not cover, and we are not responsible for, the
              following:
            </p>
            <ul id="l4">
              <li data-list-text="">
                <h1
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Pre-existing Conditions:{" "}
                  <span class="p">
                    Any repair or replacement related to the vehicle&#39;s
                    damage or condition before the Contract Purchase Date is not
                    covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Service Coverage and Purchase:{" "}
                  <span class="p">
                    Any repair or replacement not explicitly listed in the
                    Schedule of Services or not purchased as identified on the
                    Registration Page is not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Post-Expiration Damages:{" "}
                  <span class="p">
                    Damages occurring after the expiration or termination of
                    this contract are not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Accidents and Collisions:{" "}
                  <span class="p">
                    Damages resulting from any motor vehicle accident,
                    regardless of fault, or driver error are not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Nature and Disaster Events:{" "}
                  <span class="p">
                    Damages caused by fire, theft, explosion, natural disasters,
                    civil unrest, terrorism, war, or radioactive contamination
                    are not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Manufacturer Defects:{" "}
                  <span class="p">
                    Repairs or replacements due to manufacturer defects are not
                    covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Intentional Actions and Negligence:{" "}
                  <span class="p">
                    Loss or damage caused intentionally, by misuse, abuse,
                    negligence, racing, competitive driving, lack of
                    maintenance, or failure to protect covered components is not
                    covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Vehicle Alterations and Misuse:{" "}
                  <span class="p">
                    Damages resulting from alterations not recommended by us or
                    the manufacturer, or if the vehicle is used in a manner not
                    recommended, are not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Non-Original Parts:{" "}
                  <span class="p">
                    Loss or damage to parts not originally equipped or
                    recommended by the manufacturer, including custom wheels,
                    rims, accessory kits, or seat protectors, is not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Warranty and Service Contracts:{" "}
                  <span class="p">
                    - Loss or damage covered by the manufacturer&#39;s or
                    seller&#39;s original warranty, or any other warranty or
                    service contract, is not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Accuracy of Information:{" "}
                  <span class="p">
                    - Loss or damage if the information provided by you or the
                    service facility cannot be verified as accurate is not
                    covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "42pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Timing of Purchase:{" "}
                  <span class="p">
                    - Loss or damage if the Contract Purchase Date is more than
                    thirty (30) days after you purchased or leased the vehicle
                    is not covered.
                  </span>
                </h1>
              </li>
              <li data-list-text="">
                <h1
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  Age of Used Vehicles:{" "}
                  <span class="p">
                    - For used vehicles, loss or damage is not covered if the
                    Contract Purchase Date is more than ten (10) years after the
                    original purchaser or lessee first purchased or leased the
                    vehicle.
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Remember, this contract has specific exclusions, so be sure to
                  check the details for a complete understanding.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="5.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                textAlign: "left",
              }}
            >
              Procedure for Service:
            </h1>
            <ul id="l6">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Contact the selling dealer for service, or to receive
                  permission to use another authorized service facility.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Conditions of Service:
            </h1>
            <ul id="l7">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Some services may require multiple appointments and up to
                  three days without the vehicle.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Not all issues may be resolved, but best efforts will be made.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="7.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Transferring the Contract:
            </h1>
            <ul id="l8">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Only the original customer can transfer the contract to a
                  private party within 30 days of vehicle sale.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Contract is vehicle-specific and not transferable to other
                  vehicles.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="8.">
            <h1
              style={{
                paddingLeft: "16pt",
                textIndent: "-10pt",
                lineHeight: "12pt",
                textAlign: "left",
              }}
            >
              Cancelling the Contract:
            </h1>
            <ul id="l9">
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  You can cancel within 30 days for a 100% refund if no services
                  are performed.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  We may cancel and refund a pro-rated amount if necessary.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  Contract terminates automatically under specific conditions:
                  vehicle write-off, sale, or expiry of the term.
                </p>
                <h1
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Remember:
                </h1>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  The warranty is for glass and mirrors only, not the whole side
                  of the vehicle.
                </p>
              </li>
              <li data-list-text="">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                  }}
                >
                  You must apply the product coating to all applicable areas and
                  have proof of application for driver and passenger coverage.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  );
};

export default TrustSheild;
