import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import _ from "lodash";

const useStaff = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var token = JSON.parse(secureLocalStorage.getItem("Token"));

  const [role] = React.useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const [isStaffListLoader, setIsStaffLoader] = useState();
  const [staffsData, setStaffsData] = useState([]);

  useEffect(() => {
    getAllStaffs();
  }, []);

  const handleDataUpdateById = (data) => {
    const index = staffsData.findIndex((x) => x._id === data.id);
    if (index !== -1) {
      const prevData = _.cloneDeep(staffsData);
      prevData.splice(index, 1, {
        ...prevData[index],
        ...data,
      });
      setStaffsData([...prevData]);
    }
  };

  const getAllStaffs = () => {
    const allStaffs = `${apiUrl}/staff/list`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: headers,
    };
    setIsStaffLoader(true);
    fetch(allStaffs, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStaffsData(data);
        setIsStaffLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsStaffLoader(false);
      });
  };

  return { isStaffListLoader, staffsData, handleDataUpdateById };
};

export default useStaff;
