import React, { useContext, useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Dealer from "../components/dashboardComponent/dealer/dealer";
import FinanceManager from "../components/dashboardComponent/dealer/FinanceManager";
import AddServices from "../components/dashboardComponent/dealer/AddServices";
import AddRegistrants from "../components/dashboardComponent/registrants/AddRegistrants";
import ShowAllReg from "../components/dashboardComponent/registrants/showAllRegistrants/ShowAllReg";

//Dashboard-Components
import DashboardDefault from "../components/dashboardComponent/dashboardDefault/dashboardDefault";
import CeramxCarfax from "../components/dashboardComponent/registrants/carfax/CeramxCarfax";
import Request from "../components/dashboardComponent/PendingRequest/Request";
import RegistrationReports from "../components/dashboardComponent/reports/RegistrationReport/RegistrationReports";
import RemittanceReport from "../components/dashboardComponent/reports/RemittanceReport/RemittanceReport";
import RequestManager from "../components/dashboardComponent/request/RequestManager";
import SmartCares from "../components/dashboardComponent/productResources/SmartCares";
import Ceramx from "../components/dashboardComponent/productResources/Ceramx";
import CeramxAllWindow from "../components/dashboardComponent/productResources/CeramxAllWindow";
import TireAnDrim from "../components/dashboardComponent/productResources/TireAnDrim";
import KeyFobexChange from "../components/dashboardComponent/productResources/KeyFobexChange";
import AntiTheft from "../components/dashboardComponent/productResources/AntiTheft";
import CatConverter from "../components/dashboardComponent/productResources/CatConverter";
import ClaimReq from "../components/dashboardComponent/claimRequest/ClaimReq";
import CreateDealer from "../components/dashboardComponent/dealer/CreateDealer";
import CreateManager from "../components/dashboardComponent/manager/CreateManager";
import { Accordion } from "react-bootstrap";
import AntiTheftreal from "../components/dashboardComponent/productResources/antitheft2";
import Ceramicexterior from "../components/dashboardComponent/productResources/ceramicexterior";
import CreateStaff from "../components/dashboardComponent/staff/CreateStaff";
import secureLocalStorage from "react-secure-storage";
import AccountDetails from "../components/dashboardComponent/registrants/accountDetails";
import ManagerAccountDetails from "../components/dashboardComponent/manager/AccountDetails";
import { Icon } from "@iconify/react";
import ListDealer from "../components/dashboardComponent/dealer/ListDealer";
import ListManager from "../components/dashboardComponent/manager/ListManager";
import ListStaff from "../components/dashboardComponent/staff/ListStaff";
import { capitalizeFirstLetter } from "../utils/utils";
import ServiceManagement from "../components/dashboardComponent/ServiceManagement";
import CatalogueManagement from "../components/dashboardComponent/catalogueManagement";
import OrderManagement from "../components/dashboardComponent/orderManagement";
import RequestMaterial from "../components/dashboardComponent/material/RequestMaterial";
import OrderReports from "../components/dashboardComponent/reports/OrderReport/OrderReports";
import CreateAdmin from "../components/dashboardComponent/Admin/CreateAdmin";
import ListAdmin from "../components/dashboardComponent/Admin/ListAdmin";
import AuthContext from "../helpers/authContext";
import CreateServiceManager from "../components/dashboardComponent/serviceManager/createServiceManager";
import ListServiceManager from "../components/dashboardComponent/serviceManager/listServiceManagers";
import Loader from "../components/common/Loader/Loader";
import ServiceRequestModal from "./serviceRequest";
import { formatPhoneNumber } from "react-phone-number-input";
import ReportLog from "./reportLog";

const Dashboard = () => {
  const { setTimezone, isLoadingProfile, serviceManagerProfile } =
    useContext(AuthContext);

  const [activeTab, setActiveTab] = useState("dashboardDefault");
  const [showMenu, setShowMenu] = useState(true);
  const [role, setRole] = useState(null);
  const [searchFieldOnShowReg, setSearchFieldOnShowReg] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const verified = JSON.parse(secureLocalStorage.getItem("isVerified"));
    setRole(JSON.parse(secureLocalStorage.getItem("Role")));
    if (verified === false) {
      navigate("/confirmation");
      console.log("dashboard");
    }
  }, []);

  const [registrants, setRegistrants] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    coApplicant: "",
    customerComment: "",
    company: "",
    lienholder: "",
    phone: "+1",
    state: "",
  });
  const [vehical, setVehical] = useState({});

  const [inputData, setInputData] = useState({
    email: "",
    address: "",
    city: "",
    name: "",
    // phone: "",
    postalCode: "",
    profileImage: "",
    region: "",
    state: "",
    faxNumber: "",
    phone: "+1",
    newPassword: "",
    password: "",
    timezone: "",
    service_manager_id: "",
  });
  const [managerInput, setManagerInput] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  const [showEditRegistrants, setShowEditRegistrants] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const handleTabClick = (tabName) => {
    setRegistrants("");
    setVehical("");
    setShowEditRegistrants(false);
    setActiveTab(tabName);
    setSearchFieldOnShowReg(false);
    setShowMenu(false);
  };
  const [isLoading, setIsLoading] = useState(true);

  const [dealerObj, setDealerObj] = useState(null);
  const [managerObj, setManagerObj] = useState(null);

  useEffect(() => {
    // Dealer data
    if (role === "Dealer") {
      fetch(`${apiUrl}/dealer/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed with status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setIsLoading(true);
          // console.log("Dealer", data);
          setDealerObj({ ...data });
          setInputData({
            email: data.contactEmail,
            address: data.address,
            city: data.city,
            name: data.name,
            phone: data.phone,
            postalCode: data.postalCode,
            firstName: data.firstName,
            lastName: data.lastName,
            region: data.region,
            state: data.state,
            faxNumber: data.faxNumber,
            timezone: data.timezone,
            service_manager_id: data.serviceManager?._id,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching dealer profile:", error);
          setIsLoading(false);
        });
    } else if (role === "Manager") {
      // Manager Data
      fetch(`${apiUrl}/manager/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed with status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Manager", data);
          setManagerObj({ ...data });
          setManagerInput({
            email: data.contactEmail,
            firstName: data.firstName,
            lastName: data.lastName,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching manager profile:", error);
        });
    }
  }, [role, accessToken]);

  const handleLogout = () => {
    secureLocalStorage.removeItem("Token");
    secureLocalStorage.removeItem("UserID");
    secureLocalStorage.removeItem("Role");
    secureLocalStorage.removeItem("isVerified");
    setTimezone(null);
    navigate("/");
  };

  if (!role) {
    return null;
  }

  return (
    <div>
      <div className="container" style={{ paddingLeft: 0 }}>
        <div className="row ">
          <div
            className={`dashboard-sidebar bg-2 w-sm-100 dash-aside  ${
              showMenu ? " active" : ""
            }`}
          >
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/">
                <img
                  className="logo-dash"
                  src="../../../assets/img/logowhite.svg"
                  alt="Logo"
                />
              </Link>
              <Icon
                icon="icon-park-outline:close-small"
                color="white"
                width="48"
                height="48"
                className="d-md-none"
                onClick={() => setShowMenu(!showMenu)}
              />
            </div>
            <div className="ltn__tab-menu-list ">
              <div className="nav">
                <button
                  className={`dashboard-shift ${
                    activeTab === "dashboardDefault" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  onClick={() => handleTabClick("dashboardDefault")}
                >
                  {`${capitalizeFirstLetter(role)} Dashboard`}
                  <i className="fas fa-home" />
                </button>
                {/* Admin */}
                {role === "SuperAdmin" && (
                  <Accordion defaultActiveKey="-1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Admins</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateAdmin" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Admins"
                          href="#admin"
                          onClick={() => handleTabClick("CreateAdmin")}
                        >
                          Add New Admin
                        </a>
                        <a
                          className={activeTab === "listAdmin" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="listAdmin"
                          href="#listAdmin"
                          onClick={() => handleTabClick("listAdmin")}
                        >
                          Admins List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header> Claim Requests</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "claimRequests" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Approval Request Lists"
                          href="#approvalrequestlist"
                          onClick={() => handleTabClick("claimRequests")}
                        >
                          All Claims
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Reports</Accordion.Header>
                      <Accordion.Body>
                        {/* <a
                          // className={registrationReportClass}
                          className={
                            activeTab === "registrationReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Registration Report"
                          href="#registrationreport"
                          onClick={() => handleTabClick("registrationReport")}
                        >
                          Registration Reports
                        </a> */}
                        <a
                          // className={remittanceReportClass}
                          className={
                            activeTab === "remittanceReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Remittance Report"
                          href="#remittancereport"
                          onClick={() => handleTabClick("remittanceReport")}
                        >
                          Remittance Report
                        </a>
                        <a
                          className={
                            activeTab === "orderReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Material Order Report"
                          href="#orderReport"
                          onClick={() => handleTabClick("orderReport")}
                        >
                          Material Order Report
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}

                {/* Admin */}
                {role === "Admin" && (
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="12">
                      <Accordion.Header>ET Managers</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateServiceManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="ServiceManager"
                          href="#servicemanager"
                          onClick={() => handleTabClick("CreateServiceManager")}
                        >
                          Add New ET Manager
                        </a>
                        <a
                          className={
                            activeTab === "ListServiceManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="ServiceManager"
                          href="#servicemanager"
                          onClick={() => handleTabClick("ListServiceManager")}
                        >
                          ET Managers List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Dealers</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateDealer" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Dealers"
                          href="#dealer"
                          onClick={() => handleTabClick("CreateDealer")}
                        >
                          Add New Dealer
                        </a>
                        <a
                          className={activeTab === "ListDealer" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Dealers"
                          href="#dealer"
                          onClick={() => handleTabClick("ListDealer")}
                        >
                          Dealers List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Managers</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Manager"
                          href="#manager"
                          onClick={() => handleTabClick("CreateManager")}
                        >
                          Add New Manager
                        </a>
                        <a
                          className={
                            activeTab === "ListManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Manager"
                          href="#manager"
                          onClick={() => handleTabClick("ListManager")}
                        >
                          Managers List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Service Advisors</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateStaff" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Staff"
                          href="#staff"
                          onClick={() => handleTabClick("CreateStaff")}
                        >
                          Add New Service Advisor
                        </a>
                        <a
                          className={activeTab === "ListStaff" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Staff"
                          href="#staff"
                          onClick={() => handleTabClick("ListStaff")}
                        >
                          Service Advisors List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                      <Accordion.Header>Registrants</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "addRegistrant" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Add Registrants"
                          href="#ltn_tab_1_10"
                          onClick={() => handleTabClick("addRegistrant")}
                        >
                          Add Registrants
                        </a>
                        <a
                          className={
                            activeTab === "showAllRegistrants" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Show All Registrants"
                          href="#ltn_tab_1_11"
                          onClick={() => handleTabClick("showAllRegistrants")}
                        >
                          Show All Registrants
                        </a>
                        <a
                          className={
                            activeTab === "registrationLogs" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Registrants Logs"
                          href="#ltn_tab_1_11"
                          onClick={() => handleTabClick("registrationLogs")}
                        >
                          Registrants Logs
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Reports</Accordion.Header>
                      <Accordion.Body>
                        <a
                          // className={registrationReportClass}
                          className={
                            activeTab === "registrationReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Registration Report"
                          href="#registrationreport"
                          onClick={() => handleTabClick("registrationReport")}
                        >
                          Registration Reports
                        </a>
                        <a
                          // className={remittanceReportClass}
                          className={
                            activeTab === "remittanceReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Remittance Report"
                          href="#remittancereport"
                          onClick={() => handleTabClick("remittanceReport")}
                        >
                          Remittance Report
                        </a>
                        <a
                          className={
                            activeTab === "orderReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Material Order Report"
                          href="#orderReport"
                          onClick={() => handleTabClick("orderReport")}
                        >
                          Material Order Report
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Materials</Accordion.Header>
                      <Accordion.Body>
                        <a
                          // className={viewPreviousRequests}
                          className={
                            activeTab === "catalogueManagement" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Catalogue Management"
                          href="#catalogueManagement"
                          onClick={() => handleTabClick("catalogueManagement")}
                        >
                          Catalogue
                        </a>
                        {/* <a
                          // className={viewPreviousRequests}
                          className={
                            activeTab === "requestManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="View Previous Requests"
                          href="#requestManager"
                          onClick={() => handleTabClick("requestManager")}
                        >
                          View Previous Requests
                        </a> */}
                        <a
                          // className={viewPreviousRequests}
                          className={
                            activeTab === "ordermanagement" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Request Materials"
                          href="#ordermanagement"
                          onClick={() => handleTabClick("ordermanagement")}
                        >
                          Orders
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* Product Resources */}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header> Product Resources</Accordion.Header>
                      <Accordion.Body>
                        <a
                          // className={ceramixAllWindowClass}
                          className={
                            activeTab === "ceramixAllWindow" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Ceramx All-Window Warranty Program"
                          href="#ceramxallwindow"
                          onClick={() => handleTabClick("ceramxallwindow")}
                        >
                          Oil Change Program
                        </a>
                        <a
                          // className={smartCareClass}
                          className={activeTab == "smartCare" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Smart Care"
                          href="#smartcare"
                          onClick={() => handleTabClick("smartCare")}
                        >
                          Trust Care
                        </a>
                        <a
                          // className={catConverterClass}
                          className={
                            activeTab == "catConverter" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Cat. Converter Anti-Theft Plus"
                          href="#catconverter"
                          onClick={() => handleTabClick("catConverter")}
                        >
                          Trust Shield
                        </a>
                        <a
                          // className={tireAndRimClass}
                          className={activeTab == "tireAndRim" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Tire and Rim"
                          href="#tireAndRim"
                          // onClick={handleToggle}
                          onClick={() => handleTabClick("tireAndRim")}
                        >
                          Tire & Rim
                        </a>
                        <a
                          // className={keyFobExchangeClass}
                          className={
                            activeTab == "keyFobExchange" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Key Fob Exchange"
                          href="#keyfobexchange"
                          // onClick={handleToggle}
                          onClick={() => handleTabClick("keyFobExchange")}
                        >
                          Key Fob Replacement
                        </a>
                        <a
                          // className={ceramxClass}
                          className={activeTab == "ceramx" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Ceramx"
                          href="#ceramx"
                          onClick={() => handleTabClick("ceramx")}
                        >
                          Brakes & Rotors
                        </a>

                        <a
                          // className={antiTheftClass}
                          className={
                            activeTab == "antiTheftEtch" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Anti-TheftEtch"
                          href="#antitheftetch"
                          onClick={() => handleTabClick("antiTheftEtch")}
                        >
                          Anti-Theft
                        </a>
                        <a
                          // className={antiTheftClass}
                          className={
                            activeTab == "creamicexterior" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="creamicexterior"
                          href="#creamicexterior"
                          onClick={() => handleTabClick("creamicexterior")}
                        >
                          Ceramic Exterior
                        </a>
                        <a
                          // className={antiTheftClass}
                          className={activeTab == "antiTheft" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Anti-Theft"
                          href="#antitheft"
                          onClick={() => handleTabClick("antiTheft")}
                        >
                          Appearance Trust
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* Service MAangement */}
                    <Accordion eventKey="9">
                      <a
                        className={
                          activeTab === "serviceManagement" ? "active" : ""
                        }
                        data-bs-toggle="tab"
                        name="Service Management"
                        href="#serviceManagement"
                        onClick={() => handleTabClick("serviceManagement")}
                      >
                        Service Management
                      </a>
                    </Accordion>
                    {/* User Management */}
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>User Management</Accordion.Header>
                      <Accordion.Body>
                        <a
                          //   className={pendingRequests}
                          className={
                            activeTab === "pendingRequests" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Approval Request Lists"
                          href="#approvalrequestlist"
                          onClick={() => handleTabClick("pendingRequests")}
                        >
                          User List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header> Claim Requests</Accordion.Header>
                      <Accordion.Body>
                        <a
                          //   className={pendingRequests}
                          className={
                            activeTab === "claimRequests" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Approval Request Lists"
                          href="#approvalrequestlist"
                          onClick={() => handleTabClick("claimRequests")}
                        >
                          All Claims
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}

                {role === "Dealer" && (
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="12">
                      <Accordion.Header>
                        {isLoadingProfile ? (
                          <Loader isSmallView={true} cssClass={"text-black"} />
                        ) : (
                          <div className="" style={{ fontSize: "16px" }}>
                            {serviceManagerProfile
                              ? `Your Trust Rep is ${`${capitalizeFirstLetter(
                                  serviceManagerProfile?.firstName
                                )} ${capitalizeFirstLetter(
                                  serviceManagerProfile?.lastName
                                )}`}`
                              : "Your Trust Rep is Scott Dyer"}
                            <span className="" style={{ color: "#a8b1ad" }}>
                              {` ${
                                serviceManagerProfile
                                  ? formatPhoneNumber(
                                      serviceManagerProfile.phone
                                    )
                                  : "587-501-0370"
                              }`}
                            </span>
                          </div>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={activeTab === "ServiceReq" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="ServiceReq"
                          href="#servicereq"
                          onClick={() => setModalShow(true)}
                        >
                          Service Request
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                      {/* <Accordion.Header>{isLoading ? 'Loading...' : `${inputData?.firstName} ${inputData?.lastName}`}</Accordion.Header> */}
                      <div>
                        {isLoading ? (
                          <div className="loader-container-dash">
                            <div className="loader-dash"></div>
                          </div>
                        ) : (
                          <Accordion.Header>
                            {inputData?.firstName} {inputData?.lastName}
                          </Accordion.Header>
                        )}
                        {/* {!isLoading && (
                          <Accordion.Item eventKey="13">
                            
                            <Accordion.Body>
                             
                            </Accordion.Body>
                          </Accordion.Item>
                        )} */}
                      </div>
                      <Accordion.Body>
                        <a
                          // className={addRegistrantClass}
                          className={activeTab === "accDetails" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Account Details"
                          href="#accountdetails"
                          onClick={() => handleTabClick("accDetails")}
                        >
                          Account Details
                        </a>
                        {/* <a
                          // className={addRegistrantClass}
                          className={
                            activeTab == "changePassword" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Change Password"
                          href="#changepassword"
                          onClick={() => handleTabClick("changePassword")}
                        >
                          Change Password
                        </a> */}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Managers</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Manager"
                          href="#manager"
                          onClick={() => handleTabClick("CreateManager")}
                        >
                          Add New Manager
                        </a>
                        <a
                          className={
                            activeTab === "ListManager" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Manager"
                          href="#manager"
                          onClick={() => handleTabClick("ListManager")}
                        >
                          List Managers
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className=" ">
                      <Accordion.Header>Service Advisors</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "CreateStaff" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Staff"
                          href="#staff"
                          onClick={() => handleTabClick("CreateStaff")}
                        >
                          Add New Service Advisor
                        </a>
                        <a
                          className={activeTab === "ListStaff" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Staff"
                          href="#staff"
                          onClick={() => handleTabClick("ListStaff")}
                        >
                          Service Advisors List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                      <Accordion.Header>Registrants</Accordion.Header>
                      <Accordion.Body>
                        <a
                          // className={addRegistrantClass}
                          className={
                            activeTab === "addRegistrant" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Add Registrants"
                          href="#ltn_tab_1_10"
                          onClick={() => handleTabClick("addRegistrant")}
                        >
                          Add Registrants
                        </a>
                        <a
                          // ref={showAllRegistrantsRef}
                          className={
                            activeTab === "showAllRegistrants" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Show All Registrants"
                          href="#ltn_tab_1_11"
                          onClick={() => handleTabClick("showAllRegistrants")}
                        >
                          Show All Registrants
                        </a>
                        <a
                          className={
                            activeTab === "registrationLogs" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Registrants Logs"
                          href="#ltn_tab_1_11"
                          onClick={() => handleTabClick("registrationLogs")}
                        >
                          Registrants Logs
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Reports</Accordion.Header>
                      <Accordion.Body>
                        <a
                          className={
                            activeTab === "registrationReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Registration Report"
                          href="#registrationreport"
                          onClick={() => handleTabClick("registrationReport")}
                        >
                          Registration Reports
                        </a>
                        <a
                          className={
                            activeTab === "remittanceReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Remittance Report"
                          href="#remittancereport"
                          onClick={() => handleTabClick("remittanceReport")}
                        >
                          Remittance Report
                        </a>
                        <a
                          className={
                            activeTab === "orderReport" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Material Order Report"
                          href="#orderReport"
                          onClick={() => handleTabClick("orderReport")}
                        >
                          Material Order Report
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* materials */}
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Materials</Accordion.Header>
                      <Accordion.Body>
                        <a
                          // className={requestMaterialsClass}
                          className={
                            activeTab === "requestMaterial" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Request Materials"
                          href="#requestMaterial"
                          onClick={() => handleTabClick("requestMaterial")}
                        >
                          Request Materials
                        </a>
                        <a
                          className={
                            activeTab === "ordermanagement" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Request Materials"
                          href="#ordermanagement"
                          onClick={() => handleTabClick("ordermanagement")}
                        >
                          Orders
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Product Resources */}
                    <Accordion.Item eventKey="4" className=" ">
                      <Accordion.Header>Product Resources</Accordion.Header>
                      <Accordion.Body>
                        <a
                          // className={ceramixAllWindowClass}
                          className={
                            activeTab == "ceramixAllWindow" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Ceramx All-Window Warranty Program"
                          href="#ceramxallwindow"
                          onClick={() => handleTabClick("ceramxallwindow")}
                        >
                          Oil Change Program
                        </a>
                        <a
                          // className={smartCareClass}
                          className={activeTab == "smartCare" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Smart Care"
                          href="#smartcare"
                          onClick={() => handleTabClick("smartCare")}
                        >
                          Trust Care
                        </a>
                        <a
                          // className={catConverterClass}
                          className={
                            activeTab == "catConverter" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Cat. Converter Anti-Theft Plus"
                          href="#catconverter"
                          onClick={() => handleTabClick("catConverter")}
                        >
                          Trust Shield
                        </a>
                        <a
                          // className={tireAndRimClass}
                          className={activeTab == "tireAndRim" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Tire and Rim"
                          href="#tireAndRim"
                          // onClick={handleToggle}
                          onClick={() => handleTabClick("tireAndRim")}
                        >
                          Tire & Rim
                        </a>
                        <a
                          // className={keyFobExchangeClass}
                          className={
                            activeTab == "keyFobExchange" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Key Fob Exchange"
                          href="#keyfobexchange"
                          // onClick={handleToggle}
                          onClick={() => handleTabClick("keyFobExchange")}
                        >
                          Key Fob Replacement
                        </a>
                        <a
                          // className={ceramxClass}
                          className={activeTab == "ceramx" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Ceramx"
                          href="#ceramx"
                          onClick={() => handleTabClick("ceramx")}
                        >
                          Brakes & Rotors
                        </a>

                        <a
                          // className={antiTheftClass}
                          className={
                            activeTab == "antiTheftEtch" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Anti-TheftEtch"
                          href="#antitheftetch"
                          onClick={() => handleTabClick("antiTheftEtch")}
                        >
                          Anti-Theft
                        </a>
                        <a
                          // className={antiTheftClass}
                          className={
                            activeTab == "creamicexterior" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="creamicexterior"
                          href="#creamicexterior"
                          onClick={() => handleTabClick("creamicexterior")}
                        >
                          Trust Guard
                        </a>

                        <a
                          // className={antiTheftClass}
                          className={activeTab == "antiTheft" ? "active" : ""}
                          data-bs-toggle="tab"
                          name="Anti-Theft"
                          href="#antitheft"
                          onClick={() => handleTabClick("antiTheft")}
                        >
                          Appearance Trust
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* Claim Req */}
                    <Accordion.Item eventKey="6">
                      <Accordion.Header> Claim Requests</Accordion.Header>
                      <Accordion.Body>
                        <a
                          //   className={pendingRequests}
                          className={
                            activeTab === "claimRequests" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Approval Request Lists"
                          href="#approvalrequestlist"
                          onClick={() => handleTabClick("claimRequests")}
                        >
                          All Claims
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className=" ">
                      <Accordion.Header>User Management</Accordion.Header>
                      <Accordion.Body>
                        <a
                          //   className={pendingRequests}
                          className={
                            activeTab === "pendingRequests" ? "active" : ""
                          }
                          data-bs-toggle="tab"
                          name="Approval Request Lists"
                          href="#approvalrequestlist"
                          onClick={() => handleTabClick("pendingRequests")}
                        >
                          User List
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}

                {/* Manager */}
                {role === "Manager" && (
                  <>
                    <div
                      className="accordion accordion-flush d-none"
                      id="accordionExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="true"
                            aria-controls="collapseNine"
                          >
                            Manager
                          </button>
                        </h2>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingNine"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <a
                              className={
                                activeTab === "addservices" ? "active" : ""
                              }
                              data-bs-toggle="tab"
                              name="Add Services"
                              href="#addservices"
                              onClick={() => handleTabClick("addservices")}
                            >
                              Add Services
                            </a>
                          </div>
                        </div>
                      </div>

                      {/* Request */}
                      <div className="accordion-item d-none">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Request
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#materials"
                        >
                          <div class="accordion-body"></div>
                        </div>
                      </div>
                    </div>

                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>
                          {isLoadingProfile ? (
                            <Loader
                              isSmallView={true}
                              cssClass={"text-black"}
                            />
                          ) : (
                            <div className="" style={{ fontSize: "16px" }}>
                              {serviceManagerProfile
                                ? `Your Trust Rep is ${`${capitalizeFirstLetter(
                                    serviceManagerProfile?.firstName
                                  )} ${capitalizeFirstLetter(
                                    serviceManagerProfile?.lastName
                                  )}`}`
                                : "Your Trust Rep is Scott Dyer"}
                              <span className="" style={{ color: "#a8b1ad" }}>
                                {` ${
                                  serviceManagerProfile
                                    ? formatPhoneNumber(
                                        serviceManagerProfile.phone
                                      )
                                    : "587-501-0370"
                                }`}
                              </span>
                            </div>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <a
                            className={
                              activeTab === "ServiceReq" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="ServiceReq"
                            href="#servicereq"
                            onClick={() => setModalShow(true)}
                          >
                            Service Request
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="13">
                        <Accordion.Header>
                          {managerInput?.firstName} {managerInput?.lastName}
                        </Accordion.Header>
                        <Accordion.Body>
                          <a
                            // className={addRegistrantClass}
                            className={
                              activeTab === "ManageraccDetails" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Account Details"
                            href="#accountdetails"
                            onClick={() => handleTabClick("ManageraccDetails")}
                          >
                            Account Details
                          </a>
                          {/* <a
                            // className={addRegistrantClass}
                            className={
                              activeTab == "ManagerchangePassword"
                                ? "active"
                                : ""
                            }
                            data-bs-toggle="tab"
                            name="Change Password"
                            href="#changepassword"
                            onClick={() =>
                              handleTabClick("ManagerchangePassword")
                            }
                          >
                            Change Password
                          </a> */}
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* <Accordion.Item eventKey="7" className=" ">
                        <Accordion.Header>Service Advisors</Accordion.Header>
                        <Accordion.Body>
                          <a
                            className={
                              activeTab === "CreateStaff" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Staff"
                            href="#staff"
                            onClick={() => handleTabClick("CreateStaff")}
                          >
                            Add New Service Advisor
                          </a>
                          <a
                            className={
                              activeTab === "ListStaff" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Staff"
                            href="#staff"
                            onClick={() => handleTabClick("ListStaff")}
                          >
                            Service Advisors List
                          </a>
                        </Accordion.Body>
                      </Accordion.Item> */}
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>Registrants</Accordion.Header>
                        <Accordion.Body>
                          <a
                            // className={addRegistrantClass}
                            className={
                              activeTab === "addRegistrant" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Add Registrants"
                            href="#ltn_tab_1_10"
                            onClick={() => handleTabClick("addRegistrant")}
                          >
                            Add Registrants
                          </a>
                          <a
                            // ref={showAllRegistrantsRef}
                            className={
                              activeTab === "showAllRegistrants" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Show All Registrants"
                            href="#ltn_tab_1_11"
                            onClick={() => handleTabClick("showAllRegistrants")}
                          >
                            Show All Registrants
                          </a>
                          <a
                            className={
                              activeTab === "registrationLogs" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Registrants Logs"
                            href="#ltn_tab_1_11"
                            onClick={() => handleTabClick("registrationLogs")}
                          >
                            Registrants Logs
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Reports</Accordion.Header>
                        <Accordion.Body>
                          <a
                            className={
                              activeTab === "registrationReport" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Registration Report"
                            href="#registrationreport"
                            onClick={() => handleTabClick("registrationReport")}
                          >
                            Registration Reports
                          </a>
                          <a
                            className={
                              activeTab === "remittanceReport" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Remittance Report"
                            href="#remittancereport"
                            onClick={() => handleTabClick("remittanceReport")}
                          >
                            Remittance Report
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Materials</Accordion.Header>
                        <Accordion.Body>
                          <a
                            className={
                              activeTab === "requestMaterial" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Request Materials"
                            href="#requestMaterial"
                            onClick={() => handleTabClick("requestMaterial")}
                          >
                            Request Materials
                          </a>
                          <a
                            className={
                              activeTab === "ordermanagement" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Request Materials"
                            href="#ordermanagement"
                            onClick={() => handleTabClick("ordermanagement")}
                          >
                            Orders
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* Product Resources */}
                      <Accordion.Item eventKey="3" className="d-none">
                        <Accordion.Header> Product Resources</Accordion.Header>
                        <Accordion.Body>
                          <a
                            // className={ceramixAllWindowClass}
                            className={
                              activeTab == "ceramixAllWindow" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Ceramx All-Window Warranty Program"
                            href="#ceramxallwindow"
                            onClick={() => handleTabClick("ceramxallwindow")}
                          >
                            Oil Change Program
                          </a>
                          <a
                            // className={smartCareClass}
                            className={activeTab == "smartCare" ? "active" : ""}
                            data-bs-toggle="tab"
                            name="Smart Care"
                            href="#smartcare"
                            onClick={() => handleTabClick("smartCare")}
                          >
                            Trust Care
                          </a>
                          <a
                            // className={catConverterClass}
                            className={
                              activeTab == "catConverter" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Cat. Converter Anti-Theft Plus"
                            href="#catconverter"
                            onClick={() => handleTabClick("catConverter")}
                          >
                            Trust Shield
                          </a>
                          <a
                            // className={tireAndRimClass}
                            className={
                              activeTab == "tireAndRim" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Tire and Rim"
                            href="#tireAndRim"
                            // onClick={handleToggle}
                            onClick={() => handleTabClick("tireAndRim")}
                          >
                            Tire & Rim
                          </a>
                          <a
                            // className={keyFobExchangeClass}
                            className={
                              activeTab == "keyFobExchange" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Key Fob Exchange"
                            href="#keyfobexchange"
                            // onClick={handleToggle}
                            onClick={() => handleTabClick("keyFobExchange")}
                          >
                            Key Fob Replacement
                          </a>
                          <a
                            // className={ceramxClass}
                            className={activeTab == "ceramx" ? "active" : ""}
                            data-bs-toggle="tab"
                            name="Ceramx"
                            href="#ceramx"
                            onClick={() => handleTabClick("ceramx")}
                          >
                            Brakes & Rotors
                          </a>

                          <a
                            // className={antiTheftClass}
                            className={
                              activeTab == "antiTheftEtch" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Anti-TheftEtch"
                            href="#antitheftetch"
                            onClick={() => handleTabClick("antiTheftEtch")}
                          >
                            Anti-Theft
                          </a>
                          <a
                            // className={antiTheftClass}
                            className={
                              activeTab == "creamicexterior" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="creamicexterior"
                            href="#creamicexterior"
                            onClick={() => handleTabClick("creamicexterior")}
                          >
                            Ceramic Exterior
                          </a>

                          <a
                            // className={antiTheftClass}
                            className={activeTab == "antiTheft" ? "active" : ""}
                            data-bs-toggle="tab"
                            name="Anti-Theft"
                            href="#antitheft"
                            onClick={() => handleTabClick("antiTheft")}
                          >
                            Appearance Trust
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* User Management */}
                      <Accordion.Item eventKey="4" className="d-none">
                        <Accordion.Header> User Management</Accordion.Header>
                        <Accordion.Body>
                          <a
                            //   className={pendingRequests}
                            className={
                              activeTab === "pendingRequests" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Approval Request Lists"
                            href="#approvalrequestlist"
                            onClick={() => handleTabClick("pendingRequests")}
                          >
                            Requests
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header> Claim Requests</Accordion.Header>
                        <Accordion.Body>
                          <a
                            //   className={pendingRequests}
                            className={
                              activeTab === "claimRequests" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Approval Request Lists"
                            href="#approvalrequestlist"
                            onClick={() => handleTabClick("claimRequests")}
                          >
                            All Claims
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )}

                {/* Staff */}
                {role === "Technician" ||
                role === "Sales Advisor" ||
                role === "Service Advisor" ? (
                  <>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>
                          {isLoadingProfile ? (
                            <Loader
                              isSmallView={true}
                              cssClass={"text-black"}
                            />
                          ) : (
                            <div className="" style={{ fontSize: "16px" }}>
                              {serviceManagerProfile
                                ? `Your Trust Rep is ${`${capitalizeFirstLetter(
                                    serviceManagerProfile?.firstName
                                  )} ${capitalizeFirstLetter(
                                    serviceManagerProfile?.lastName
                                  )}`}`
                                : "Your Trust Rep is Scott Dyer"}
                              <span className="" style={{ color: "#a8b1ad" }}>
                                {` ${
                                  serviceManagerProfile
                                    ? formatPhoneNumber(
                                        serviceManagerProfile.phone
                                      )
                                    : "587-501-0370"
                                }`}
                              </span>
                            </div>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <a
                            className={
                              activeTab === "ServiceReq" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="ServiceReq"
                            href="#servicereq"
                            onClick={() => setModalShow(true)}
                          >
                            Service Request
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>Registrants</Accordion.Header>
                        <Accordion.Body>
                          <a
                            // ref={showAllRegistrantsRef}
                            className={
                              activeTab === "showAllRegistrants" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Show All Registrants"
                            href="#ltn_tab_1_11"
                            onClick={() => handleTabClick("showAllRegistrants")}
                          >
                            Show All Registrants
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header> Product Resources</Accordion.Header>
                        <Accordion.Body>
                          <a
                            // className={ceramixAllWindowClass}
                            className={
                              activeTab === "ceramixAllWindow" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Ceramx All-Window Warranty Program"
                            href="#ceramxallwindow"
                            onClick={() => handleTabClick("ceramxallwindow")}
                          >
                            Oil Change Program
                          </a>
                          <a
                            // className={smartCareClass}
                            className={
                              activeTab === "smartCare" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Smart Care"
                            href="#smartcare"
                            onClick={() => handleTabClick("smartCare")}
                          >
                            Trust Care
                          </a>
                          <a
                            // className={catConverterClass}
                            className={
                              activeTab === "catConverter" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Cat. Converter Anti-Theft Plus"
                            href="#catconverter"
                            onClick={() => handleTabClick("catConverter")}
                          >
                            Trust Shield
                          </a>
                          <a
                            // className={tireAndRimClass}
                            className={
                              activeTab === "tireAndRim" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Tire and Rim"
                            href="#tireAndRim"
                            // onClick={handleToggle}
                            onClick={() => handleTabClick("tireAndRim")}
                          >
                            Tire & Rim
                          </a>
                          <a
                            // className={keyFobExchangeClass}
                            className={
                              activeTab === "keyFobExchange" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Key Fob Exchange"
                            href="#keyfobexchange"
                            // onClick={handleToggle}
                            onClick={() => handleTabClick("keyFobExchange")}
                          >
                            Key Fob Replacement
                          </a>
                          <a
                            // className={ceramxClass}
                            className={activeTab === "ceramx" ? "active" : ""}
                            data-bs-toggle="tab"
                            name="Ceramx"
                            href="#ceramx"
                            onClick={() => handleTabClick("ceramx")}
                          >
                            Brakes & Rotors
                          </a>
                          <a
                            // className={antiTheftClass}
                            className={
                              activeTab === "antiTheftEtch" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Anti-TheftEtch"
                            href="#antitheftetch"
                            onClick={() => handleTabClick("antiTheftEtch")}
                          >
                            Anti-Theft
                          </a>
                          <a
                            // className={antiTheftClass}
                            className={
                              activeTab === "creamicexterior" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="creamicexterior"
                            href="#creamicexterior"
                            onClick={() => handleTabClick("creamicexterior")}
                          >
                            Ceramic Exterior
                          </a>
                          <a
                            // className={antiTheftClass}
                            className={
                              activeTab === "antiTheft" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Anti-Theft"
                            href="#antitheft"
                            onClick={() => handleTabClick("antiTheft")}
                          >
                            Appearance Trust
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header> Claim Requests</Accordion.Header>
                        <Accordion.Body>
                          <a
                            //   className={pendingRequests}
                            className={
                              activeTab === "claimRequests" ? "active" : ""
                            }
                            data-bs-toggle="tab"
                            name="Approval Request Lists"
                            href="#approvalrequestlist"
                            onClick={() => handleTabClick("claimRequests")}
                          >
                            All Claims
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                ) : null}
                <a onClick={handleLogout} className="Logout-a" href="/">
                  Logout <i className="fas fa-sign-out-alt" />
                </a>
              </div>
            </div>
          </div>
          <div className="right-content-dashboard w-sm-100 ">
            <div className="dashboard-text-header ">
              <div className=" d-md-none d-flex align-items-center justify-content-between">
                <Link to="/">
                  <img
                    className="logo-dash"
                    src="../../../assets/img/logowhite.svg"
                    alt="Logo"
                  />
                </Link>
                <Icon
                  icon="entypo:menu"
                  color="white"
                  width="48"
                  height="48"
                  className="d-md-none"
                  onClick={() => setShowMenu(!showMenu)}
                />
              </div>
            </div>
            {/* <p
              className="bg-1 white letter-spacing"
              style={{ fontWeight: "bolder" }}
            >
              Our Presentation Software brings numerous advantages:
            </p> */}

            {/* {handleTabClick(activeTab)} */}
            {activeTab === "dashboardDefault" ? (
              <DashboardDefault />
            ) : activeTab === "Dealers" ? (
              <Dealer />
            ) : activeTab === "CreateDealer" ? (
              <CreateDealer setActiveTab={setActiveTab} />
            ) : activeTab === "ListDealer" ? (
              <ListDealer />
            ) : activeTab === "FinanceManager" ? (
              <>
                <FinanceManager />
              </>
            ) : activeTab === "addservices" ? (
              <AddServices />
            ) : activeTab === "addRegistrant" ? (
              <AddRegistrants
                dealerObj={dealerObj}
                managerObj={managerObj}
                role={role}
                setRegistrants={setRegistrants}
                setActiveTab={setActiveTab}
              />
            ) : activeTab === "showAllRegistrants" ? (
              <ShowAllReg
                dealerObj={dealerObj}
                managerObj={managerObj}
                setShowEditRegistrants={setShowEditRegistrants}
                showEditRegistrants={showEditRegistrants}
                setSearchFieldOnShowReg={setSearchFieldOnShowReg}
                searchFieldOnShowReg={searchFieldOnShowReg}
                registrants={registrants}
                vehical={vehical}
                setVehical={setVehical}
                //
                setRegistrants={setRegistrants}
                setActiveTab={setActiveTab}
              />
            ) : activeTab === "ceramxCarfax" ? (
              <CeramxCarfax />
            ) : activeTab === "pendingRequests" ? (
              <Request />
            ) : activeTab === "registrationReport" ? (
              <RegistrationReports role={role} />
            ) : activeTab === "remittanceReport" ? (
              <RemittanceReport
                role={role}
                setActiveTab={setActiveTab}
                managerObj={managerObj}
              />
            ) : activeTab === "orderReport" ? (
              <OrderReports role={role} />
            ) : activeTab === "requestDealer" ? (
              // <RequestDealer />
              <></>
            ) : activeTab === "catalogueManagement" ? (
              <CatalogueManagement />
            ) : activeTab === "requestManager" ? (
              <RequestManager />
            ) : activeTab === "smartCare" ? (
              <SmartCares />
            ) : activeTab === "ceramx" ? (
              <Ceramx />
            ) : activeTab === "ceramxallwindow" ? (
              <CeramxAllWindow />
            ) : activeTab === "tireAndRim" ? (
              <TireAnDrim />
            ) : activeTab === "keyFobExchange" ? (
              <KeyFobexChange />
            ) : activeTab === "antiTheft" ? (
              <AntiTheft />
            ) : activeTab === "catConverter" ? (
              <CatConverter />
            ) : activeTab === "claimRequests" ? (
              <ClaimReq />
            ) : activeTab === "antiTheftEtch" ? (
              <AntiTheftreal />
            ) : activeTab === "creamicexterior" ? (
              <Ceramicexterior />
            ) : activeTab === "CreateStaff" ? (
              <CreateStaff setActiveTab={setActiveTab} />
            ) : activeTab === "ListStaff" ? (
              <ListStaff setActiveTab={setActiveTab} />
            ) : activeTab === "CreateManager" ? (
              <CreateManager setActiveTab={setActiveTab} />
            ) : activeTab === "ListManager" ? (
              <ListManager setActiveTab={setActiveTab} />
            ) : activeTab === "accDetails" ? (
              <AccountDetails
                role={role}
                inputData={inputData}
                setInputData={setInputData}
              />
            ) : activeTab === "changePassword" ? (
              <AccountDetails
                role={role}
                isForDetails={false}
                inputData={inputData}
                setInputData={setInputData}
              />
            ) : activeTab === "ManageraccDetails" ? (
              <ManagerAccountDetails
                managerInput={managerInput}
                setManagerInput={setManagerInput}
              />
            ) : activeTab === "ManagerchangePassword" ? (
              <ManagerAccountDetails
                managerInput={managerInput}
                setManagerInput={setManagerInput}
              />
            ) : activeTab === "serviceManagement" ? (
              <ServiceManagement />
            ) : activeTab === "requestMaterial" ? (
              <RequestMaterial />
            ) : activeTab === "ordermanagement" ? (
              <OrderManagement />
            ) : activeTab === "CreateAdmin" ? (
              <CreateAdmin setActiveTab={setActiveTab} />
            ) : activeTab === "listAdmin" ? (
              <ListAdmin setActiveTab={setActiveTab} />
            ) : activeTab === "CreateServiceManager" ? (
              <CreateServiceManager setActiveTab={setActiveTab} />
            ) : activeTab === "ListServiceManager" ? (
              <ListServiceManager setActiveTab={setActiveTab} />
            ) : activeTab === "registrationLogs" ? (
              <ReportLog />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {modalShow && (
        <ServiceRequestModal
          modalShow={modalShow}
          handleClose={() => {
            setModalShow(false);
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;
