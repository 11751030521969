import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import CustomButton from "../../common/Button/CustomButton";
import Loader from "../../common/Loader/Loader";
import { capitalizeAllFirstLetters } from "../../../utils/utils";
import CreateServiceManager from "./createServiceManager";
import useServiceManager from "../../../Hooks/useServiceManager";
import ConfirmationModal from "../../common/Modal/ConfirmationModal";
import { apiDeleteRequest } from "../../../helpers/Requests";
import { URL_ADMIN_SERVICE_MANAGER } from "../../../service/URL";
import { toast } from "react-toastify";
import "./serviceManager.css";

const ListServiceManager = ({ setActiveTab }) => {
  const [selectedServiceManager, setSelectedServiceManager] = useState(null);
  const [isConfirmDeleteServiceManager, setIsConfirmDeleteServiceManager] =
    useState(null);
  const [isDeleteLoader, setIsDeleteLoader] = useState(null);

  const {
    isServiceManagerListLoader,
    serviceManagersData,
    handleDataUpdateById,
    handleRemoveById,
  } = useServiceManager();

  const handleDeleteServiceManager = async (id) => {
    try {
      setIsDeleteLoader(id);
      const response = await apiDeleteRequest(URL_ADMIN_SERVICE_MANAGER, {
        service_manager_id: id,
      });
      if (response.data?.message) {
        handleRemoveById(id);
        toast.success("ET Manager Deleted Successfully.");
      }
      setIsDeleteLoader(null);
    } catch (err) {
      setIsDeleteLoader(null);
      toast.error(err ?? "Something Went Wrong");
    }
  };

  return (
    <>
      <div className="checkoverflow " style={{ paddingRight: "20px" }}>
        <div className="row mb-50 mt-3">
          <h3 className="pb-3">ET Managers List</h3>
          <div className="mobileTable tableListServiceManager pt-3">
            <Table striped hover className="overflow-table">
              <thead>
                <tr style={{ backgroundColor: "#4A6543" }}>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Notes</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {isServiceManagerListLoader ? (
                  <Loader
                    style={{ width: "35px", height: "35px" }}
                    isSmallView={true}
                  />
                ) : serviceManagersData.length > 0 ? (
                  serviceManagersData.map((item, index) => (
                    <tr key={index}>
                      <td className="w-10">
                        {capitalizeAllFirstLetters(item?.firstName)}
                      </td>
                      <td className="w-10">
                        {capitalizeAllFirstLetters(item?.lastName)}
                      </td>
                      <td className="w-10">{item?.email}</td>
                      <td className="w-10">{item?.phone}</td>
                      <td className="w-20">{item?.notes}</td>
                      <td className="w-10">
                        {item?.isActive === true ? "Active" : "Inactive"}
                      </td>
                      <td className="w-30 text-center">
                        <div className="d-flex justify-content-center align-items-center space-x-2">
                          <CustomButton
                            isDisabled={isDeleteLoader !== null}
                            label="View"
                            type="button"
                            cssClass="btn-effect-1 text-uppercase"
                            clickHandler={() => {
                              setSelectedServiceManager(item);
                            }}
                          />
                          <CustomButton
                            isDisabled={isDeleteLoader !== null}
                            showLoading={isDeleteLoader === item._id}
                            cssClass={"btn-effect-5 btn1 btn-fit-content"}
                            variant={"danger"}
                            label={"Delete"}
                            clickHandler={() => {
                              setIsConfirmDeleteServiceManager(item._id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                      >
                        <span className="error-text">No data found!</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Modal
        show={selectedServiceManager !== null}
        onHide={() => {
          setSelectedServiceManager(null);
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Update ET Manager Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <CreateServiceManager
            setActiveTab={setActiveTab}
            handleClose={() => {
              setSelectedServiceManager(null);
            }}
            selectedServiceManager={selectedServiceManager}
            handleDataUpdateById={handleDataUpdateById}
          />
        </Modal.Body>
      </Modal>
      {/* Confirmation Modal */}
      {isConfirmDeleteServiceManager !== null && (
        <ConfirmationModal
          isOpen={isConfirmDeleteServiceManager !== null}
          confirmationStatus={(e) => {
            if (e === true) {
              handleDeleteServiceManager(isConfirmDeleteServiceManager);
            }
            setIsConfirmDeleteServiceManager(null);
          }}
          title={"Delete ET Manager Confirmation"}
          heading={"Are you sure to delete this ET Manager?"}
        />
      )}
    </>
  );
};

export default ListServiceManager;
