import React, { useContext, useState } from "react";
import ClaimButton from "./claimActionButtons/ClaimButton";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  classNames,
  convertDateByTimezone,
  formatDateToDDMMMYYYY,
} from "../../../utils/utils";
import moment from "moment";
import AuthContext from "../../../helpers/authContext";

const ClaimReqItem = ({
  group,
  role,
  handleUpdateReportClaimStatus,
  handleDeleteReportClaim,
  reportDeleteLoader,
  getData,
  resultData,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { timezone } = useContext(AuthContext);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const renderClaimDetail = (claim, index) => (
    <tr id={`collapse-${claim.agreementNumber}`} key={claim._id}>
      <td
        style={{ width: "5%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      ></td>
      <td
        style={{ width: "12%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      >
        <div className="d-block">
          {claim.agreementNumber}
          <br />
          {formatDateToDDMMMYYYY(claim.createdAt)}
        </div>
      </td>
      <td
        style={{ width: "12%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      >
        <div className="d-block">
          {claim.name}
          <br />
          {claim.email}
        </div>
      </td>
      <td
        style={{ width: "10%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      >
        {claim.odometerReading ?? "-"}
      </td>
      <td
        style={{ width: "15%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      >
        {claim?.categories?.length > 0
          ? capitalizeAllFirstLetters(
              claim.categories.join(", ").replaceAll("_", " ")
            )
          : "-"}
      </td>
      <td
        style={{ width: "10%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      >
        {capitalizeFirstLetter(claim.status)}
      </td>

      <td
        style={{ width: "10%" }}
        className={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
      >
        {formatDateToDDMMMYYYY(
          convertDateByTimezone(claim.createdAt, timezone)
        )}
      </td>
      <ClaimButton
        cssClass={classNames(
          "px-3 py-2",
          index === 1 && "pt-4",
          group.repairs.length - 1 === index && "pb-4"
        )}
        role={role}
        claimItem={claim}
        id={claim._id}
        resultData={resultData}
        getData={getData}
        handleUpdateReportClaimStatus={handleUpdateReportClaimStatus}
        handleDeleteReportClaim={handleDeleteReportClaim}
        deleteLoaderId={reportDeleteLoader}
      />
    </tr>
  );

  return (
    <>
      {/* Render the first claim */}
      {group.repairs.length > 0 && (
        <tr key={group.repairs[0]._id}>
          <td
            style={{ width: "5%", verticalAlign: "middle" }}
            className="text-center"
          >
            <span
              style={{
                padding: "15px 15px 15px 15px",
                cursor: group.repairs.length > 1 ? "pointer" : "auto",
              }}
              role="button"
              className="pt-4"
              tabIndex={0}
              onClick={() => group.repairs.length > 1 && toggle()}
              aria-expanded={isOpen}
              aria-controls={`example-collapse-table-${group.repairs[0]._id}`}
            >
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </td>
          <td style={{ width: "12%" }}>
            <div className="d-block">
              {group.agreementNumber}
              <br />
              {moment(group.repairs[0].createdAt).format("DD-MM-YYYY")}
            </div>
          </td>
          <td style={{ width: "12%" }}>
            <div className="d-block">
              {group.repairs[0].name}
              <br />
              {group.repairs[0].email}
            </div>
          </td>
          <td style={{ width: "10%" }}>
            {group.repairs[0].odometerReading ?? "-"}
          </td>
          <td style={{ width: "15%" }}>
            {group.repairs[0]?.categories?.length > 0
              ? capitalizeAllFirstLetters(
                  group.repairs[0].categories.join(", ").replaceAll("_", " ")
                )
              : "-"}
          </td>
          <td style={{ width: "10%" }}>
            {capitalizeFirstLetter(group.repairs[0].status)}
          </td>
          <td style={{ width: "10%" }}>
            {formatDateToDDMMMYYYY(
              convertDateByTimezone(group.repairs[0].createdAt, timezone)
            )}
          </td>
          <ClaimButton
            role={role}
            claimItem={group.repairs[0]}
            id={group.repairs[0]._id}
            resultData={resultData}
            getData={getData}
            handleUpdateReportClaimStatus={handleUpdateReportClaimStatus}
            handleDeleteReportClaim={handleDeleteReportClaim}
            deleteLoaderId={reportDeleteLoader}
          />
        </tr>
      )}

      {group.repairs.length > 1 && isOpen && (
        <>
          <tr>
            <td colSpan={11} className="previous-claims-text">
              <div className="py-3 px-4" style={{ fontWeight: "bold" }}>
                Previous Claims History
              </div>
            </td>
          </tr>
          <Collapse in={isOpen}>
            <tr id={`example-collapse-table-${group.repairs[0]._id}`}>
              <td colSpan="11" className="collapse-tr">
                {group.repairs
                  .slice(1)
                  .map((repair, index) => renderClaimDetail(repair, index + 1))}
              </td>
            </tr>
          </Collapse>
        </>
      )}
    </>
  );
};

export default ClaimReqItem;
