import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./ApprovalButton.css";
import secureLocalStorage from "react-secure-storage";
const ApprovalButton = ({ id, role, isVerified, onVerify }) => {
  // console.log(id, role, isVerified);
  var apiUrl = process.env.REACT_APP_API_URL;
  const staffrole = useRef();
  var token = JSON.parse(secureLocalStorage.getItem("Token"));
  var lowerCase = role.toLowerCase();

  const HandleVerify = async () => {
    if (
      lowerCase === "service advisor" ||
      lowerCase === "sales advisor" ||
      lowerCase === "technician"
    ) {
      staffrole.current = "staff";
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    console.log(`${apiUrl}/admin/verify-${lowerCase}/${id}`, "1");
    console.log(`${apiUrl}/admin/verify-${staffrole.current}/${id}`, "2");

    const apiUrlForVerification = staffrole.current
      ? `${apiUrl}/admin/verify-${staffrole.current}/${id}`
      : `${apiUrl}/admin/verify-${lowerCase}/${id}`;

    try {
      const response = await axios.post(apiUrlForVerification, {}, { headers });
      console.log(response.data, "data");

      if (onVerify) {
        onVerify();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const statusStyle = {
    backgroundColor: isVerified ? "green" : "red",
  };
  return (
    <div>
      <button
        className="approveButton"
        onClick={HandleVerify}
        style={statusStyle}
      >
        {isVerified ? "Authorized" : "UnAuthorized"}
      </button>
    </div>
  );
};

export default ApprovalButton;
