import React from "react";
import Cover from "./components/cover";
import TermCondition from "./components/termCondition";
import CustomerInformation from "./components/customerInformation";
import KeyFob from "./components/keyFob";
import OilChange from "./components/oilChange";
import TrustCare from "./components/trustCare";
import TrustSheild from "./components/trustSheild";
import BrakesRotors from "./components/brakesRotors";
import Tiny from "./components/tiny";
import AntiTheft from "./components/antiTheft";
// import "./pdfRegistrant.css";

const PrintRegistration = ({ editReport, data, downloadRef }) => {
  // console.log("data", data);
  // console.log("editReport", editReport);

  // const printData = {
  //   services: data,
  //   customer: editReport?.customer || null,
  //   vehicle: editReport?.customer?.vehical || null,
  //   dealer: editReport?.dealer || null,
  //   manager: editReport?.manager || null,
  //   agreementNumber: editReport?.agreementNumber,
  // };

  // console.log("printData", printData);

  const sequences = [
    { seq: 1, value: "Tire & Rim", element: <Tiny /> },
    { seq: 2, value: "Key Fob", element: <KeyFob /> },
    { seq: 3, value: "Anti Theft", element: <AntiTheft /> },
    { seq: 4, value: "Trust Shield", element: <TrustSheild /> },
    { seq: 5, value: "Trust Care", element: <TrustCare /> },
    { seq: 6, value: "Brakes & Rotors", element: <BrakesRotors /> },
    { seq: 7, value: "Oil Change Program", element: <OilChange /> },
    // { seq: 8, value: "Misc" , element: <KeyFob /> },
  ];

  const orderedComponents = sequences
    .filter((seqItem) =>
      data.some((dataItem) => dataItem.categoryName === seqItem.value)
    )
    .map((seqItem) => seqItem.element);

  return (
    <div className="printpdf-sec" id="printpdf-registrant" ref={downloadRef}>
      <div className="c146 doc-content container">
        <Cover editReport={editReport} />
        <CustomerInformation data={data} editReport={editReport} />

        {orderedComponents}

        <TermCondition />
      </div>
    </div>
  );
};

export default PrintRegistration;
