import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import "./manager.css";
import CustomButton from "../../common/Button/CustomButton";
import axios from "axios";
import parseError from "../../../utils/ErrorParse";
const AccountDetails = (props) => {
  const { managerInput, setManagerInput } = props;
  const [passwordVisibility1, setPasswordVisibility1] = useState(false);

  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));

  // useEffect(() => {
  //     cloudinaryRef.current = window.cloudinary;
  //     if (typeof cloudinaryRef.current !== "undefined") {
  //         widgetRef.current = cloudinaryRef.current.createUploadWidget(
  //             {
  //                 cloudName: "dvhp99frp",
  //                 uploadPreset: "oz0okrve",
  //             },
  //             function (error, result) {
  //                 // console.log(result);
  //                 if (result.event === "success") {
  //                     setInputData((prev) => ({
  //                         ...prev,
  //                         profileImage: result.info.secure_url,
  //                     }));
  //                 }
  //             }
  //         );
  //     }
  // }, []);

  const [dealerImageToShow, setDealerImageToShow] = useState("");
  const [dealerUpdateCounter, setDealerUpdateCounter] = useState(0);
  // const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const ValidationForm = () => {
    let errorObj = {
      firstName: false,
      lastName: false,
      email: false,
    };

    if (managerInput.firstName == "" || managerInput.firstName == undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        firstName: true,
      }));
      errorObj.firstName = true;
    } else if (
      managerInput.lastName == "" ||
      managerInput.lastName == undefined
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        lastname: true,
      }));
      errorObj.lastName = true;
    } else if (managerInput.email == "" || managerInput.email == undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        email: true,
      }));
      errorObj.email = true;
    }

    return errorObj;
  };

  useEffect(() => {
    fetch(`${apiUrl}/manager/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setManagerInput({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  }, [accessToken]);

  const submitDealerBasicInfo = (event) => {
    event.preventDefault();

    const valid = ValidationForm();

    if (valid.firstName || valid.lastName || valid.email) {
      console.log("Form has validation errors");
    } else {
      fetch(`${apiUrl}/manager/edit-account-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },

        body: JSON.stringify({
          firstName: managerInput.firstName,
          lastName: managerInput.lastName,
          email: managerInput.email,
        }),
      })
        .then((response) => response.json())

        .then((data) => {
          console.log("Success:", data);
          if (!data.message) {
            toast.success("Changes Saved Sucessfully");
          }
        })

        .catch((error) => {
          toast.error("Failed to save changes. Please try again.");
          console.error("Error:", error);
        })
        .finally(() => {
          setManagerInput((prev) => ({
            ...prev,
            password: "",
            newPassword: "",
          }));
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setManagerInput((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const submitChangePassword = async (event) => {
    try {
      event.preventDefault();
      if (
        managerInput.password &&
        managerInput.password !== managerInput.newPassword
      ) {
        toast.error("Passwords do not match");
        return;
      }
      setIsLoading(true);

      const updateManagerPasswordUrl = `${apiUrl}/manager/change-password`;
      const response = await axios.post(
        updateManagerPasswordUrl,
        { password: managerInput.password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        toast.success(
          response.data?.message || "Password Updated Successfully!"
        );

        setManagerInput((prev) => ({
          ...prev,
          password: "",
          newPassword: "",
        }));
      }

      setIsLoading(false);
    } catch (err) {
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
      setIsLoading(false);
      console.log("err", err);
    }
  };

  return (
    <div>
      {/* <div className="tab-content">
      </div> */}

      {/* <div className="tab-pane" id="dealer"> */}
      <div className="ltn__myaccount-tab-content-inner">
        <div className="ltn__form-box">
          <div className="row px-4 pt-5 mb-50">
            <h3 className="pb-4">Basic Information</h3>
            <form action="submit" onSubmit={submitDealerBasicInfo}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accord-Header dropBtn-after">
                    ACCOUNT DETAILS
                  </Accordion.Header>
                  <Accordion.Body className="ac-Body mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          {" "}
                          First Name
                          <span className="required-class">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          value={
                            managerInput?.firstName
                              ? managerInput?.firstName
                              : ""
                          }
                          onChange={handleInputChange}
                          required
                        />

                        {validationErrors.firstName && (
                          <p className="error-message">Province is required</p>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>
                          {" "}
                          Last Name
                          <span className="required-class">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          value={
                            managerInput?.lastName ? managerInput?.lastName : ""
                          }
                          onChange={handleInputChange}
                          required
                        />
                        {validationErrors.lastName && (
                          <p className="error-message">Province is required</p>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>
                          Email Address:
                          <span className="required-class">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={managerInput.email ? managerInput.email : ""}
                          onChange={handleInputChange}
                          required
                          //   disabled
                        />
                      </div>
                    </div>
                    <div
                      className="btn-wrapper px-2 py-4"
                      style={{ marginTop: "0px" }}
                    >
                      <button
                        style={{ backgroundColor: "#000000" }}
                        type="submit"
                        className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                      >
                        save Changes
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </form>
            <form action="submit" onSubmit={submitChangePassword}>
              <Accordion defaultActiveKey="2">
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="accord-Header dropBtn-after">
                    CHANGE PASSWORD
                  </Accordion.Header>
                  <Accordion.Body className="ac-Body">
                    <div className="row mt-3">
                      <div className="mainpass col-md-4">
                        <label>Password</label>
                        <input
                          type={passwordVisibility1 ? "text" : "password"}
                          name="password"
                          value={managerInput?.password}
                          onChange={(e) => {
                            setManagerInput((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                          placeholder="Enter your new password"
                          style={{ background: "white" }}
                          required
                        />
                        {validationErrors?.password && (
                          <p className="error-message mt-0">
                            Province is required
                          </p>
                        )}

                        <i
                          className={
                            passwordVisibility1
                              ? "fas fa-eye eye-icon eyeiconn"
                              : "fas fa-eye-slash eye-icon eyeiconn"
                          }
                          style={{
                            color: passwordVisibility1 ? "black" : "black",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setPasswordVisibility1(!passwordVisibility1)
                          }
                        ></i>
                      </div>
                      <div className="mainpass col-md-4">
                        <label>Re-Enter Password</label>
                        <input
                          type={passwordVisibility1 ? "text" : "password"}
                          name="newPassword"
                          value={managerInput?.newPassword}
                          onChange={(e) => {
                            setManagerInput((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                          placeholder="Enter your new password"
                          style={{ background: "white" }}
                          required
                        />
                        {managerInput?.password !==
                          managerInput?.newPassword && (
                          <p
                            className="error-message mt-0 "
                            style={{ color: "red", marginTop: "-20px" }}
                          >
                            Passwords do not match
                          </p>
                        )}
                        <i
                          className={
                            passwordVisibility1
                              ? "fas fa-eye eye-icon eyeiconn"
                              : "fas fa-eye-slash eye-icon eyeiconn"
                          }
                          style={{
                            color: passwordVisibility1 ? "black" : "black",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setPasswordVisibility1(!passwordVisibility1)
                          }
                        ></i>
                      </div>
                    </div>
                    <div
                      className="btn-wrapper px-2 py-2"
                      style={{ marginTop: "0px" }}
                    >
                      <CustomButton
                        style={{ backgroundColor: "#000000" }}
                        label="Update Password"
                        type="submit"
                        cssClass="btn-effect-1 text-uppercase btn1"
                        showLoading={isLoading}
                        isDisabled={isLoading}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
