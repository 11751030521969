import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import {
  apiFormDataRequest,
  apiGetRequest,
} from "../../../../helpers/Requests";
import CustomButton from "../../../common/Button/CustomButton";
import { classNames } from "../../../../utils/utils";
import { BASE_URL } from "../../../../service/URL";
import CustomSelect from "../../../common/Select/CustomSelect";
import "./CreateClaim.css";
import {
  CLAIM_CATEGORY_TAGS,
  EMAIL_REGEX_EXP,
} from "../../../../utils/constant";

const CreateClaim = ({ isShow, handleClose }) => {
  var apiUrl = process.env.REACT_APP_API_URL;

  const [repairData, setRepairData] = useState({
    name: "",
    email: "",
    phone: "",
    agreement: "",
    image: "",
    reportDescription: "",
    odometerReading: 0,
    categoryTag: null,
  });

  const [submitLoader, setSubmitLoader] = useState(false);

  const [isFetchReports, setIsFetchReports] = useState(false);
  const [reportOptions, setReportOptions] = useState([]);

  useEffect(() => {
    getReportOptionsData();
  }, []);

  const getReportOptionsData = async () => {
    try {
      setIsFetchReports(true);
      const response = await apiGetRequest(`${BASE_URL}/dealer/report-options`);
      if (response) {
        setReportOptions(response?.data);
      }
      setIsFetchReports(false);
    } catch (err) {
      setIsFetchReports(false);
      console.log("err", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setRepairData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  useEffect(() => {
    if (repairData.image) {
      const fileNameLabel = document.querySelector(".File-label");
      if (fileNameLabel) {
        fileNameLabel.textContent = `Selected File: ${repairData.image.name}`;
      }
    }
  }, [repairData.image]);

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    agreement: "",
    image: "",
    reportDescription: "",
    odometerReading: "",
  });

  const validationForm = () => {
    let errorObj = {
      name: false,
      email: false,
      phone: false,
      agreement: false,
      image: false,
      reportDescription: false,
      odometerReading: false,
    };

    if (repairData.name === "" || repairData === undefined) {
      setFormErrors((prevData) => ({
        ...prevData,
        name: true,
      }));

      errorObj.name = true;
    }
    if (repairData.email === "" || !EMAIL_REGEX_EXP.test(repairData.email)) {
      setFormErrors((prevData) => ({
        ...prevData,
        email: true,
      }));
      errorObj.email = true;
    }
    if (repairData.phone === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        phone: true,
      }));
      errorObj.phone = true;
    }
    if (repairData.agreement === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        agreement: true,
      }));
      errorObj.agreement = true;
    }
    if (repairData.image === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        image: true,
      }));
      errorObj.image = true;
    }
    if (repairData.reportDescription === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        reportDescription: true,
      }));
      errorObj.reportDescription = true;
    }
    if (
      repairData.odometerReading === "" ||
      repairData.odometerReading === undefined ||
      repairData.odometerReading === 0
    ) {
      setFormErrors((prevData) => ({
        ...prevData,
        odometerReading: true,
      }));
      errorObj.odometerReading = true;
    }

    return errorObj;
  };

  const handleSubmit = async (e) => {
    if (submitLoader) return;
    const valid = validationForm();

    if (
      valid.name ||
      valid.email ||
      valid.phone ||
      valid.image ||
      valid.agreement ||
      valid.reportDescription ||
      valid.odometerReading
    ) {
      console.log("test");
    } else {
      e.preventDefault();
      try {
        let formData = new FormData();
        formData.append("name", repairData.name);
        formData.append("email", repairData.email);
        formData.append("phoneNumber", repairData.phone);
        formData.append("agreementNumber", repairData.agreement);
        formData.append("image", repairData.image);
        formData.append("reportDescription", repairData.reportDescription);
        formData.append("odometerReading", repairData.odometerReading);

        if (repairData?.categoryTag && repairData?.categoryTag?.length > 0) {
          repairData?.categoryTag.forEach((cat, index) => {
            formData.append(`categoryTag[]`, cat.value);
          });
        }

        setSubmitLoader(true);

        const response = await apiFormDataRequest(
          `${apiUrl}/dealer/repair-claim`,
          formData,
          "POST"
        );
        if (response) {
          toast.success("Claim Created Successfully!");
          handleClose();
        }
        setSubmitLoader(false);
      } catch (error) {
        setSubmitLoader(false);
        console.log("error", error);
        toast.error(error ?? "Something went wrong!");
      }
    }
  };

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  // console.log(
  //   "EMAIL_REGEX_EXP.test(repairData.email)",
  //   EMAIL_REGEX_EXP.test(repairData.email)
  // );

  return (
    <div className="createClaimModal">
      <Modal className="createClaimModal" show={isShow} onHide={handleClose}>
        <Modal.Header
          style={{
            borderBottom:
              "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
          }}
          closeButton
        >
          <Modal.Title>Add a new claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-overflow custom-scroller">
            <div className="row">
              <div class="col-6 form-field">
                <label class="label" for="msg-name">
                  Full Name <span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  class="input jsvalidate"
                  id="msg-name"
                  value={repairData.name}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.name && (
                  <p className="error-message">Name is required</p>
                )}
              </div>

              <div class="col-6 form-field">
                <label class="label" for="msg-email">
                  Email Address <span class="required">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="msg-email"
                  class="input jsvalidate"
                  value={repairData.email}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.email && (
                  <p className="error-message">
                    {repairData.email?.trim()?.length > 0 &&
                    !EMAIL_REGEX_EXP.test(repairData.email)
                      ? "Invalid Email"
                      : "Email is required"}
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div class="form-field col-6">
                <label class="label" for="msg-phone">
                  Phone Number <span class="required">*</span>
                </label>
                <input
                  maxLength={13}
                  type="tel"
                  name="phone"
                  id="msg-phone"
                  className={`text no-spin-buttons ${
                    !isValidPhoneNumber ? "invalid" : ""
                  }`}
                  value={repairData.phone}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const formattedValue = value.startsWith("+1")
                      ? value
                      : `+1 ${value}`;
                    if (name === "phone") {
                      const phonePattern =
                        /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                      const isValidPhoneNumber =
                        phonePattern.test(formattedValue);
                      setIsValidPhoneNumber(isValidPhoneNumber);
                    }
                    handleInputChange({
                      target: {
                        name,
                        value: formattedValue,
                        files: null,
                      },
                    });
                    // setRepairData((prev) => ({
                    //   ...prev,
                    //   [name]: formattedValue,
                    // }));
                  }}
                  required
                />
                {formErrors.phone && (
                  <p className="error-message">PhoneNumber is required</p>
                )}
              </div>
              <div class="form-field col-6">
                <label class="label" for="msg-agreement">
                  Contract Number<span class="required">*</span>
                </label>
                <CustomSelect
                  error={formErrors.agreement && "Contract Number is required!"}
                  isSearch={true}
                  isDisabled={isFetchReports}
                  loading={isFetchReports}
                  placeHolder={"Select Contract Number"}
                  selectedValue={
                    repairData?.agreement
                      ? reportOptions?.find(
                          (x) => x.value === repairData?.agreement
                        )
                      : null
                  }
                  handleChange={(e) => {
                    handleInputChange({
                      target: { name: "agreement", value: e.value },
                    });
                  }}
                  options={reportOptions}
                />
              </div>
            </div>

            <div className="row">
              <div class="form-field col-6">
                <label class="label" for="msg-agreement">
                  Odometer Reading <span class="required">*</span>
                </label>
                <NumericFormat
                  allowNegative={false}
                  required={true}
                  placeholder="Odometer Raading"
                  id="numericInput"
                  min={1}
                  maxLength={"15"}
                  className={classNames("text numericInput")}
                  value={
                    repairData?.odometerReading
                      ? repairData?.odometerReading
                      : null
                  }
                  valueIsNumericString={true}
                  decimalScale={2}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;
                    handleInputChange({
                      target: { name: "odometerReading", value: value },
                    });
                  }}
                />
                {formErrors.odometerReading && (
                  <p className="error-message">Odometer Reading is required</p>
                )}
              </div>
              <div class="form-field col-6">
                <label class="label" for="msg-agreement">
                  Category Tag
                </label>
                <CustomSelect
                  isMulti={true}
                  placeHolder={"Select Category Tag!"}
                  selectedValue={repairData?.categoryTag}
                  handleChange={(e) => {
                    handleInputChange({
                      target: { name: "categoryTag", value: e },
                    });
                  }}
                  options={CLAIM_CATEGORY_TAGS}
                />
              </div>
            </div>
            <div class="form-field px-2">
              <div>
                <label class="label" for="msg-file">
                  Image/Video <span class="required">*</span>
                </label>
              </div>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="msg-file"
                  class="input jsvalidate d-none"
                  onChange={handleInputChange}
                  // onChange={(e) => convertToBase64(e)}
                  required
                />

                <label
                  class="File-label text d-flex align-items-center"
                  for="msg-file"
                >
                  Choose File
                </label>
                {formErrors.image && (
                  <p className="error-message">please Upload Image</p>
                )}
              </div>
            </div>
            <div class="form-field pb-4 px-2">
              <label class="label" for="msg-comments">
                Report Description <span class="required">*</span>
              </label>
              <textarea
                name="reportDescription"
                id="msg-comments"
                class="textarea jsvalidate"
                value={repairData.reportDescription}
                onChange={handleInputChange}
                required
              ></textarea>
              {formErrors.reportDescription && (
                <p className="pt-3 error-message">
                  {" "}
                  Report Description is required
                </p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            label="Cancel"
            clickHandler={() => {
              handleClose();
            }}
            variant="secondary"
            // cssClass="btn-effect-1 btn1"
          />
          <CustomButton
            isDisabled={submitLoader || isFetchReports}
            showLoading={submitLoader}
            label="Submit"
            clickHandler={handleSubmit}
            cssClass="btn-effect-1 btn1"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateClaim;
