import React from "react";
import Image1 from "../../../../../asset/image1.svg";
import { capitalizeAllFirstLetters } from "../../../../../utils/utils";

const Cover = ({ editReport }) => {
  return (
    <>
      <p className="c21">
        <span
          style={{
            display: "inline-block",

            width: "200.00px",
            height: "83.53px",
          }}
        >
          <img
            alt=""
            src={Image1}
            style={{
              width: "200.00px",
              height: "83.53px",
            }}
            title=""
          />
        </span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c21">
        <span className="c28">
          {capitalizeAllFirstLetters(
            `${editReport.customer?.firstName} ${editReport.customer?.lastName}`
          )}
        </span>
      </p>
      <p className="c21">
        <span className="c28">{editReport.customer?.address}</span>
      </p>
      <p className="c21">
        <span className="c28">{`${editReport.customer?.city}, ${editReport.customer?.state}`}</span>
      </p>
      <p className="c21">
        <span className="c3">{editReport.customer?.postalCode}</span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c123">
        <span className="c28">
          We would like to take this opportunity to thank you for your recent
          purchase of your ExtendedTrust
        </span>
        <span className="c165">TM</span>
        <span className="c3">
          &nbsp;service contract. We recommend that you carefully review the
          contract including both the face page and the attached Terms and
          Conditions, to ensure you have a clear understanding of our services.
        </span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c80">
        <span className="c36 c59 c96">
          This service contract is not intended to be a substitute for
          insurance. Please speak to an insurance specialist to make sure you
          have appropriate insurance coverage.
        </span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c167">
        <span className="c3">
          Should you have any questions regarding your service contract, you may
          reach us by phone at 888 418 1711 or by email at
          support@extendedtrust.ca
        </span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c21">
        <span className="c28">Thank you,</span>
      </p>
      <p className="c21">
        <span className="c28">ExtendedTrust</span>
      </p>
      <p className="c1">
        <span className="c15"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      {/* <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p> */}
      {/* <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p> */}
      {/* <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p> */}
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>

      <p className="c68">
        <span className="c3">
          For your convenience, please retain the wallet card below which has
          the contact details for customer assistance. In the event you require
          services as outlined in this Contract, please present this card to
          your authorized service facility for assistance.
        </span>
      </p>

      <p className="c46">
        <span className="c3"></span>
      </p>

      <p className="c1">
        <span className="c15"></span>
      </p>

      <table
        className="c150"
        style={{
          border: "dashed gray 2px",
          width: "90%",
        }}
      >
        <tr className="c171">
          <td className="c54 umer1 gray-color" colspan="2" rowspan="2">
            <p
              className="c21"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "inline-block",

                  width: "160.00px",
                  height: "66.80px",
                }}
              >
                <img
                  alt=""
                  src={Image1}
                  style={{
                    width: "160.00px",
                    height: "66.80px",
                  }}
                  title=""
                />
              </span>
            </p>
          </td>

          <td className="c73" colspan="2" rowspan="2">
            <p className="c18 c32">
              <span className="c39">TO BOOK A SERVICE OR</span>
            </p>
          </td>
        </tr>

        <tr className="c29"></tr>
        <tr className="c170">
          <td className="c100  gray-color" colspan="2" rowspan="2">
            <p className="c21 c84">
              <span className="c98 c96">CUSTOMER SERVICE CARD</span>
            </p>
          </td>
          <td className="c73" colspan="1" rowspan="1">
            <p className="c18 c138">
              <span className="c39">FOR CUSTOMER ASSISTANCE</span>
            </p>
          </td>
        </tr>
        <tr className="c147">
          <td className="c73" colspan="1" rowspan="2">
            <p className="c18 c32">
              <span className="c39">PLEASE CALL 888 418 1711</span>
            </p>
          </td>
        </tr>
        <tr className="c136">
          <td className="c55 gray-color" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c109 gray-color" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
        </tr>
        <tr className="c104">
          <td className="c55 gray-color" colspan="1" rowspan="1">
            <p className="c21 c32">
              <span className="c3">Customer Name:</span>
            </p>
          </td>
          <td className="c109 gray-color" colspan="1" rowspan="1">
            <p className="c21">
              <span className="c3">
                {capitalizeAllFirstLetters(
                  `${editReport.customer?.firstName} ${editReport.customer?.lastName}`
                )}
              </span>
            </p>
          </td>
          <td className="c73" colspan="1" rowspan="1">
            <p className="c18 c32">
              <span className="c65">
                Approved repair work will be paid by credit card
              </span>
            </p>
          </td>
        </tr>
        <tr className="c157">
          <td className="c100 gray-color" colspan="2" rowspan="2">
            <p className="c21 c32">
              <span className="c3">
                Reference#: &nbsp;{editReport.agreementNumber}
              </span>
            </p>
          </td>
          <td className="c73" colspan="1" rowspan="1">
            <p className="c18 c32">
              <span className="c65">if service facility prefers.</span>
            </p>
          </td>
        </tr>
        <tr className="c160">
          <td className="c73" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c96 c140"></span>
            </p>
          </td>
        </tr>
        <tr className="c34">
          <td className="c55 gray-color" colspan="1" rowspan="1">
            <p className="c21 c32">
              <span className="c3">Issuing Dealer:</span>
            </p>
          </td>
          <td className="c109 gray-color" colspan="1" rowspan="1">
            <p className="c21">
              <span className="c3">
                {capitalizeAllFirstLetters(
                  `${editReport.dealer?.firstName} ${editReport.dealer?.lastName}`
                )}
              </span>
            </p>
          </td>
          <td className="c73" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
        </tr>
        <tr className="c85">
          <td className="c55 gray-color" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c109 gray-color" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c73" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
      </table>
      <p className="c1">
        <span className="c25"></span>
      </p>
      <p className="c46">
        <span className="c3"></span>
      </p>

      <p className="c5">
        <span className="c25"></span>
      </p>
    </>
  );
};

export default Cover;
